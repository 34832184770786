import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TableBody, Typography, colors } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import MuiAlert from "@material-ui/lab/Alert";
import { FaCheck, FaEdit, FaEye, FaMobileAlt, FaPhone, FaTrash, FaUserCircle, FaWindowClose } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';

function BlockForm() {
    const [leadData, setLeadData] = useState([]);


    const [pname, setPName] = useState('')
    const [Pemail, setPEmail] = useState('')
    const [pmobile, setPMobile] = useState('')
    const [alternatemob, setAlternateMob] = useState('')
    const [aadharnumber, setAadharNumber] = useState('')
    const [pannumber, setPanNumber] = useState('')
    const [peraddress, setPerAddress] = useState('')
    const [addresscom, setAddressCom] = useState('')
    const [paymentsource, setPaymentSource] = useState('')
    const [chequenumber, setChequeNumber] = useState('')
    const [transferdetails, setTransferDetails] = useState('')
    const [amountinwords, setAmountInWords] = useState('')
    const [Amount, setAmount] = useState('')
    const [tflatno, setTowardsFlatNumber] = useState('')
    const [tfloorno, setTowardsFloorNumber] = useState('')
    const [ttowerno, setTowardsTowerNumber] = useState('')
    const [salessource, setSalesSource] = useState('')
    const [signature, setSignature] = useState('')


    const [isVeiw, setIsVeiw] = useState()
    const [response, setResponse] = useState()



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };
    const [open, setOpen] = useState(false)


    const [Validated, setValidated] = useState(false);

    const [open1, setOpen1] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_blockform()
        }

        setValidated(true);
    };

    const [res, setRes] = React.useState([])

    const getLeadname = () => {
        axios.get('https://buildfastapi.emedha.in/api/v1/lead-data').then((res) => {
            setRes(res.data.data)
            // console.log("leaddata",res.data.data);
            // setIsFilter(true)
        })
    }

    React.useEffect(() => {
        getLeadname()
        get_unit()
        get_floor()
        get_floorf()
        customerObjsib()
    },[])

    const[nname, setNname] = useState('')
    const[nmobile, setNmobile] = useState('')
    const[nemail, setNemail ] = useState('')

    const getdetls = (value) => {
        
        axios.post('https://buildfastapi.emedha.in/api/v1/get-lead-details', {
            value
        })
            .then(res => {
                
                setNname(res.data.name)
                setNmobile(res.data.mobile)
                setNemail(res.data.email)
                setSalesSource(res.data.salesExecutive)
            })
        }
    
        const add_blockform = () => {
            console.log({name:nname, mobile:nmobile, alternateMobile:alternatemob, email:nemail, permanentAddress:peraddress, addComm:addresscom, aadharNo:aadharnumber, panNo:pannumber, paySource:paymentsource, cheqNo:chequenumber, transferDetail:transferdetails, amtInWords:amountinwords, amt:Amount, tflatNo:tflatno, ttowerNo:ttowerno, salesSOurce:salessource});
            const formdata = new FormData()
            formdata.append('name', nname)
            formdata.append('mobile', nmobile)
            formdata.append('alternateMobile', alternatemob)
            formdata.append('email', nemail)
            formdata.append('permanentAddress', peraddress)
            formdata.append('addComm', addresscom)
            formdata.append('aadharNo', aadharnumber)
            formdata.append('panNo', pannumber)
            formdata.append('paySource', paymentsource)
            formdata.append('cheqNo', chequenumber)
            formdata.append('transferDetail', transferdetails)
            formdata.append('amtInWords', amountinwords)
            formdata.append('amt', Amount)
            formdata.append('tflatNo', tflatno)
            formdata.append('ttowerNo', ttowerno)
            formdata.append('salesSOurce', salessource)
            formdata.append('sig', signature)
            axios.post("https://buildfastapi.emedha.in/create-customer/api/v1/add-blockform",
            formdata).then((res) => {
                if (res.data.status === 200) {
                    
                    setOpen(false)
                    // getlm()
                    Swal.fire({
                        icon: 'success',
                        title: "Success !",
                        text: "Data is added successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            })
        }

        const [unitdata, setUnitdata] = useState([])
        const get_unit = () => {
            axios.post("https://buildfastapi.emedha.in/project/api/v1/unit-show").then((res) => {
                setUnitdata(res.data)
                console.log(res.data);
    
            })
        }

        const [responset, setResponset] = useState([])

        const get_floor = () => {
            axios.post("https://buildfastapi.emedha.in/project/api/v1/towers-getall").then((res) => {
                if (res.data.status === 200) {
                    setResponset(res.data.data)
                    console.log(res.data.data);
                }
            })
        }

        const [responsef, setResponsef] = useState([])

        const get_floorf = () => {
            axios.get("https://buildfastapi.emedha.in/project/api/v1/get-floor").then((res) => {
                if (res.data.status === 200) {
                    setResponsef(res.data.data)
                }
            })
        }

        const [customerRessib, setCustomerResponsesib] = React.useState([])


        const customerObjsib = () => {
            axios.get('https://buildfastapi.emedha.in/create-customer/api/v1/getblockform').then((res) => {
                setCustomerResponsesib(res.data)
            })
        }

        const [sameaspaddress, setSameaspaddress] = useState(false)

        const handlecheck = () => {      
            
           if(sameaspaddress){
            setAddressCom('')
           }else{
            setAddressCom(peraddress)
           }
           setSameaspaddress(!sameaspaddress)
        }
      


    return (
        <div>
            {
                !isVeiw && (
                    <div className='leadManagementTable'>
                        <Paper elevation={0} >

                            <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                                <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                                <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>
                                        <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>
                                        <input onChange={(e) => leadDataSearch(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                                    </div>
                                    <div>
                                        <Button onClick={handleClickOpen} style={{ width: '13rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>Block</Button>
                                    </div>
                                </div>
                                <Table style={{ position: 'relative', top: '1.3rem' }}>
                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                        <TableRow>

                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
                                            >
                                                Name
                                            </TableCell>
                                            <TableCell

                                                align={"left"}
                                                style={{ top: 57, fontWeight: '600', }}
                                            >
                                                Mobile
                                            </TableCell>

                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Aadhar
                                            </TableCell>

                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', }}
                                            >
                                                Address
                                            </TableCell>




                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', width: 18 }}
                                            >
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {customerRessib && customerRessib
                                            .map((row) => {

                                                let color = ''
                                                let widths = ''

                                                if (row.lead === 'Not Customer') {
                                                    color = 'red'
                                                    widths = false
                                                }

                                                if (row.lead === 'Customer') {
                                                    color = 'green'
                                                    widths = true
                                                }

                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.name}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', cursor: 'auto' }}><FaMobileAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{row.mobile}</button>
                                                        </TableCell>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.aadharNo}</TableCell>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.permanentAddress}</TableCell>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                            <div style={{ display: 'flex' }} >
                                                                <div>
                                                                    <IconButton
                                                                        id="fade-button"
                                                                        aria-controls={open2 ? 'fade-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open2 ? 'true' : undefined}
                                                                        onClick={(e) => {
                                                                            handleClick(e.currentTarget)
                                                                            setId(row.Id)
                                                                        }}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="fade-menu"
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'fade-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open2}
                                                                        onClose={handleClose2}
                                                                        TransitionComponent={Fade}
                                                                    >
                                                                        {
                                                                            (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    viewData(id)
                                                                                    handleClose2()
                                                                                    setIsVeiw(true)
                                                                                }}><FaEye style={{ marginRight: 4 }} /> View</MenuItem>
                                                                            )

                                                                        }

                                                                        {
                                                                            (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    viewData(id)
                                                                                    handleClose2()
                                                                                    setOpen3(true)

                                                                                }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>
                                                                            )
                                                                        }

                                                                        {
                                                                            (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => deleteAlert(row.Id)}><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                                            )
                                                                        }
                                                                    </Menu>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {
                                    customerRessib.length === 0 && (
                                        <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                            <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                            <Typography>0 customers</Typography>
                                        </Box>
                                    )
                                }
                            </TableContainer>
                        </Paper>

                    </div>
                )
            }
            {
                isVeiw && (
                    <div className='leadManagementTable leadManagementTable1 p-4' style={{ backgroundColor: 'white', borderRadius: '0.4rem', position: 'relative', }} >
                        <FaWindowClose onClick={() => {
                            setIsVeiw(false)
                            leadDataGet()
                            setIsFilter(false)
                            setEmpType('Business')
                            setLeadStatus('New')
                        }} style={{ position: 'absolute', right: 10, top: 12, color: 'rgb(126, 110, 228)', fontSize: 21, cursor: 'pointer', zIndex: 10000 }} ></FaWindowClose>
                        <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 26, fontWeight: 'bold', color: 'rgb(126, 110, 228)' }} ><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></Typography>
                            <Typography>Lead customers information details</Typography>
                        </div>
                        <br />
                        <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>

                            <div className='card' style={{ paddingTop: 43, border: '1px solid rgb(126, 110, 228)', borderRadius: '0.3rem', padding: 11, flexDirection: 'row', justifyContent: 'space-between' }} >
                                <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Name:</span></Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>DOB:</span> </Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Age:</span> </Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Area:</span> </Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Address:</span> </Typography>
                                </div>

                            </div>

                        </div>
                    </div>
                )
            }
            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "74rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <center><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></center>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Block Form</Typography>

                                </div>
                                <Row className="mb-3">

                                    <Row>
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <div style={{ marginBottom: 14 }}>
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Lead Name</Typography>
                                                <select onChange={(e) => getdetls(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
                                                    <option>--Select--</option>
                                                    {
                                                        res.map((item => (
                                                            <option>{item.name},{item.mobile}</option>
                                                        )))
                                                    }
                                                </select>
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Source type
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Name *</Typography>
                                            <Form.Control


                                                disabled
                                                type="text"
                                                value={nname}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Name
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Mobile </Typography>
                                            <Form.Control


                                                disabled
                                                type="number"
                                                value={nmobile}
                                                style={{ padding: '0.42rem', marginBottom: 14, }}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Mobile
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Email </Typography>
                                            <Form.Control
                                                value={nemail}
                                                type="text"
                                                disabled
                                                style={{ padding: '0.42rem', marginBottom: 14 }}


                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid email
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Alternate Mobile </Typography>
                                        <Form.Control
                                            type="number"
                                            placeholder='Enter Alternate Mobile Number'
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                            onChange={(e) => setAlternateMob(e.target.value)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Alternate Mobile
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Aadhar Number</Typography>
                                        <Form.Control
                                            placeholder='Enter Aadhar Number'
                                            onChange={(e) => setAadharNumber(e.target.value)}
                                            required
                                            type="number"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid Aadhar Number
                                        </Form.Control.Feedback>
                                    </Form.Group>



                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Pan Number</Typography>
                                        <Form.Control
                                            required
                                            placeholder='Enter Pan Number'
                                            onChange={(e) => setPanNumber(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Pan Number
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Permanent Address</Typography>
                                        <Form.Control
                                            re onChange={(e) => setPerAddress(e.target.value)}
                                            required
                                         
                                            as="textarea"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Permanent Address
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        
                                        <Typography style={{ color: 'gray', fontSize: 13 }}> Current Address</Typography>
                                        <Form.Control
                                            onChange={(e) => setAddressCom(e.target.value)}
                                            value={addresscom}
                                            required
                                            as="textarea"
                                            disabled={sameaspaddress}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />
                                        <Form.Check 
                                        style={{ color: 'red', fontSize: 12 }}
                                        onChange={handlecheck}
                                        label='Same As Permanent Address'
                                       
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid Address For Communication
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Payment Source</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setPaymentSource(e.target.value)}
                                            type="text"
                                            placeholder='Enter Payment Source'
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Payment Source
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Cheque Number</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setChequeNumber(e.target.value)}
                                            type="text"
                                            placeholder='Enter Cheque Number'
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Cheque Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Transfer Details</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setTransferDetails(e.target.value)}
                                            type="text"
                                            placeholder='Enter Transfer Details'
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Transfer Details
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Amount In Words</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setAmountInWords(e.target.value)}
                                            type="text"
                                            placeholder='Enter Amount In Words'
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Amount In Words
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Amount</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setAmount(e.target.value)}
                                            type="text"
                                            placeholder='Enter Amount'
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Tower </Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setTowardsTowerNumber(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select  Tower </option>
                                                    {
                                                        responset.map((item => (
                                                            <option>{item.Towers}</option>
                                                        )))
                                                    }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Towards Tower Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setTowardsFloorNumber(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select unit</option>
                                            {
                                                        responsef.map((item => (
                                                            <option>{item.selectType}</option>
                                                        )))
                                                    }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Towards Flat Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Flat/Unit</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setTowardsFlatNumber(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select unit</option>
                                            {
                                                        unitdata.map((item => (
                                                            <option>{item.unit_name}</option>
                                                        )))
                                                    }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Towards Flat Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                   

                                    <Form.Group as={Col} md="4" controlId="validationCustom01">

                                        <Typography style={{ color: 'gray', fontSize: 13 }}> Sales Source  </Typography>
                                       
                                        <Form.Control
                                            value={salessource}
                                            disabled
                                            // onChange={(e) => setSalesSource(e.target.value)}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please Select valid  Sales Source
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Signature</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setSignature(e.target.files[0])}
                                            type="file"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Signature
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen(false)
                                            setValidated(false)
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

export default BlockForm
