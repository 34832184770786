import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaDotCircle, FaEdit, FaRupeeSign, FaWindowClose, FaUserCircle } from 'react-icons/fa';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import Units from './Units';
import Pagination from '@mui/material/Pagination';
import CommonAmenities from './CommonAmenities'
import { common } from '@mui/material/colors';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import VisibilityIcon from '@mui/icons-material/Visibility';



export default function Block() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [common, setCommon] = useState()
    const [anyothertaxes, setAnyothertaxes] = useState()
    const [master, setMaster] = useState()
    const [permissions, setPermissions] = useState()
    const [legalset, setLegalSet] = useState()



    const [ProjectName, setProjectName] = useState("")
    const [id, setId] = useState()
    const [bval, setBval] = useState('')
    const [units, setUnits] = useState();
    const [Tower, setTower] = useState("")
    const [desc, setDesc] = useState('')
    const [amount, setAmount] = useState('')
    const [blog, setBlog] = useState('');


    const renderInputs = () => {
        const input = parseInt(blog)
        return Array.from({ length: input }, (_, index) => (
            <>
                <Typography style={{ color: 'gray', fontSize: 13 }}>{index}</Typography>
                <Form.Control
                    key={index}
                    required
                    type="text"
                    style={{ padding: '0.42rem', marginBottom: 14 }}
                    onChange={(e) => setBval(e.target.value)}
                />

            </>
        ))
    };


    const [inputs, setInputs] = useState([]);
    const addBlock = (value, index) => {

        let updatedInputs = [...inputs];
        updatedInputs[index] = value;
        setInputs(updatedInputs);
    };

    const add_blocks = () => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-commonamities", {
            ComAmities: common, ComAmitAmt: amount
        }).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data);
                setOpen(false)
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [response, setResponse] = useState([])

    const get_common = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-common").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }


    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        get_basicom()
        get_common()
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_blocks()
        }

        setValidated(true);
    };

    const [Validated1, setValidated1] = useState(false);

    const formValidation1 = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_gst()
        }

        setValidated1(true);
    };

    const [gst, setGst] = useState(0)

    const add_gst = () => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-gsttax", {
            GstTax: gst
        }).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data);
                setOpen(false)
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [Validated2, setValidated2] = useState(false);

    const formValidation2 = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_othertax()
        }

        setValidated2(true);
    };

    const [xtrpark, setXtrpark] = useState('')
    const [xtrparkamt, setXtrparkamt] = useState('')

    const [Validated2xp, setValidated2xp] = useState(false);

    const formValidation2xp = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_xtra()
        }

        setValidated2xp(true);
    };

    const add_xtra = () => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-extracarpark", {
            extracarpark: xtrpark, extramt: xtrparkamt
        }).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data);
                setOpen(false)
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [anygst, setAnygst] = useState(0)

    const add_othertax = () => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-anytax", {
            AnyotherTax: anygst
        }).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data);
                setOpen(false)
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [Validated3, setValidated3] = useState(false);

    const formValidation3 = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_otherinfo()
        }

        setValidated3(true);
    };

    const add_otherinfo = () => {

        const formData = new FormData()

        formData.append('u1', legalset)
        formData.append('u2', permissions)
        formData.append('u3', master)

        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-lpm", formData).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data);
                setOpen(false)
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }


    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/delete-blocks', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_common()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic data is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const editAlert = () => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                blockEdit(id)
            }
        })
    }

    const blockEdit = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/block-edit', {
            ProjectName, id
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.status === 200) {
                    get_common()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic data is updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            }
        })
    }

    const [open1, setOpen1] = useState(false)

    const viewData = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/view-basic', {
            id
        }).then((res) => {
            setCommon(res.data.ComAmities)
            setAmount(res.data.ComAmitAmt)
            setGst(res.data.GstTax)
            setAnygst(res.data.AnyotherTax)
            setLegalSet(res.data.Legal)
            setPermissions(res.data.Proceeding)
            console.log(res.data.Proceeding);
            setMaster(res.data.Master)
        })
    }


    const searchCommon = (text) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/search-common', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }


    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    const currentpost = response.slice(indexOffpage, indexOflpage)

    const [responsec, setResponsec] = useState([])

    const get_basicom = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponsec(res.data.data)
            }
        })
    }

    const [isView, setIsVeiw] = useState()

    const [selectedLayout, setSelectedLayout] = useState('Legal');

    const handleLayoutChange = (layout) => {
        setSelectedLayout(layout);
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const openDialog = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const closeDialog = () => {
        setSelectedImage(null);
    };

    return (
        <div>
            {
                !isView && (


                    <div className='leadManagementTable'>
                        <Paper elevation={0} >

                            <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                                <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

                                <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                                <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>

                                        <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                                    </div>
                                    {
                                        (sessionStorage.getItem('Add') === '1' || sessionStorage.getItem('superAdmin')) && (

                                            <div>
                                                <Button onClick={() => setOpen(true)} style={{ width: '14rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Common Ameniteis</Button>
                                            </div>
                                        )
                                    }
                                </div>
                                <Table style={{ position: 'relative', top: '1.3rem' }}>
                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                        <TableRow>

                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Common Amenities
                                            </TableCell>
                                            <TableCell

                                                align={"start"}
                                                style={{ minWidth: 0, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Amount
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                GST Tax
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Any Other Tax
                                            </TableCell>


                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                            >
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {responsec
                                            .map((row) => {

                                                let color = ''
                                                let widths = ''

                                                if (row.lead === 'Not Customer') {
                                                    color = 'red'
                                                    widths = false
                                                }

                                                if (row.lead === 'Customer') {
                                                    color = 'green'
                                                    widths = true
                                                }


                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.ComAmities}</TableCell>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.ComAmitAmt}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.GstTax}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.AnyotherTax}</TableCell>

                                                        <TableCell align="start">
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    viewData(row.Id);
                                                                    setIsVeiw(true)
                                                                }}
                                                            >
                                                                <VisibilityIcon style={{ color: 'primary' }} />
                                                                <span style={{ marginLeft: 4 }}>View</span>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {
                                    responsec.length === 0 && (
                                        <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                            <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                            <Typography>0 customers</Typography>
                                        </Box>
                                    )
                                }
                            </TableContainer>
                        </Paper>



                        <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                            <Pagination count={Math.ceil(response.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                        </div>
                    </div>
                )
            }
            {
                isView && (
                    <div className='leadManagementTable leadManagementTable1 p-4' style={{ backgroundColor: 'white', borderRadius: '0.4rem', position: 'relative' }}>
                        <FaWindowClose onClick={() => setIsVeiw(false)} style={{ position: 'absolute', right: 10, top: 12, color: 'rgb(126, 110, 228)', fontSize: 21, cursor: 'pointer', zIndex: 10000 }} />

                        <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 26, fontWeight: 'bold', color: 'rgb(126, 110, 228)' }}>
                                <img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' />
                            </Typography>
                            <Typography>Project information details</Typography>
                        </div>
                        <br />

                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '21px 46px' }}>
                            <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: '1px solid rgb(126, 110, 228)', padding: '11px', flexDirection: 'column', flex: 1 }}>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Common Aminities: {common} </span> </Typography>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Common Aminities Amount: {amount}  </span></Typography>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>GST Tax: {gst} </span> </Typography>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Any Other Taxes: {anygst} </span> </Typography>

                            </div>
                            <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: '1px solid rgb(126, 110, 228)', padding: '11px', flexDirection: 'column', flex: 1 }}>

                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                }}>
                                    <button style={{
                                        backgroundColor: '#4CAF50',
                                        border: 'none',
                                        color: 'white',
                                        width: '40%',
                                        // padding: '5px 3px',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        display: 'inline-block',
                                        fontSize: '16px',
                                        margin: '2px 2px',
                                        cursor: 'pointer',
                                        borderRadius: '8px'
                                    }} onClick={() => handleLayoutChange('Legal')}>Legal Set</button>

                                    <button style={{
                                        backgroundColor: '#4CAF50',
                                        border: 'none',
                                        color: 'white',
                                        width: '40%',
                                        padding: '10px 12px',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        display: 'inline-block',
                                        fontSize: '16px',
                                        margin: '4px 2px',
                                        cursor: 'pointer',
                                        borderRadius: '8px'
                                    }} onClick={() => handleLayoutChange('Master')}>Master Layout</button>

                                    <button style={{
                                        backgroundColor: '#4CAF50',
                                        border: 'none',
                                        color: 'white',
                                        width: '40%',
                                        padding: '5px 3px',
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                        display: 'inline-block',
                                        fontSize: '16px',
                                        margin: '4px 2px',
                                        cursor: 'pointer',
                                        borderRadius: '8px'
                                    }} onClick={() => handleLayoutChange('Permissions')}>permissions and Proceedings</button>






                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                                        <h5>{selectedLayout} - Layout</h5>
                                        {selectedLayout === 'Legal' && (
                                            <img src={legalset} alt="Legal Set" style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxWidth: '300px',
                                                marginTop: '10px'
                                            }} />

                                        )}

                                        {selectedLayout === 'Permissions' && (



                                            <img src={permissions} alt="Legal Set" style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxWidth: '300px',
                                                marginTop: '10px'
                                            }} />


                                        )}

                                        {selectedLayout === 'Master' && (
                                            <img src={master} alt="Legal Set" style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxWidth: '300px',
                                                marginTop: '10px'
                                            }} />
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )
            }

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', color: 'rgb(86, 63, 146)' }} >
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary" >
                                        <Tab label="Common Aminities" value="1" style={{ color: 'rgb(86, 63, 146)' }} />
                                        <Tab label="Extra Car Parking" value="5" style={{ color: 'rgb(86, 63, 146)' }} />
                                        <Tab label="GST Tax" value="2" style={{ color: 'rgb(86, 63, 146)' }} />
                                        <Tab label="Any Other Taxes" value="3" style={{ color: 'rgb(86, 63, 146)' }} />
                                        <Tab label="Other Info" value="4" style={{ color: 'rgb(86, 63, 146)' }} />
                                    </TabList>
                                </Box>
                                <TabPanel value='1'>
                                    <div style={{ width: "40rem" }} >
                                        <Form noValidate validated={Validated} onSubmit={formValidation} >
                                            <div className='mb-4 mt-1'>
                                                <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Common Aminities & Car Park</Typography>
                                            </div>
                                            <Row className="mb-3">

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Common Aminities & Car Park</Typography>
                                                    <Form.Select
                                                        required
                                                        onChange={(e) => setCommon(e.target.value)}
                                                        type="text"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    >
                                                        <option>--select--</option>
                                                        <option>Per sq.ft</option>
                                                        <option>LUMPSUM</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a valid square feet
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}> Amount</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setAmount(e.target.value)}
                                                        placeholder='Enter your  Amount'
                                                        type="number"

                                                        style={{ padding: '0.42rem', marginBottom: 14, }}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid  Amount
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </Row>
                                            <div className='d-flex'>
                                                <div>
                                                    <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidated(false)
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </TabPanel>
                                <TabPanel value='2'>
                                    <div style={{ width: "40rem" }} >
                                        <Form noValidate validated={Validated1} onSubmit={formValidation1} >
                                            <div className='mb-4 mt-1'>
                                                <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>GST Taxes</Typography>
                                            </div>
                                            <Row className="mb-3">

                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>GST Tax</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setGst(e.target.value)}
                                                        type="number"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter a valid GST Tax
                                                    </Form.Control.Feedback>
                                                </Form.Group>



                                            </Row>
                                            <div className='d-flex'>
                                                <div>
                                                    <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidated1(false)
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </TabPanel>
                                <TabPanel value='3'>
                                    <div style={{ width: "40rem" }} >
                                        <Form noValidate validated={Validated2} onSubmit={formValidation2} >
                                            <div className='mb-4 mt-1'>
                                                <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Any Other Taxes</Typography>
                                            </div>
                                            <Row className="mb-3">

                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Any Other Taxes</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setAnygst(e.target.value)}
                                                        type="number"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a valid Any other taxes
                                                    </Form.Control.Feedback>
                                                </Form.Group>



                                            </Row>
                                            <div className='d-flex'>
                                                <div>
                                                    <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidated2(false)
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </TabPanel>
                                <TabPanel value='5'>
                                    <div style={{ width: "40rem" }} >
                                        <Form noValidate validated={Validated2xp} onSubmit={formValidation2xp} >
                                            <div className='mb-4 mt-1'>
                                                <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Extra Car Parking</Typography>
                                            </div>
                                            <Row className="mb-3">

                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Extra Car Park</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => setXtrpark(e.target.value)}
                                                    type="text"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                >
                                                    <option>--select--</option>
                                                    <option>Per sq.ft</option>
                                                    <option>LUMPSUM</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please select a valid square feet
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}> Amount</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setXtrparkamt(e.target.value)}
                                                    placeholder='Enter your  Amount'
                                                    type="number"

                                                    style={{ padding: '0.42rem', marginBottom: 14, }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid  Amount
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            </Row>
                                            <div className='d-flex'>
                                                <div>
                                                    <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidated2xp(false)
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </TabPanel>
                                <TabPanel value='4'>
                                    <div style={{ width: "40rem" }} >
                                        <Form noValidate validated={Validated3} onSubmit={formValidation3} >
                                            <div className='mb-4 mt-1'>
                                                <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Any Other Info</Typography>
                                            </div>
                                            <Row className="mb-3">

                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>LEGAL SET</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setLegalSet(e.target.files[0])}
                                                        type="file"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please upload valid file
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>PERMISSIONS AND PROCEEDINGS</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setPermissions(e.target.files[0])}
                                                        type="file"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please upload valid file
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>MASTER LAYOUT</Typography>
                                                    <Form.Control
                                                        required
                                                        onChange={(e) => setMaster(e.target.files[0])}
                                                        type="file"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please upload valid file
                                                    </Form.Control.Feedback>
                                                </Form.Group>




                                            </Row>
                                            <div className='d-flex'>
                                                <div>
                                                    <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                </div>
                                                <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidated3(false)
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={() => setOpen1(!open1)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate validated={Validated} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Blocks</Typography>
                                    {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Updating blocks details will receive a privacy audit.</Typography> */}
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setProjectName(e.target.value)}
                                            type="text"
                                            value={ProjectName}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert(id)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen1(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

        </div>
    );
}