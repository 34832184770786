import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";

import {
  FaCheck,
  FaEdit,
  FaEye,
  FaMobileAlt,
  FaPhone,
  FaTrash,
  FaUserCircle,
  FaWindowClose,
} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { Badge } from "react-bootstrap";
import { Box, Button, TableBody, Typography, colors } from "@mui/material";

const CancelCustomers = () => {
  const [customerCrm, setCustomersCrm] = React.useState([]);

  const crmData = () => {
    axios
      .get(
        "https://buildfastapi.emedha.in/create-customer/api/v1/customers-crm"
      )
      .then((response) => {
        setCustomersCrm(response.data);
        console.log(customerCrm);
      });
  };

  React.useEffect(() => {
    crmData();
  }, []);

  return (
    <>
      <div className="mb-4 mt-1">
        <Typography
          style={{
            textAlign: "center",
            fontWeight: "500",
            color: "rgb(126, 110, 228)",
            fontSize: 21,
          }}
        >
          Cancel Customers
        </Typography>
      </div>
      <Table style={{ position: "relative", top: "1.3rem" }}>
        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
          <TableRow>
            <TableCell
              align={"start"}
              style={{
                minWidth: 0,
                fontSize: 14,
                fontWeight: "600",
              }}
            >
              Name
            </TableCell>
            <TableCell align={"left"} style={{ top: 57, fontWeight: "600" }}>
              Mobile
            </TableCell>

            <TableCell align={"left"} style={{ top: 21, fontWeight: "600" }}>
              Aadhar
            </TableCell>
            <TableCell align={"left"} style={{ top: 21, fontWeight: "600" }}>
              Pan
            </TableCell>

            <TableCell align={"left"} style={{ top: 21, fontWeight: "600" }}>
              Address
            </TableCell>
            <TableCell
              align={"left"}
              style={{ top: 21, fontWeight: "600", width: 18 }}
            >
              ProjectName
            </TableCell>

            <TableCell
              align={"left"}
              style={{ top: 21, fontWeight: "600", width: 18 }}
            >
              FlatNo
            </TableCell>
            <TableCell
              align={"left"}
              style={{ top: 21, fontWeight: "600", width: 18 }}
            >
              UCIN
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerCrm &&
            customerCrm
              .filter((item) => item.status === "Cancelled")
              .map((item) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={item.Id}
                  style={{
                    color: item.status === "Cancelled" ? "red" : "inherit",
                  }}
                >
                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: 12,
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                  >
                    {item.customerName}
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: 12,
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                  >
                    <button
                      style={{
                        fontSize: 10.7,
                        border: "2px solid rgb(126, 110, 228)",
                        backgroundColor: "white",
                        borderRadius: "1.2rem",
                        cursor: "auto",
                        width: "80px",
                      }}
                    >
                      <FaMobileAlt
                        style={{
                          marginRight: 3,
                          color: "rgb(126, 110, 228)",
                          marginTop: "-0.16rem",
                          fontSize: 9,
                          color:
                            item.status === "Cancelled" ? "red" : "inherit",
                        }}
                      />
                      {item.mobileNumber}
                    </button>
                  </TableCell>

                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: 12,
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                  >
                    {item.aadharNo}
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: 12,
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                  >
                    {item.panNo}
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: 12,
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                  >
                    {item.address}
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: "12px",
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                  >
                    {item.ProjectName}
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: 12,
                      color: "rgb(126, 110, 228) ",
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                  >
                    {item.flatNo}
                  </TableCell>

                  <TableCell
                    align={"start"}
                    style={{
                      top: 57,
                      fontSize: 12,
                      color: "green",
                      cursor: "pointer",
                      color: item.status === "Cancelled" ? "red" : "inherit",
                    }}
                    onClick={() => handleShowModal(item.ucin)}
                  >
                    {item.ucin}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </>
  );
};

export default CancelCustomers;
