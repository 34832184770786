import React, { useState } from 'react'
import './Dashboardhome.css'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Typography } from '@mui/material';

function DashBoardHomePage() {

const [avl, setAvl] = useState(0)
const [bok, setBok] = useState(0)
const [boked, setBoked] = useState(0)
const [mort, setMort] = useState(0)

  const data = [
    { name: 'Group A', value: avl },
    { name: 'Group B', value: boked },
    { name: 'Group C', value: bok },
    { name: 'Group D', value: mort },
];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', 'gray'];

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const [flagCount, setFlagCount] = React.useState(0);
  const [flagNotOneCount, setFlagNotOneCount] = React.useState(0);

  React.useEffect(() => {
    const getUnit = () => {
      axios.post("https://buildfastapi.emedha.in/project/api/v1/unit-show")
        .then((response) => {
          let newFlagOneCount = 0;
          let newFlagNotOneCount = 0;
          response.data.forEach((item) => {
            if (item.flag === 1) {
              newFlagOneCount++;
            } else {
              newFlagNotOneCount++;
            }
          });

          setFlagCount(newFlagOneCount);
          setFlagNotOneCount(newFlagNotOneCount);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };

    get_basic()
    get_common()
    get_tower()
    get_floor()
  }, []);

  

    const get_basic = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
              setProjectOptions(res.data.data)
              
            }
        })
    }

    const get_common = () => {
      axios.get("https://buildfastapi.emedha.in/project/api/v1/get-blocks").then((res) => {
          if (res.data.status === 200) {
            setBlockOptions(res.data.data)
            
          }
      })
  }

  const get_tower = () => {
    axios.post("https://buildfastapi.emedha.in/project/api/v1/towers-getall").then((res) => {
        if (res.data.status === 200) {
          setTowerOptions(res.data.data)
            
        }
    })
}

const get_floor = () => {
  axios.get("https://buildfastapi.emedha.in/project/api/v1/get-floor").then((res) => {
      if (res.data.status === 200) {
        setFloorOptions(res.data.data)
      }
  })
}



const getdetails = (text) => {
  console.log("t",text);
  axios.post("https://buildfastapi.emedha.in/project/api/v1/search-dash",{text}).then((res) => {
   
      setAvl(res.data.totalCount);
      setBok(res.data.countFlag0);
      setBoked(res.data.countFlag1);
      setMort(res.data.countFlag2);
    
})
}

  

  const [selectedOption, setSelectedOption] = useState('');
  const [projectOptions, setProjectOptions] = useState([]);
  const [blockOptions, setBlockOptions] = useState([]);
  const [towerOptions, setTowerOptions] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    // setBlockOptions(value);
    // setTowerOptions([]);
    // setFloorOptions([]);

  
  };
  return (
    <main className='main-container'>
      <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
        <div>
          <Typography style={{ marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>
          <select onChange={(e) => handleOptionChange(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
            <option value="">Select Option</option>
            <option value="project">Project</option>
            <option value="block">Block</option>
            <option value="tower">Tower</option>
            <option value="floor">Floor</option>
          </select>
          {selectedOption === 'project' && (
            <select  onChange={(e) => getdetails(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
              <option value="">Select Project</option>
              {projectOptions.map((option, index) => (
                <option key={index} >{option.ProjectName}</option>
              ))}
            </select>
          )}
          {selectedOption === 'block' && (
            <select onChange={(e) => getdetails(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
              <option value="">Select Block</option>
              {blockOptions.map((option, index) => (
                <option key={index}>{option.Blocks}</option>
              ))}
            </select>
          )}
          {selectedOption === 'tower' && (
            <select onChange={(e) => getdetails(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
              <option value="">Select Tower</option>
              {towerOptions.map((option, index) => (
                <option key={index} >{option.Towers}</option>
              ))}
            </select>
          )}
          {selectedOption === 'floor' && (
            <select  onChange={(e) => getdetails(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '15rem', border: '0.7px solid lightgray', marginRight: '1rem' }}>
              <option value="">Select Floor</option>
              {floorOptions.map((option, index) => (
                <option key={index} >{option.selectType}</option>
              ))}
            </select>
          )}
        </div>

      </div>
      <Row>
        {/* <Col as={Col} md="4">
          <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>
          <div>
            <div>
              <h5 style={{ color: 'blue' }}>Blocks</h5>
              <select onChange={(e) => handleBlockSelect(e.target.value)}>
                <option value="">Select Block</option>
                {response.map((data, index) => (
                  <option key={index} value={data.Blocks}>
                    {data.Blocks}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <h5 style={{ color: 'blue' }}>Towers</h5>
              <select onChange={(e) => handleTowerSelect(e.target.value)}>
                <option value="">Select Tower</option>
                {responset.map((data, index) => (
                  <option key={index} value={data.Towers}>
                    {data.Towers}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <h5 style={{ color: 'blue' }}>Floors</h5>
              <select onChange={(e) => handleFloorSelect(e.target.value)}>
                <option value="">Select Floor</option>
                {response3.map((data, index) => (
                  <option key={index} value={data.selectType}>
                    {data.selectType}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <h5 style={{ color: 'blue' }}>Units</h5>
              <select onChange={(e) => handleUnitSelect(e.target.value)}>
                <option value="">Select Unit</option>
                {responsegu.map((data, index) => (
                  <option key={index} value={data.unit_name}>
                    {data.unit_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <h5 style={{ color: 'blue' }}>Facing</h5>
              <select onChange={(e) => handleUnitSelect(e.target.value)}>
                <option value="">Select Facing</option>
                {responsegu.map((data, index) => (
                  <option key={index} value={data.facing_name}>
                    {data.facing_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <h5 style={{ color: 'blue' }}>Corner</h5>
              <select onChange={(e) => handleUnitSelect(e.target.value)}>
                <option value="">Select Corner</option>
                {responsegu.map((data, index) => (
                  <option key={index} value={data.corner_name}>
                    {data.corner_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <h5 style={{ color: 'blue' }}>Availability</h5>
              <select onChange={(e) => handleUnitSelect(e.target.value)}>
                <option value="">Select Availability</option>
                {responsegu.map((data, index) => (
                  <option key={index} value={data.corner_name}>
                    {data.corner_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Col> */}
        <br></br>
        <Col as={Col} md="8">
      <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 30, color: 'gray', fontWeight: '500' }}>Sales</Typography>
      
      <div>
        <PieChart width={600} height={360}>
          <Pie
            data={data}
            cx="40%"
            cy="30%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#00C49F', marginRight: '5px' }}></div>
          <span style={{color:'black'}}> Booked</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#FFBB28', marginRight: '5px' }}></div>
          <span style={{color:'black'}}>Blocked</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: 'gray', marginRight: '5px' }}></div>
          <span style={{color:'black'}}> Mortgaged</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#0088FE', marginRight: '5px' }}></div>
          <span style={{color:'black'}}>Availability</span>
        </div>
      </div>
    </Col>
      </Row>
    </main>
  )
}

export default DashBoardHomePage;
