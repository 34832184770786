import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Row from "react-bootstrap/Row";
import {
  FaBlenderPhone,
  FaEdit,
  FaMapMarkerAlt,
  FaMarker,
  FaPhone,
  FaUserCircle,
  FaPhoneAlt,
  FaPhoneSlash,
  FaPhoneSquare,
  FaTrash,
  FaUser,
  FaUserAlt,
} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axios from "axios";
import { Bolt } from "@mui/icons-material";
import Pagination from "react-bootstrap/Pagination";
import "./index.css";

function PaymentStatus() {
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  // const [totalpayment, setTotalPayment] = useState('')
  const [payment, setPayment] = useState("");
  const [date, setDate] = useState("");
  const [utrno, setUTRNO] = useState("");
  // const [checkno, setCheckNo] = useState("");
  // const [branch, setBranch] = useState("");
  // const [bankname, setBankName] = useState("");
  const [id, setId] = useState("");

  const [data, setData] = useState([]);
  const [response, setResponse] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [Validated, setValidated] = useState(false);

  const [open1, setOpen1] = useState(false);

  const formValidation = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // paycus()
    }

    setValidated(true);
  };

  const [customerRes, setCustomerResponse] = React.useState([]);

  const customerObj = () => {
    axios
      .get("https://buildfastapi.emedha.in/create-customer/api/v1/customers")
      .then((res) => {
        setCustomerResponse(res.data);
      });
  };

  const [payments, setPayments] = React.useState([]);

  const paymentsList = () => {
    axios
      .get("https://buildfastapi.emedha.in/create-customer/api/v1/api/payments")
      .then((res) => {
        setPayments(res.data);
        console.log(payments);
      });
  };

  React.useEffect(() => {
    customerObj();
    paymentsList();
  }, []);

  const [nid, setNid] = React.useState("");
  const [unt, setUnt] = React.useState("");
  const [tamt, setTamt] = React.useState("");

  const getdetls = (value) => {
    console.log({ value });
    axios
      .post(
        "https://buildfastapi.emedha.in/create-customer/api/v1/get-cus-detdrop",
        {
          value,
        }
      )
      .then((res) => {
        setNid(res.data.id);
        console.log("id", res.data.id);
        setUnt(res.data.unitNo);
        setTamt(res.data.TotalAmount);
      });
  };

  const paycus = () => {
    console.log("fff", {
      id: nid,
      Date: date,
      Cheq_utr: checkno,
      bank_name: bankname,
      bank_branch: branch,
    });
    axios
      .post("https://buildfastapi.emedha.in/create-customer/api/v1/addpay", {
        id: nid,
        Date: date,
        Cheq_utr: checkno,
        bank_name: bankname,
        bank_branch: branch,
      })
      .then((res) => {
        setOpen(false);
        Swal.fire({
          icon: "success",
          title: "Successfull !",
          text: " added successfully !",
          confirmButtonColor: "rgb(126, 110, 228)",
          cancelButtonColor: "#d33",
          showCancelButton: false,
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            return;
          }
        });
      });
  };

  // const [customers, setCustomers] = useState([]);
  // const [selectedCustomer, setSelectedCustomer] = useState("");
  const [totalPayment, setTotalPayment] = useState("");
  const [invoices, setInvoices] = useState([]);

  const [installmentAmount, setInstallmentAmount] = useState("");

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [flatNo, setFlatNo] = useState("");
  const [billDate, setBillDate] = useState("");
  const [checkno, setCheckNo] = useState("");
  const [checkdate, setCheckDate] = useState("");
  const [branch, setBranch] = useState("");
  const [bankname, setBankName] = useState("");
  const [ucin, setUcin] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [paidInvoices, setPaidInvoices] = useState(new Set());

  React.useEffect(() => {
    axios
      .get(
        "https://buildfastapi.emedha.in/create-customer/api/v1/customerBilling"
      )
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching customer billing data:", error);
      });
  }, []);

  const handleCustomerSelect = (event) => {
    const customerId = event.target.value;
    const customer = customers.find((c) => c.id === parseInt(customerId));
    setSelectedCustomer(customer);
    setUcin(customer.ucin);
    setCustomerName(customer.customerName);
    setSelectedInvoice("");
    setInvoiceAmount("");
    setFlatNo(customer.flatNo);
    setBillDate(customer.date);
  };

  // const handleInvoiceSelect = (event) => {
  //   const invoice = event.target.value;
  //   setSelectedInvoice(invoice);

  //   if (selectedCustomer) {
  //     // Loop through invoice columns (invoice1, invoice2, etc.) to find the matching one
  //     for (let i = 1; i <= 10; i++) {
  //       const invoiceColumn = `invoice${i}`;
  //       const installmentColumn = `installment${i}`;
  //       const dateColumn = `date${i}`;

  //       if (selectedCustomer[invoiceColumn] === invoice) {
  //         setInvoiceAmount(selectedCustomer[installmentColumn]);
  //         setBillDate(selectedCustomer[dateColumn]);
  //         break; // Exit loop once found
  //       }
  //     }
  //   } else {
  //     console.warn("No selected customer found.");
  //   }
  // };

  const [balance, setBalance] = useState(0);
  const [invoiceDisabled, setInvoiceDisabled] = useState(false);

  const handleInvoiceSelect = (event) => {
    const invoice = event.target.value;
    setSelectedInvoice(invoice);

    if (selectedCustomer) {
      // Loop through invoice columns (invoice1, invoice2, etc.) to find the matching one
      for (let i = 1; i <= 10; i++) {
        const invoiceColumn = `invoice${i}`;
        const installmentColumn = `installment${i}`;
        const balanceColumn = `balance${i}`;

        if (selectedCustomer[invoiceColumn] === invoice) {
          const installment = parseFloat(selectedCustomer[installmentColumn]);

          let balance = parseFloat(selectedCustomer[balanceColumn]);

          // Handle NULL or undefined balance
          if (isNaN(balance)) {
            balance = 0; // Default to 0 if balance is NULL or NaN
          }

          // Debugging: Log values to understand where NaN is coming from
          console.log("Selected Invoice:", invoice);
          console.log("Installment:", installment);
          console.log("Balance:", balance);

          if (!isNaN(installment)) {
            setInvoiceAmount(installment);
            setBalance(balance);
            setBillDate(selectedCustomer[`date${i}`]); // Assuming date handling

            // Disable invoice select if balance is zero
            if (balance === 0) {
              setInvoiceDisabled(true);
            } else {
              setInvoiceDisabled(false);
            }
          } else {
            console.error(
              "Invalid installment or balance:",
              installment,
              balance
            );
            // Handle error or provide feedback if necessary
          }
          break; // Exit loop once found
        }
      }
    } else {
      console.warn("No selected customer found.");
    }
  };

  const [payingAmount, setPayingAmount] = useState("");

  const handlePayment = async () => {
    setOpen(false);

    try {
      const response = await axios.post(
        "https://buildfastapi.emedha.in/create-customer/api/v1/api/pay",
        {
          ucin: ucin,
          customerName: customerName,
          invoice: selectedInvoice,
          payment: invoiceAmount,
          paidAmount: payingAmount,
          billdate: billDate,
          bankname: bankname,
          branch: branch,
          utr_check_no: checkno,
          checkdate: checkdate, // Assuming current date for check_date, adjust as needed
        }
      );

      if (response.status === 200) {
        const remainingBalance =
          parseFloat(invoiceAmount) - parseFloat(payingAmount);
        setBalance(remainingBalance);

        Swal.fire({
          icon: "success",
          title: "Success !",
          text: "Data is added successfully !",
          confirmButtonColor: "rgb(126, 110, 228)",
          cancelButtonColor: "#d33",
          showCancelButton: false,
          confirmButtonText: "Ok",
          customClass: {
            popup: "swal2-custom-zindex",
          },
        }).then((result) => {
          if (result.value) {
            // Add any additional actions you want to perform after the alert is confirmed
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error !",
          text: "Failed to record payment",
          confirmButtonColor: "rgb(126, 110, 228)",
          cancelButtonColor: "#d33",
          showCancelButton: false,
          confirmButtonText: "Ok",
          customClass: {
            popup: "swal2-custom-zindex",
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error !",
        text: "An error occurred",
        confirmButtonColor: "rgb(126, 110, 228)",
        cancelButtonColor: "#d33",
        showCancelButton: false,
        confirmButtonText: "Ok",
        customClass: {
          popup: "swal2-custom-zindex",
        },
      });
      console.error(error);
    }
  };

  const [page, setPage] = useState(1); // State to track current page

  const paymentsPerPage = 10; // Number of payments per page
  const totalPayments = payments.length; // Total number of payments

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update current page
  };

  return (
    <>
      <div className="leadManagementTable">
        <Paper elevation={0}>
          <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }}>
            <div
              style={{
                height: "0.1rem",
                borderBottom: "0.2px solid lightgray",
              }}
            ></div>
            <div
              className="card mt-2"
              style={{
                border: "none",
                paddingLeft: 15,
                paddingRight: 15,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <input
                  onChange={(e) => searchBasic(e.target.value)}
                  style={{
                    height: "2.3rem",
                    color: "gray",
                    borderRadius: "0.3rem",
                    outline: "none",
                    width: "26rem",
                    border: "0.7px solid lightgray",
                  }}
                  placeholder="Search..."
                />
              </div>
              <div>
                <Button
                  onClick={() => setOpen(true)}
                  style={{
                    width: "9rem",
                    height: "2.3rem",
                    backgroundColor: "rgb(126, 110, 228)",
                    color: "white",
                  }}
                >
                  + Add Payment
                </Button>
              </div>
            </div>
         
            <Table style={{ position: "relative", top: "1.3rem" }}>
              <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                <TableRow>
                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    Ucin
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    Invoice
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    Total Payment
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    UTR/Check No
                  </TableCell>

                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    Bank Name
                  </TableCell>
                  <TableCell
                    align={"start"}
                    style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
                  >
                    Branch
                  </TableCell>

                  <TableCell
                    align={"left"}
                    style={{ top: 21, fontWeight: "600", width: 21 }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.length > 0 ? (
                  payments
                    .filter((item) => item.status === "Approved")
                    .slice((page - 1) * paymentsPerPage, page * paymentsPerPage)
                    .map((item) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                      >
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12 }}
                        >
                          {item.customerName}
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12, width: 100 }}
                        >
                          {item.ucin}
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12, width: 100 }}
                        >
                          {item.invoice}
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12, width: 100 }}
                        >
                          {item.payment}
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12, width: 100 }}
                        >
                          {item.billdate}
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12, width: 100 }}
                        >
                          {item.utr_check_no}
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12, width: 100 }}
                        >
                          {item.bankname}
                        </TableCell>

                        <TableCell
                          align={"start"}
                          style={{ top: 57, fontSize: 12, width: 100 }}
                        >
                          {item.branch}
                        </TableCell>

                        <TableCell
                          align={"start"}
                          style={{
                            top: 57,
                            fontSize: 12,
                            color: "rgb(126, 110, 228) ",
                          }}
                        >
                          {item.status}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>No payments found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            {payments.length === 0 && (
              <Box
                className="card"
                style={{
                  border: "none",
                  height: "58vh",
                  width: "100%",
                  boxShadow: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/128/2291/2291944.png"
                  width="145px"
                />
                <Typography>0 customers</Typography>
              </Box>
            )}
          </TableContainer>
        </Paper>
      </div>
      <div>
        <Dialog
          style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
          maxWidth="xl"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <div style={{ width: "37rem" }}>
              <Form noValidate validated={Validated}>
                <div className="mb-4 mt-1">
                  <Typography
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "rgb(126, 110, 228)",
                      fontSize: 21,
                    }}
                  >
                    Add Payment
                  </Typography>
                </div>

                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="customerSelect">
                    <Form.Label>Select Customer</Form.Label>
                    <Form.Control as="select" onChange={handleCustomerSelect}>
                      <option value="">-- Select a Customer --</option>
                      {customers.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.customerName} ({customer.ucin})
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  {selectedCustomer && (
                    <>
                      <Form.Group as={Col} md="6" controlId="invoiceSelect">
                        <Form.Label>Select Invoice</Form.Label>
                        <Form.Control
                          as="select"
                          value={selectedInvoice}
                          onChange={handleInvoiceSelect}
                        >
                          <option value="">-- Select an Invoice --</option>
                          {Array.from({ length: 10 }, (_, i) => i + 1).map(
                            (i) => {
                              const invoiceColumn = `invoice${i}`;
                              const installmentColumn = `installment${i}`;
                              const invoice = selectedCustomer[invoiceColumn];
                              const installment =
                                selectedCustomer[installmentColumn];
                              const balanceColumn = `balance${i}`;
                              let disbleed =
                                parseFloat(selectedCustomer[balanceColumn]) ===
                                0;
                              return (
                                invoice &&
                                installment !== "0" && (
                                  <option
                                    key={invoice}
                                    value={invoice}
                                    disabled={disbleed}
                                  >
                                    {invoice}
                                  </option>
                                )
                              );
                            }
                          )}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="balanceDisplay">
                        <Form.Label>Balance</Form.Label>
                        <Form.Control type="text" value={balance} readOnly />
                      </Form.Group>

                      {selectedInvoice && (
                        <Form.Group as={Col} md="6" controlId="invoiceAmount">
                          <Form.Label>Invoice Amount</Form.Label>
                          <Form.Control
                            type="text"
                            value={invoiceAmount}
                            readOnly
                          />
                        </Form.Group>
                      )}
                    </>
                  )}

                  <Form.Group as={Col} md="6" controlId="payingAmount">
                    <Form.Label>Paying Amount</Form.Label>
                    <Form.Control
                      type="text"
                      value={payingAmount}
                      onChange={(e) => setPayingAmount(e.target.value)}
                      required
                    />
                  </Form.Group>

                  {selectedCustomer && (
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 13 }}>
                        Unit
                      </Typography>
                      <Form.Control
                        required
                        type="text"
                        value={flatNo}
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Unit
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Billing Date
                    </Typography>
                    <Form.Control
                      required
                      value={billDate}
                      type="text"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Date
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      UTR/Cheque No
                    </Typography>
                    <Form.Control
                      required
                      value={checkno}
                      onChange={(e) => setCheckNo(e.target.value)}
                      type="text"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid UTR no
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      UTR/Cheque Date
                    </Typography>
                    <Form.Control
                      required
                      value={checkdate}
                      onChange={(e) => setCheckDate(e.target.value)}
                      type="date"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Check no
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Bank Name
                    </Typography>
                    <Form.Control
                      required
                      onChange={(e) => setBankName(e.target.value)}
                      type="text"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Bank name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Branch Name
                    </Typography>
                    <Form.Control
                      required
                      onChange={(e) => setBranch(e.target.value)}
                      type="text"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Branch Name
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Button
                  onClick={handlePayment}
                  style={{
                    backgroundColor: "rgb(126, 110, 228)",
                    color: "white",
                    width: "5rem",
                    marginTop: 7,
                  }}
                >
                  Pay
                </Button>

                {/* <div className='d-flex'>
                        <div>
                            <Button type='submit'  style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                        </div>
                        <div>
                            <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                        </div>
                    </div> */}
              </Form>
            </div>
          </DialogContent>
        </Dialog>
        {/* <Pagination>
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Ellipsis />

          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Ellipsis />
          <Pagination.Item>{20}</Pagination.Item>

          <Pagination.Next />
          <Pagination.Last />
        </Pagination> */}
   <Pagination
          className="my-3"
          count={Math.ceil(totalPayments / paymentsPerPage)}
          page={page}
          onChange={handleChangePage}
          style={{ display: "flex", justifyContent: "center"}}
        />
       
      </div>
    </>
  );
}

export default PaymentStatus;
