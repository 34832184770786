import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Row from "react-bootstrap/Row";
import {
  FaBlenderPhone,
  FaEdit,
  FaPhoneSquare,
  FaTrash,
  FaEye,
  FaWindowClose,
} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import { FaMobileAlt } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const PreSales = () => {
  const [value, setValue] = React.useState("1");
  const [id, setId] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   const add_blockform = () => {
  //     console.log({
  //       name: nname,
  //       mobile: nmobile,
  //       alternateMobile: alternatemob,
  //       email: nemail,
  //       permanentAddress: peraddress,
  //       addComm: addresscom,
  //       aadharNo: aadharnumber,
  //       panNo: pannumber,
  //       paySource: paymentsource,
  //       cheqNo: chequenumber,
  //       transferDetail: transferdetails,
  //       amtInWords: amountinwords,
  //       amt: Amount,
  //       tflatNo: tflatno,
  //       ttowerNo: ttowerno,
  //       salesSOurce: salessource,
  //     });
  //     const formdata = new FormData();
  //     formdata.append("name", nname);
  //     formdata.append("mobile", nmobile);
  //     formdata.append("alternateMobile", alternatemob);
  //     formdata.append("email", nemail);
  //     formdata.append("permanentAddress", peraddress);
  //     formdata.append("addComm", addresscom);
  //     formdata.append("aadharNo", aadharnumber);
  //     formdata.append("panNo", pannumber);
  //     formdata.append("paySource", paymentsource);
  //     formdata.append("cheqNo", chequenumber);
  //     formdata.append("transferDetail", transferdetails);
  //     formdata.append("amtInWords", amountinwords);
  //     formdata.append("amt", Amount);
  //     formdata.append("tflatNo", tflatno);
  //     formdata.append("ttowerNo", ttowerno);
  //     formdata.append("salesSOurce", salessource);
  //     formdata.append("sig", signature);
  //     axios
  //       .post(
  //         "https://buildfastapi.emedha.in/create-customer/api/v1/add-blockform",
  //         formdata
  //       )
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           setOpenbf(false);
  //           customerObjsib();
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success !",
  //             text: "Data is added successfully !",
  //             confirmButtonColor: "rgb(126, 110, 228)",
  //             cancelButtonColor: "#d33",
  //             showCancelButton: false,
  //             confirmButtonText: "Ok",
  //           }).then((result) => {
  //             if (result.value) {
  //               return;
  //             }
  //           });
  //         }
  //       });
  //   };

  const [customerRessib, setCustomerResponsesib] = React.useState([]);

  const customerObjsib = () => {
    axios
      .get("https://buildfastapi.emedha.in/create-customer/api/v1/getblockform")
      .then((res) => {
        setCustomerResponsesib(res.data);
      });
  };

  const [customerRes, setCustomerResponse] = React.useState([]);

  const customerObj = () => {
    axios
      .get("https://buildfastapi.emedha.in/create-customer/api/v1/customers")
      .then((res) => {
        setCustomerResponse(res.data);
      });
  };

  React.useEffect(() => {
    customerObj();
    customerObjsib();
  }, []);

  const [openRejectForm, setOpenReject] = React.useState();

  const handleClickOpenReject = () => {
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const [rejectDate, setDataForReject] = useState("");
  const [rejectComment, setCommentForReject] = useState("");

  const [Validatedtabcb, setValidatedtabcb] = useState(false);

  const formValidationReject = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      //   callbackTab()
    }

    setValidatedtabcb(true);
  };

  const [openBookRejectForm, setOpenBookRejectForm] = useState();

  const handleClickBookingReject = () => {
    setOpenBookRejectForm(true);
  };

  const handleCloseBookReject = () => {
    setOpenBookRejectForm(false);
  };

  const [bookingRejectDate, setDataForBookingReject] = useState("");
  const [bookingRejectComment, setCommentForBookingReject] = useState("");

  const formValidationRejectBook = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      //   callbackTab()
    }
  };

  const [blockApproveDate, setDataForBlockApprove] = useState("");
  const [blockApproveComment, setCommentForBlockApprove] = useState("");
  const [blockTransactionId,setTransactionIdForApproveBlock]=useState("")
  const [blockedCustomers, setBlockedCustomers] = useState(customerRessib);

  const [ValidatedtaBlockApproveForm, setValidatedBlockApproveForm] = useState(false);

  const [openApproveBlockForm, setOpenApproveBlockForm] = useState();

  const handleClickOpenApproveBlock = () => {
    setOpenApproveBlockForm(true);
  };

  const handleCloseApproveBlockForm = () => {
    setOpenApproveBlockForm(false);
  };

  const formValidationForBlockApprove =async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        await axios.post('https://buildfastapi.emedha.in/create-customer/api/v1/block/approve', {
          id,
          approvalDate: blockApproveDate,
          comment: blockApproveComment,
          transactionId:blockTransactionId
        });

        // https://buildfastapi.emedha.in/create-customer/api/v1/block/approve
        // Update the blocked and booking lists
        // const updatedBlockedCustomers = blockedCustomers.filter(item => item.Id !== approveData.Id);
        // const updatedCustomers = [...customers, { ...approveData, status: 'Approved' }];
        // setBlockedCustomers(updatedBlockedCustomers);
        // setCustomers(updatedCustomers);
        setApproveData({});
        setCommentForBlockApprove('');
        setDataForBlockApprove('')
        setOpenApproveBlockForm(false);
        alert("Block Successfully Approved")
        window.location.reload();
      } catch (error) {
        console.error("Error approving blocked customer:", error);
        alert("Error approving blocked customer:", error)
      }
      setValidatedBlockApproveForm(false);
    }
    
  };

  const [bookDateApprove, setDataForApproveBooking] = useState("");
  const [bookCommentApprove, setCommentForApproveBooking] = useState("");
  const [bookTransactionId,setTransactionIdForApproveBooking]= useState("");

  const [openApproveBookingForm, setApproveBookingForm] = useState("");
  
  const [ValidatedtaBookingForm, setValidatedBookingForm] = useState(false);
  const [approveData, setApproveData] = useState({});

  const handleClickBookingApprove = (item) => {
    setApproveBookingForm(true);
    setApproveData(item);
  };

  const handleCloseApproveBookingForm = () => {
    setApproveBookingForm(false);
  };

  const formValidationApproveBookingForm = async(event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
     

      //https://buildfastapi.emedha.in/create-customer/api/v1/book/approve

      try{
        await axios.post('https://buildfastapi.emedha.in/create-customer/api/v1/book/approve', {
          id,
          approvalDate: bookDateApprove,
          comment: bookCommentApprove,
          transactionId:bookTransactionId
        });
       
       
        setApproveData({});
        setCommentForApproveBooking('');
        setDataForApproveBooking('')
        setApproveBookingForm(false)
        alert("Booking Successfully Approved")
        window.location.reload();
      } catch (error) {
        // Handle error, e.g., show error message
        console.error("Error approving booking:", error);
        alert("Error approving blocked customer:", error)
      }
      setValidatedBookingForm(false);
    }
    
  };

  return (
    <>
      <div>
        {
          <div className="leadManagementTable">
            <Typography
              style={{
                color: "rgba(41, 16, 105, 1)",
                marginBottom: 6,
                marginLeft: 15.5,
                fontSize: 18,
                color: "gray",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              PreSales Details
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 5,
              }}
            ></div>
            <Paper elevation={0}>
              {/* <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} > */}

              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      color: "rgb(86, 63, 146)",
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      indicatorColor="secondary"
                    >
                      <Tab
                        label="Blocked"
                        value="1"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                      <Tab
                        label="Booked"
                        value="2"
                        style={{ color: "rgb(86, 63, 146)" }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Blocked
                      </Typography>
                      <div style={{ fontWeight: "bold" }}>
                        Total Blocked: {customerRessib.filter((item=>item.status!=='Approved')).length}
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Mobile
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Aadhar
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Address
                            </TableCell>

                            <TableCell
                              align={"center"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customerRessib &&
                            customerRessib.filter((item=>item.status!=='Approved')).map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.name}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  <button
                                    style={{
                                      fontSize: 10.7,
                                      border: "2px solid rgb(126, 110, 228)",
                                      backgroundColor: "white",
                                      borderRadius: "1.2rem",
                                      cursor: "auto",
                                    }}
                                  >
                                    <FaMobileAlt
                                      style={{
                                        marginRight: 3,
                                        color: "rgb(126, 110, 228)",
                                        marginTop: "-0.16rem",
                                        fontSize: 9,
                                      }}
                                    />
                                    {item.mobile}
                                  </button>
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.aadharNo}
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.permanentAddress}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "rgb(126, 110, 228) ",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <Badge
                                      bg="success"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleClickOpenApproveBlock();
                                        setId(item.id);
                                      }}
                                    >
                                      Approve
                                    </Badge>
                                    {/* <Badge
                                      bg="danger"
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "15px",
                                      }}
                                      onClick={() => {
                                        handleClickOpenReject();
                                        setId(item.Id);
                                      }}
                                    >
                                      Reject
                                    </Badge> */}
                                    {/* <div>
                                                                                <IconButton
                                                                                    id="fade-button"
                                                                                    aria-controls={open2 ? 'fade-menu' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    aria-expanded={open2 ? 'true' : undefined}
                                                                                    onClick={(e) => {
                                                                                        handleClick(e.currentTarget)
                                                                                        setId(item.Id)
                                                                                    }}
                                                                                >
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    id="fade-menu"
                                                                                    MenuListProps={{
                                                                                        'aria-labelledby': 'fade-button',
                                                                                    }}
                                                                                    anchorEl={anchorEl}
                                                                                    open={open2}
                                                                                    onClose={handleClose2}
                                                                                    TransitionComponent={Fade}
                                                                                >
                                                                                   
                                                                                    {
                                                                                        (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                            <MenuItem style={{ color: 'gray' }} onClick={handleClickOpenbok}>Book</MenuItem>
                                                                                        )

                                                                                    }


                                                                                  

                                                                                </Menu>
                                                                            </div> */}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>

                      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                                                <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                                            </div> */}
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div style={{ width: "58rem" }}>
                      <Typography
                        style={{
                          color: "rgba(41, 16, 105, 1)",
                          marginBottom: 3,
                          marginLeft: 15.5,
                          fontSize: 18,
                          color: "gray",
                          fontWeight: "500",
                          textAlign: "center",
                        }}
                      >
                        Booked
                      </Typography>
                      <div style={{ fontWeight: "bold" }}>
                        Total Booked: {customerRes.filter((item=>item.status!=='approved')).length}
                      </div>

                      <Table style={{ position: "relative", top: "1.3rem" }}>
                        <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                          <TableRow>
                            <TableCell
                              align={"start"}
                              style={{
                                minWidth: 0,
                                fontSize: 14,
                                fontWeight: "600",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 57, fontWeight: "600" }}
                            >
                              Mobile
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Aadhar
                            </TableCell>
                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Pan
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600" }}
                            >
                              Address
                            </TableCell>

                            <TableCell
                              align={"left"}
                              style={{ top: 21, fontWeight: "600", width: 18 }}
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customerRes && 
                            customerRes.filter((item=>item.status!=='approved')).map((item) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item.Id}
                              >
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.customerName}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  <button
                                    style={{
                                      fontSize: 10.7,
                                      border: "2px solid rgb(126, 110, 228)",
                                      backgroundColor: "white",
                                      borderRadius: "1.2rem",
                                      cursor: "auto",
                                    }}
                                  >
                                    <FaMobileAlt
                                      style={{
                                        marginRight: 3,
                                        color: "rgb(126, 110, 228)",
                                        marginTop: "-0.16rem",
                                        fontSize: 9,
                                      }}
                                    />
                                    {item.mobileNumber}
                                  </button>
                                </TableCell>

                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.aadharNo}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.panNo}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{ top: 57, fontSize: 12 }}
                                >
                                  {item.addres}
                                </TableCell>
                                <TableCell
                                  align={"start"}
                                  style={{
                                    top: 57,
                                    fontSize: 12,
                                    color: "rgb(126, 110, 228) ",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <Badge
                                      bg="success"
                                      style={{ cursor: "pointer" }}
                                      onClick={()=>{handleClickBookingApprove(), setId(item.id)}}
                                     
                                    >
                                      Approve
                                    </Badge>
                                   
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </Paper>
          </div>
        }
      </div>

      {/*Blocked Approve Form*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openApproveBlockForm}
        onClose={handleCloseApproveBlockForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={ValidatedtaBlockApproveForm}
              onSubmit={formValidationForBlockApprove}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Blocked Approve
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Approve *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForBlockApprove(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) => setCommentForBlockApprove(e.target.value)}
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Comment
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 11 ,marginTop:'10px'}}>
                        Transaction ID *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setTransactionIdForApproveBlock(e.target.value)}
                        value={blockTransactionId}
                        // placeholder="Transaction ID"
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Transaction ID
                      </Form.Control.Feedback>
                    </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setOpenApproveBlockForm(false);
                      setValidatedBlockApproveForm(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>

      {/*Blocked Reject Form*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openRejectForm}
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={Validatedtabcb}
              onSubmit={formValidationReject}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Blocked Reject
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Reject *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForReject(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) => setCommentForReject(e.target.value)}
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Comment
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setOpenReject(false);
                      setValidatedtabcb(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>

      {/* Booking Approve From*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openApproveBookingForm}
        onClose={handleCloseApproveBookingForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={ValidatedtaBookingForm}
              onSubmit={formValidationApproveBookingForm}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Booking Approve
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Approve *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForApproveBooking(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) =>
                      setCommentForApproveBooking(e.target.value)
                    }
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Permanent Address
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 11 ,marginTop:'10px'}}>
                        Transaction ID *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setTransactionIdForApproveBooking(e.target.value)}
                        value={bookTransactionId}
                        // placeholder="Transaction ID"
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Transaction ID
                      </Form.Control.Feedback>
                    </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setApproveBookingForm(false);
                      setValidatedBookingForm(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>

      {/*Booking Reject From*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openBookRejectForm}
        onClose={handleCloseBookReject}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={Validatedtabcb}
              onSubmit={formValidationRejectBook}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Booking Reject
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Reject *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForBookingReject(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) => setCommentForBookingReject(e.target.value)}
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Permanent Address
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setOpenBookRejectForm(false);
                      setValidatedtabcb(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreSales;
