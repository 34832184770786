
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaEdit, FaSquarespace, FaTrash, FaEye, FaWindowClose } from 'react-icons/fa';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import { FaDotCircle } from 'react-icons/fa';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';


export default function Design() {

    const [FloorPlan, setFloorPlan] = useState("")
    const [UnitType, setUnitType] = useState()
    const [UnitSize, setUnitSize] = useState()
    const [UnitFacing, setUnitFacing] = useState()
    const [ProjectName, setProjectName] = useState("")
    const [unitNo, setUnitNo] = useState('')
    const [floor, setFloor] = useState('')
    const [block, setBlock] = useState("")
    const [Floorname, setFloorname] = useState('')
    const [selectType, setSelectType] = useState('')
    const [id, setId] = useState()




    const add_design = () => {

        const formData = new FormData()

        formData.append('ProjectName', ProjectName)
        formData.append('file', FloorPlan)
        formData.append('UnitType', UnitType)
        formData.append('UnitSize', UnitSize)
        formData.append('UnitFacing', UnitFacing)
        formData.append('unitNo', unitNo)
        formData.append('block', ft)
        formData.append('floor', Floorname)
        formData.append('Towers', block)

        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-design", formData).then((res) => {
            if (res.data.status === 200) {
                setOpen(false)
                get_design()
                setProjectName("")
                setBlock("")
                setFloorPlan("")

                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        window.location.reload()
                        return
                    } else {
                        window.location.reload()
                    }
                })
            }
        }).catch((err => {
            setOpen(false)
            Swal.fire({
                icon: 'warning',
                title: "warning !",
                text: `design plan is already added for the selected unit ${unitNo} !`,
                confirmButtonColor: "rgb(126, 110, 228)",
                cancelButtonColor: "#d33",
                showCancelButton: false,
                confirmButtonText: "Ok"
            })
        }))
    }

    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    const [response, setResponse] = useState([])

    const get_design = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-design").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_design()
        get_basic()
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_design()
        }

        setValidated(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/delete-design', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_design()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic data is deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const editAlert = () => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataEdit(id)
            }
        })
    }

    const basicDataEdit = () => {

        const formData = new FormData()

        formData.append('ProjectName', ProjectName)
        formData.append('file', FloorPlan)
        formData.append('UnitType', UnitType)
        formData.append('UnitSize', UnitSize)
        formData.append('UnitFacing', UnitFacing)
        formData.append('id', id)
        formData.append('unitNo', unitNo)
        formData.append('block', ft)
        formData.append('floor', Floorname)
        formData.append('Ft', block)
        formData.append('floor', floor)

        console.log(formData)

        axios.post('https://buildfastapi.emedha.in/project/api/v1/edit-design', formData).then((res) => {
            if (res.data.status === 200) {
                if (res.data.status === 200) {
                    get_design()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Design is updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            }
        })
    }

    const [open1, setOpen1] = useState(false)

    const viewData = () => {

        axios.post('https://buildfastapi.emedha.in/project/api/v1/view-design', {
            id
        }).then((res) => {
            setProjectName(res.data.ProjectName)
            setFloorPlan(res.data.FloorPlan)
            setUnitFacing(res.data.UnitFacing)
            setUnitSize(res.data.UnitSize)
            setUnitType(res.data.UnitType)
            setUnitNo(res.data.unitNo)
            setBlock(res.data.Block)
            setFloor(res.data.floor)
            setFt(res.data.Ft)
            setFloorname(res.data.Floorname)

        })
    }

    const searchDesign = (text) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/search-design', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }

    const [response2, setResponse2] = useState([])

    const get_block = (value) => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise-blocks", {
            value
        }).then((res) => {

            setResponse2(res.data)
            setProjectName(value)

        })
    }

    const [response3, setResponse3] = useState([])

    const get_floor = (value1,) => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/select-floor", {
            value1,
        }).then((res) => {

            setResponse3(res.data)
            setBlock(value1)


        })
    }




    const [response4, setResponse4] = useState([])

    const get_unit = (value) => {
        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise/unitss", {
            value
        })
            .then((res) => {
                setResponse4(res.data)
                console.log(res.data);
                setFloorname(value)

            })
    }

    const [ft, setFt] = useState('')

    const [responset, setResponset] = useState([])

    const get_tower = (value1) => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise-towers", {
            value1
        }).then((res) => {

            setResponset(res.data)
            console.log(res.data)
            setFt(value1)


        })
    }
    const [isVeiw, setIsVeiw] = useState(false)

    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    const currentpost = response.slice(indexOffpage, indexOflpage)

    return (
        <div>

            {
                !isVeiw && (
                    <div className='leadManagementTable'>
                        <Paper elevation={0} >

                            <TableContainer sx={{ height: 85.4 + "vh", p: 0.3, pt: 2 }} >

                                <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

                                <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                                <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div>

                                        <input onChange={(e) => searchDesign(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Design</Button>
                                    </div>
                                </div><br></br>
                                <TableHead style={{ justifyContent: "center", alignItems: "center" }}><strong>Project Name : NILE VALLEY</strong></TableHead>
                                <Table style={{ position: 'relative', top: '1.3rem' }}>

                                    <TableHead sx={{ borderTop: '1px solid lightgray' }}>

                                        <TableRow>

                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Block
                                            </TableCell>

                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Tower
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Floor
                                            </TableCell>
                                            {/* <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Flat/Unit Type
                                    </TableCell> */}

                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Unit No
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Flat/Unit Size
                                            </TableCell>
                                            <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Floor/Unit Plan
                                            </TableCell>


                                            {/* <TableCell

                                        align={"start"}
                                        style={{ top: 57, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Flat/Unit Facing
                                    </TableCell> */}

                                            <TableCell

                                                align={"left"}
                                                style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                            >
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {currentpost
                                            .map((row) => {

                                                let color = ''
                                                let widths = ''

                                                if (row.lead === 'Not Customer') {
                                                    color = 'red'
                                                    widths = false
                                                }

                                                if (row.lead === 'Customer') {
                                                    color = 'green'
                                                    widths = true
                                                }


                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.Block}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.Towers}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.Floor}</TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.UnitNo}</TableCell>
                                                        {/* <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.UnitType}</TableCell> */}
                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>
                                                            <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaSquarespace style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.UnitSize}</button>
                                                        </TableCell>
                                                        <TableCell align={"start"}
                                                            style={{ top: 57 }}>
                                                            <div style={{ border: `3px solid ${color}`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%', overflow: 'hidden' }}>
                                                                <img src={`${row.FloorPlan}`} width='35px' />
                                                            </div>
                                                        </TableCell>


                                                        {/*                                                
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>{row.UnitFacing}</TableCell> */}

                                                        <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                            <div style={{ display: 'flex' }} >
                                                                {/* <Button onClick={() => deleteAlert(row.id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Delete</Button> */}

                                                                <div>
                                                                    <IconButton
                                                                        id="fade-button"
                                                                        aria-controls={open2 ? 'fade-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open2 ? 'true' : undefined}
                                                                        onClick={(e) => {
                                                                            handleClick(e.currentTarget)
                                                                            setId(row.id)
                                                                        }}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="fade-menu"
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'fade-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open2}
                                                                        onClose={handleClose2}
                                                                        TransitionComponent={Fade}
                                                                    >
                                                                        {
                                                                            (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    setOpen1(true)
                                                                                    handleClose2()
                                                                                    viewData(id)

                                                                                }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>
                                                                            )

                                                                        }
                                                                        {
                                                                            (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {
                                                                                    viewData(id)
                                                                                    handleClose2()
                                                                                    setIsVeiw(true)
                                                                                }}><FaEye style={{ marginRight: 4 }} /> View</MenuItem>
                                                                            )

                                                                        }
                                                                        {
                                                                            (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
                                                                                <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                                            )

                                                                        }
                                                                    </Menu>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {
                                    response.length === 0 && (
                                        <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                            <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                            <Typography>0 customers</Typography>
                                        </Box>
                                    )
                                }
                            </TableContainer>
                        </Paper>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                    <Pagination count={Math.ceil(response.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                </div>
                    </div>
                )
            }
            {
                isVeiw && (
                    <div className='leadManagementTable leadManagementTable1 p-4' style={{ backgroundColor: 'white', borderRadius: '0.4rem', position: 'relative', }} >
                        <FaWindowClose onClick={() => {
                            setIsVeiw(false)
                        }} style={{ position: 'absolute', right: 10, top: 12, color: 'rgb(126, 110, 228)', fontSize: 21, cursor: 'pointer', zIndex: 10000 }} ></FaWindowClose>
                        <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'center' }}>
                            <Typography style={{ fontSize: 26, fontWeight: 'bold', color: 'rgb(126, 110, 228)' }} ><img src='https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg' width='145px' /></Typography>
                            <Typography>Lead customers information details</Typography>
                        </div>
                        <br />
                        <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>

                            <div className='card' style={{ paddingTop: 43, border: '1px solid rgb(126, 110, 228)', borderRadius: '0.3rem', padding: 11, flexDirection: 'row', justifyContent: 'space-between' }} >
                                <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: 'none', boxShadow: 'none', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Project Name:</span> {ProjectName}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Block:</span> {block}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Tower:</span> {ft}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Floor Name:</span> {Floorname}</Typography>
                                    {/* <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Floor Plan:</span> {FloorPlan}</Typography> */}
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Flat No:</span> {unitNo}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Flat Size:</span> {UnitSize}</Typography>
                                    <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Floor Plan:</span>
                                        <img src={FloorPlan} alt="Floor Plan" style={{ width: '100%', height: 'auto', maxWidth: '300px', marginTop: '10px' }} />
                                    </Typography>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Design</Typography>
                                </div>
                                <Row className="mb-3">


                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => get_block(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {
                                        ProjectName !== "" && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Block</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => get_tower(e.target.value)}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Block</option>

                                                    {
                                                        response2.map((data, index) => (
                                                            <React.Fragment key={index}>
                                                                {data.Blocks.split(',').map((block, blockIndex) => (
                                                                    <option key={blockIndex}>{block.trim()}</option>
                                                                ))}
                                                            </React.Fragment>
                                                        ))

                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    }
                                    {
                                        ft !== "" && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Tower</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => { get_floor(e.target.value, ProjectName) }}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Towers</option>

                                                    {
                                                        responset.map((data, index) => (
                                                            <React.Fragment key={index}>
                                                                {data.Towers.split(',').map((block, blockIndex) => (
                                                                    <option key={blockIndex}>{block.trim()}</option>
                                                                ))}
                                                            </React.Fragment>
                                                        ))

                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    }

                                    {
                                        block !== "" && (

                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                                <Form.Select
                                                    required
                                                    onChange={(e) => { get_unit(e.target.value) }}
                                                    type="text"
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                >
                                                    <option>Select Floor Name</option>



                                                    {
                                                        response3.map((data, index) => (
                                                            <React.Fragment key={index}>
                                                                {data.selectType.split(',').map((block, blockIndex) => (
                                                                    <option key={blockIndex} >{block.trim()}</option>
                                                                ))}
                                                            </React.Fragment>

                                                        ))

                                                    }

                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter project name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            // <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            //     {
                                            //         selectType === '' && (
                                            //             <FormControl>
                                            //                 <FormLabel id="demo-radio-buttons-group-label">Select Type</FormLabel>
                                            //                 <RadioGroup
                                            //                     aria-labelledby="demo-radio-buttons-group-label"
                                            //                     defaultValue="female"
                                            //                     name="radio-buttons-group"
                                            //                     className='d-flex'
                                            //                 >
                                            //                     <FormControlLabel onChange={(e) => setSelectType(e.target.value)} value="Floor" control={<Radio />} label="Floor" />
                                            //                     <FormControlLabel onChange={(e) => setSelectType(e.target.value)} value="Unit" control={<Radio />} label="Unit" />
                                            //                 </RadioGroup>
                                            //             </FormControl>
                                            //         )
                                            //     }
                                            //     <Typography style={{ color: 'gray', fontSize: 13 }}>{selectType}</Typography>
                                            //     {
                                            //         selectType !== '' && (
                                            //             <Form.Select
                                            //                 required
                                            //                 onChange={(e) => setFloor(e.target.value)}
                                            //                 type="text"
                                            //                 style={{ padding: '0.42rem', marginBottom: 14 }}

                                            //             >
                                            //                 <option>{selectType}</option>

                                            //                 {
                                            //                     response3.map((data) => {
                                            //                         let type = ''
                                            //                         if (data.selectType === selectType) {
                                            //                             type = data.Floors
                                            //                         } else {
                                            //                             return
                                            //                         }
                                            //                         return (
                                            //                             <option>{type} - {data.selectType}</option>
                                            //                         )
                                            //                     })
                                            //                 }

                                            //             </Form.Select>
                                            //         )
                                            //     }
                                            //     <Form.Control.Feedback type="invalid">
                                            //         Please Enter project name
                                            //     </Form.Control.Feedback>
                                            // </Form.Group>
                                        )
                                    }

                                    {
                                        Floorname !== "" && (

                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Unit name</Typography>
                                                <Form.Select
                                                    required
                                                    type="text"
                                                    onChange={(e) => {
                                                        setUnitNo(e.target.value)
                                                    }}
                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                >
                                                    <option>Select units Name</option>
                                                    {response4.map((data, index) => (
                                                        <React.Fragment key={index}>
                                                            {data.unit_name.split(',').map((block, blockIndex) => (
                                                                <option key={blockIndex}>{block.trim()}</option>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid unit name
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    }


                                    {
                                        unitNo !== '' && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>{selectType === 'Floor' ? 'Flat' : 'Unit'} Plan</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setFloorPlan(e.target.files[0])}
                                                    type="file"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}

                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid floor Plan
                                                </Form.Control.Feedback>
                                            </Form.Group>



                                        )
                                    }
                                    {/* 
                                    {
                                        FloorPlan !== "" && (
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>{selectType === 'Floor' ? 'Flat' : 'Unit'} No</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setUnitNo(e.target.value)}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid unit no
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    } */}


                                    {
                                        FloorPlan !== "" && (
                                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                <Typography style={{ color: 'gray', fontSize: 13 }}>{selectType === 'Floor' ? 'Flat' : 'Unit'} Size</Typography>
                                                <Form.Control
                                                    required
                                                    onChange={(e) => setUnitSize(e.target.value)}
                                                    type="text"

                                                    style={{ padding: '0.42rem', marginBottom: 14 }}


                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter valid Unit Size
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )
                                    }





                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            setOpen(false)
                                            window.location.reload()
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={() => setOpen1(false)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "52rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Design</Typography>
                                </div>
                                <Row className="mb-3">


                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => get_block(e.target.value)}
                                            type="text"
                                            value={ProjectName}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Block</Typography>
                                        <Form.Select
                                            required
                                            value={block}
                                            onChange={(e) => get_tower(e.target.value)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Block</option>

                                            {
                                                response2.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.Blocks.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex}>{block.trim()}</option>
                                                        ))}
                                                    </React.Fragment>
                                                ))

                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Tower</Typography>
                                        <Form.Select
                                            required
                                            value={ft}
                                            onChange={(e) => { get_floor(e.target.value) }}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Tower</option>

                                            {
                                                responset.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.Towers.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex}>{block.trim()}</option>
                                                        ))}
                                                    </React.Fragment>
                                                ))

                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                        <Form.Select
                                            required
                                            value={floor}
                                            onChange={(e) => { get_unit(e.target.value) }}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Floor Name</option>



                                            {
                                                response3.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.selectType.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex} >{block.trim()}</option>
                                                        ))}
                                                    </React.Fragment>

                                                ))

                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Floor Plan</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setFloorPlan(e.target.files[0])}
                                            type="file"
                                            // value={FloorPlan}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}

                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid floor Plan
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Flat No</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setUnitNo(e.target.value)}
                                            type="text"
                                            value={unitNo}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        />


                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid facing
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Flat Size</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setUnitSize(e.target.value)}
                                            type="text"
                                            value={UnitSize}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid location
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    {/* <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Flat Facing</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setUnitFacing(e.target.value)}
                                            type="date"
                                            value={UnitFacing}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select Facing</option>
                                            <option>East</option>
                                            <option>West</option>
                                            <option>South</option>
                                            <option>North</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid facing
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Flat Type</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setUnitType(e.target.value)}
                                            type="date"
                                            value={UnitType}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select Type</option>
                                            <option>1 BHK</option>
                                            <option>2 BHK</option>
                                            <option>3 BHK</option>
                                            <option>Studio</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid facing
                                        </Form.Control.Feedback>
                                    </Form.Group> */}

                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert()} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen1(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            
        </div>
    );
}