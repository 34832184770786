import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import axios from "axios";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Swal from "sweetalert2";
import {
  FaEdit,
  FaMapMarkerAlt,
  FaMarker,
  FaTrash,
  FaWindowClose,
  FaUserAlt,
} from "react-icons/fa";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import Units from "./Units";
import Towerblocks from "./Towerblocks";
import Floors from "./Floors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import '../ProjectManegement/Bas/index.css';
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
// import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import pdfUr from "../ProjectManegement/pdfUr.pdf";
import specificPdf from "../ProjectManegement/specificPdf.pdf";
import SpecificationsManager from "../ProjectManegement/SpecificationsManager";
import PrintComponent from "../ProjectManegement/PrintComponent";
import { FaDownload } from "react-icons/fa";
import { Select, FormControl, InputLabel } from "@material-ui/core";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

const steps = ["Step 1", "Step 2"];

export default function Copybasic() {
  const [selectedSpecs, setSelectedSpecs] = useState([]);
  const [anchorElPayment, setAnchorElPayment] = useState(null);

  const handleTabClick = (event, tabValue) => {
    if (tabValue === "6") {
      setAnchorElPayment(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorElPayment(null);
  };

  const openPayment = Boolean(anchorElPayment);

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  const handleRemoveSelectedSpec = (index) => {
    const newSelectedSpecs = selectedSpecs.filter((_, i) => i !== index);
    setSelectedSpecs(newSelectedSpecs);
  };

  const [managerName, setManagerName] = useState();
  const [projectName, setProjectName] = useState();
  const [desc, setDesc] = useState();
  const [location, setLocation] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [id, setId] = useState();
  const [projId, setProjId] = useState();

  const [Intrest, setIntrest] = useState();
  const [floor, setFloor] = useState();
  const [noofunits, setNoofUnits] = useState();
  const [unitno, setUnitno] = useState();
  const [square, setSquare] = useState();
  const [floorplan, setFloorplan] = useState();
  const [blog, setBlog] = useState();
  const [units, setUnits] = useState([]);
  const [noofblocks, setNoofBlocks] = useState();
  const [block, setBlock] = useState();
  const [floorname, setFloorname] = useState([]);
  const [nooftowers, setNoofTowers] = useState();
  const [tower, setTower] = useState();
  const [brochure, setBrochure] = useState();

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "4") {
      // get_dropBlock()
      // get_tyunz()
      console.log("executed");
    } else if (newValue === "5") {
      // get_dropfloor()
    } else if (newValue === "6") {
      // get_floor()
    }
  };

  const currentProjectId=projectName

  const [valuevi, setValuevi] = React.useState("1");

  const handleChangevi = (eventv, newValuev) => {
    setValuevi(newValuev);
  };

  const handleButtonClick = () => {
    // Change tab to the second tab (value="2")
    handleChange(null, "7");
  };

  const [isView, setIsVeiw] = useState();

  const [selectproject, setSelectProject] = useState();
  const [basicprice, setBasicprice] = useState();
  const [upload, setUpload] = useState();
  const [extent, setExtent] = useState();
  const [uom, setUom] = useState();
  const [brochar, setBrochar] = useState();

  const add_basic = () => {
    const formData = new FormData();

    formData.append("projectType", selectproject);
    formData.append("ProjectName", projectName);
    formData.append("plan", upload);
    formData.append("broch", brochar);
    formData.append("Location", location);
    formData.append("BasePrice", basicprice);
    formData.append("Extent", extent);
    formData.append("Uom", uom);

    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/add-basic", formData)
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          // setOpen(false)
          setValidated(true);
          setUpload("");
          get_basic();
          handleChange(null, "2");
          // Swal.fire({
          //     icon: 'success',
          //     title: "Success !",
          //     text: "Data added successfully !",
          //     confirmButtonColor: "rgb(126, 110, 228)",
          //     cancelButtonColor: "#d33",
          //     showCancelButton: false,
          //     confirmButtonText: "Ok"
          // }).then((result) => {
          //     if (result.value) {
          //         return
          //     }
          // })
        } else {
          if (res.data.status === 400) {
            alert("Project name already exists");
          } else {
            console.log("Unhandled status code:", res.data.status);
          }
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
        setOpen(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Project name already exist .please try again.",
          confirmButtonColor: "#d33",
          cancelButtonColor: "rgb(126, 110, 228)",
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      });
  };

  const [response, setResponse] = useState([]);

  const get_basic = () => {
    axios
      .get("https://buildfastapi.emedha.in/project/api/v1/get-basic")
      .then((res) => {
        if (res.data.status === 200) {
          setResponse(res.data.data);
        }
      });
  };

  React.useEffect(() => {
    get_basic();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setValidated(false);
  };

  const [openvi, setOpenvi] = useState(false);

  const handleClosevi = () => {
    setOpenvi(false);
    // setValidated(false)
  };

  const [Validated, setValidated] = useState(false);

  const formValidation = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_basic();
      get_basicp()
    }

    setValidated(true);
  };

  const [unitName, setUnitName] = useState("");
  const [typicalUnitFloor, setTypicalUnitFloor] = useState("");
  const [unitNameRows, setUnitNameRows] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [unam, setUnam] = useState("");

  const handleUnitNameChange = (e) => {
    setUnitName(e.target.value);
    setUnam(e.target.value);
  };

  const handleTypicalUnitFloorChange = (e) => {
    setTypicalUnitFloor(e.target.value);
    gettypical(e.target.value);

    // Generate 10 unit name rows based on the entered unit name
    const rows = Array.from(
      { length: 10 },
      (_, index) => `${unitName} ${index + 1}`
    );
    setUnitNameRows(rows);
  };

  const [Validatedus, setValidatedus] = useState(false);

  const formValidationus = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // addTypicalData()
    }

    setValidatedus(true);
  };

  const [Validatedu, setValidatedu] = useState(false);

  const formValidationu = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_unitstsubmit();
    }

    setValidatedu(true);
  };

  const [tufloor, setTufloor] = useState("");
  const [tusize, setTusize] = useState("");
  const [tufacing, setTufacing] = useState("");
  const [tucorner, setTucorner] = useState("");
  const [tuplan, setTuplan] = useState("");

  const [unitRows, setUnitRows] = useState([]);
  const [formData, setFormData] = useState([]);

  const [numRows, setNumRows] = useState();
  const [sfloor, setSfloor] = useState("");
  const [adata, setAdata] = useState([]);

  const subdata = () => {
    // await handleFormSubmit()

    console.log("sdsubasf", tur);

    const formdata = new FormData();
    // formdata.append("floor", sfloor)
    tur.forEach((item, index) => {
      formdata.append(`data[${index}][unitsize]`, item.unitsize);
      formdata.append(`data[${index}][facing]`, item.facing);
      formdata.append(`data[${index}][cornor]`, item.cornor);
      formdata.append(`data[${index}][pName]`, item.pName);
      formdata.append(`unitplan`, item.unitplan);
    });

    for (const [key, value] of formdata.entries()) {
      console.log(`${key}: ${value}`);
    }

    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/typicalunit",
        formdata
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          handleChange(null, "3");
          setValidatedtu(true);
        }
      });
  };

  const handleNumRowsChange = (e) => {
    const value = parseInt(e.target.value);
    setNumRows(value);
    generateUnitRows(value);
  };

  const [Validatedtu, setValidatedtu] = useState(false);

  const formValidationtu = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      subdata();
    }

    setValidatedtu(true);
  };

  const [Validatedf, setValidatedf] = useState(false);

  const formValidationf = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_floortsubmit();
      // get_floor(bpname)
    }

    setValidatedf(true);
  };

  const [Validatedum, setValidatedum] = useState(false);

  const formValidationum = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      add_um();
    }

    setValidatedum(true);
  };

  const [facing, setFacing] = useState();
  const [facingwise, setFacingwise] = useState();
  const [floorwise, setFloorwise] = useState();
  const [floorwiseamount, setFloorwiseAmount] = useState();
  const [corner, setCorner] = useState("Yes");
  const [corneramount, setCornerAmount] = useState();

  const [waterele, setWaterele] = useState("");
  const [watereleamt, setWatereleamt] = useState("");
  const [clubhou, setClubhou] = useState("");
  const [clubhouamt, setClubhouamt] = useState("");
  const [advmain, setAdvmain] = useState("");
  const [advmainamt, setAdvmainamt] = useState("");
  const [carpar, setCarpar] = useState("");
  const [carparamt, setCarparamt] = useState("");
  const [excarpa, setExcarpa] = useState("");
  const [excarpaamt, setExcarpaamt] = useState("");
  const [carpous, setCarpose] = useState("");
  const [carpousamt, setCarposeamt] = useState("");
  const [cautionamtRef,setCautionamtRef]=useState("")
  const [cautionamtNRef,setCautionamtNRef]=useState("")

  // https://buildfastapi.emedha.in/project/api/v1/add-unitamities

  const add_um = () => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/add-unitamities", {
        waterElectricityAmt: watereleamt,
        clubHouseAmt: clubhouamt,
        advMaitanceAmt: advmainamt,
        carpousAmt: carpousamt,
        cautionRefAmt:cautionamtRef,
        cautionNonRefAmt:cautionamtNRef
      })
      .then((res) => {
        if (res.data.status === 200) {
          // Swal.fire({
          //     icon: 'success',
          //     title: "Success !",
          //     text: "Data added successfully !",
          //     confirmButtonColor: "rgb(126, 110, 228)",
          //     cancelButtonColor: "#d33",
          //     showCancelButton: false,
          //     confirmButtonText: "Ok"
          // }).then((result) => {
          //     if (result.value) {
          //         window.location.reload()
          //         return
          //     }
          // })
        }
      });
  };

  const [Validatedb, setValidatedb] = useState(false);

  const formValidationb = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      blocktabsubmit();
    }

    setValidatedb(true);
  };

  const [Validatedt, setValidatedt] = useState(false);

  const formValidationt = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      towertabsubmit();
      // get_dropfloor(bpname)
    }

    setValidatedt(true);
  };

  const [tbname, setTbname] = useState([]);

  // const [units, setUnits] = useState([]);
  const [bpname, setBpname] = useState("");
  const [bupload, setBupload] = useState();

  const add_blocks = () => {
    const formData1 = new FormData();

    formData1.append("ProjectName", bpname);
    formData1.append("bu", bupload);
    units.forEach((unit, index) => {
      formData1.append("Blocks[]", unit);
    });

    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/add-blocks",
        formData1
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          setOpen(false);

          get_basic();
          Swal.fire({
            icon: "success",
            title: "Success !",
            text: "Data added successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        } else {
          if (res.data.status === 400) {
            alert("Project name already exists");
          } else {
            console.log("Unhandled status code:", res.data.status);
          }
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
        setOpen(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Project name already exist .please try again.",
          confirmButtonColor: "#d33",
          cancelButtonColor: "rgb(126, 110, 228)",
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      });
  };

  const [blockbyid, setBlockbyid] = useState([]);
  const [floordataid, setFloordataid] = useState([]);
  const [towerdataid, setTowerdataid] = useState([]);
  const [unitdataid, setUnitdataid] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [playout, setPlayout] = useState();
  const [blayout, setBlayout] = useState();

  const viewData = (id) => {
    console.log(id);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/view-basic", {
        id,
      })
      .then((res) => {
        console.log(res.data.anotherTableData);
        setBlockbyid(res.data.blocksData);
        setBlayout(res.data.BrochUpload);
        setPlayout(res.data.ImgUpload);
        setFloordataid(res.data.floorData);
        setTowerdataid(res.data.towersData);
        setUnitdataid(res.data.unitsData);
        setProjectName(res.data.ProjectName);
        setUpload(res.data.ImgUpload);
        setBasicprice(res.data.BasePrice);
        setExtent(res.data.Extent);
        setUom(res.data.Uom);
        setLocation(res.data.Location);
        setFacing(res.data.Facing);
        setFacingwise(res.data.FacingAmt);
        setFloorwise(res.data.Floorwise);
        setFloorwiseAmount(res.data.FloorwiseAmt);
        setCorner(res.data.Corner);
        setCornerAmount(res.data.CornerAmt);
        // setOpen1(true)
      });
  };

  const searchBasic = (text) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/search-basic", {
        text,
      })
      .then((res) => {
        setResponse(res.data);
      });
  };

  const [response2, setResponse2] = useState([]);

  const get_account = () => {
    axios
      .get("https://buildfastapi.emedha.in/account-setting/api/v1/get-account")
      .then((res) => {
        if (res.data.status === 200) {
          setResponse2(res.data.data);
        }
      });
  };

  React.useEffect(() => {
    get_account();
    // get_floor()
    // get_dropBlock()
    // get_dropfloor()
    // get_tyunz()
  }, []);

  const [response3, setResponse3] = useState([]);

  const get_floor = (text) => {
    console.log("text", text);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/get-pwfloor", {
        text,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setResponse3(res.data.data);
          console.log("floors getting with the flag", res.data.data);
        }
      });
  };

  const [response4, setResponse4] = useState([]);

  const get_block = (value) => {
    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/projectwise-blocks",
        {
          value,
        }
      )
      .then((res) => {
        setResponse4(res.data);
        setProjectName(value);
      });
  };

  const [noOfUnits, setNoOfUnits] = useState(0);
  const [unitData1, setUnitData1] = useState([]);
  const [utname, setUtname] = useState([]);
  const [tUFloor, setTUFloor] = useState("ss");
  const [uname, setUName] = useState("");
  const [ndia, setNdia] = useState([]);

  const handleNoOfUnitsChange = (e) => {
    const count = parseInt(e.target.value, 10) || 0;
    setNoOfUnits(count);

    const newUnitData = Array.from({ length: count }, (_, index) => ({
      // unit_name: utname ? `${utname} - ${index+1}` : '',
      unitsize: "",
      floor: ndia,
      // floor: utname ? `${utname}`.split(',').map(item => item.trim()) : [],
      mortgage: null,
    }));
    setUnitData1(newUnitData);
    // setUnitData1(() => {
    //     return newUnitData.map(item => ({
    //         ...item,
    //         floor: ndia
    //     }));
    // });
    console.log("undathan", newUnitData);
  };

  // const handleUnitFieldChange = (index, field, value) => {
  //     // const updatedUnitData = [...unitData1];
  //     // updatedUnitData[index] = {
  //     //     ...updatedUnitData[index],
  //     //     [field]: value,
  //     // };
  //     // setUnitData1(updatedUnitData);

  //     const unntna = utname ? `${utname} -` : '';
  //     const unitName = value.split('-')[1]?.trim();

  //     if (value.startsWith(unntna)) {
  //         // const unitName = value.substring(floorName.length).trim(); // Extract the unit name part after the hyphen
  //         const updatedUnitData = [...unitData1];
  //         updatedUnitData[index] = {
  //             ...updatedUnitData[index],
  //             [field]: value,
  //         };
  //         setUnitData1(updatedUnitData);
  //         console.log("asd",updatedUnitData);
  //     }

  // };

  const handleUnitFieldChange = (index, field, value) => {
    const unntna = utname ? `${utname} - ` : "";
    const unitName = value.split("-")[1]?.trim();

    if (field === "unit_name" && value.startsWith(unntna)) {
      const updatedUnitData = [...unitData1];
      updatedUnitData[index] = {
        ...updatedUnitData[index],
        [field]: value,
      };
      setUnitData1(updatedUnitData);
      // console.log("asd", updatedUnitData);
    } else if (field === "unitsize") {
      const updatedUnitData = [...unitData1];
      updatedUnitData[index] = {
        ...updatedUnitData[index],
        [field]: value,
      };
      setUnitData1(updatedUnitData);
      // console.log("asd", updatedUnitData);
    } else if (field === "mortgage") {
      const updatedUnitData = [...unitData1];
      updatedUnitData[index] = {
        ...updatedUnitData[index],
        [field]: value,
      };
      setUnitData1(updatedUnitData);
      console.log("asd", updatedUnitData);
    }
  };

  const handleFileChangeu = (index, event) => {
    const file = event.target.files[0];
    const newUnitData = [...unitData1];
    newUnitData[index].file = file;
    setUnitData1(newUnitData);

    // console.log("File Data for unit", index + 1 + ":", file); https://buildfastapi.emedha.in/project/api/v1/add-unit
  };

  const add_unitstsubmit = () => {
    console.log("submitunitdata", unitData1, ndia);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/add-unit", {
        data: unitData1,
        floors: ndia,
        towers:selectedTowers
      })
      .then((res) => {
        if (res.status === 200) {
          setValidatedu(false);
          setSelectedTowers([]);
          get_floor(selectedTowers);
          handleSecondDialogClose();
          // console.log('floors got executed');
          // console.log(res.data);
          setNdia([]);
          // setOpen(false)
          // handleChange(null, "7");
          setUtname("");
          handleNoOfUnitsChange({ target: { value: 0 } });
        }
      });
  };

  const add_paymntsubmit = () => {
    console.log("paymntsubmit", payha);
    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/paymentschedule",
        payha
      )
      .then((res) => {
        if (res.status === 200) {
          // setValidatedus(false)
          setOpen(false);
          // setTybtn(true)
          Swal.fire({
            icon: "success",
            title: "Success !",
            text: "Data added successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
              handleNoofpaymentChange({ target: { value: 0 } });
            }
          });
        }
      });
  };

  // -------end------//

  //----- blocks------//

  //   const [noofblocks, setNoOfBlocks] = useState(0);
  // const [blockData, setBlockData] = useState([]);

  // const handleNoofBlocksChange = (e) => {
  //     const count = parseInt(e.target.value, 10) || 0;
  //     setNoofBlocks(count);

  //     const newBlockData = Array.from({ length: count }, () => ({
  //         blockname: bpname ? `${bpname} - ` : '',
  //     }));
  //     setBlockData(newBlockData);
  // };

  // const handleBlockFieldChange = (index, field, value) => {
  //     const updatedBlockData = [...unitData1];
  //     updatedBlockData[index] = {
  //         ...updatedBlockData[index],
  //         [field]: value,
  //     };
  //     setBlockData(updatedBlockData);
  // };

  const [payha, setPayha] = useState([]);

  const handleNoofpaymentChange = (e) => {
    const count = parseInt(e.target.value, 10) || 0;
    // setNoofBlocks(count);

    const newpayData = Array.from({ length: count }, (_, index) => ({
      install: `${index + 1}`,
      name: "",
      percentage: "",
      duedate: "",
    }));
    setPayha(newpayData);
  };

  const handlepaymFieldChange = (index, field, value) => {
    const updatedpay = [...payha];
    updatedpay[index] = { ...updatedpay[index], [field]: value };
    setPayha(updatedpay);
    // console.log("paymnt", updatedpay);
  };

  const [tur, setTur] = useState([]);
  const [tyval, setTyval] = useState();

  const handleNooftypicalChange = (e) => {
    const count = parseInt(e.target.value, 10) || 0;
    setTyval(count);
    // setNoofBlocks(count);

    const newTyData = Array.from({ length: count }, (_, index) => ({
      unitsize: "",
      facing: "",
      cornor: "",
      unitplan: null,
      pName: projectName,
    }));
    setTur(newTyData);
  };

  const handleTypicalFieldChange = (index, field, value) => {
    const updatedTur = [...tur];
    updatedTur[index] = { ...updatedTur[index], [field]: value };
    setTur(updatedTur);
    // console.log("typicalData", updatedTur);
  };

  const handleFileChangety = (index, event) => {
    const updatedTur = [...tur];
    updatedTur[index] = {
      ...updatedTur[index],
      unitplan: event.target.files[0],
    };
    setTur(updatedTur);
    console.log("typicalData file", updatedTur);
    // setAdata(updatedTur)
  };

  const [blockData, setBlockData] = useState([]);

  const handleNoofBlocksChange = (e) => {
    const count = parseInt(e.target.value, 10) || 0;
    setNoofBlocks(count);

    const newBlockData = Array.from({ length: count }, (_, index) => ({
      blockname: projectName ? `${projectName} - ` : "",
      file: null,
    }));
    setBlockData(newBlockData);
  };

  const handleBlockFieldChange = (index, field, value) => {
    const projectNames = projectName ? `${projectName} - ` : "";
    let blockName = value.split(" - ")[1];

    blockName = blockName ? blockName.trim() : null;

    const updatedBlockData = [...blockData];
    updatedBlockData[index] = {
      ...updatedBlockData[index],
      blockname: projectNames + (blockName || ""),
    };
    setBlockData(updatedBlockData);
  };

  const handleFileChange = (index, event) => {
    const fileList = event.target.files[0].name;
    const file = event.target.files[0];
    const newBlockData = [...blockData];
    newBlockData[index].file = file;
    setBlockData(newBlockData);

    console.log("File Data for Block", index + 1 + ":", fileList);
  };

  //-----end------//

  const [apblockbtn, setApblockbtn] = useState(false);

  const blocktabsubmit = () => {
    const formData = new FormData();

    formData.append("ProjectName", bpname);
    blockData.forEach((block, index) => {
      formData.append(`Blocks[${index}]`, block.blockname);
      formData.append(`blockPlans`, block.file);
    });

    console.log("FormData values:");
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/add-blocks",
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          handleChange(null, "4");
          setValidatedb(true);
          setApblockbtn(true);
          get_dropBlock(bpname);
        }
      });
  };

  //----- towers------//

  const [towerData, setTowerData] = useState([]);

  console.log(towerData);

  const handleNoofTowersChange = (e) => {
    console.log("Event Target Value:", e.target.value);
    // Your existing code here

    const count = parseInt(e.target.value, 10) || 0;
    setNoofTowers(count);

    const newTowerData = Array.from({ length: count }, (_, index) => ({
      towername: tbname ? `${tbname}-` : "",
      file: null,
    }));

    console.log("New Tower Data:", newTowerData);
    setTowerData(newTowerData);
  };

  // const handleTowerFieldChange = (index, field, value) => {
  //     const updatedTowerData = [...towerData];

  //     updatedTowerData[index] = {
  //         ...updatedTowerData[index],
  //         [field]: value,
  //     };
  //     setTowerData(updatedTowerData);

  // }

  const handleTowerFieldChange = (index, field, value) => {
    console.log("blv", value);
    const blockName = tbname ? `${tbname}-` : "";
    const towerName = value.split("-")[1]?.trim();

    console.log("bn", blockName);
    if (value.startsWith(blockName)) {
      const updatedTowerData = [...towerData];
      updatedTowerData[index] = {
        ...updatedTowerData[index],
        [field]: value,
      };
      setTowerData(updatedTowerData);
    }
  };

  const handleFileChanget = (index, event) => {
    const file = event.target.files[0];
    const newTowerData = [...towerData];
    newTowerData[index].file = file;
    setTowerData(newTowerData);
  };

  //-----end------//

  const [aptowerbtn, setAptowerbtn] = useState(false);

  const towertabsubmit = () => {
    const formData = new FormData();

    formData.append("ProjectName", bpname);

    formData.append("Blocks", tbname);
    towerData.forEach((towe, index) => {
      formData.append(`Towers[${index}]`, towe.towername);
      formData.append(`towerImages`, towe.file);
    });

    // "",

    axios.post("https://buildfastapi.emedha.in/project/api/v1/add-towers", formData).then((res) => {
      if (res.status === 200) {
        // handleChange(null, "5");
        setValidatedt(false);
        get_dropBlock(bpname);
        setAptowerbtn(true);
        setTbname("");

        handleNoofTowersChange({ target: { value: 0 } });
      }
    });
  };

  //----- floors------//

  const [floorData, setFloorData] = useState([]);
  const [nooffloors, setNoOfFLoors] = useState();
  const [ftname, setFtname] = useState([]);

  const handleNoofFloorsChange = (e) => {
    const count = parseInt(e.target.value, 10) || 0;
    setNoOfFLoors(count);

    const newFloorData = Array.from({ length: count }, (_, index) => ({
      selectType: ftname ? `${ftname}-${index + 1}` : "",
      file: null,
    }));
    setFloorData(newFloorData);
    console.log(newFloorData);
  };

  const handleFloorFieldChange = (index, field, value) => {
    // const updatedFloorData = [...floorData];
    // updatedFloorData[index] = {
    //     ...updatedFloorData[index],
    //     [field]: value,
    // };
    // setFloorData(updatedFloorData);

    const towerName = ftname ? `${ftname}-` : "";
    const floorName = value.split("-")[1]?.trim();

    if (value.startsWith(towerName)) {
      const updatedFloorData = [...floorData];
      updatedFloorData[index] = {
        ...updatedFloorData[index],
        [field]: value,
      };
      setFloorData(updatedFloorData);
      // console.log("fldat",updatedFloorData);
    }
  };

  const handleFileChangef = (index, event) => {
    const file = event.target.files[0];
    const newFloorData = [...floorData];
    newFloorData[index].file = file;
    setFloorData(newFloorData);
    console.log(newFloorData);
  };

  //-----end------//


  const add_floortsubmit = () => {
    const formData = new FormData();

    // formData.append("ProjectName", bpname);
    // formData.append("Blocks", tbname);
    // formData.append("Towers", JSON.stringify(towerData));
    formData.append('Floor_Unit', ftname)
    floorData.forEach((floor, index) => {
    formData.append(`selectType[${index}]`, floor.selectType);
      formData.append(`floorPlan`, floor.file);
    });
    console.log("Before appending Towers:", towerData);

    // "https://buildfastapi.emedha.in/project/api/v1/add-floor"

    axios.post("https://buildfastapi.emedha.in/project/api/v1/add-floor", formData).then((res) => {
      // console.log(res.data);
      if (res.status === 200) {
        setValidatedf(false);
        // setOpen(false)
        // handleChange(null, "6");
        console.log(res.data);
        get_dropfloor(bpname);
        setFtname("");

        handleNoofFloorsChange({ target: { value: 0 } });
      }
    });
  };

  // const add_floortsubmit = () => {
  //     console.log('floor',floorDat
  //     axios.post('https://buildfastapi.emedha.in/project/api/v1/add-floor',floorData).then((res => {
  //         console.log(res.data);
  //         get_floor()

  //     }))
  //     .catch((err => {
  //         console.error('Error adding floor:', err);
  //                 Swal.fire("Error", "Failed to add floor", "error");
  //                 setOpen(false)
  //         setTybtn(true)

  //     }))
  // }

  // const add_floortsubmit = () => {

  //     if (Object.values(floorData).some(value => value === "")) {

  //         Swal.fire("Error", "Please fill in all fields", "error");
  //         setOpen(false)
  //         setTybtn(true)
  //         return;
  //     }

  //     console.log('floor', floorData);

  //     axios.post('https://buildfastapi.emedha.in/project/api/v1/add-floor', floorData)
  //     .then((res => {
  //         console.log(res.data);
  //         get_floor();
  //     }))
  //     .catch(error => {
  //         console.error('Error adding floor:', error);
  //         Swal.fire("Error", "Failed to add floor", "error");
  //         setOpen(false)
  //         setTybtn(true)
  //     });
  // }

  const [dropblock, setDropblock] = useState([]);

  const get_dropBlock = (text) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/get-pwb", { text })
      .then((res) => {
        if (res.data.status === 200) {
          setDropblock(res.data.data);
          console.log("pnaajh", res.data.data);
        }
      });
  };

  const areAllCheckboxesDisabled = () => {
    for (const data of dropblock) {
      if (data.flag !== 1) {
        return false;
      }
    }
    return true;
  };

  const areAllCheckboxesDisabledtower = () => {
    for (const data of droptower) {
      if (data.flag !== 1) {
        return false;
      }
    }
    return true;
  };

  const areAllCheckboxesDisabledunit = () => {
    for (const data of response3) {
      if (data.flag !== 1) {
        return false;
      }
    }
    return true;
  };

  // if (areAllCheckboxesDisabledunit()) {
  //     setValidatedu(true);
  // }

  const [droptower, setDroptower] = useState([]);

  const get_dropfloor = (text) => {
    console.log("floordrop", text);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/get-projwt", {
        text,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setDroptower(res.data.data);
          console.log("drops", res.data.data);
        }
      });
  };

  const [typicaluzdrop, setTypicaluzdrop] = useState([]);

  const get_tyunz = (text) => {
    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/gettypical-unitsize",
        { text }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setTypicaluzdrop(res.data.data);
          console.log(res.data.data);
        }
      });
  };

  const [selectedLayout, setSelectedLayout] = useState("Project");

  const handleLayoutChange = (layout) => {
    setSelectedLayout(layout);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const openDialog = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeDialog = () => {
    setSelectedImage(null);
  };

  const Bookapi = (id) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/update-flag", { id })
      .then((res) => {
        if (res.data.status === 200) {
          if (res.status === 200) {
            setValidatedf(false);
            setOpen(false);
            Swal.fire({
              icon: "success",
              title: "Success !",
              text: "Blocked successfully !",
              confirmButtonColor: "rgb(126, 110, 228)",
              cancelButtonColor: "#d33",
              showCancelButton: false,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.value) {
                return;
              }
            });
          }
        }
      });
  };

  const [switchDisabled, setSwitchDisabled] = useState(true);

  const handleSwitchChange = (e) => {
    setSwitchDisabled(!e.target.checked);
    // generateUnitRows(numRows, !e.target.checked);
  };

  const [tydata, setTydata] = useState([]);

  const gettypical = (text) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/search-typical", {
        text,
      })
      .then((res) => {
        console.log(res.data.data);
        setTydata(res.data.data);
      });
  };

  // const  addTypicalData = ()=>{
  //    const totaldata =  tydata.map((item, index) => ({
  //        unit_name : `${utname}-${unam}${index + 1}`,
  //         unitSize : item.unitsize,
  //         Facing: item.facing,
  //         cornor:item.cornor,
  //         UnitPlan:item.unitplan
  //     }))

  //     console.log("asd",totaldata);

  //     axios.post('https://buildfastapi.emedha.in/project/api/v1/unitsadd',totaldata)
  //     .then((res => {
  //         console.log(res.data);
  //         if (res.data.status === 200) {

  //             setValidatedus(false)
  //                 setOpen(false)
  //                 Swal.fire({
  //                     icon: 'success',
  //                     title: "Success !",
  //                     text: "Blocked successfully !",
  //                     confirmButtonColor: "rgb(126, 110, 228)",
  //                     cancelButtonColor: "#d33",
  //                     showCancelButton: false,
  //                     confirmButtonText: "Ok"
  //                 }).then((result) => {
  //                     if (result.value) {
  //                         return
  //                     }
  //                 })
  //         }
  //     }))
  // }

  const combinedData = [...tydata, ...unitNameRows];

  const [tybtn, setTybtn] = useState(true);

  // const [editshow, setEditshow] = useState(true)

  // const editbtnhandle = (id) =>{
  //     alert(id)
  //     setEditshow(!editshow)

  // }
  const [toggId, setToggId] = useState("");
  const [tid, setTid] = useState("");

  const [editStates, setEditStates] = useState(unitdataid.map(() => false));
  const [editedValues, setEditedValues] = useState(
    unitdataid.map((item) => item.unit_name)
  );

  const toggleEdit = (index, Itid) => {
    const newEditStates = [...editStates];
    newEditStates[index] = !newEditStates[index];
    setEditStates(newEditStates);
    viewtoggledata(Itid);
    setTid(Itid);
  };

  const viewtoggledata = (id) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/unit/view", {
        id,
      })
      .then((res) => {
        setToggId(res.data.unit_name);
        console.log(res.data);
      });
  };

  const handleInputChangeed = (event, index, itemId) => {
    const newEditedValues = [...editedValues];
    newEditedValues[index] = event.target.value;
    setEditedValues(newEditedValues);
    console.log("Item ID:", itemId, "Edited Value:", event.target.value);
  };

  const blockEdittoggle = () => {
    console.log({ unit_name: toggId, id: tid });
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/unit-edit", {
        unit_name: toggId,
        id: tid,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          // get_unit()
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: " data is updated successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
              return;
            }
          });
        }
      });
  };

  const deleteAlert = (id) => {
    setOpen(false);
    Swal.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure to delete this data",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        basicDataDelete(id);
      }
    });
  };

  const basicDataDelete = (id) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/delete-basic", {
        id,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setOpen(false);
          get_basic();
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "data is deleted successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
              navigate("/project-basic");
            }
          });
        }
      });
  };

  const [plvale, setPlvale] = useState("");
  const [blvale, setBlvale] = useState("");

  const handleFileChangeply = (e) => {
    const file = e.target.files[0];
    setPlvale(e.target.files[0].name);

    const maxSize = 1024 * 1024;

    if (file.size > maxSize) {
      alert("File size exceeds 1MB limit.");

      e.target.value = null;
      return;
    }
    setUpload(file);
  };

  const handleFileChangebroc = (e) => {
    const file = e.target.files[0];
    setBlvale(e.target.files[0].name);
    const maxSize = 1024 * 1024;

    if (file.size > maxSize) {
      alert("File size exceeds 1MB limit.");

      e.target.value = null;
      return;
    }
    setBrochar(file);
  };

  const [openedd, setOpenedd] = React.useState(false);

  const handleClickOpenedd = () => {
    setOpenedd(true);
  };

  const handleCloseedd = () => {
    setOpenedd(false);
  };

  const navigate = useNavigate();

  const editAlert = (id) => {
    setOpen1(false);
    handleClose2();
    Swal.fire({
      icon: "warning",
      title: "Warning !",
      text: "Are you sure to edit this data",
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        blockEdit(id);
      }
    });
  };

  const blockEdit = (id) => {
    // console.log("edit",{
    //     projectType:selectproject, ProjectName:projectName, Location:location,BasePrice:basicprice,Extent:extent,Uom:uom,id:projId
    // });
    const formData = new FormData();

    formData.append("projectType", selectproject);
    formData.append("ProjectName", projectName);
    formData.append("plan", upload);
    formData.append("broch", brochar);
    formData.append("Location", location);
    formData.append("BasePrice", basicprice);
    formData.append("Extent", extent);
    formData.append("Uom", uom);
    formData.append("id", projId);

    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/basic-edit",
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          get_basic();
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Basic data is updated successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
              return;
            }
          });
        }
      });
  };

  const handleFinishButtonClick = () => {
    setOpen(false);
    Swal.fire({
      title: "Are you sure?",
      text: "Once finished, you cannot revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, finish it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Finished!", "Your task has been completed.", "success").then(
          () => {
            window.location.reload();
          }
        );
      }
    });
  };

  const [npn, setNpn] = useState("");


  const [Validated2, setValidated2] = useState(false);

  const formValidation2 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // handleSubmit()
      handletwo();
    }

    setValidated2(true);
  };


  const [unitdata, setUnitdata] = useState([]);
  const get_unit = (text) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/get-pwunit", {
        text,
      })
      .then((res) => {
        setUnitdata(res.data.data);
        console.log("asddasd", res.data);
      });
  };

  

  

  const [response3p, setResponse3p] = useState([]);

  // const get_floorp = (text) => {
  //     axios.post("https://buildfastapi.emedha.in/project/api/v1/get-pwfloor",{text}).then((res) => {
  //         if (res.data.status === 200) {
  //             setResponse3p(res.data.data)
  //             console.log('floorcount',res.data.data.map((a) => a.selectType));
  //             console.log('Maximum last digit:', Math.max(...res.data.data.map(a => parseInt(a.selectType.slice(-1)))));
  //             const maxLastDigit = Math.max(...res.data.data.map(a => {
  //                 const lastDigit = parseInt(a.selectType.slice(-1));
  //                 return isNaN(lastDigit) ? 0 : lastDigit;
  //             }));
  //             setFcnt(maxLastDigit);
  //             setMaxLastDigit(maxLastDigit);
  //             setFloorDatap(Array.from({ length: maxLastDigit }, () => ({ floorAmount: '' })));
  //         }
  //     })
  // }

 

  


  // const get_floorp = (text) => {
  //   console.log("Fetching floor data for project:", text); // Debugging log
  //   axios
  //     .post("https://buildfastapi.emedha.in/project/api/v1/get-pwfloor", {
  //       text,
  //     })
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         console.log("Floor data fetched successfully:", res.data.data); // Debugging log
  //         setResponse3p(res.data.data);
  //         const selectTypeArray = res.data.data.map((a) => a.selectType);
  
  //         const floorNumbers = selectTypeArray.map((a) => {
  //           const number = parseInt(a.slice(a.lastIndexOf("-") + 1));
  //           return isNaN(number) ? 0 : number;
  //         });
  
  //         const maxFloorNumber = Math.max(...floorNumbers);
          
  //         const validMaxFloorNumber = maxFloorNumber === -Infinity ? 0 : maxFloorNumber;
  
  //         console.log("Max floor number:", validMaxFloorNumber); // Debugging log
  
  //         setFcnt(validMaxFloorNumber);
  //         setMaxLastDigit(validMaxFloorNumber);
          
  //       } else {
  //         console.error("Error: Unexpected status code:", res.data.status);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };
  
  const get_floorp = (text) => {
    axios.post("https://buildfastapi.emedha.in/project/api/v1/get-pwfloor", { text }).then((res) => {
        if (res.data.status === 200) {
            setResponse3p(res.data.data);
            console.log('floorcount', res.data.data.map((a) => a.selectType));

            // Correctly extract the floor number from selectType
            const maxFloorNumber = Math.max(...res.data.data.map(a => {
                // Split the string by '-' and get the last part as the floor number
                const floorNumber = parseInt(a.selectType.split('-').pop());
                return isNaN(floorNumber) ? 0 : floorNumber;
            }));

            console.log('Maximum floor number:', maxFloorNumber);

            setFcnt(maxFloorNumber);
            setMaxLastDigit(maxFloorNumber);
            setFloorDatap(Array.from({ length: maxFloorNumber }, () => ({ floorAmount: '' })));
        }
    });
}


const [prjname, setPrjname] = useState("");

const handleChangePrjName=(e)=>{
  const selectedProject = e.target.value;
  setPrjname(selectedProject)
  get_floorp(selectedProject)

}


const [responsep, setResponsep] = useState([]);


const get_basicp = () => {
  axios
    .get("https://buildfastapi.emedha.in/project/api/v1/get-basic")
    .then((res) => {
      if (res.data.status === 200) {
        setResponsep(res.data.data);
        console.log(res.data.data)
        console.log(responsep)
      }
    });
};




React.useEffect(() => {
  get_basicp();
 


  getpriceface();
  get_unit();

}, []);



  const [maxLastDigit, setMaxLastDigit] = useState(0);
  const [floorDatap, setFloorDatap] = useState([]);

  console.log(floorDatap)

  const handleFloorAmountChange = (index, event) => {
    const { value } = event.target;
    setFloorDatap(prevFloorData => {
        const updatedFloorData = [...prevFloorData];
        updatedFloorData[index] = { ...updatedFloorData[index], floorAmount: value };
        return updatedFloorData;
    });
};




  React.useEffect(() => {
    get_floorp(prjname); // Fetch data and update maxLastDigit
  }, [prjname]);
  
  // React.useEffect(() => {
  //   calculateFloorGroups(); // Calculate floor groups based on maxLastDigit
  // }, [maxLastDigit]);


  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleFinish = () => {
    setActiveStep(0);
    setOpen1(false);
    setValidated(false);
    setValidated2(false);
    window.location.reload();
  };

  //   const handleFloorRiseAmountChange = (index, blockIndex, amount) => {
  //     // Log the data
  //     console.log(`Floor ${index + 1}, ${response3[index].selectType.split(',')[blockIndex].trim()}: ${amount}`);
  //     // You can assign the amount to the particular floor here
  // };

  const [fcnt, setFcnt] = useState(0);

  const initialFloorRiseAmounts = React.useMemo(
    () => Array(fcnt).fill({}),
    [fcnt]
  );

  const [floorRiseAmounts, setFloorRiseAmounts] = useState(
    initialFloorRiseAmounts
  );

  const handleFloorRiseAmountChange = (index, blockIndex, amount) => {
    // Update floor rise amount for the specified floor and block
    const updatedFloorRiseAmounts = [...floorRiseAmounts];
    updatedFloorRiseAmounts[index] = {
      ...updatedFloorRiseAmounts[index],
      [blockIndex]: amount,
    };
    setFloorRiseAmounts(updatedFloorRiseAmounts);
    console.log("framt", updatedFloorRiseAmounts);
  };

  // const [prjname, setPrjname] = useState("");
  const [ocpark, setOcpark] = useState("");
  const [tcpark, setTcpark] = useState("");
  const [ecpark, setEpark] = useState("");
  const [pvamt, setPvamt] = useState("");
  const [camt, setCamt] = useState("");
  const [sname, setSname] = useState("");
  const [scheamt, setScheamt] = useState("");
  const [btntwo, setBtntwo] = useState(true);
  const [btnone, setBtnone] = useState(true);

  const handletwo = () => {
    console.log({ ocpark, tcpark, ecpark, pvamt, camt, sname, scheamt });
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/pricetwodata", {
        onecarpark: ocpark,
        twocarpark: tcpark,
        threecarpark: ecpark,
        parkview_amt: pvamt,
        corner_amt: camt,
        scheme_amt: scheamt,
        scheme_name: sname,
        projName: prjname,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setBtntwo(false);
          console.log(res.data);
        }
      });
  };

  const [fdata, setFdata] = useState();
  const [basepricep, setBasicpricep] = useState("");
  const [eamt, setEamt] = useState("");
  const [wamt, setWamt] = useState("");
  const [namt, setNamt] = useState("");
  const [samt, setSamt] = useState("");
  // const [prjname, setPrjname] = useState('')
  const [facingData,setFacingData]=useState([])
  console.log(facingData)

  // https://buildfastapi.emedha.in/project/api/v1/pricdataso

  const handleSubmit = async() => {

    const formattedFloorData = floorDatap.map((item, index) => ({
      floorName: `floor${index + 1}`,
      floorRiseAmount: item.floorAmount || 0,
    }));

    console.log("Formatted Floor Data:", formattedFloorData);

    console.log({
      basepricep,
      eamt,
      wamt,
      namt,
      samt,
      prjname,
      fdata: formattedFloorData,
    });

    axios
      .post("http://localhost:3006/pricdataso", {
        baseprice: basepricep,
        east_amt: eamt,
        west_amt: wamt,
        north_amt: namt,
        south_amt: samt,
        projName: prjname,
        floorrisedata: formattedFloorData,
        onecarpark: ocpark,
        twocarpark: tcpark,
        threecarpark: ecpark,
        parkview_amt: pvamt,
        corner_amt: camt,
        scheme_amt: scheamt,
        scheme_name: sname,
      })
      .then((res) => {
        if (res.data.status == 200) {
          console.log(res.data);
          setFacingData(res.data)
          setBtnone(false);
        }
      })
      .catch((err) => {
        alert(
          "already pricing has been set for the selected project choose another project"
        );
        console.error("errorrrrr", err);
      });
  
    }

//   const handleSubmit = async () => {
//     try {
//         // Prepare formatted floor data
//         const formattedFloorData = response3p.map((item) => ({
//             floorName: item.selectType,
//             floorRiseAmount: item.floorAmount || 0,
//         }));

//         // Log formatted data for debugging
//         console.log("Formatted Floor Data:", formattedFloorData);

//         // Log payload for debugging
//         console.log({
//             basepricep,
//             eamt,
//             wamt,
//             namt,
//             samt,
//             prjname,
//             fdata: formattedFloorData,
//         });

//         // First API call to update floor rise data
//         let response1;
//         try {
//             response1 = await axios.post(
//                 "https://buildfastapi.emedha.in/project/api/v1/pricdataso",
//                 {
//                     baseprice: basepricep,
//                     east_amt: eamt,
//                     west_amt: wamt,
//                     north_amt: namt,
//                     south_amt: samt,
//                     projName: prjname,
//                     floorrisedata: formattedFloorData,
//                     onecarpark: ocpark,
//                     twocarpark: tcpark,
//                     threecarpark: ecpark,
//                     parkview_amt: pvamt,
//                     corner_amt: camt,
//                     scheme_amt: scheamt,
//                     scheme_name: sname,
//                 }
//             );

//             // Check response from the first API call
//             if (response1.data.status === 200) {
//                 console.log("Response 1:", response1.data);
//                 setFacingData(response1.data); // Update state or handle response data as needed
//                 setBtnone(false); // Example of state update
//             } else {
//                 throw new Error("Invalid response status from first API call");
//             }
//         } catch (error) {
//             console.error("Error in first API call:", error);
//             throw new Error("Error occurred while updating floor rise data");
//         }

//         // Second API call to update floor amounts
//         let response2;
//         try {
//             response2 = await axios.post(
//                 "http://localhost:3006/api/update-floor-amount",
//                 {
//                     projectName: prjname,
//                     floorAmounts: formattedFloorData.map((floor) => ({
//                         floorName: floor.floorName, // Assuming floorName is correctly mapped
//                         startFloor: floor.floorName.replace(/\D/g, ""), // Extract numeric part of floorName
//                         endFloor: floor.floorName.replace(/\D/g, ""), // Extract numeric part of floorName
//                         amount: floor.floorRiseAmount,
//                     })),
//                 }
//             );

//             // Check response from the second API call
//             console.log("Response 2:", response2.data);

//             // Handle response or update state as needed

//         } catch (error) {
//             console.error("Error in second API call:", error);
//             throw new Error("Error occurred while updating floor amounts");
//         }

//     } catch (error) {
//         console.error("General error in handleSubmit:", error);
//         // Handle general errors, such as showing an alert or logging the error
//         alert("Error occurred. Please try again.");
//     }
// };



  const [scheme, setScheme] = useState("no");

  const handleSchemeChange = (e) => {
    setScheme(e.target.value);
  };

  const [pricfacedata, setPricfacedata] = useState([]);

  const getpriceface = () => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/getallfacing")
      .then((res) => {
        setPricfacedata(res.data.data);
        console.log(pricfacedata)
      });
  };

  const [showm, setShowm] = useState(false);

  const handleClosem = () => setShowm(false);
  const handleShowm = () => setShowm(true);

  const [pdprojname, setPdprojname] = useState("");
  const [pdeamt, setPdeamt] = useState("");
  const [pdnamt, setPdnamt] = useState("");
  const [pdsamt, setPdsamt] = useState("");
  const [pdwamt, setPdwamt] = useState("");
  const [pdcor, setPdcor] = useState("");
  const [pdo, setPdo] = useState("");
  const [pdtwo, setPdtwo] = useState("");
  const [pdthre, setPdthre] = useState("");
  const [pdpv, setPdpv] = useState("");
  const [pdbp, setPdbp] = useState("");
  const [pdsname, setPdsname] = useState("");
  const [pdscheamt, setPdschamt] = useState("");
  const [pdwateramt, setPdwateramt] = useState("");
  const [pdadvance, setPdadvance] = useState("");
  const [pdclub, setPdclub] = useState("");
  const [pdcarpusfund, setPdcarpusfund] = useState("");

  const viewprice = (id) => {
    axios
      .post(
        "https://buildfastapi.emedha.in/project/api/v1/projwiseviewfacing",
        { id }
      )
      .then((res) => {
        console.log("view", res.data);
        setPdwateramt(res.data[0].waterElectricityAmt);
        setPdadvance(res.data[0].advMaitanceAmt);
        setPdclub(res.data[0].clubHouseAmt);
        setPdcarpusfund(res.data[0].carpousAmt);
        setPdprojname(res.data[0].projName);
        setPdeamt(res.data[0].east_amt);
        setPdnamt(res.data[0].north_amt);
        setPdsamt(res.data[0].south_amt);
        setPdwamt(res.data[0].west_amt);
        setPdcor(res.data[0].corner_amt);
        setPdo(res.data[0].onecarpark);
        setPdtwo(res.data[0].twocarpark);
        setPdthre(res.data[0].threecarpark);
        setPdpv(res.data[0].parkview_amt);
        setPdbp(res.data[0].baseprice);
        setPdsname(res.data[0].scheme_name);
        setPdschamt(res.data[0].scheme_amt);
      });
  };

  const [priceid, setPriceid] = useState("");

  const priceedit = () => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/editpricedata", {
        east_amt: pdeamt,
        west_amt: pdwamt,
        north_amt: pdnamt,
        south_amt: pdsamt,
        baseprice: pdbp,
        onecarpark: pdo,
        twocarpark: pdtwo,
        threecarpark: pdthre,
        parkview_amt: pdpv,
        corner_amt: pdcor,
        scheme_name: pdsname,
        scheme_amt: pdscheamt,
        id: priceid,
      })
      .then((res) => {
        if (res.data.status === 200) {
          setOpen11(false);
          getpriceface();
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: "Basic data is updated successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  const [floorrisecharge, setFloorrisecharge] = useState([]);

  const getchgrefloor = (text) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/getfloorisecharge", {
        text,
      })
      .then((res) => {
        console.log(res.data);
        setFloorrisecharge(res.data.data);
      });
  };


  // const [floorGroups, setFloorGroups] = useState([]);

  // React.useEffect(() => {
  //   const calculateFloorGroups = () => {
  //     const groups = [];
  //     const maxLastDigit = response3.length;

  //     if (maxLastDigit <= 3) {
  //       groups.push({
  //         startFloor: response3[0].selectType,
  //         endFloor: response3[maxLastDigit - 1].selectType,
  //         amount: '',
  //       });
  //     } else {
  //       let start = 0;
  //       const increment = 5;

  //       while (start < maxLastDigit) {
  //         const end = Math.min(start + increment, maxLastDigit);
  //         groups.push({
  //           startFloor: response3[start].selectType,
  //           endFloor: response3[end - 1].selectType,
  //           amount: '',
  //         });
  //         start += increment;
  //       }
  //     }

  //     setFloorGroups(groups);
  //   };

  //   if (response3.length > 0) {
  //     calculateFloorGroups();
  //   }
  // }, [response3]);

  // const handleFloorGroupAmountChange = (index, value) => {
  //   setFloorGroups((prevGroups) => {
  //     const updatedGroups = [...prevGroups];
  //     updatedGroups[index] = {
  //       ...updatedGroups[index],
  //       amount: value,
  //     };
  //     return updatedGroups;
  //   });
  // };




  // const handleSubmit = async () => {
  //   try {
  //     const formattedFloorData = floorGroups.map(group => ({
  //       selectType: `${group.startFloor} - ${group.endFloor}`,
  //       amount: group.amount,
  //     }));

  //     console.log({
  //       basepricep,
  //       eamt,
  //       wamt,
  //       namt,
  //       samt,
  //       prjname,
  //       fdata: formattedFloorData,
  //     });

  //     const response1 = await axios.post(
  //       "https://buildfastapi.emedha.in/project/api/v1/pricdataso",
  //       {
  //         baseprice: basepricep,
  //         east_amt: eamt,
  //         west_amt: wamt,
  //         north_amt: namt,
  //         south_amt: samt,
  //         projName: prjname,
  //         floorrisedata: formattedFloorData,
  //         onecarpark: ocpark,
  //         twocarpark: tcpark,
  //         threecarpark: ecpark,
  //         parkview_amt: pvamt,
  //         corner_amt: camt,
  //         scheme_amt: scheamt,
  //         scheme_name: sname,
  //       }
  //     );

  //     if (response1.data.status === 200) {
  //       console.log("Response 1:", response1.data);
  //       setFacingData(response1.data);
  //       setBtnone(false);
  //     } else {
  //       throw new Error("Invalid response status from first API call");
  //     }

  //     for (const floorData of formattedFloorData) {
  //       const { selectType, amount } = floorData;
  //       try {
  //         const response2 = await axios.put('http://localhost:3006/api/floor-units', {
  //           floor: selectType,
  //           amount: amount
  //         });

  //         if (response2.status === 200) {
  //           console.log(`Successfully updated floor ${selectType} with amount ${amount}`);
  //         } else {
  //           console.error(`Failed to update floor ${selectType}`);
  //         }
  //       } catch (error) {
  //         console.error(`Error updating floor ${selectType}:`, error);
  //       }
  //     }

  //   } catch (error) {
  //     console.error("Error in handleSubmit:", error);
  //     alert("Error occurred. Please try again.");
  //   }
  // };

  const [Validatedpric, setValidatedpric] = useState(false);

const formValidationpric = (event) => {
  event.preventDefault();
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
  } else {
    // onepricesubmit()
    handleSubmit();
  }

  setValidatedpric(true);
};


  const [openpd, setOpenpd] = useState(false);

  const [valuee, setValuee] = React.useState("1");

  const handleChangev = (event, newValue) => {
    setValuee(newValue);
    if (newValue !== "6") {
      handleMenuClose();
    }
  };

  const priceDataDelete = (id) => {
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/pricing-rem", {
        id,
      })
      .then((res) => {
        if (res.status === 200) {
          getpriceface();
          Swal.fire({
            icon: "success",
            title: "Successfull !",
            text: " data is deleted successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.value) {
              return;
            }
          });
        }
      });
  };

  const [storrectprjname, setStorrectprjname] = useState("");

  const blockflagtozero = (id) => {
    console.log(id);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/blckzero", { id })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          get_dropBlock(storrectprjname);
          setValidatedt(false);
        }
      });
  };

  const towerflagtozero = (id) => {
    console.log(id);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/towerzero", { id })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          get_dropfloor(storrectprjname);
          setValidatedf(false);
        }
      });
  };

  const [remval, setRemval] = useState("");

  const unitflagtozero = (id) => {
    console.log(id);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/floorzero", { id })
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          get_floor(remval);
          setValidatedu(false);
        }
      });
  };

  const [showblockabtn, setShowblockabtn] = useState(false);
  const [showtowerabtn, setShowtowerabtn] = useState(false);
  const [showfloorabtn, setShowfloorabtn] = useState(false);

  const [openSecondDialog, setOpenSecondDialog] = useState(false);

  const handleSecondDialogOpen = () => {
    setOpenSecondDialog(true);
  };

  const handleSecondDialogClose = () => {
    setOpenSecondDialog(false);
  };

  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedTowers, setSelectedTowers] = useState([]);

  // const handleBlockChange = (block) => {
  //     const updatedBlocks = [...selectedBlocks];
  //     const index = updatedBlocks.indexOf(block);
  //     if (index === -1) {
  //         updatedBlocks.push(block);
  //     } else {
  //         updatedBlocks.splice(index, 1);
  //         // Clear towers and floors when a block is unchecked
  //         setSelectedTowers([]);
  //     }
  //     setSelectedBlocks(updatedBlocks);
  //     get_dropfloor(updatedBlocks);
  // };

  const handleBlockChange = (block) => {
    setSelectedBlocks([block]);
    setSelectedTowers([]);
    setSelectedCheckboxs("");
    get_dropfloor([block]);
  };

  // const handleTowerChange = (tower) => {
  //     const updatedTowers = [...selectedTowers];
  //     const index = updatedTowers.indexOf(tower);
  //     if (index === -1) {
  //         updatedTowers.push(tower);
  //     } else {
  //         updatedTowers.splice(index, 1);
  //     }
  //     setSelectedTowers(updatedTowers);
  //     get_floor(updatedTowers);
  // };

  const handleTowerChange = (tower) => {
    setSelectedTowers([tower]);
    setSelectedCheckboxs("");
    get_floor([tower]);
  };

  const [selectedBlocksm, setSelectedBlocksm] = useState([]);
  const [selectedTowersm, setSelectedTowersm] = useState([]);
  const [selectedCheckboxs, setSelectedCheckboxs] = useState("");

  // const handleBlockChangem = (block) => {
  //     const updatedBlocksm = [...selectedBlocksm];
  //     const index = updatedBlocksm.indexOf(block);
  //     if (index === -1) {
  //         updatedBlocksm.push(block);
  //     } else {
  //         updatedBlocksm.splice(index, 1);
  //         setSelectedTowersm([]);
  //     }
  //     setSelectedBlocksm(updatedBlocksm);
  //     get_dropfloor(updatedBlocksm);
  // };

  const handleBlockChangem = (block) => {
    setSelectedBlocksm([block]);
    setSelectedTowersm([]);
    setSelectedCheckboxs("");
    get_dropfloor([block]);
  };

  // const handleTowerChangem = (tower) => {
  //     const updatedTowersm = [...selectedTowersm];
  //     const index = updatedTowersm.indexOf(tower);
  //     if (index === -1) {
  //         updatedTowersm.push(tower);
  //     } else {
  //         updatedTowersm.splice(index, 1);
  //     }
  //     setSelectedTowersm(updatedTowersm);
  //     get_floor(updatedTowersm);
  // };

  const handleTowerChangem = (tower) => {
    setSelectedTowersm([tower]);
    setSelectedCheckboxs("");
    get_floor([tower]);
  };

  const [mortgdata, setMortgdata] = useState([]);

  const handlemortgage = (text) => {
    setSelectedCheckbox(text);
    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/getmortgageset", {
        text,
      })
      .then((res) => {
        setMortgdata(res.data.data);
      });
  };

  const [selectedMortgages, setSelectedMortgages] = useState({});

  const handleSelectChange = (e, id) => {
    const { value } = e.target;
    setSelectedMortgages((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    console.log(value, id);

    axios
      .post("https://buildfastapi.emedha.in/project/api/v1/updatemortageval", {
        value,
        id,
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  const handleEditClick = (id) => {
    setSelectedMortgages((prevState) => ({
      ...prevState,
      [id]: undefined,
    }));
  };

  const [selectedCheckbox, setSelectedCheckbox] = useState("");

  const openPdf = () => {
    window.open(specificPdf);
  };

  const openSpecificationsPdf = () => {
    window.open(specificPdf);
  };

  const [openPreEmi, setPreEmi] = useState(false);
  const [openRera, setRera] = useState(false);

  const handleChangePreEMi = () => {
    setPreEmi(true);
    setRera(false);
  };

  const handleChangeRera = () => {
    setPreEmi(false);
    setRera(true);
  };

  const [count, setCount] = useState(0);
  const [schedules, setSchedules] = useState([]);

  const handleCountChange = (event) => {
    const value = event.target.value;
    const newCount = parseInt(value, 10);

    if (!isNaN(newCount) && newCount > 0) {
      setCount(newCount);
      setSchedules(Array(newCount).fill({ due: "", stage: "", timeline: "" }));
    } else {
      setCount(0);
      setSchedules([]);
    }
  };

  const handleInputChange = (index, field, value) => {
    const newSchedules = schedules.map((schedule, i) =>
      i === index ? { ...schedule, [field]: value } : schedule
    );
    setSchedules(newSchedules);
  };

  const handleSubmitPayment = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
    try {
      const response = await axios.post("https://buildfastapi.emedha.in/project/api/v1/rera", {
        schedulesRera: schedules,
      });
      alert(response.data.message); // Success message from backend
      // Optionally, you can reset the form or show a success message to the user
    } catch (error) {
      alert("Error saving RERA data:", error.response.data.message); // Error message from backend
      // Optionally, you can show an error message to the user
    }
    console.log("Submitted schedules:", schedules);
  };

  const handleCancel = () => {
    // Handle form cancellation logic here
    setSchedules(Array(count).fill({ due: "", stage: "", timeline: "" }));
  };

  const [countPreEMi, setCountPreMi] = useState(0);
  const [schedulesPreEmi, setSchedulesPreEmi] = useState([]);

  const handleCountChangePreEmi = (event) => {
    const value = event.target.value;
    const newCount = parseInt(value, 10);

    if (!isNaN(newCount) && newCount > 0) {
      setCountPreMi(newCount);
      setSchedulesPreEmi(
        Array(newCount).fill({ due: "", stage: "", timeline: "" })
      );
    } else {
      setCountPreMi(0);
      setSchedulesPreEmi([]);
    }
  };

  const handleInputChangePreEmi = (index, field, value) => {
    const newSchedules = schedulesPreEmi.map((schedule, i) =>
      i === index ? { ...schedule, [field]: value } : schedule
    );
    setSchedulesPreEmi(newSchedules);
  };

  const handleSubmitPaymentPreEmi = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://buildfastapi.emedha.in/project/api/v1/pre-emi", {
        schedulesPre: schedulesPreEmi,
      });
      alert(response.data.message); // Success message from backend
      // Optionally, you can reset the form or show a success message to the user
    } catch (error) {
      alert("Error saving Pre EMI data:", error.response.data.message); // Error message from backend
      // Optionally, you can show an error message to the user
    }
    console.log("Submitted schedules:", schedules);
  };

  const handleCancelPreEmi = () => {
    // Handle form cancellation logic here
    setSchedules(Array(count).fill({ due: "", stage: "", timeline: "" }));
  };

  const Pname = sessionStorage.getItem("ProjectName");

  return (
    <div>
      <div className="leadManagementTable">
        <Paper elevation={0}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={valuee}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  color: "rgb(86, 63, 146)",
                }}
              >
                <TabList
                  onChange={handleChangev}
                  aria-label="lab API tabs example"
                  indicatorColor="secondary"
                >
                  <Tab
                    label="Project Details"
                    value="1"
                    style={{ color: "rgb(86, 63, 146)" }}
                  />
                  <Tab
                    label="Pricing Details"
                    value="2"
                    style={{ color: "rgb(86, 63, 146)" }}
                  />
                  <Tab
                    label="UnitwisePrice"
                    value="3"
                    style={{ color: "rgb(86, 63, 146)" }}
                  />
                  <Tab
                    label="FAQs"
                    value="4"
                    style={{ color: "rgb(86, 63, 146)" }}
                    onClick={openPdf}
                  />
                  <Tab
                    label="Specifications"
                    value="5"
                    style={{ color: "rgb(86, 63, 146)" }}
                  />
                  <Tab
                    label="Payment Schedule"
                    value="6"
                    style={{ color: "rgb(86, 63, 146)" }}
                    onClick={(event) => handleTabClick(event, "6")}
                  />
                </TabList>
              </Box>

              <Menu
                anchorEl={anchorElPayment}
                open={openPayment}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleChangePreEMi}>Pre EMI</MenuItem>
                <MenuItem onClick={handleMenuClose}>Customized</MenuItem>
                <MenuItem onClick={handleChangeRera}>As Per RERA</MenuItem>
              </Menu>
              <TabPanel value="1">
                {!isView && (
                  <div className="leadManagementTable">
                    <Typography
                      style={{
                        color: "rgba(41, 16, 105, 1)",
                        marginBottom: 3,
                        marginLeft: 15.5,
                        fontSize: 18,
                        color: "gray",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Project Management Details
                    </Typography>
                    <Paper elevation={0}>
                      <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }}>
                        <Typography
                          style={{
                            color: "rgba(41, 16, 105, 1)",
                            marginBottom: 3,
                            marginLeft: 15.5,
                            fontSize: 18,
                            color: "gray",
                            fontWeight: "500",
                          }}
                        >
                          Search Filter
                        </Typography>

                        <div
                          style={{
                            height: "0.1rem",
                            borderBottom: "0.2px solid lightgray",
                          }}
                        ></div>
                        <div
                          className="card mt-2"
                          style={{
                            border: "none",
                            paddingLeft: 15,
                            paddingRight: 15,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <input
                              onChange={(e) => searchBasic(e.target.value)}
                              style={{
                                height: "2.3rem",
                                color: "gray",
                                borderRadius: "0.3rem",
                                outline: "none",
                                width: "26rem",
                                border: "0.7px solid lightgray",
                              }}
                              placeholder="Search..."
                            />
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                setOpen(true);
                              }}
                              style={{
                                width: "9rem",
                                height: "2.3rem",
                                backgroundColor: "rgb(126, 110, 228)",
                                color: "white",
                              }}
                            >
                              + Add Project
                            </Button>
                          </div>
                        </div>
                        <Table style={{ position: "relative", top: "1.3rem" }}>
                          <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                            <TableRow>
                              {/* <TableCell
                                                align={"start"}
                                                style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                            >
                                                Project type
                                            </TableCell> */}
                              <TableCell
                                align={"start"}
                                style={{
                                  minWidth: 0,
                                  fontSize: 14,
                                  fontWeight: "600",
                                  color: "gray",
                                  fontSize: 13,
                                }}
                              >
                                Project Name
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{
                                  minWidth: 0,
                                  fontSize: 14,
                                  fontWeight: "600",
                                  color: "gray",
                                  fontSize: 13,
                                }}
                              >
                                Basic Price
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{
                                  top: 57,
                                  fontWeight: "600",
                                  color: "gray",
                                  fontSize: 13,
                                }}
                              >
                                Location
                              </TableCell>
                              <TableCell
                                align={"start"}
                                style={{
                                  top: 57,
                                  fontWeight: "600",
                                  color: "gray",
                                  fontSize: 13,
                                }}
                              >
                                Extent
                              </TableCell>

                              <TableCell
                                align={"start"}
                                style={{
                                  top: 57,
                                  fontWeight: "600",
                                  color: "gray",
                                  fontSize: 13,
                                }}
                              >
                                Unit Of Measurement
                              </TableCell>

                              <TableCell
                                align={"left"}
                                style={{
                                  top: 21,
                                  fontWeight: "600",
                                  color: "gray",
                                  fontSize: 13,
                                  width: 21,
                                }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {response.map((row) => {
                              let color = "";
                              let widths = "";

                              if (row.lead === "Not Customer") {
                                color = "red";
                                widths = false;
                              }

                              if (row.lead === "Customer") {
                                color = "green";
                                widths = true;
                              }

                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  {/* <TableCell align={"start"}
                                                            style={{ top: 57, fontSize: 12 }}>{row.projectType}</TableCell> */}
                                  <TableCell
                                    align={"start"}
                                    style={{ top: 57, fontSize: 12 }}
                                  >
                                    {row.ProjectName}
                                  </TableCell>
                                  <TableCell
                                    align={"start"}
                                    style={{ top: 57, fontSize: 12 }}
                                  >
                                    {row.BasePrice}
                                  </TableCell>
                                  <TableCell
                                    align={"start"}
                                    style={{ top: 57, fontSize: 12 }}
                                  >
                                    <button
                                      style={{
                                        fontSize: 10.7,
                                        border: "2px solid rgb(126, 110, 228)",
                                        backgroundColor: "rgb(126, 110, 228)",
                                        borderRadius: "1.2rem",
                                        color: "white",
                                        cursor: "auto",
                                      }}
                                    >
                                      <FaMapMarkerAlt
                                        style={{
                                          marginRight: 3,
                                          color: "white",
                                          marginTop: "-0.16rem",
                                          fontSize: 9,
                                        }}
                                      />
                                      {row.Location}
                                    </button>
                                  </TableCell>
                                  <TableCell
                                    align={"start"}
                                    style={{ top: 57, fontSize: 12 }}
                                  >
                                    {row.Extent}
                                  </TableCell>
                                  {/* <TableCell align={"start"}
                                                            style={{ top: 57 }}>
                                                            <div style={{ border: `3px solid ${color}`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%', overflow: 'hidden' }}>
                                                                <img src={`${row.upload}`} width='35px' />
                                                            </div>
                                                        </TableCell> */}
                                  <TableCell
                                    align={"start"}
                                    style={{ top: 57, fontSize: 12 }}
                                  >
                                    {row.Uom}
                                  </TableCell>
                                  {/* <img src={`${row.ImgUpload}`} alt='h'/> */}

                                  <TableCell
                                    align="start"
                                    style={{
                                      top: 57,
                                      fontSize: 12,
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "grey",
                                      }}
                                    >
                                      <VisibilityIcon
                                        onClick={(e) => {
                                          viewData(row.Id);
                                          setProjId(row.Id);
                                          setOpenvi(true);
                                          // setIsVeiw(true);
                                        }}
                                        style={{ color: "dark" }}
                                      />
                                      <DeleteForeverIcon
                                        style={{
                                          top: 60,
                                          marginLeft: 10,
                                          color: "grey",
                                        }}
                                        onClick={() => deleteAlert(row.Id)}
                                      />
                                      <EditNoteIcon
                                        style={{
                                          top: 60,
                                          marginLeft: 10,
                                          color: "grey",
                                        }}
                                        onClick={() => {
                                          handleClickOpenedd();
                                          setProjId(row.Id);
                                          viewData(row.Id);
                                          setNpn(row.ProjectName);
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        {response.length === 0 && (
                          <Box
                            className="card"
                            style={{
                              border: "none",
                              height: "58vh",
                              width: "100%",
                              boxShadow: "none",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="https://cdn-icons-png.flaticon.com/128/2291/2291944.png"
                              width="145px"
                            />
                            <Typography>0 customers</Typography>
                          </Box>
                        )}
                      </TableContainer>
                    </Paper>
                  </div>
                )}
              </TabPanel>
              <TabPanel value="2">
                <Typography
                  style={{
                    color: "rgba(41, 16, 105, 1)",
                    marginBottom: 3,
                    marginLeft: 15.5,
                    fontSize: 18,
                    color: "gray",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Pricing Details
                </Typography>

                <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }}>
                  {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}

                  <div
                    style={{
                      height: "0.1rem",
                      borderBottom: "0.2px solid lightgray",
                    }}
                  ></div>
                  <div
                    className="card mt-2"
                    style={{
                      border: "none",
                      paddingLeft: 15,
                      paddingRight: 15,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <input
                        onChange={(e) => searchBasic(e.target.value)}
                        style={{
                          height: "2.3rem",
                          color: "gray",
                          borderRadius: "0.3rem",
                          outline: "none",
                          width: "26rem",
                          border: "0.7px solid lightgray",
                        }}
                        placeholder="Search..."
                      />
                    </div>
                    <div></div>
                    {(sessionStorage.getItem("Add") === "1" ||
                      sessionStorage.getItem("superAdmin")) && (
                      <div>
                        {/* <Button onClick={() => setOpen1(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add </Button> */}
                      </div>
                    )}
                  </div>
                  <Table style={{ position: "relative", top: "1.3rem" }}>
                    <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                      <TableRow>
                        <TableCell
                          align={"start"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          Project Name
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          Base Price
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          Scheme Name
                        </TableCell>

                        <TableCell
                          align={"start"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          Scheme Amount
                        </TableCell>

                        <TableCell
                          align={"left"}
                          style={{
                            top: 21,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                            width: 21,
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pricfacedata.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.Id}
                          >
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {row.projName}
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {row.baseprice}
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {row.scheme_name}
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {" "}
                              {row.scheme_amt}
                            </TableCell>

                            <TableCell
                              align={"center"}
                              style={{ top: 57, fontSize: 12, display: "flex" }}
                            >
                              <div>
                                <VisibilityIcon
                                  style={{
                                    top: 60,
                                    marginLeft: 10,
                                    color: "grey",
                                  }}
                                  onClick={() => {
                                    handleShowm();
                                    viewprice(row.Id);
                                    setOpenpd(true);
                                    getchgrefloor(row.projName);
                                  }}
                                />
                              </div>
                              <div>
                                <DeleteRoundedIcon
                                  style={{
                                    top: 60,
                                    marginLeft: 10,
                                    color: "grey",
                                  }}
                                  onClick={() => priceDataDelete(row.Id)}
                                />
                              </div>
                              <EditNoteIcon
                                onClick={() => {
                                  setOpen11(true);
                                  viewprice(row.Id);
                                  setPriceid(row.Id);
                                }}
                                style={{
                                  top: 60,
                                  marginLeft: 10,
                                  color: "grey",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {pricfacedata.length === 0 && (
                    <Box
                      className="card"
                      style={{
                        border: "none",
                        height: "58vh",
                        width: "100%",
                        boxShadow: "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/2291/2291944.png"
                        width="145px"
                      />
                      <Typography>No Data Found</Typography>
                    </Box>
                  )}
                </TableContainer>
              </TabPanel>
              <TabPanel value="3">
                <Typography
                  style={{
                    color: "rgba(41, 16, 105, 1)",
                    marginBottom: 3,
                    marginLeft: 15.5,
                    fontSize: 18,
                    color: "gray",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Unit wise Price Details
                </Typography>

                <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }}>
                  {/* <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography> */}

                  <div
                    style={{
                      height: "0.1rem",
                      borderBottom: "0.2px solid lightgray",
                    }}
                  ></div>
                  <div
                    className="card mt-2"
                    style={{
                      border: "none",
                      paddingLeft: 15,
                      paddingRight: 15,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <input
                        onChange={(e) => searchCommon(e.target.value)}
                        style={{
                          height: "2.3rem",
                          color: "gray",
                          borderRadius: "0.3rem",
                          outline: "none",
                          width: "26rem",
                          border: "0.7px solid lightgray",
                        }}
                        placeholder="Search..."
                      />
                    </div>
                    <div></div>
                    {/* {
                                (sessionStorage.getItem('Add') === '1' || sessionStorage.getItem('superAdmin')) && (

                                    <div>
                                        <Button onClick={() => setOpen1(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add </Button>
                                    </div>
                                )
                            } */}

                    <div style={{ width: "20rem" }}>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Project Name
                        </Typography>
                        <Form.Select
                          onChange={(e) => {
                            get_unit(e.target.value);
                          }}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        >
                          <option>Select Project Name</option>
                          {responsep.map((data) => {
                            return <option>{data.ProjectName}</option>;
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Unit Measurements
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <Table style={{ position: "relative", top: "1.3rem" }}>
                    <TableHead sx={{ borderTop: "1px solid lightgray" }}>
                      <TableRow>
                        <TableCell
                          align={"start"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          unitname
                        </TableCell>
                        <TableCell
                          align={"start"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          unitsize
                        </TableCell>
                        <TableCell
                          align={"center"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          Cornor -- Amount
                        </TableCell>

                        <TableCell
                          align={"center"}
                          style={{
                            minWidth: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          Facing -- amount
                        </TableCell>

                        <TableCell
                          align={"left"}
                          style={{
                            top: 21,
                            fontWeight: "600",
                            color: "gray",
                            fontSize: 13,
                            width: 21,
                          }}
                        >
                          Total value (INR)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unitdata.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {row.unit_name}
                            </TableCell>
                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {row.unitsizeNum}
                            </TableCell>
                            <TableCell
                              align={"center"}
                              style={{ top: 57, fontSize: 12 }}
                            >{`${row.cornor} -- ${row.cornerAmount}`}</TableCell>
                            <TableCell
                              align={"center"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {" "}
                              {`${row.Facing} -- ${row.facingAmount}`}
                            </TableCell>

                            <TableCell
                              align={"start"}
                              style={{ top: 57, fontSize: 12 }}
                            >
                              {row.totalvalue}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {unitdata.length === 0 && (
                    <Box
                      className="card"
                      style={{
                        border: "none",
                        height: "58vh",
                        width: "100%",
                        boxShadow: "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/128/2291/2291944.png"
                        width="145px"
                      />
                      <Typography>No Data Found</Typography>
                    </Box>
                  )}
                </TableContainer>
              </TabPanel>
              <TabPanel value="4">
                <Typography
                  style={{
                    color: "rgba(41, 16, 105, 1)",
                    marginBottom: 3,
                    marginLeft: 15.5,
                    fontSize: 18,
                    color: "gray",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Frequently Asked Questions
                </Typography>
              </TabPanel>
              <TabPanel value="5">
                <div style={{ width: "47rem" }}>
                  <Form noValidate validated={Validatedt}>
                    <div className="mb-4 mt-1">
                      <Typography
                        style={{
                          textAlign: "center",
                          fontWeight: "500",
                          color: "rgb(126, 110, 228)",
                          fontSize: 21,
                        }}
                      >
                        Add Specifications
                      </Typography>
                    </div>
                    <div>
                      <button
                        onClick={openSpecificationsPdf}
                        type="button"
                        style={{
                          marginLeft: "auto",
                          color: "rgb(126, 110, 228)",
                          cursor: "pointer",
                        }}
                      >
                        All Specifications <FaDownload />
                      </button>
                      <SpecificationsManager
                        setSelectedSpecs={setSelectedSpecs}
                      />
                      <div style={{ display: "flex", alignItems: "end" }}>
                        <PrintComponent
                          selectedSpecs={selectedSpecs}
                          handleRemoveSelectedSpec={handleRemoveSelectedSpec}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </TabPanel>

              <TabPanel value="6">
                {openPreEmi && (
                  <>
                    <Typography
                      style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                      PRE EMI
                    </Typography>
                    <form
                      onSubmit={handleSubmitPaymentPreEmi}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        padding: "1rem",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        maxWidth: "800px",
                        margin: "auto",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1rem",
                        }}
                      >
                        Enter the number of schedules:
                        <input
                          type="number"
                          value={countPreEMi}
                          onChange={handleCountChangePreEmi}
                          min="1"
                          required
                          style={{
                            padding: "0.5rem",
                            fontSize: "1rem",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            marginTop: "0.5rem",
                          }}
                        />
                      </label>

                      {schedulesPreEmi.map((schedule, index) => (
                        <div
                          key={index}
                          className="schedule-input"
                          style={{
                            display: "flex",
                            gap: "1rem",
                            marginBottom: "1rem",
                            padding: "1rem",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        >
                          <label
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            Due Percentage:
                            <input
                              type="text"
                              value={schedule.due}
                              onChange={(e) =>
                                handleInputChangePreEmi(
                                  index,
                                  "due",
                                  e.target.value
                                )
                              }
                              required
                              style={{
                                padding: "0.5rem",
                                fontSize: "1rem",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginTop: "0.5rem",
                              }}
                            />
                          </label>
                          <label
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            Stage of Construction:
                            <input
                              type="text"
                              value={schedule.stage}
                              onChange={(e) =>
                                handleInputChangePreEmi(
                                  index,
                                  "stage",
                                  e.target.value
                                )
                              }
                              required
                              style={{
                                padding: "0.5rem",
                                fontSize: "1rem",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginTop: "0.5rem",
                              }}
                            />
                          </label>
                          <label
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            Expected Timeline:
                            <input
                              type="date"
                              value={schedule.timeline}
                              onChange={(e) =>
                                handleInputChangePreEmi(
                                  index,
                                  "timeline",
                                  e.target.value
                                )
                              }
                              required
                              style={{
                                padding: "0.5rem",
                                fontSize: "1rem",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginTop: "0.5rem",
                              }}
                            />
                          </label>
                        </div>
                      ))}

                      <div style={{ display: "flex", gap: "1rem" }}>
                        <button
                          type="submit"
                          style={{
                            padding: "0.5rem 1rem",
                            fontSize: "1rem",
                            border: "none",
                            borderRadius: "5px",
                            background: "#007BFF",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelPreEmi}
                          style={{
                            padding: "0.5rem 1rem",
                            fontSize: "1rem",
                            border: "none",
                            borderRadius: "5px",
                            background: "#6c757d",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </>
                )}

                {openRera && (
                  <>
                    <Typography
                      style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                      RERA
                    </Typography>

                    <form
                      onSubmit={handleSubmitPayment}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        padding: "1rem",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        maxWidth: "800px",
                        margin: "auto",
                      }}
                    >
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1rem",
                        }}
                      >
                        Enter the number of schedules:
                        <input
                          type="number"
                          value={count}
                          onChange={handleCountChange}
                          min="1"
                          required
                          style={{
                            padding: "0.5rem",
                            fontSize: "1rem",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            marginTop: "0.5rem",
                          }}
                        />
                      </label>

                      {schedules.map((schedule, index) => (
                        <div
                          key={index}
                          className="schedule-input"
                          style={{
                            display: "flex",
                            gap: "1rem",
                            marginBottom: "1rem",
                            padding: "1rem",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        >
                          <label
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            Due Percentage:
                            <input
                              type="text"
                              value={schedule.due}
                              onChange={(e) =>
                                handleInputChange(index, "due", e.target.value)
                              }
                              required
                              style={{
                                padding: "0.5rem",
                                fontSize: "1rem",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginTop: "0.5rem",
                              }}
                            />
                          </label>
                          <label
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            Stage of Construction:
                            <input
                              type="text"
                              value={schedule.stage}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "stage",
                                  e.target.value
                                )
                              }
                              required
                              style={{
                                padding: "0.5rem",
                                fontSize: "1rem",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginTop: "0.5rem",
                              }}
                            />
                          </label>
                          <label
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            Expected Timeline:
                            <input
                              type="date"
                              value={schedule.timeline}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "timeline",
                                  e.target.value
                                )
                              }
                              required
                              style={{
                                padding: "0.5rem",
                                fontSize: "1rem",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                marginTop: "0.5rem",
                              }}
                            />
                          </label>
                        </div>
                      ))}

                      <div style={{ display: "flex", gap: "1rem" }}>
                        <button
                          type="submit"
                          style={{
                            padding: "0.5rem 1rem",
                            fontSize: "1rem",
                            border: "none",
                            borderRadius: "5px",
                            background: "#007BFF",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={handleCancel}
                          style={{
                            padding: "0.5rem 1rem",
                            fontSize: "1rem",
                            border: "none",
                            borderRadius: "5px",
                            background: "#6c757d",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </div>

      {isView && (
        <div
          className="leadManagementTable leadManagementTable1 p-4"
          style={{
            backgroundColor: "white",
            borderRadius: "0.4rem",
            position: "relative",
          }}
        >
          <FaWindowClose
            onClick={() => setIsVeiw(false)}
            style={{
              position: "absolute",
              right: 10,
              top: 12,
              color: "rgb(126, 110, 228)",
              fontSize: 21,
              cursor: "pointer",
              zIndex: 10000,
            }}
          />

          <div
            className="card"
            style={{
              backgroundColor: "white",
              borderRadius: "0.4rem",
              border: "none",
              boxShadow: "none",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: 26,
                fontWeight: "bold",
                color: "rgb(126, 110, 228)",
              }}
            >
              <img
                src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
                width="145px"
              />
            </Typography>
            <Typography>Project information details</Typography>
          </div>
          <br />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "21px 46px",
            }}
          >
            <div
              className="card"
              style={{
                backgroundColor: "white",
                borderRadius: "0.4rem",
                border: "1px solid rgb(126, 110, 228)",
                padding: "11px",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Project Name: {projectName}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Basic Price: {basicprice}
                </span>
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Location: {location}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Extent: {extent}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Unit of Measurement: {uom}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  No Of Blocks: {blockbyid.length}
                </span>
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Block Names:
                </span>
                {blockbyid.map((item) => (
                  <p>{item.Blocks}</p>
                ))}{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  No Of Towers: {towerdataid.length}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Tower Names:
                </span>{" "}
                {towerdataid.map((item) => (
                  <p>{item.Towers}</p>
                ))}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  No Of Floors: {floordataid.length}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Floor Names:
                </span>
                {floordataid.map((item) => (
                  <p>{item.selectType}</p>
                ))}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  No Of Units: {unitdataid.length}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Unit Names &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                </span>
                {unitdataid.map((item) => (
                  <p>{`${item.unit_name}  --  ${item.Facing}  --  ${item.cornor}  --  ${item.unitsizeNum}`}</p>
                ))}{" "}
              </Typography>
              {/* <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Unit Names - Facing - corner - unitSize </span>{unitdataid.map((item) => {
                                <p>{`${item.unit_name} - ${item.unitsizeNum} - ${item.Facing} - ${item.cornor}`}</p>
                                })} </Typography> */}
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Facing: {facing}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Facingwise Amount: {facingwise}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Floorwise: {floorwise}
                </span>
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Floorwise Amount: {floorwiseamount}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Corner: {corner}
                </span>{" "}
              </Typography>
              <Typography
                style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
              >
                <span style={{ fontSize: 17, color: "black" }}>
                  Corner Amount: {corneramount}
                </span>{" "}
              </Typography>
            </div>

            {/* <div className='card' style={{ backgroundColor: 'white', borderRadius: '0.4rem', border: '1px solid rgb(126, 110, 228)', padding: '11px', flexDirection: 'column', flex: 1 }}>

                                {
                                    response.map((item) => {
                                        <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Project Layout:</span>
                                    <img src={item.ImgUpload} alt="Project Layout" style={{ width: '100%', height: 'auto', maxWidth: '300px', marginTop: '10px' }} />
                                </Typography>
                                    })
                                }
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Brochure:</span>
                                    <img src='/' alt="Brochure" style={{ width: '100%', height: 'auto', maxWidth: '300px', marginTop: '10px' }} />
                                </Typography>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Block Layout:</span>
                                    <img src={bupload} alt="Block Layout" style={{ width: '100%', height: 'auto', maxWidth: '300px', marginTop: '10px' }} />
                                </Typography>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Tower Layout:</span>
                                    <img src='/' alt="Tower Layout" style={{ width: '100%', height: 'auto', maxWidth: '300px', marginTop: '10px' }} />
                                </Typography>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Floor Layout:</span>
                                    <img src='/' alt="Floor Layout" style={{ width: '100%', height: 'auto', maxWidth: '300px', marginTop: '10px' }} />
                                </Typography>
                                <Typography style={{ marginBottom: 34, color: 'rgb(163, 163, 165)' }}><span style={{ fontSize: 17, color: 'black' }}>Unit Layout:</span>
                                    <img src='/' alt="Unit Layout" style={{ width: '100%', height: 'auto', maxWidth: '300px', marginTop: '10px' }} />
                                </Typography>

                            </div> */}

            <div
              className="card"
              style={{
                backgroundColor: "white",
                borderRadius: "0.4rem",
                border: "1px solid rgb(126, 110, 228)",
                padding: "11px",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    // padding: '15px 3px',
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "2px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleLayoutChange("Project")}
                >
                  Project Layout
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleLayoutChange("Block")}
                >
                  Block Layout
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleLayoutChange("Tower")}
                >
                  Tower Layout
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    padding: "5px 3px",
                    width: "47%",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleLayoutChange("Floor")}
                >
                  Floor Layout
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleLayoutChange("Unit")}
                >
                  Unit Layout
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleLayoutChange("Brochure")}
                >
                  Brochure Layout
                </button>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <h5>{selectedLayout} - Layout</h5>
                  {selectedLayout === "Project" && (
                    <img
                      src={playout}
                      alt="Project Layout"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "300px",
                        marginTop: "10px",
                      }}
                    />
                  )}
                  {selectedLayout === "Block" &&
                    blockbyid.map((item) => (
                      <div key={item.id} style={{ marginBottom: "10px" }}>
                        <Chip
                          avatar={
                            <Avatar alt="Natacha" src={item.BlockUpload} />
                          }
                          label={item.Blocks}
                          variant="outlined"
                          onClick={() => openDialog(item.BlockUpload)}
                          style={{ margin: "0 auto" }}
                        />
                      </div>
                    ))}
                  {selectedImage && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                      onClick={closeDialog}
                    >
                      <div
                        style={{
                          position: "relative",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      >
                        <img
                          src={selectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                          }}
                        />
                        <button
                          onClick={closeDialog}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: "#fff",
                            fontSize: "20px",
                            color: "black",
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                  )}
                  {selectedLayout === "Tower" &&
                    towerdataid.map((item) => (
                      <div key={item.id} style={{ marginBottom: "10px" }}>
                        <Chip
                          avatar={<Avatar alt="Natacha" src={item.TUpload} />}
                          label={item.Towers}
                          variant="outlined"
                          onClick={() => openDialog(item.TUpload)}
                          style={{ margin: "0 auto" }}
                        />
                      </div>
                    ))}
                  {selectedLayout === "Floor" &&
                    floordataid.map((item) => (
                      <div key={item.id} style={{ marginBottom: "10px" }}>
                        <Chip
                          avatar={<Avatar alt="Natacha" src={item.Fuploads} />}
                          label={item.selectType}
                          variant="outlined"
                          onClick={() => openDialog(item.Fuploads)}
                          style={{ margin: "0 auto" }}
                        />
                      </div>
                    ))}
                  {selectedLayout === "Unit" &&
                    unitdataid.map((item, index) => (
                      <div key={item.id} style={{ marginBottom: "10px" }}>
                        <div className="d-flex gap-4">
                          {item.flag === 0 && (
                            <FaLockOpen
                              style={{
                                color: "green",
                                fontSize: 8,
                                cursor: "pointer",
                                height: "1.0rem",
                                width: "3rem",
                                position: "relative",
                                top: "0.4rem",
                                textAlign: "center",
                              }}
                              onClick={() => Bookapi(item.id)}
                            />
                          )}
                          {item.flag === 2 && (
                            <FaLock
                              style={{
                                color: "red",
                                fontSize: 8,
                                cursor: "pointer",
                                height: "1.0rem",
                                width: "3rem",
                                position: "relative",
                                top: "0.4rem",
                                textAlign: "center",
                              }}
                              onClick={() => Bookapi(item.id)}
                            />
                          )}
                          <div>
                            {editStates[index] ? (
                              <input
                                type="text"
                                value={toggId}
                                onChange={(event) => {
                                  setToggId(event.target.value);
                                  handleInputChangeed(event, index, item.id);
                                }}
                                style={{
                                  width: "18rem",
                                  borderBottom: "1px solid black",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  padding: "5px",
                                  fontSize: "small",
                                }}
                              />
                            ) : (
                              <Chip
                                avatar={
                                  <Avatar alt="Natacha" src={item.UnitPlan} />
                                }
                                label={item.unit_name}
                                variant="outlined"
                                onClick={() => openDialog(item.UnitPlan)}
                                style={{
                                  margin: "0 auto",
                                  width: "18rem",
                                  backgroundColor: `${
                                    item.flag === 0
                                      ? "green"
                                      : item.flag === 1
                                      ? "red"
                                      : "grey"
                                  }`,
                                }}
                              />
                            )}
                            {editStates[index] ? (
                              <PublishIcon onClick={blockEdittoggle} />
                            ) : (
                              <ModeEditIcon
                                onClick={() => toggleEdit(index, item.id)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  {selectedLayout === "Brochure" && (
                    <>
                      <a href={blayout}>view pdf</a>
                      <img
                        src={blayout}
                        alt="Brochure Layout"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "300px",
                          marginTop: "10px",
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Dialog
          style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
          maxWidth="xl"
          open={openvi}
          onClose={handleClosevi}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={valuevi}>
                {/* <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 18, marginBottom:10 }}>Add Project & Pricing</Typography> */}
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    color: "rgb(86, 63, 146)",
                  }}
                >
                  <TabList
                    onChange={handleChangevi}
                    aria-label="lab API tabs example"
                    indicatorColor="secondary"
                  >
                    <Tab
                      label="Project"
                      value="1"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    {/* <Tab label="Typical Units" value="2" style={{ color: 'rgb(86, 63, 146)' }} /> */}
                    <Tab
                      label="Blocks"
                      value="3"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Towers"
                      value="4"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Floors"
                      value="5"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Units"
                      value="6"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  {/* <Typography style={{  fontWeight: 'bold', color: 'grey', fontSize: 15 }}>FloorRise Charges</Typography> */}
                  <div>
                    <Typography
                      style={{ marginBottom: 20, color: "rgb(163, 163, 165)" }}
                    >
                      <span style={{ fontSize: 17, color: "black" }}>
                        Project Name: {projectName}
                      </span>{" "}
                    </Typography>
                    <Typography
                      style={{ marginBottom: 20, color: "rgb(163, 163, 165)" }}
                    >
                      <span style={{ fontSize: 17, color: "black" }}>
                        Basic Price: {basicprice}
                      </span>
                    </Typography>
                    <Typography
                      style={{ marginBottom: 20, color: "rgb(163, 163, 165)" }}
                    >
                      <span style={{ fontSize: 17, color: "black" }}>
                        Location: {location}
                      </span>{" "}
                    </Typography>
                    <Typography
                      style={{ marginBottom: 20, color: "rgb(163, 163, 165)" }}
                    >
                      <span style={{ fontSize: 17, color: "black" }}>
                        Extent: {extent}
                      </span>{" "}
                    </Typography>
                    <Typography
                      style={{ marginBottom: 20, color: "rgb(163, 163, 165)" }}
                    >
                      <span style={{ fontSize: 17, color: "black" }}>
                        Unit of Measurement: {uom}
                      </span>{" "}
                    </Typography>
                    <img
                      src={playout}
                      alt="Project Layout"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "300px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </TabPanel>

                <TabPanel value="2">
                  <Typography
                    style={{ fontWeight: "bold", color: "grey", fontSize: 15 }}
                  >
                    Typical Units
                  </Typography>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>UnitSize</th>
                        <th>Facing</th>
                        <th>Corner</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unitdataid.map((data) => {
                        return (
                          <tr>
                            <td>{data.unitsizeNum}</td>
                            <td>{data.Facing}</td>
                            <td>{data.cornor}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value="3">
                  <Typography
                    style={{ fontWeight: "bold", color: "grey", fontSize: 15 }}
                  >
                    Total Blocks : {blockbyid.length}
                  </Typography>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Block Layout</th>
                        <th>Block Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blockbyid.map((data) => {
                        return (
                          <tr key={data.Id}>
                            <td>
                              <Chip
                                avatar={
                                  <Avatar
                                    alt="Natacha"
                                    src={data.BlockUpload}
                                  />
                                }
                                variant="outlined"
                                onClick={() => openDialog(data.BlockUpload)}
                                style={{ margin: "0 auto" }}
                              />
                            </td>
                            <td>{data.Blocks}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value="4">
                  <Typography
                    style={{ fontWeight: "bold", color: "grey", fontSize: 15 }}
                  >
                    Total Towers : {towerdataid.length}
                  </Typography>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Tower Layout</th>
                        <th>Tower Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {towerdataid.map((data) => {
                        return (
                          <tr key={data.Id}>
                            <td>
                              <Chip
                                avatar={
                                  <Avatar alt="Natacha" src={data.TUpload} />
                                }
                                variant="outlined"
                                onClick={() => openDialog(data.TUpload)}
                                style={{ margin: "0 auto" }}
                              />
                            </td>
                            <td>{data.Towers}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value="5">
                  <Typography
                    style={{ fontWeight: "bold", color: "grey", fontSize: 15 }}
                  >
                    Total Floors : {floordataid.length}
                  </Typography>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Floor Layout</th>
                        <th>Floor Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {floordataid.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>
                              <Chip
                                avatar={
                                  <Avatar alt="Natacha" src={data.Fuploads} />
                                }
                                variant="outlined"
                                onClick={() => openDialog(data.Fuploads)}
                                style={{ margin: "0 auto" }}
                              />
                            </td>
                            <td>{data.selectType}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value="6">
                  <Typography
                    style={{ fontWeight: "bold", color: "grey", fontSize: 15 }}
                  >
                    Total Units : {unitdataid.length}
                  </Typography>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Unit Layout</th>
                        <th>Unit name</th>
                        <th>Facing</th>
                        <th>Corner</th>
                        <th>unitsize</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unitdataid.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>
                              <Chip
                                avatar={
                                  <Avatar alt="Natacha" src={data.UnitPlan} />
                                }
                                variant="outlined"
                                onClick={() => openDialog(data.UnitPlan)}
                                style={{ margin: "0 auto" }}
                              />
                            </td>
                            <td>{data.unit_name}</td>
                            <td>{data.Facing}</td>
                            <td>{data.cornor}</td>
                            <td>{data.unitsizeNum}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </TabPanel>
              </TabContext>
              {selectedImage && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                  onClick={closeDialog}
                >
                  <div
                    style={{
                      position: "relative",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  >
                    <img
                      src={selectedImage}
                      alt="Selected Image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <button
                      onClick={closeDialog}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        color: "#fff",
                        fontSize: "20px",
                        color: "black",
                      }}
                    >
                      ×
                    </button>
                  </div>
                </div>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <Dialog
          style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
          maxWidth="xl"
          open={open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <button
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "20px",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  color: "#fff",
                  fontSize: "15px",
                  color: "black",
                }}
                onClick={handleClose}
              >
                x
              </button>
              <TabContext value={value}>
                {/* <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 18, marginBottom:10 }}>Add Project & Pricing</Typography> */}
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    color: "rgb(86, 63, 146)",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    indicatorColor="secondary"
                  >
                    <Tab
                      label="Add Project"
                      value="1"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Typical Units"
                      value="2"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Add Blocks"
                      value="3"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Add Towers"
                      value="4"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Add Floors"
                      value="5"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Add Units"
                      value="6"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Mortgage"
                      value="7"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    <Tab
                      label="Pricing"
                      value="8"
                      style={{ color: "rgb(86, 63, 146)" }}
                    />
                    {/* <Tab label="Payment" value="8" style={{ color: 'rgb(86, 63, 146)' }} /> */}
                  </TabList>
                </Box>
                <TabPanel value="7">
                  <div style={{ width: "47rem" }}>
                    <Form
                      noValidate
                      validated={Validatedu}
                      onSubmit={formValidationu}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Add Mortgage
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <div>
                          <Form.Group controlId="validationCustom01">
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Blocks
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: 14,
                              }}
                            >
                              {/* Map over blocks */}
                              {dropblock.map((data, index) => (
                                <React.Fragment key={index}>
                                  {data.Blocks.split(",").map(
                                    (block, blockIndex) => (
                                      <div
                                        key={blockIndex}
                                        style={{
                                          marginRight: "20px",
                                          marginBottom: "5px",
                                          display: "flex",
                                        }}
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label={block.trim()}
                                          onClick={() =>
                                            handleBlockChangem(block.trim())
                                          }
                                          checked={selectedBlocksm.includes(
                                            block.trim()
                                          )}
                                        />
                                      </div>
                                    )
                                  )}
                                </React.Fragment>
                              ))}
                            </div>

                            {/* Towers */}
                            {selectedBlocksm.length > 0 && (
                              <>
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Towers
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginBottom: 14,
                                  }}
                                >
                                  {droptower.map((data, index) => (
                                    <React.Fragment key={index}>
                                      {data.Towers.split(",").map(
                                        (tower, towerIndex) => (
                                          <div
                                            key={towerIndex}
                                            style={{
                                              marginRight: "20px",
                                              marginBottom: "5px",
                                              display: "flex",
                                            }}
                                          >
                                            <Form.Check
                                              type="checkbox"
                                              label={tower.trim()}
                                              onClick={() =>
                                                handleTowerChangem(tower.trim())
                                              }
                                              checked={selectedTowersm.includes(
                                                tower.trim()
                                              )}
                                            />
                                          </div>
                                        )
                                      )}
                                    </React.Fragment>
                                  ))}
                                </div>
                              </>
                            )}

                            {/* Floors */}
                            {selectedTowersm.length > 0 && (
                              <>
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Floor
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginBottom: 14,
                                  }}
                                >
                                  {response3.map((data, index) => (
                                    <React.Fragment key={index}>
                                      {data.selectType
                                        .split(",")
                                        .map((block, blockIndex) => {
                                          const isChecked =
                                            selectedCheckbox === block.trim();
                                          return (
                                            <div
                                              key={blockIndex}
                                              style={{
                                                marginRight: "20px",
                                                marginBottom: "5px",
                                                display: "flex",
                                              }}
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                label={block.trim()}
                                                onClick={() =>
                                                  handlemortgage(block.trim())
                                                }
                                                checked={isChecked}
                                              />
                                            </div>
                                          );
                                        })}
                                    </React.Fragment>
                                  ))}
                                </div>
                              </>
                            )}
                          </Form.Group>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Unit Name</th>
                                <th>UnitSize</th>
                                <th>Facing</th>
                                <th>Corner</th>
                                <th>Mortgage</th>
                              </tr>
                            </thead>
                            <tbody>
                              {mortgdata.map((data) => {
                                return (
                                  <tr key={data.id}>
                                    <td>{data.unit_name}</td>
                                    <td>{data.unitsizeNum}</td>
                                    <td>{data.Facing}</td>
                                    <td>{data.cornor}</td>
                                    <td>
                                      {selectedMortgages[data.id] !==
                                      undefined ? (
                                        <span
                                          onClick={() =>
                                            handleEditClick(data.id)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {selectedMortgages[data.id] === "2"
                                            ? "Yes"
                                            : "No"}{" "}
                                          (Edit)
                                        </span>
                                      ) : data.mort_check === 1 ? (
                                        <p>done</p>
                                      ) : (
                                        <select
                                          required
                                          onChange={(e) =>
                                            handleSelectChange(e, data.id)
                                          }
                                          style={{
                                            padding: "0.42rem",
                                            marginBottom: 14,
                                          }}
                                          value={
                                            selectedMortgages[data.id] !==
                                            undefined
                                              ? selectedMortgages[data.id]
                                              : ""
                                          }
                                        >
                                          <option disabled value="">
                                            --Select Mortgage--
                                          </option>
                                          <option value={2}>Yes</option>
                                          <option value={0}>No</option>
                                        </select>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        <div className="d-flex">
                          <div>
                            <Button
                              onClick={() => {
                                let newValue = (
                                  parseInt(value, 10) - 1
                                ).toString();
                                handleChange(null, newValue);
                              }}
                              style={{
                                width: "9rem",
                                height: "2.3rem",
                                backgroundColor: "gray",
                                color: "white",
                                marginRight: 3,
                              }}
                            >
                              Previous
                            </Button>
                          </div>
                          {!areAllCheckboxesDisabledunit() && (
                            <div>
                              <Button
                                type="submit"
                                style={{
                                  width: "9rem",
                                  height: "2.3rem",
                                  backgroundColor: "rgb(126, 110, 228)",
                                  color: "white",
                                  marginRight: 3,
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          )}

                          {areAllCheckboxesDisabledunit() && (
                            <div>
                              <Button
                                onClick={() => {
                                  handleChange(null, "8");
                                  setShowfloorabtn(true);
                                }}
                                style={{
                                  width: "9rem",
                                  height: "2.3rem",
                                  backgroundColor: "rgb(126, 110, 228)",
                                  color: "white",
                                  marginRight: 3,
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          )}
                        </div>
                      </Row>
                    </Form>
                  </div>
                </TabPanel>

                <TabPanel value="1">
                  <div style={{ width: "47rem" }}>
                    <Form
                      noValidate
                      validated={Validated}
                      onSubmit={formValidation}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          {" "}
                          Project
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Select Project
                          </Typography>
                          <Form.Select
                            required
                            onChange={(e) => setSelectProject(e.target.value)}
                            type="text"
                            value={selectproject}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          >
                            <option>Select Project</option>
                            <option>Apartment</option>
                            <option>Villa</option>
                            <option>Plotting</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Basic Price
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Project Name
                          </Typography>
                          <Form.Control
                            required
                            value={projectName}
                            onChange={(e) => {
                              setProjectName(e.target.value);
                              setBpname(e.target.value);
                            }}
                            type="text"
                            placeholder="Enter your Project Name"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid project name.
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Basic Price
                          </Typography>
                          <Form.Control
                            required
                            value={basicprice}
                            onChange={(e) => setBasicprice(e.target.value)}
                            placeholder="Enter Basic price"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Basic Price
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Location
                          </Typography>
                          <Form.Control
                            required
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            type="text"
                            placeholder="Enter Location"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter valid location
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Extent
                          </Typography>
                          <Form.Control
                            required
                            value={extent}
                            onChange={(e) => setExtent(e.target.value)}
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Extent
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Unit of Measurement
                          </Typography>
                          <Form.Select
                            required
                            value={uom}
                            onChange={(e) => setUom(e.target.value)}
                            type="text"
                            placeholder="sft,smt,syd,Ac-Gts"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          >
                            <option>Select Measurement</option>
                            <option>Square Feet</option>
                            <option>Square Meter</option>
                            <option>Square Yard</option>
                            <option>Acres Guntas</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Unit Measurements
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Upload Project Layout
                          </Typography>
                          <Form.Control
                            onChange={(e) => handleFileChangeply(e)}
                            disabled={Validated}
                            type="file"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {plvale}
                          </Typography>
                          <Typography
                            style={{
                              color: "gray",
                              fontSize: 11,
                              marginTop: 12,
                            }}
                          >
                            Note : Image Size Needs to be less than 1mb
                          </Typography>

                          <Form.Control.Feedback type="invalid">
                            Please Upload a Valid Project Layout File
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Brochure
                          </Typography>
                          <Form.Control
                            onChange={(e) => handleFileChangebroc(e)}
                            disabled={Validated}
                            type="file"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Typography style={{ color: "gray", fontSize: 11 }}>
                            {blvale}
                          </Typography>
                          <Form.Control.Feedback type="invalid">
                            Please upload a Valid Brochure File
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <div className="d-flex">
                        {/* <button onClick={handleButtonClick}>Submit</button> */}
                        <div>
                          <Button
                            type="submit"
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "rgb(126, 110, 228)",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Next
                          </Button>
                        </div>
                        {/* <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidated(false)
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div> */}
                      </div>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div style={{ width: "47rem" }}>
                    <Form
                      noValidate
                      validated={Validatedb}
                      onSubmit={formValidationb}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Add Blocks
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                                    <Form.Select
                                                        required
                                                        onChange={(e) => {
                                                            setBpname(e.target.value)
                                                            get_dropBlock(e.target.value);
                                                            setStorrectprjname(e.target.value);
                                                            
                                                        }}
                                                        value={bpname}
                                                        type="text"
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    >
                                                        <option>Select Project Name</option>
                                                        {
                                                            response.map((data) => {
                                                                return (
                                                                    <option>{data.ProjectName}</option>
                                                                )
                                                            })
                                                        }

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter project name
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            No Of Blocks
                          </Typography>
                          <Form.Control
                            required
                            type="number"
                            value={noofblocks}
                            onChange={handleNoofBlocksChange}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Blocks
                          </Form.Control.Feedback>
                        </Form.Group>
                        {apblockbtn && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setValidatedb(false);
                              }}
                              style={{
                                width: "2rem",
                                height: "1.3rem",
                                backgroundColor: "gray",
                                color: "white",
                                marginRight: 3,
                              }}
                            >
                              +
                            </Button>
                          </div>
                        )}

                        {Array.isArray(blockData) &&
                          blockData.map((block, index) => (
                            <React.Fragment key={index}>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId={`unitName_${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Block Name
                                </Typography>
                                <Form.Control
                                  required
                                  value={block.blockname}
                                  onChange={(e) =>
                                    handleBlockFieldChange(
                                      index,
                                      "blockname",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter Block Name"
                                  type="text"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Valid Block Name
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Upload Block Layout
                                </Typography>
                                <Form.Control
                                  // disabled={Validatedb}
                                  onChange={(e) => handleFileChange(index, e)}
                                  type="file"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Upload a Valid Block Layout File
                                </Form.Control.Feedback>
                              </Form.Group>
                              {blockData[index]?.file && (
                                <div style={{ marginTop: "10px" }}>
                                  Selected File: {blockData[index].file.name}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                      </Row>
                      <div className="d-flex">
                        <div>
                          <Button
                            onClick={() => {
                              let newValue = (
                                parseInt(value, 10) - 1
                              ).toString(); //asd
                              handleChange(null, newValue);
                            }}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "gray",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Previous
                          </Button>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "rgb(126, 110, 228)",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Next
                          </Button>
                        </div>
                        {/* <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidatedb(false)
                                                        handleNoofBlocksChange({ target: { value: 0 } })
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div> */}
                      </div>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel value="4">
                  <div style={{ width: "47rem" }}>
                    <Form
                      noValidate
                      validated={Validatedt}
                      onSubmit={formValidationt}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Add Towers
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Block</Typography>
                                                    <Form.Select
                                                        required
                                                        onChange={(e) => {
                                                            setTbname(e.target.value)
                                                            get_dropfloor(e.target.value)
                                                        }}
                                                        value={tbname}
                                                        type="text"
                                                        placeholder='Enter your Block Name'
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    >
                                                        <option>Select Block Name</option>

                                                        {
                                                            dropblock.map((data, index) => (
                                                                <React.Fragment key={index}>
                                                                    {data.Blocks.split(',').map((block, blockIndex) => (
                                                                        <option key={blockIndex}>{block.trim()}</option>
                                                                    ))}
                                                                </React.Fragment>
                                                            ))

                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter a valid block name.
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}

                        <Form.Group controlId="validationCustom01">
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Block
                          </Typography>
                          <div style={{ padding: "0.42rem", marginBottom: 14 }}>
                            {
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                
                                {dropblock.map((data, index) => (
                                  <React.Fragment key={index}>
                                    {data.Blocks.split(",").map(
                                      (block, blockIndex) => {
                                        const isDisabled = data.flag === 1;
                                        const isChecked = isDisabled
                                          ? false
                                          : tbname.includes(block.trim());
                                        const plusbtn = data.Id;
                                        return (
                                          <>
                                            {/* <button onClick={() => blockflagtozero(plusbtn)}>+</button> */}
                                            {showblockabtn && (
                                              <Tooltip
                                                title="Append"
                                                placement="bottom-start"
                                              >
                                                <button
                                                  onClick={() =>
                                                    blockflagtozero(plusbtn)
                                                  }
                                                >
                                                  +
                                                </button>
                                              </Tooltip>
                                            )}

                                            <Form.Check
                                              key={blockIndex}
                                              type="checkbox"
                                              label={block.trim()}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setTbname([
                                                    ...tbname,
                                                    block.trim(),
                                                  ]);
                                                } else {
                                                  setTbname(
                                                    tbname.filter(
                                                      (selectedBlock) =>
                                                        selectedBlock !==
                                                        block.trim()
                                                    )
                                                  );
                                                }
                                                console.log(
                                                  `${block.trim()}`.split(
                                                    " "
                                                  )[0]
                                                );
                                                // get_dropfloor(block.trim().split(' ')[0])
                                                get_dropfloor(bpname);
                                              }}
                                              checked={isChecked}
                                              disabled={isDisabled}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                  </React.Fragment>
                                ))}
                              </div>
                            }
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid block name.
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            No Of Towers
                          </Typography>
                          <Form.Control
                            required
                            onChange={handleNoofTowersChange}
                            value={nooftowers}
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Towers
                          </Form.Control.Feedback>
                        </Form.Group>

                        {Array.isArray(towerData) &&
                          towerData.map((tower, index) => (
                            <React.Fragment key={index}>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId={`unitName_${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Tower Name
                                </Typography>
                                <Form.Control
                                  required
                                  value={tower.towername}
                                  onChange={(e) =>
                                    handleTowerFieldChange(
                                      index,
                                      "towername",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter Tower Name"
                                  type="text"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Valid Tower Name
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Upload Tower Layout
                                </Typography>
                                <Form.Control
                                  // disabled={Validatedt}
                                  onChange={(e) => handleFileChanget(index, e)}
                                  type="file"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Upload a Valid Tower Layout File
                                </Form.Control.Feedback>
                              </Form.Group>
                            </React.Fragment>
                          ))}
                      </Row>
                      <div className="d-flex">
                        <div>
                          <Button
                            onClick={() => {
                              let newValue = (
                                parseInt(value, 10) - 1
                              ).toString(); //asd
                              handleChange(null, newValue);
                            }}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "gray",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Previous
                          </Button>
                        </div>
                        {!areAllCheckboxesDisabled() && (
                          <div>
                            <Button
                              type="submit"
                              style={{
                                width: "9rem",
                                height: "2.3rem",
                                backgroundColor: "rgb(126, 110, 228)",
                                color: "white",
                                marginRight: 3,
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        )}
                        {areAllCheckboxesDisabled() && (
                          <div>
                            <Button
                              onClick={() => {
                                handleChange(null, "5");
                                get_dropfloor(bpname);
                                setValidatedt(true);
                                setShowblockabtn(true);
                              }}
                              style={{
                                width: "9rem",
                                height: "2.3rem",
                                backgroundColor: "rgb(126, 110, 228)",
                                color: "white",
                                marginRight: 3,
                              }}
                            >
                              Next
                            </Button>
                          </div>
                        )}
                        {/* <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidatedt(false)
                                                        handleNoofTowersChange({ target: { value: 0 } })
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div> */}
                      </div>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel value="5">
                  <div style={{ width: "47rem" }}>
                    <Form
                      noValidate
                      validated={Validatedf}
                      onSubmit={formValidationf}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Add Floors
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <div>
                          {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                    <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
                                                    <Form.Select
                                                        required
                                                        onChange={(e) => {
                                                            setFtname(e.target.value)
                                                            get_floor(e.target.value)
                                                        }}
                                                        value={ftname}
                                                        type="text"
                                                        placeholder='Enter your Tower Name'
                                                        style={{ padding: '0.42rem', marginBottom: 14 }}
                                                    >
                                                        <option>Select Tower Name</option>

                                                        {
                                                            droptower.map((data, index) => (
                                                                <React.Fragment key={index}>
                                                                    {data.Towers.split(',').map((block, blockIndex) => (
                                                                        <option key={blockIndex}>{block.trim()}</option>
                                                                    ))}
                                                                </React.Fragment>
                                                            ))

                                                        }

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter a valid tower name.
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}

                          {/* <Form.Group controlId="validationCustom01">
    <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
    <div style={{ padding: '0.42rem', marginBottom: 14 }}>
        {
            <div style={{display:'flex', justifyContent:'space-between'}}>
                {
            droptower.map((data, index) => (
                <React.Fragment key={index}>
                    
                    {data.Towers.split(',').map((block, blockIndex) => {
                        const isDisabled = data.flag === 1; 
                        const isChecked = isDisabled ? false : ftname.includes(block.trim()); 
                        return (
                           
                            <Form.Check
                                key={blockIndex}
                                type="checkbox"
                                label={block.trim()}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFtname([...ftname, block.trim()]);
                                    } else {
                                        setFtname(ftname.filter(selectedBlock => selectedBlock !== block.trim()));
                                    }
                                    console.log(`${block.trim()}`);
                                    
                                }}
                                checked={isChecked}
                                disabled={isDisabled} 
                            />
                             
                        );
                    })}
                   
                </React.Fragment>
            ))
            }
                </div>
        }
    </div>
    <Form.Control.Feedback type="invalid">
        Please enter a valid block name.
    </Form.Control.Feedback>
</Form.Group> */}

                          <Form.Group controlId="validationCustom01">
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Towers
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: 14,
                              }}
                            >
                              {droptower.map((data, index) => (
                                <React.Fragment key={index}>
                                  {data.Towers.split(",").map(
                                    (block, blockIndex) => {
                                      const isDisabled = data.flag === 1;
                                      const isChecked = isDisabled
                                        ? false
                                        : ftname.includes(block.trim());
                                      const plusbtn = data.Id;
                                      return (
                                        <div
                                          key={blockIndex}
                                          style={{
                                            marginRight: "20px",
                                            marginBottom: "5px",
                                            display: "flex",
                                          }}
                                        >
                                          {/* <button onClick={() => towerflagtozero(plusbtn)}>+</button> */}
                                          {showtowerabtn && (
                                            <Tooltip
                                              title="Append"
                                              placement="bottom-start"
                                            >
                                              <button
                                                onClick={() =>
                                                  towerflagtozero(plusbtn)
                                                }
                                              >
                                                +
                                              </button>
                                            </Tooltip>
                                          )}
                                          <Form.Check
                                            type="checkbox"
                                            label={block.trim()}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setFtname([
                                                  ...ftname,
                                                  block.trim(),
                                                ]);
                                              } else {
                                                setFtname(
                                                  ftname.filter(
                                                    (selectedBlock) =>
                                                      selectedBlock !==
                                                      block.trim()
                                                  )
                                                );
                                              }
                                              console.log(`${block.trim()}`);
                                              // get_floor(bpname)
                                            }}
                                            checked={isChecked}
                                            disabled={isDisabled}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid block name.
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              No Of Floors
                            </Typography>
                            <Form.Control
                              required
                              type="number"
                              value={nooffloors}
                              onChange={handleNoofFloorsChange}
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Floors
                            </Form.Control.Feedback>
                          </Form.Group>
                          {Array.isArray(floorData) &&
                            floorData.map((floor, index) => (
                              <Row key={index}>
                                <React.Fragment key={index}>
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId={`unitName_${index}`}
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      Floor Name
                                    </Typography>
                                    <Form.Control
                                      required
                                      disabled
                                      value={floor.selectType}
                                      onChange={(e) =>
                                        handleFloorFieldChange(
                                          index,
                                          "selectType",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter Floor Name"
                                      type="text"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter a Valid Floor Name
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId={`file${index}`}
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      Floor Plan
                                    </Typography>
                                    <Form.Control
                                      // required
                                      // disabled={Validatedf}
                                      onChange={(e) =>
                                        handleFileChangef(index, e)
                                      }
                                      placeholder="Enter Floor Name"
                                      type="file"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter a Valid Floor Name
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </React.Fragment>
                              </Row>
                            ))}
                        </div>

                        <div style={{ display: "flex" }}>
                          <div>
                            <Button
                              onClick={() => {
                                let newValue = (
                                  parseInt(value, 10) - 1
                                ).toString(); //asd
                                handleChange(null, newValue);
                              }}
                              style={{
                                width: "9rem",
                                height: "2.3rem",
                                backgroundColor: "gray",
                                color: "white",
                                marginRight: 3,
                              }}
                            >
                              Previous
                            </Button>
                          </div>
                          {!areAllCheckboxesDisabledtower() ? (
                            <div>
                              <Button
                                type="submit"
                                style={{
                                  width: "9rem",
                                  height: "2.3rem",
                                  backgroundColor: "rgb(126, 110, 228)",
                                  color: "white",
                                  marginRight: 3,
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                onClick={() => {
                                  get_floor(bpname);
                                  setValidatedf(true);
                                  handleChange(null, "6");
                                  get_tyunz(bpname);
                                  setShowtowerabtn(true);
                                  console.log("ss");
                                }}
                                style={{
                                  width: "9rem",
                                  height: "2.3rem",
                                  backgroundColor: "rgb(126, 110, 228)",
                                  color: "white",
                                  marginRight: 3,
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          )}

                          {/* {
                                            areAllCheckboxesDisabledtower() && (
                                                <div>
                                        <Button onClick={() => {
                                            get_floor(bpname)
                                            setValidatedf(true)
                                            handleChange(null, "6");
                                            get_tyunz(bpname)
                                            console.log('ss');
  
                                        }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Next</Button>
                                        </div>
                                            )
                                        } */}
                        </div>
                      </Row>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel value="6">
                  <div style={{ width: "47rem" }}>
                    <Form
                      noValidate
                      validated={Validatedu}
                      onSubmit={formValidationu}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Add Units
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <div>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                           >
                          {/* //   <Typography style={{ color: "gray", fontSize: 13 }}>
                          //     Floor
                          //   </Typography> */}
                            {/* <Form.Select
                              required
                              onChange={(e) => setUtname(e.target.value)}
                              value={utname}
                              type="text"
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            >
                              <option>Select Floor Name</option>

                              {response3.map((data, index) => (
                                <React.Fragment key={index}>
                                  {data.selectType
                                    .split(",")
                                    .map((block, blockIndex) => (
                                      <option key={blockIndex}>
                                        {block.trim()}
                                      </option>
                                    ))}
                                </React.Fragment>
                              ))}
                            </Form.Select> */}
                            <Form.Control.Feedback type="invalid">
                              Please Enter Floor name
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group controlId="validationCustom01">
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Blocks
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: 14,
                              }}
                            >
                              {/* Map over blocks */}
                              {dropblock.map((data, index) => (
                                <React.Fragment key={index}>
                                  {data.Blocks.split(",").map(
                                    (block, blockIndex) => (
                                      <div
                                        key={blockIndex}
                                        style={{
                                          marginRight: "20px",
                                          marginBottom: "5px",
                                          display: "flex",
                                        }}
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label={block.trim()}
                                          onClick={() =>
                                            handleBlockChange(block.trim())
                                          }
                                          checked={selectedBlocks.includes(
                                            block.trim()
                                          )}
                                        />
                                      </div>
                                    )
                                  )}
                                </React.Fragment>
                              ))}
                            </div>

                            {/* Towers */}
                            {selectedBlocks.length > 0 && (
                              <>
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Towers
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginBottom: 14,
                                  }}
                                >
                                  {droptower.map((data, index) => (
                                    <React.Fragment key={index}>
                                      {data.Towers.split(",").map(
                                      
                                        (tower, towerIndex) => (
                                         
                                          <div
                                            key={towerIndex}
                                            style={{
                                              marginRight: "20px",
                                              marginBottom: "5px",
                                              display: "flex",
                                            }}
                                          >
                                            <Form.Check
                                              type="checkbox"
                                              label={tower.trim()}
                                              onClick={() =>
                                                handleTowerChange(tower.trim())
                                              }
                                              checked={selectedTowers.includes(
                                                tower.trim()
                                              )}
                                            />
                                          </div>
                                        )
                                      )}
                                    </React.Fragment>
                                  ))}
                                </div>
                              </>
                            )}

                            {/* Floors */}
                            {selectedTowers.length > 0 && (
                              <>
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Floor (You can assign the same values to
                                  multiple floors by selecting those floors)
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginBottom: 14,
                                  }}
                                >
                                  {response3.map((data, index) => (
                                    <React.Fragment key={index}>
                                      {data.selectType
                                        .split(",")
                                        .map((block, blockIndex) => {
                                          const isDisabled = data.flag === 1;
                                          const isChecked = isDisabled
                                            ? false
                                            : utname.includes(block.trim());
                                          const plusbtn = data.id;
                                          const reval = data.block;
                                          return (
                                            <div
                                              key={blockIndex}
                                              style={{
                                                marginRight: "20px",
                                                marginBottom: "5px",
                                                display: "flex",
                                              }}
                                            >
                                              {/* <button onClick={() => unitflagtozero(plusbtn)}>+</button> */}
                                              {showfloorabtn && (
                                                <Tooltip
                                                  title="Append"
                                                  placement="bottom-start"
                                                >
                                                  <button
                                                    onClick={() => {
                                                      unitflagtozero(plusbtn);
                                                      setRemval(reval);
                                                    }}
                                                  >
                                                    +
                                                  </button>
                                                </Tooltip>
                                              )}
                                              <Form.Check
                                                type="checkbox"
                                                label={block.trim()}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setUtname([
                                                      ...utname,
                                                      block.trim(),
                                                    ]);
                                                  } else {
                                                    setUtname(
                                                      utname.filter(
                                                        (selectedBlock) =>
                                                          selectedBlock !==
                                                          block.trim()
                                                      )
                                                    );
                                                  }
                                                  console.log(
                                                    `${block.trim()}`
                                                  );
                                                }}
                                                checked={isChecked}
                                                disabled={isDisabled}
                                              />
                                            </div>
                                          );
                                        })}
                                    </React.Fragment>
                                  ))}
                                </div>
                              </>
                            )}
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              No Of Units
                            </Typography>
                            <Form.Control
                              required
                              type="number"
                              value={noOfUnits}
                              onChange={handleNoOfUnitsChange}
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter a Valid Units
                            </Form.Control.Feedback>
                          </Form.Group>

                          {Array.isArray(unitData1) &&
                            unitData1.map((unit, index) => (
                              <Row key={index}>
                                {/* <Form.Group as={Col} md="6" controlId={`unitName_${index}`}>
                                                                <Typography style={{ color: 'gray', fontSize: 13 }}>Unit Name</Typography>
                                                                <Form.Control
                                                                    required
                                                                    disabled
                                                                    value={unit.unit_name}
                                                                    onChange={(e) => handleUnitFieldChange(index, 'unit_name', e.target.value)}
                                                                    placeholder="Enter Unit Name"
                                                                    type="text"
                                                                    style={{ padding: '0.42rem', marginBottom: 14 }}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please Enter Valid Unit Name
                                                                </Form.Control.Feedback>
                                                            </Form.Group> */}

                                <Form.Group
                                  as={Col}
                                  md="3"
                                  controlId={`facing${index}`}
                                >
                                  <Typography
                                    style={{ color: "gray", fontSize: 13 }}
                                  >
                                    UnitType
                                  </Typography>
                                  <Form.Select
                                    required
                                    value={unit.unitsize}
                                    onChange={(e) =>
                                      handleUnitFieldChange(
                                        index,
                                        "unitsize",
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      padding: "0.42rem",
                                      marginBottom: 14,
                                    }}
                                  >
                                    <option>--Select UnitType--</option>
                                    {typicaluzdrop.map((data, index) => (
                                      <option
                                        key={index}
                                        value={data.slno}
                                      >{`${data.tnum}`}</option>
                                    ))}
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Valid Facing
                                  </Form.Control.Feedback>
                                </Form.Group>
                                {/* <Form.Group as={Col} md="3" controlId={`mortgage${index}`}>
                                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Mortgage</Typography>
                                                            <Form.Select
                                                                required
                                                                value={unit.mortgage}
                                                                onChange={(e) => handleUnitFieldChange(index, 'mortgage', e.target.value)}
                                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                                            >
                                                                    <option>--Select Mortgage--</option>
                                                                    <option value={2}>Yes</option>
                                                                    <option value={0}>No</option>
                                                                    
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please Enter Valid Facing
                                                                </Form.Control.Feedback>
                                                            </Form.Group> */}
                              </Row>
                            ))}
                        </div>

                        <div className="d-flex">
                          <div>
                            <Button
                              onClick={() => {
                                let newValue = (
                                  parseInt(value, 10) - 1
                                ).toString();
                                handleChange(null, newValue);
                              }}
                              style={{
                                width: "9rem",
                                height: "2.3rem",
                                backgroundColor: "gray",
                                color: "white",
                                marginRight: 3,
                              }}
                            >
                              Previous
                            </Button>
                          </div>
                          {!areAllCheckboxesDisabledunit() && (
                            <div>
                              <Button
                                onClick={handleSecondDialogOpen}
                                style={{
                                  width: "9rem",
                                  height: "2.3rem",
                                  backgroundColor: "rgb(126, 110, 228)",
                                  color: "white",
                                  marginRight: 3,
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          )}

                          {areAllCheckboxesDisabledunit() && (
                            <div>
                              <Button
                                onClick={() => {
                                  handleChange(null, "7");
                                  setShowfloorabtn(true);
                                  get_floor(bpname);
                                }}
                                style={{
                                  width: "9rem",
                                  height: "2.3rem",
                                  backgroundColor: "rgb(126, 110, 228)",
                                  color: "white",
                                  marginRight: 3,
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          )}
                        </div>
                      </Row>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel value="9">
                  <div style={{ width: "47rem" }}>
                    <Form noValidate>
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Aminities
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Water & Electricity Charges
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setWaterele(e.target.value)}
                            value={waterele}
                            type="text"
                            placeholder="Water & Electricity Charges"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please enter a valid Facing
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Water & Electricity Charges Amount
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setWatereleamt(e.target.value)}
                            value={watereleamt}
                            placeholder="Water & Electricity Charges Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Facingwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Club House
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setClubhou(e.target.value)}
                            value={clubhou}
                            type="text"
                            placeholder="Club House"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />

                          <Form.Control.Feedback type="invalid">
                            Please enter a valid Floorrise
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Club House Amount
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setClubhouamt(e.target.value)}
                            value={clubhouamt}
                            placeholder="Club House Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Advance Maintance
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setAdvmain(e.target.value)}
                            value={advmain}
                            placeholder="Advance Maintance"
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Advance Maintance Amount
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setAdvmainamt(e.target.value)}
                            value={advmainamt}
                            placeholder="Advance Maintance Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Corpus Fund
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setCarpose(e.target.value)}
                            value={carpous}
                            placeholder="Corpus Fund"
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Corpus Fund Amount
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setCarposeamt(e.target.value)}
                            value={carpousamt}
                            placeholder="Corpus Fund Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Car Parking
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setCarpar(e.target.value)}
                            value={carpar}
                            placeholder="Car Parking"
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Car Parking Amount
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setCarparamt(e.target.value)}
                            value={carparamt}
                            placeholder="Car Parking Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Extra Car Parking
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setExcarpa(e.target.value)}
                            value={excarpa}
                            placeholder="Extra Car Parking"
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Extra Car Parking Amount
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setExcarpaamt(e.target.value)}
                            value={excarpaamt}
                            placeholder="Extra Car Parking Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>

                        {/* <>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                        <label style={{ color: 'gray', fontSize: 13 }}>Corner</label>
                                                        {['radio'].map((type) => (
                                                            <div key={`inline-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    inline
                                                                    label="Yes"
                                                                    name="cornor"
                                                                    type={type}
                                                                    id={`inline-${type}-1`}
                                                                    value="Yes"
                                                                    onChange={(e) => {
                                                                        setCorner(e.target.value);
                                                                        setIntrest(true);
                                                                    }}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="No"
                                                                    name="cornor"
                                                                    type={type}
                                                                    id={`inline-${type}-2`}
                                                                    value="No"
                                                                    onChange={() => {
                                                                        setCorner('');
                                                                        setIntrest(false);
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                        <Form.Control.Feedback type="invalid">
                                                            Please select Corner option
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    {Intrest && corner === 'Yes' && (
                                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Corner Amount</Typography>
                                                            <Form.Control
                                                                required
                                                                onChange={(e) => setCornerAmount(e.target.value)}
                                                                value={corneramount}
                                                                type="number"
                                                                placeholder='Enter Corner Amount'
                                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Enter a Valid Corner Amount
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                                </> */}
                      </Row>
                      <div className="d-flex">
                        <div>
                          <Button
                            onClick={() => {
                              let newValue = (
                                parseInt(value, 10) - 1
                              ).toString(); //asd
                              handleChange(null, newValue);
                            }}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "gray",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Previous
                          </Button>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "rgb(126, 110, 228)",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Next
                          </Button>
                        </div>
                        {/* <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidatedum(false)
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div> */}
                      </div>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel value="8">
                  <div style={{ width: "37rem", margin: "auto" }}>
                    <div className="mb-4 mt-1">
                      <Typography
                        style={{
                          textAlign: "center",
                          fontWeight: "500",
                          color: "rgb(126, 110, 228)",
                          fontSize: 21,
                        }}
                      >
                        Pricing
                      </Typography>
                      {/* <Typography style={{ textAlign: 'center', fontSize: 15 }}>Updating blocks details will receive a privacy audit.</Typography> */}
                    </div>

                    <Box sx={{ width: "100%" }}>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};
                          //   if (isStepOptional(index)) {
                          //     labelProps.optional = (
                          //       <Typography variant="caption">Optional</Typography>
                          //     );
                          //   }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      {activeStep === 0 ? (
                        <div>
                          <Form
                            noValidate
                            validated={Validatedpric}
                            onSubmit={formValidationpric}
                          >
                            <div className="mb-4 mt-1">
                              {/* <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}> Project</Typography> */}
                            </div>
                            <Row className="mb-3">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Base Price
                                </Typography>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Enter your Base Price"
                                  value={basepricep}
                                  onChange={(e) =>
                                    setBasicpricep(e.target.value)
                                  }
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid project name.
                                </Form.Control.Feedback>
                              </Form.Group>

                              <div>
                                <Typography
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    marginBottom: 3,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Facing Charges
                                </Typography>
                                <Row className="mb-3">
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      East
                                    </Typography>
                                    <Form.Control
                                      required
                                      placeholder="Enter East Amount"
                                      type="text"
                                      value={eamt}
                                      onChange={(e) => setEamt(e.target.value)}
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Valid Basic Price
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      West
                                    </Typography>
                                    <Form.Control
                                      required
                                      onChange={(e) => setWamt(e.target.value)}
                                      value={wamt}
                                      type="text"
                                      placeholder="Enter West Amount"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter valid location
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      North
                                    </Typography>
                                    <Form.Control
                                      required
                                      placeholder="Enter North Amount"
                                      type="text"
                                      value={namt}
                                      onChange={(e) => setNamt(e.target.value)}
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Valid Basic Price
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      South
                                    </Typography>
                                    <Form.Control
                                      required
                                      onChange={(e) => setSamt(e.target.value)}
                                      type="text"
                                      value={samt}
                                      placeholder="Enter South Amount"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter valid location
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                              </div>

                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                              >
                                <Typography
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    marginBottom: 3,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Park View Charges
                                </Typography>
                                <Form.Control
                                  required
                                  placeholder="Enter  Amount"
                                  type="text"
                                  value={pvamt}
                                  onChange={(e) => setPvamt(e.target.value)}
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Valid Basic Price
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                              >
                                <Typography
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    marginBottom: 3,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Corner Charges
                                </Typography>
                                <Form.Control
                                  required
                                  placeholder="Enter  Amount"
                                  type="text"
                                  value={camt}
                                  onChange={(e) => setCamt(e.target.value)}
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Valid Basic Price
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Row className="mb-3"></Row>

                              <Typography
                                style={{
                                  color: "black",
                                  fontSize: 14,
                                  marginBottom: 3,
                                  fontWeight: "bold",
                                }}
                              >
                                Floor Rise Charges*
                              </Typography>

                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Project Name
                                </Typography>
                                <Form.Select
                                  required
                                  type="text"
                                  value={prjname}
                                  onChange={
                                    handleChangePrjName
                                 
                                  }
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                >
                                  <option>Select Project Name</option>
                                  {responsep.map((data) => {
                                    return <option key={data.ProjectName}>{data.ProjectName}</option>;
                                  })}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Valid Unit Measurements
                                </Form.Control.Feedback>
                              </Form.Group>
{/* 
                              <Form.Group controlId="validationCustom01">
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Floor
                                </Typography>
                                {/* <div>
                                                {floorRiseAmounts.map((_, index) => (
                                                <div key={index}>
                                                    {[...Array(fcnt)].map((_, blockIndex) => {
                                                        return (
                                                            <div key={blockIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={`Floor${blockIndex + 1}`}
                                                                    disabled
                                                                    style={{ marginRight: '20px', width: '120px' }}
                                                                />
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Enter floor rise amount"
                                                                    onChange={(e) => handleFloorRiseAmountChange(index, blockIndex, e.target.value)}
                                                                    style={{ width: '200px' }}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                                </div> */}
                                <div>
                                  {/* {Array.from({ length: maxLastDigit }, (_, index) => (
                                                    <div key={index}>
                                                        <label htmlFor={`floor${index + 1}`}>Floor name: </label>
                                                        <input type="text" id={`floor${index + 1}`} defaultValue={`floor${index + 1}`} disabled />
                                                        <label htmlFor={`floorAmount${index + 1}`}>Floor Amount:</label>
                                                        <input
                                                            type="text"
                                                            id={`floorAmount${index + 1}`}
                                                            name={`floorAmount${index + 1}`}
                                                            value={floorData[index]?.floorAmount || ''}  
                                                            onChange={(event) => handleFloorAmountChange(index, event)}
                                                        />
                                                    </div>
                                                ))} */}

                                  {floorDatap.map((floor, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-evenly",
                                        marginBottom: 4,
                                      }}
                                    >
                                      <Form.Group
                                        controlId={`floor${index + 1}`}
                                      >
                                        <Form.Control
                                          type="text"
                                          defaultValue={`floor${index + 1}`}
                                          disabled
                                        />
                                      </Form.Group>
                                      <Form.Group
                                        controlId={`floorAmount${index + 1}`}
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="floorrise Amount"
                                          name={`floorAmount${index + 1}`}
                                          value={
                                            floorDatap[index]?.floorAmount || ""
                                          }
                                          onChange={(event) =>
                                            handleFloorAmountChange(
                                              index,
                                              event
                                            )
                                          }
                                        />
                                      </Form.Group>
                                    </div>
                                  ))} 
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  Please enter a valid block name.
                                </Form.Control.Feedback>
                              {/* </Form.Group> */} 



                              <Typography
                                style={{
                                  color: "black",
                                  fontSize: 14,
                                  marginBottom: 3,
                                  fontWeight: "bold",
                                }}
                              >
                                Car Parking Charges{" "}
                              </Typography>
                              <Row className="mb-3">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="validationCustom01"
                                >
                                  <Typography
                                    style={{ color: "gray", fontSize: 13 }}
                                  >
                                    One Cark Park
                                  </Typography>
                                  <Form.Control
                                    required
                                    placeholder="Enter One Cark Park Amount"
                                    value={ocpark}
                                    onChange={(e) => setOcpark(e.target.value)}
                                    type="text"
                                    style={{
                                      padding: "0.42rem",
                                      marginBottom: 14,
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Valid Basic Price
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="validationCustom01"
                                >
                                  <Typography
                                    style={{ color: "gray", fontSize: 13 }}
                                  >
                                    Two Cark Park
                                  </Typography>
                                  <Form.Control
                                    required
                                    type="text"
                                    value={tcpark}
                                    onChange={(e) => setTcpark(e.target.value)}
                                    placeholder="Enter Two Cark Park Amount"
                                    style={{
                                      padding: "0.42rem",
                                      marginBottom: 14,
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please Enter valid location
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="validationCustom01"
                                >
                                  <Typography
                                    style={{ color: "gray", fontSize: 13 }}
                                  >
                                    Three Cark Park
                                  </Typography>
                                  <Form.Control
                                    required
                                    value={ecpark}
                                    placeholder="Enter Three Cark Park Amount"
                                    type="text"
                                    onChange={(e) => setEpark(e.target.value)}
                                    style={{
                                      padding: "0.42rem",
                                      marginBottom: 14,
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please Enter Valid Basic Price
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>

                              <>
                                <Typography
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    marginBottom: 3,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Scheme
                                </Typography>
                                <Row className="mb-3">
                                  <Form.Group as={Col} md="6">
                                    <Form.Check
                                      inline
                                      label="Yes"
                                      type="radio"
                                      id="schemeYes"
                                      value="yes"
                                      checked={scheme === "yes"}
                                      onChange={handleSchemeChange}
                                    />
                                    <Form.Check
                                      inline
                                      label="No"
                                      type="radio"
                                      id="schemeNo"
                                      value="no"
                                      checked={scheme === "no"}
                                      onChange={handleSchemeChange}
                                    />
                                  </Form.Group>
                                </Row>
                                {scheme === "yes" && (
                                  <Row className="mb-3">
                                    <Form.Group
                                      as={Col}
                                      md="6"
                                      controlId="validationCustom01"
                                    >
                                      <Typography
                                        style={{ color: "gray", fontSize: 13 }}
                                      >
                                        Scheme Name
                                      </Typography>
                                      <Form.Control
                                        required
                                        placeholder="Enter Scheme Name"
                                        type="text"
                                        value={sname}
                                        onChange={(e) =>
                                          setSname(e.target.value)
                                        }
                                        style={{
                                          padding: "0.42rem",
                                          marginBottom: 14,
                                        }}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Basic Price
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                      as={Col}
                                      md="6"
                                      controlId="validationCustom01"
                                    >
                                      <Typography
                                        style={{ color: "gray", fontSize: 13 }}
                                      >
                                        Amount
                                      </Typography>
                                      <Form.Control
                                        required
                                        placeholder="Enter  Amount"
                                        type="number"
                                        value={scheamt}
                                        onChange={(e) =>
                                          setScheamt(e.target.value)
                                        }
                                        style={{
                                          padding: "0.42rem",
                                          marginBottom: 14,
                                        }}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Basic Price
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                )}
                              </>
                            </Row>
                            {btnone && (
                              <Button
                                type="submit"
                                style={{
                                  width: "6rem",
                                  height: "1.8rem",
                                  backgroundColor: "rgb(126, 110, 228)",
                                  color: "white",
                                  marginRight: 3,
                                }}
                              >
                                Submit
                              </Button>
                            )}
                          </Form>
                        </div>
                      ) : activeStep === 1 ? (
                        <div>
                          <div>
                            <div>
                              <Form
                                noValidate
                                validated={Validatedum}
                                onSubmit={formValidationum}
                              >
                                <div className="mb-4 mt-1">
                                  <Typography
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "500",
                                      color: "rgb(126, 110, 228)",
                                      fontSize: 21,
                                    }}
                                  >
                                    Aminities
                                  </Typography>
                                </div>
                                <Row className="mb-3">
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      Water & Electricity Charges Amount
                                    </Typography>
                                    <Form.Control
                                      required
                                      onChange={(e) =>
                                        setWatereleamt(e.target.value)
                                      }
                                      value={watereleamt}
                                      placeholder="Water & Electricity Charges Amount"
                                      type="number"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Valid Facingwise Amount
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      Club House Amount
                                    </Typography>
                                    <Form.Control
                                      required
                                      onChange={(e) =>
                                        setClubhouamt(e.target.value)
                                      }
                                      value={clubhouamt}
                                      placeholder="Club House Amount"
                                      type="number"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Valid Floorwise Amount
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      Advance Maintance Amount
                                    </Typography>
                                    <Form.Control
                                      required
                                      onChange={(e) =>
                                        setAdvmainamt(e.target.value)
                                      }
                                      value={advmainamt}
                                      placeholder="Advance Maintance Amount"
                                      type="number"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Valid Floorwise Amount
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom01"
                                  >
                                    <Typography
                                      style={{ color: "gray", fontSize: 13 }}
                                    >
                                      Corpus Fund Amount
                                    </Typography>
                                    <Form.Control
                                      required
                                      onChange={(e) =>
                                        setCarposeamt(e.target.value)
                                      }
                                      value={carpousamt}
                                      placeholder="Corpus Fund Amount"
                                      type="number"
                                      style={{
                                        padding: "0.42rem",
                                        marginBottom: 14,
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Valid Floorwise Amount
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Caution Deposit Refund 
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setCautionamtRef(e.target.value)}
                            value={cautionamtRef}
                            placeholder="Caution Fund Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                          Caution Deposit Non Refund 
                          </Typography>
                          <Form.Control
                            required
                            onChange={(e) => setCautionamtNRef(e.target.value)}
                            value={cautionamtNRef}
                            placeholder="Caution Fund Amount"
                            type="number"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Floorwise Amount
                          </Form.Control.Feedback>
                        </Form.Group>

                                  {/* <>
                                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                        <label style={{ color: 'gray', fontSize: 13 }}>Corner</label>
                                                        {['radio'].map((type) => (
                                                            <div key={`inline-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    inline
                                                                    label="Yes"
                                                                    name="cornor"
                                                                    type={type}
                                                                    id={`inline-${type}-1`}
                                                                    value="Yes"
                                                                    onChange={(e) => {
                                                                        setCorner(e.target.value);
                                                                        setIntrest(true);
                                                                    }}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="No"
                                                                    name="cornor"
                                                                    type={type}
                                                                    id={`inline-${type}-2`}
                                                                    value="No"
                                                                    onChange={() => {
                                                                        setCorner('');
                                                                        setIntrest(false);
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                        <Form.Control.Feedback type="invalid">
                                                            Please select Corner option
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    {Intrest && corner === 'Yes' && (
                                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Corner Amount</Typography>
                                                            <Form.Control
                                                                required
                                                                onChange={(e) => setCornerAmount(e.target.value)}
                                                                value={corneramount}
                                                                type="number"
                                                                placeholder='Enter Corner Amount'
                                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Enter a Valid Corner Amount
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                                </> */}
                                </Row>
                                <div className="d-flex">
                                  {btntwo && (
                                    <Button
                                      type="submit"
                                      style={{
                                        width: "6rem",
                                        height: "1.8rem",
                                        backgroundColor: "rgb(126, 110, 228)",
                                        color: "white",
                                        marginRight: 3,
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </div>
                              </Form>
                            </div>

                            {/* <Form noValidate validated={Validated2A} onSubmit={formValidation2A} >
                                            <div className='mb-4 mt-1'>
                                            </div>
                                           
                                            {
                                                btntwo && (
                                                    <Button type='submit' style={{ width: '6rem', height: '1.8rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                )
                                            }
                                           
                                                </Form> */}
                          </div>
                        </div>
                      ) : null}
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        {activeStep !== 0 && (
                          <Button
                            color="inherit"
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Back
                          </Button>
                        )}
                        <Box sx={{ flex: "1 1 auto" }} />
                        {activeStep === 0 && (
                          <Button onClick={handleNext}>Next</Button>
                        )}
                        {activeStep === 1 && (
                          <Button onClick={handleFinish}>Finish</Button>
                        )}
                      </Box>
                    </Box>
                  </div>
                </TabPanel>

                <TabPanel value="9">
                  <div style={{ width: "47rem" }}>
                    <Form
                      noValidate
                      validated={Validatedus}
                      onSubmit={formValidationus}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Payment
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            No Of Rows
                          </Typography>
                          <Form.Control
                            required
                            type="number"
                            onChange={handleNoofpaymentChange}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter a Valid Units
                          </Form.Control.Feedback>
                        </Form.Group>

                        {Array.isArray(payha) &&
                          payha.map((unit, index) => (
                            <React.Fragment key={index}>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`unitName_${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Installment
                                </Typography>
                                <Form.Control
                                  required
                                  value={unit.install}
                                  onChange={(e) =>
                                    handlepaymFieldChange(
                                      index,
                                      "install",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Installment"
                                  type="text"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Valid Unit Name
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`sqFeet${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Name
                                </Typography>
                                <Form.Control
                                  required
                                  value={unit.name}
                                  onChange={(e) =>
                                    handlepaymFieldChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Name"
                                  type="text"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter a Valid Square Feet
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`facing${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Percentage
                                </Typography>
                                <Form.Control
                                  required
                                  value={unit.percentage}
                                  onChange={(e) =>
                                    handlepaymFieldChange(
                                      index,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Percentage"
                                  type="number"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter a Valid Square Feet
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`floorPlan${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Due Date
                                </Typography>
                                <Form.Control
                                  required
                                  value={unit.duedate}
                                  onChange={(e) =>
                                    handlepaymFieldChange(
                                      index,
                                      "duedate",
                                      e.target.value
                                    )
                                  }
                                  type="date"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Upload a Valid Floor Layout File
                                </Form.Control.Feedback>
                              </Form.Group>
                            </React.Fragment>
                          ))}
                      </Row>

                      <div className="d-flex">
                        <div>
                          <Button
                            onClick={() => {
                              let newValue = (
                                parseInt(value, 10) - 1
                              ).toString(); //asd
                              handleChange(null, newValue);
                            }}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "gray",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Previous
                          </Button>
                        </div>
                        <div>
                          <Button
                            onClick={add_paymntsubmit}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "rgb(126, 110, 228)",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Finish
                          </Button>
                        </div>
                        {/* <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidatedus(false)
                                                        handleTypicalUnitFloorChange({ target: { value: 0 } })
                                                        // handleNoOfUnitsChange({ target: { value: 0 } })
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div> */}
                      </div>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div style={{ width: "50rem" }}>
                    <Form
                      noValidate
                      validated={Validatedtu}
                      onSubmit={formValidationtu}
                    >
                      <div className="mb-4 mt-1">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            color: "rgb(126, 110, 228)",
                            fontSize: 21,
                          }}
                        >
                          Typical Unit {bpname}
                        </Typography>
                      </div>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            No Of Typical units
                          </Typography>
                          <Form.Control
                            required
                            type="number"
                            value={tyval}
                            onChange={handleNooftypicalChange}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Blocks
                          </Form.Control.Feedback>
                        </Form.Group>
                        {Array.isArray(tur) &&
                          tur.map((block, index) => (
                            <React.Fragment key={index}>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`unitsize${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Unit Size
                                </Typography>
                                <Form.Control
                                  required
                                  value={block.unitsize}
                                  placeholder="Enter Unit Size"
                                  type="number"
                                  onChange={(e) =>
                                    handleTypicalFieldChange(
                                      index,
                                      "unitsize",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`facing${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Unit Facing
                                </Typography>
                                <Form.Select
                                  required
                                  value={block.facing}
                                  onChange={(e) =>
                                    handleTypicalFieldChange(
                                      index,
                                      "facing",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                >
                                  <option>Select Facing</option>
                                  <option>East</option>
                                  <option>West</option>
                                  <option>North</option>
                                  <option>South</option>
                                </Form.Select>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`cornor${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Unit Corner
                                </Typography>
                                <Form.Select
                                  required
                                  value={block.cornor}
                                  onChange={(e) =>
                                    handleTypicalFieldChange(
                                      index,
                                      "cornor",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                >
                                  <option>Select Corner</option>
                                  <option>Yes</option>
                                  <option>No</option>
                                </Form.Select>
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="3"
                                controlId={`unitplan${index}`}
                              >
                                <Typography
                                  style={{ color: "gray", fontSize: 13 }}
                                >
                                  Unit Plan
                                </Typography>
                                <Form.Control
                                  // disabled={Validatedtu}
                                  onChange={(e) => handleFileChangety(index, e)}
                                  type="file"
                                  style={{
                                    padding: "0.42rem",
                                    marginBottom: 14,
                                  }}
                                />
                              </Form.Group>
                            </React.Fragment>
                          ))}
                        {/* <Typography style={{ color: 'gray', fontSize: 10 }}>Note : Image Size Needs to be less than 1mb</Typography> */}
                      </Row>
                      {/* {unitRows.map((row, index) => (
                                                <div key={index}>{row}</div>
                                            ))} */}

                      <div className="d-flex">
                        <div>
                          <Button
                            onClick={() => {
                              let newValue = (
                                parseInt(value, 10) - 1
                              ).toString(); //asd
                              handleChange(null, newValue);
                            }}
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "gray",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Previous
                          </Button>
                        </div>
                        <div>
                          <Button
                            type="submit"
                            style={{
                              width: "9rem",
                              height: "2.3rem",
                              backgroundColor: "rgb(126, 110, 228)",
                              color: "white",
                              marginRight: 3,
                            }}
                          >
                            Next
                          </Button>
                        </div>

                        {/* <div>
                                                    <Button onClick={() => {
                                                        setOpen(false)
                                                        setValidatedtu(false)
                                                        
                                                        // handleNoOfUnitsChange({target : {value : 0 }})
                                                    }} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                                </div> */}
                      </div>
                    </Form>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={openSecondDialog} onClose={handleSecondDialogClose}>
          <DialogTitle>
            Would you like those same values assigned below any of the floors?
          </DialogTitle>
          <DialogContent>
            <Form.Group controlId="validationCustom01">
              <Typography style={{ color: "gray", fontSize: 13 }}>
                Floor
              </Typography>
              <div
                style={{ display: "flex", flexWrap: "wrap", marginBottom: 14 }}
              >
                {response3.map((data, index) => (
                  <React.Fragment key={index}>
                    {data.selectType.split(",").map((block, blockIndex) => {
                      const isDisabled = data.flag === 1;
                      const isChecked = isDisabled
                        ? false
                        : ndia.includes(block.trim());
                      const plusbtn = data.id;
                      return (
                        <div
                          key={blockIndex}
                          style={{
                            marginRight: "20px",
                            marginBottom: "5px",
                            display: "flex",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            label={block.trim()}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setNdia([...ndia, block.trim()]);
                              } else {
                                setNdia(
                                  ndia.filter(
                                    (selectedBlock) =>
                                      selectedBlock !== block.trim()
                                  )
                                );
                              }
                              console.log(`${block.trim()}`);
                            }}
                            checked={isChecked}
                            disabled={isDisabled}
                          />
                        </div>
                      );
                    })}
                  </React.Fragment>
                ))}
              </div>
              <Form.Control.Feedback type="invalid">
                Please enter a valid block name.
              </Form.Control.Feedback>
            </Form.Group>

            <DialogActions>
              <Button onClick={add_unitstsubmit} color="primary">
                Add Data
              </Button>
              <Button onClick={handleSecondDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={openedd}
          onClose={handleCloseedd}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        >
          <Typography
            style={{
              color: "rgba(41, 16, 105, 1)",
              marginBottom: 3,
              marginTop: 9,
              marginLeft: 15.5,
              fontSize: 18,
              color: "gray",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Choose Below For Editing
          </Typography>

          <DialogContent>
            <div
              className="card"
              style={{
                backgroundColor: "white",
                borderRadius: "0.4rem",
                border: "1px solid rgb(126, 110, 228)",
                padding: "11px",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    // padding: '15px 3px',
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "2px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    setOpen1(true);
                    handleCloseedd();
                  }}
                >
                  {" "}
                  Project{" "}
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => navigate(`/project-blocks?id=${npn}`)}
                >
                  Block{" "}
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => navigate(`/project-towers?id=${npn}`)}
                >
                  Tower{" "}
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    padding: "5px 3px",
                    width: "47%",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => navigate(`/project-floor?id=${npn}`)}
                >
                  Floor{" "}
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => navigate(`/project-unit?id=${npn}`)}
                >
                  Unit{" "}
                </button>

                <button
                  style={{
                    backgroundColor: "#4CAF50",
                    border: "none",
                    color: "white",
                    width: "47%",
                    padding: "5px 3px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  onClick={() => navigate(`/typical-units?id=${npn}`)}
                >
                  Typical Units
                </button>
              </div>
            </div>
          </DialogContent>
          <Button onClick={handleCloseedd}>Close</Button>
        </Dialog>
      </div>

      <div>
        <Dialog
          style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
          maxWidth="xl"
          open={open1}
          onClose={() => setOpen1(!open1)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <div style={{ width: "37rem" }}>
              <Form noValidate>
                <div className="mb-4 mt-1">
                  <Typography
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "rgb(126, 110, 228)",
                      fontSize: 21,
                    }}
                  >
                    Update Project
                  </Typography>
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Select Project
                    </Typography>
                    <Form.Select
                      required
                      onChange={(e) => setSelectProject(e.target.value)}
                      type="text"
                      value={selectproject}
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Project</option>
                      <option>Apartment</option>
                      <option>Villa</option>
                      <option>Plotting</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Basic Price
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Project Name
                    </Typography>
                    <Form.Control
                      required
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      type="text"
                      placeholder="Enter your Project Name"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid project name.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Basic Price
                    </Typography>
                    <Form.Control
                      required
                      value={basicprice}
                      onChange={(e) => setBasicprice(e.target.value)}
                      placeholder="Enter Basic price"
                      type="number"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Basic Price
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Location
                    </Typography>
                    <Form.Control
                      required
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      type="text"
                      placeholder="Enter Location"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter valid location
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Extent
                    </Typography>
                    <Form.Control
                      required
                      value={extent}
                      onChange={(e) => setExtent(e.target.value)}
                      type="text"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Extent
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Unit of Measurement
                    </Typography>
                    <Form.Select
                      required
                      value={uom}
                      onChange={(e) => setUom(e.target.value)}
                      type="text"
                      placeholder="sft,smt,syd,Ac-Gts"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    >
                      <option>Select Measurement</option>
                      <option>Square Feet</option>
                      <option>Square Meter</option>
                      <option>Square Yard</option>
                      <option>Acres Guntas</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Valid Unit Measurements
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Upload Project Layout
                    </Typography>
                    <Form.Control
                      required
                      onChange={(e) => handleFileChangeply(e)}
                      type="file"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Typography style={{ color: "gray", fontSize: 11 }}>
                      Note : Image Size Needs to be less than 1mb
                    </Typography>

                    <Form.Control.Feedback type="invalid">
                      Please Upload a Valid Project Layout File
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Typography style={{ color: "gray", fontSize: 13 }}>
                      Brochure
                    </Typography>
                    <Form.Control
                      required
                      onChange={(e) => handleFileChangebroc(e)}
                      type="file"
                      style={{ padding: "0.42rem", marginBottom: 14 }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please upload a Valid Brochure File
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <div className="d-flex">
                  <div>
                    <Button
                      onClick={() => editAlert(id)}
                      style={{
                        width: "9rem",
                        height: "2.3rem",
                        backgroundColor: "rgb(126, 110, 228)",
                        color: "white",
                        marginRight: 3,
                      }}
                    >
                      Update
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => setOpen1(false)}
                      style={{
                        width: "9rem",
                        height: "2.3rem",
                        backgroundColor: "gray",
                        color: "white",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <div>
          <div>
            <Dialog
              style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
              maxWidth="xl"
              open={openpd}
              onClose={() => setOpenpd(!openpd)}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent>
                <div style={{ width: "37rem" }}>
                  <div className="mb-4 mt-1">
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "500",
                        color: "rgb(126, 110, 228)",
                        fontSize: 21,
                      }}
                    >
                      Pricing Details
                    </Typography>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <p>
                        <strong>Project Name:</strong> {pdprojname}
                      </p>
                      <p>
                        <strong>BasePrice:</strong> {pdbp}
                      </p>
                      <p>
                        <strong>East Amount:</strong> {pdeamt}
                      </p>
                      <p>
                        <strong>Corner Amount:</strong> {pdcor}
                      </p>
                      <p>
                        <strong>water&Electricity:</strong> {pdwateramt}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <strong>West Amount:</strong> {pdwamt}
                      </p>
                      <p>
                        <strong>Two Car Park:</strong> {pdtwo}
                      </p>
                      <p>
                        <strong>Scheme Name:</strong> {pdsname}
                      </p>
                      <p>
                        <strong>Park View Amount:</strong> {pdpv}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <strong>North Amount:</strong> {pdnamt}
                      </p>
                      <p>
                        <strong>Three Car Park:</strong> {pdthre}
                      </p>
                      <p>
                        <strong>Scheme Amount:</strong> {pdscheamt}
                      </p>
                      <p>
                        <strong>One Car Park:</strong> {pdo}
                      </p>
                      <p>
                        <strong>Club house:</strong> {pdclub}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>
                        <strong>Carpus :</strong> {pdcarpusfund}
                      </p>
                      <p>
                        <strong>Advancemaintance:</strong> {pdadvance}
                      </p>
                    </div>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "grey",
                        fontSize: 15,
                      }}
                    >
                      FloorRise Charges
                    </Typography>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>FloorName</th>
                          <th>Floorrise Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        {floorrisecharge.map((data) => {
                          return (
                            <tr>
                              <td>{data.floorName}</td>
                              <td>{data.floorRiseAmount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <div>
          <div>
            <Dialog
              style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
              maxWidth="xl"
              open={open11}
              onClose={() => setOpen11(!open11)}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent>
                <div style={{ width: "37rem" }}>
                  <Form noValidate>
                    <div className="mb-4 mt-1">
                      <Typography
                        style={{
                          textAlign: "center",
                          fontWeight: "500",
                          color: "rgb(126, 110, 228)",
                          fontSize: 21,
                        }}
                      >
                        {" "}
                        Update Pricing
                      </Typography>
                    </div>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom01"
                      >
                        <Typography style={{ color: "gray", fontSize: 13 }}>
                          Base Price
                        </Typography>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter your Base Price"
                          value={pdbp}
                          onChange={(e) => setPdbp(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid project name.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div>
                        <Typography
                          style={{
                            color: "black",
                            fontSize: 14,
                            marginBottom: 3,
                            fontWeight: "bold",
                          }}
                        >
                          Facing Charges
                        </Typography>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              East
                            </Typography>
                            <Form.Control
                              required
                              placeholder="Enter East Amount"
                              type="text"
                              value={pdeamt}
                              onChange={(e) => setPdeamt(e.target.value)}
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Basic Price
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              West
                            </Typography>
                            <Form.Control
                              required
                              onChange={(e) => setPdwamt(e.target.value)}
                              value={pdwamt}
                              type="text"
                              placeholder="Enter West Amount"
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter valid location
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              North
                            </Typography>
                            <Form.Control
                              required
                              placeholder="Enter North Amount"
                              type="text"
                              value={pdnamt}
                              onChange={(e) => setPdnamt(e.target.value)}
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Basic Price
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              South
                            </Typography>
                            <Form.Control
                              required
                              onChange={(e) => setPdsamt(e.target.value)}
                              type="text"
                              value={pdsamt}
                              placeholder="Enter South Amount"
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter valid location
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </div>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography
                          style={{
                            color: "black",
                            fontSize: 14,
                            marginBottom: 3,
                            fontWeight: "bold",
                          }}
                        >
                          Park View Charges
                        </Typography>
                        <Form.Control
                          required
                          placeholder="Enter  Amount"
                          type="text"
                          value={pdpv}
                          onChange={(e) => setPdpv(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Basic Price
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Typography
                          style={{
                            color: "black",
                            fontSize: 14,
                            marginBottom: 3,
                            fontWeight: "bold",
                          }}
                        >
                          Corner Charges
                        </Typography>
                        <Form.Control
                          required
                          placeholder="Enter  Amount"
                          type="text"
                          value={pdcor}
                          onChange={(e) => setPdcor(e.target.value)}
                          style={{ padding: "0.42rem", marginBottom: 14 }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid Basic Price
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Row className="mb-3"></Row>

                      <Typography
                        style={{
                          color: "black",
                          fontSize: 14,
                          marginBottom: 3,
                          fontWeight: "bold",
                        }}
                      >
                        Car Parking Charges{" "}
                      </Typography>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            One Cark Park
                          </Typography>
                          <Form.Control
                            required
                            placeholder="Enter One Cark Park Amount"
                            value={pdo}
                            onChange={(e) => setPdo(e.target.value)}
                            type="text"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Basic Price
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Two Cark Park
                          </Typography>
                          <Form.Control
                            required
                            type="text"
                            value={pdtwo}
                            onChange={(e) => setPdtwo(e.target.value)}
                            placeholder="Enter Two Cark Park Amount"
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter valid location
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Typography style={{ color: "gray", fontSize: 13 }}>
                            Three Cark Park
                          </Typography>
                          <Form.Control
                            required
                            value={pdthre}
                            placeholder="Enter Three Cark Park Amount"
                            type="text"
                            onChange={(e) => setPdthre(e.target.value)}
                            style={{ padding: "0.42rem", marginBottom: 14 }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Valid Basic Price
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <>
                        <Typography
                          style={{
                            color: "black",
                            fontSize: 14,
                            marginBottom: 3,
                            fontWeight: "bold",
                          }}
                        >
                          Scheme
                        </Typography>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Scheme Name
                            </Typography>
                            <Form.Control
                              required
                              placeholder="Enter Scheme Name"
                              type="text"
                              value={pdsname}
                              onChange={(e) => setPdsname(e.target.value)}
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Basic Price
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Typography style={{ color: "gray", fontSize: 13 }}>
                              Amount
                            </Typography>
                            <Form.Control
                              required
                              placeholder="Enter  Amount"
                              type="number"
                              value={pdscheamt}
                              onChange={(e) => setPdschamt(e.target.value)}
                              style={{ padding: "0.42rem", marginBottom: 14 }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Valid Basic Price
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </>
                    </Row>
                    <div className="d-flex">
                      <div>
                        <Button
                          onClick={priceedit}
                          style={{
                            width: "9rem",
                            height: "2.3rem",
                            backgroundColor: "rgb(126, 110, 228)",
                            color: "white",
                            marginRight: 3,
                          }}
                        >
                          Update
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() => setOpen11(false)}
                          style={{
                            width: "9rem",
                            height: "2.3rem",
                            backgroundColor: "gray",
                            color: "white",
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    {/* {
                                                btnone && (
                                                    <Button type='submit' style={{ width: '6rem', height: '1.8rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                                )
                                            } */}
                  </Form>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}
