import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2';
import { FaEdit, FaRupeeSign, FaTrash, FaUserCircle } from 'react-icons/fa';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import Pagination from '@mui/material/Pagination';


export default function Pricing() {

    const [Basic_Amount, setBasic_Amount] = useState()
    const [Floorwise_Amount, setFloorwise_Amount] = useState(0)
    const [Facing, setFacing] = useState('')
    const [Floorwise, setFloorwise] = useState('')
    const [ProjectName, setProjectName] = useState()
    const [Facingwise_Amount, setFacingwise_Amount] = useState()
    const [id, setId] = useState()
    const [selectfloor, setSelectfloor] = useState([])
    const [selectunit, setSelectunit] = useState([])
    const [Blocks, setBlocks] = useState("")
    const [corner, setCorner] = useState()
    const [corneramount, setCornerAmount] = useState(0)

    const [response2, setResponse2] = useState([])

    const get_block = (value) => {
        setProjectName('')
        setBlocks('')

        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise-blocks", {
            value
        }).then((res) => {

            setResponse2(res.data)
            setProjectName(value)
            res.data.forEach(data => {
                // console.log(data.Blocks);
            });
            // console.log(res.data)

        })
    }

    const select_floor = () => {
        axios.post("https://buildfastapi.emedha.in/project/api/v1/select-floor", {
            projectName: ProjectName
        }).then((res) => {
            setSelectfloor(res.data)
            console.log(res.data);
        })
    }

    const select_unit = () => {
        axios.post("https://buildfastapi.emedha.in/project/api/v1/select-unit", {
            projectName: ProjectName
        }).then((res) => {
            setSelectunit(res.data)
            console.log(res.data);
        })
    }

    const [Cornor, setCornor] = React.useState('')

    const handleCornerCheckboxChange = (event) => {

        console.log(event.target.value);

        setCornor(event.target.value)

        setCornerAmount(event.target.value === 'Yes');

    };

    const fetchCurrentCornerValue = () => {

        const currentCornerValue = 'Yes';
        // const currentCornerAmountValue = '';

        setCorner(currentCornerValue);
        // setCornerAmount(currentCornerAmountValue);
    };


    useEffect(() => {
        fetchCurrentCornerValue();
    }, []);
    const fetchCurrentCornerAmountValue = () => {
        setCorner(currentCornerAmountValue);

        const currentCornerAmountValue = 'No';
    };


    useEffect(() => {
        fetchCurrentCornerValue();
    }, []);

    const [corno, setCorno] = useState(0)

    const add_pricing = () => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/add-pricing", {
            Basic_Amount, ProjectName, Floorwise_Amount, Facing, Floorwise, Facingwise_Amount, Cornor, CornorAmount: corneramount
        }).then((res) => {
            if (res.data.status === 200) {
                setOpen(false)
                get_pricing()
                Swal.fire({
                    icon: 'success',
                    title: "Success !",
                    text: "Data added successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const [response, setResponse] = useState([])

    const get_pricing = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-pricing").then((res) => {
            if (res.data.status === 200) {
                setResponse(res.data.data)
            }
        }).catch((err => {
            console.error(err)
        }))
    }


    const [response1, setResponse1] = useState([])

    const get_basic = () => {
        axios.get("https://buildfastapi.emedha.in/project/api/v1/get-basic").then((res) => {
            if (res.data.status === 200) {
                setResponse1(res.data.data)
            }
        })
    }

    React.useEffect(() => {
        get_basic()
        get_pricing()
    }, [])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose2 = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false);
        setValidated(false)
    };

    const [Validated, setValidated] = useState(false);

    const formValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            add_pricing()
        }

        setValidated(true);
    };

    const deleteAlert = (id) => {
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to delete this data",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                basicDataDelete(id)
            }
        })
    }

    const basicDataDelete = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/delete-pricing', {
            id
        }).then((res) => {
            if (res.data.status === 200) {
                get_pricing()
                Swal.fire({
                    icon: 'success',
                    title: "Successfull !",
                    text: "Basic data deleted successfully !",
                    confirmButtonColor: "rgb(126, 110, 228)",
                    cancelButtonColor: "#d33",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then((result) => {
                    if (result.value) {
                        return
                    }
                })
            }
        })
    }

    const editAlert = (Id) => {
        setOpen1(false)
        handleClose2()
        Swal.fire({
            icon: 'warning',
            title: "Warning !",
            text: "Are you sure to edit this data",
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.value) {
                pricingDataEdit(id)
            }
        })
    }

    const pricingDataEdit = (id) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/pricing-edit', {
            Basic_Amount, ProjectName, Floorwise_Amount, Facing, Floorwise, Facingwise_Amount, corneramount, id
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.status === 200) {
                    get_pricing()
                    Swal.fire({
                        icon: 'success',
                        title: "Successfull !",
                        text: "Basic data updated successfully !",
                        confirmButtonColor: "rgb(126, 110, 228)",
                        cancelButtonColor: "#d33",
                        showCancelButton: false,
                        confirmButtonText: "Ok"
                    }).then((result) => {
                        if (result.value) {
                            return
                        }
                    })
                }
            }
        })
    }

    const [open1, setOpen1] = useState(false)

    const viewData = () => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/view-pricing', {
            id
        }).then((res) => {
            setBasic_Amount(res.data.Basic_Amount)
            setProjectName(res.data.ProjectName)
            setFloorwise_Amount(res.data.Floorwise_Amount)
            setFloorwise(res.data.Floorwise)
            setFacing(res.data.Facing)
            setFacingwise_Amount(res.data.Facingwise_Amount)
            setCornerAmount(res.data.CornerAmount)
            setOpen1(true)
        })
    }


    const searchpricing = (text) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/search-pricing', {
            text
        }).then((res) => {

            setResponse(res.data)

        })
    }

    const [responset, setResponset] = useState([])

    const get_tower = (value1) => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise-towers", {
            value1
        }).then((res) => {

            setResponset(res.data)
            // console.log(res.data)
            // get_tower(value1)


        })
    }

    const [response3, setResponse3] = useState([])

    const get_floor = (value1, value2) => {

        axios.post("https://buildfastapi.emedha.in/project/api/v1/select-floor", {
            value1, value2
        }).then((res) => {

            setResponse3(res.data)
            // setun(value1)


        })
    }

    const [response4, setResponse4] = useState([])

    const get_unit = (value) => {
        axios.post("https://buildfastapi.emedha.in/project/api/v1/projectwise/unitss", {
            value
        })
            .then((res) => {
                setResponse4(res.data)
                // console.log(res.data);
                //    setFloorname(value)

            })
    }


    const [selectedTower, setSelectedTower] = useState('');

    const handleTowerChange = (selectedTower) => {
        setSelectedTower(selectedTower);
    };

    // const [selectedFloors, setSelectedFloors] = useState([]);

    // const handleFloorChange = (floor) => {
    //     const updatedFloors = [...selectedFloors];

    //     if (updatedFloors.includes(floor)) {
    //         updatedFloors.splice(updatedFloors.indexOf(floor), 1);
    //     } else {
    //         updatedFloors.push(floor);
    //     }

    //     setSelectedFloors(updatedFloors);
    // };

    const [selectedFloors, setSelectedFloors] = useState([]);

    useEffect(() => {
        if (selectedFloors.length > 0) {
            getbulkunit(selectedFloors);
        }
    }, [selectedFloors]);
    
    const handleFloorChange = (floor) => {
        
        console.log(floor.selectType); 
        const updatedFloors = [...selectedFloors];
      
        if (updatedFloors.includes(floor.selectType)) {
          updatedFloors.splice(updatedFloors.indexOf(floor.selectType));
        } else {
          updatedFloors.push(floor.selectType);
        }
        setSelectedFloors(updatedFloors);
        console.log('updatedFloors', updatedFloors);
       
        // getbulkunit(updatedFloors);
        
      }
      const [bunit, setBunit]  = useState([]);
      
     

      const getbulkunit = (selectedFloors) => {
        axios.post('https://buildfastapi.emedha.in/project/api/v1/getpricfloor',{
            floor:selectedFloors
        })
        .then((res => {
            console.log("asd", res.data.data);
            setBunit(res.data.data)
        }))
      }

      const [showCornerAmountField, setShowCornerAmountField] = useState(false);
      const [showEastFacingAmountField, setShowEastFacingAmountField] = useState(false);
      const [floorwiseamt, setFloorwiseamt] = useState(false)
      const [selectedUnitDigits, setSelectedUnitDigits] = useState([]); // Store the last digits of selected unit IDs

      const handleUnitChange = (selectedUnitId) => {
          console.log(selectedUnitId);
          setFloorwise(selectedUnitId)
          const lastDigit = parseInt(selectedUnitId.toString().slice(-1)); // Extract the last digit of the selected unit ID
          setSelectedUnitDigits(prevDigits => {
              // Check if the last digit is already in the list
              if (prevDigits.includes(lastDigit)) {
                  // If yes, remove it
                  return prevDigits.filter(digit => digit !== lastDigit);
              } else {
                  // If not, add it
                  return [...prevDigits, lastDigit];
              }
          });
      }

      
    useEffect(() => {
      
        if (selectedUnitDigits.some(digit => [1, 5].includes(digit))) {
            setShowCornerAmountField(true);
            setShowEastFacingAmountField(true)
        } else if (selectedUnitDigits.some(digit => [2, 3, 4].includes(digit))) {
            setShowCornerAmountField(false);
            setShowEastFacingAmountField(true);
        }  else if (selectedUnitDigits.some(digit => [6,0].includes(digit))) {
            setShowCornerAmountField(true);
            // setShowEastFacingAmountField(true);
        }else if (selectedUnitDigits.some(digit => [7,8,9].includes(digit))) {
            setFloorwiseamt(true);
            // setShowEastFacingAmountField(true);
        }else {
            setShowCornerAmountField(false);
            setShowEastFacingAmountField(false)
            setFloorwiseamt(false);
        }
    }, [selectedUnitDigits]);

    //   const handleUnitChange = (a) => {
    //     console.log(a);
    //   }

      const groupUnitsByFloor = (units) => {
        const floorUnitsMap = {};
        units.forEach(unit => {
            if (!floorUnitsMap[unit.floor]) {
                floorUnitsMap[unit.floor] = [];
            }
            floorUnitsMap[unit.floor].push(unit);
        });
        return floorUnitsMap;
    }


    const [page, setPage] = useState(5)
    const [cpage, setCpage] = useState(1)

    const pagehandle = (e, val) => {
        setCpage(val);
    }

    const indexOflpage = cpage * page;
    const indexOffpage = indexOflpage - page;
    const currentpost = response.slice(indexOffpage, indexOflpage)

    return (
        <div>


            <div className='leadManagementTable'>
                <Paper elevation={0} >

                    <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

                        <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

                        <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
                        <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div>

                                <input onChange={(e) => searchpricing(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
                            </div>
                            <div>
                                <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add Pricing</Button>
                            </div>
                        </div>
                        <Table style={{ position: 'relative', top: '1.3rem' }}>
                            <TableHead sx={{ borderTop: '1px solid lightgray' }}>
                                <TableRow>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Project Name
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Basic Amount
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Unit
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Floorwise Amount
                                    </TableCell>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Facing
                                    </TableCell>

                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        East Facing Amount
                                    </TableCell>
                                    
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Corner Amount
                                    </TableCell>
                                    <TableCell
                                        align={"start"}
                                        style={{ minWidth: 0, fontSize: 14, fontWeight: '600', color: 'gray', fontSize: 13 }}
                                    >
                                        Total Amount
                                    </TableCell>

                                    <TableCell

                                        align={"left"}
                                        style={{ top: 21, fontWeight: '600', color: 'gray', fontSize: 13, width: 21 }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {currentpost
                                    .map((row) => {

                                        let color = ''
                                        let widths = ''

                                        if (row.lead === 'Not Customer') {
                                            color = 'red'
                                            widths = false
                                        }

                                        if (row.lead === 'Customer') {
                                            color = 'green'
                                            widths = true
                                        }


                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align={"start"}
                                                    style={{ fontSize: 12 }}>{row.ProjectName}</TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaRupeeSign style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.Basic_Amount} /-</button>
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ fontSize: 12 }}>{row.Unit} </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaRupeeSign style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.Floorwise_Amount} /-</button>
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ fontSize: 12 }}>{row.Facing} </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}><FaRupeeSign style={{ marginRight: 3, color: 'white', marginTop: '-0.16rem', fontSize: 9 }} />{row.Facingwise_Amount} /-</button>
                                                </TableCell>
                                               
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}>{row.CornorAmount}</button>
                                                </TableCell>
                                                <TableCell align={"start"}
                                                    style={{ top: 57, fontSize: 12 }}>
                                                    <button className='shadow' style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'rgb(126, 110, 228)', borderRadius: '1.2rem', color: 'white', cursor: 'auto' }}>{row.TotalValue}</button>
                                                </TableCell>

                                                <TableCell align={"start"}
                                                    style={{ fontSize: 12, color: "rgb(126, 110, 228) " }}>

                                                    <div style={{ display: 'flex' }} >
                                                        {/* <Button onClick={() => deleteAlert(row.id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Delete</Button> */}
                                                        <div>
                                                            <IconButton
                                                                id="fade-button"
                                                                aria-controls={open2 ? 'fade-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open2 ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    handleClick(e.currentTarget)
                                                                    setId(row.id)
                                                                }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open2}
                                                                onClose={handleClose2}
                                                                TransitionComponent={Fade}
                                                            >

                                                                <MenuItem style={{ color: 'gray' }} onClick={() => {

                                                                    handleClose2()
                                                                    viewData(id)

                                                                }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>

                                                                <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {
                            response.length === 0 && (
                                <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
                                    <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
                                    <Typography>0 customers</Typography>
                                </Box>
                            )
                        }
                    </TableContainer>
                </Paper>
                <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
                    <Pagination count={Math.ceil(response.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
                </div>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add pricing</Typography>
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => { get_block(e.target.value) }}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid name
                                        </Form.Control.Feedback>
                                    </Form.Group>



                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Block</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => {
                                                get_tower(e.target.value)
                                            }}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Block</option>


                                            {
                                                response2.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.Blocks.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex}>{block.trim()}</option>
                                                        ))}
                                                    </React.Fragment>
                                                ))

                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group>



                                    {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => get_floor(e.target.value, ProjectName)}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Towers</option>

                                            {
                                                responset.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.Towers.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex}>{block.trim()}</option>
                                                        ))}
                                                    </React.Fragment>
                                                ))

                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter project name
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                   
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => {
                                                get_unit(e.target.value)
                                            }}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Floor</option>


                                            {
                                                response3.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.selectType.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex} >{block.trim()}</option>
                                                        ))}
                                                    </React.Fragment>

                                                ))

                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group> */}


                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Towers</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => {
                                                handleTowerChange(e.target.value);
                                                get_floor(e.target.value, ProjectName);
                                                
                                            }}
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select Towers</option>
                                            {/* {response2.map((data, index) => (
                                                <option key={index}>{data.Blocks}</option>
                                                
                                            ))} */}
                                            {
                                                responset.map((data, index) => (
                                                    <React.Fragment key={index}>
                                                        {data.Towers.split(',').map((block, blockIndex) => (
                                                            <option key={blockIndex}>{block.trim()}</option>
                                                        ))}
                                                    </React.Fragment>
                                                ))

                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    {selectedTower && (
                                        <Form.Group as={Col} md="12" controlId="floorsCheckboxes">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Floors</Typography>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {response3 && Array.isArray(response3) && response3.map((floorNumber) => (
                                                <Form.Check
                                                    key={floorNumber}
                                                    inline
                                                    label={`Floor ${floorNumber.selectType }`}
                                                    type="checkbox"
                                                    id={`floor-checkbox-${floorNumber.selectType}`}
                                                    onChange={() => handleFloorChange(floorNumber)}
                                                />
                                                ))}
                                            </div>
                                        </Form.Group>
                                    )}

                                    <Form.Group as={Col} md="12" controlId="floorsCheckboxes">
                                    <div style={{marginTop:5}}>
                                        {
                                            Object.entries(groupUnitsByFloor(bunit)).map(([floor, units]) => (
                                                <div key={floor}>
                                                    <h6 style={{color:'grey',fontWeight:'bold'}}> {floor} Floor - units</h6>
                                                    {units.length === 0 ? (
                                                        <p>No units available</p>
                                                    ) : (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {units.map(unit => (
                                                            <div key={unit.id} style={{ marginRight: '10px' }}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`unit-checkbox-${unit.id}`}
                                                                    label={`${unit.unit_name}`}
                                                                    // checked={selectedUnits.includes(unit.id)} // Assuming selectedUnits state contains IDs of selected units
                                                                    onChange={() => handleUnitChange(unit.unit_name)}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </div>

                                    </Form.Group>
                                                                          

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Basic Amount</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setBasic_Amount(e.target.value)}
                                            placeholder='per sft'
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Basic Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {
                                        showCornerAmountField && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Corner Amount</Typography>
                                        <Form.Control
                                            required
                                          // Disable the field if showCornerAmountField is false
                                            onChange={(e) => setCornerAmount(e.target.value)}
                                            placeholder='Corner Amount'
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14, }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Corner Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                        )
                                    }
                                    {
                                        showEastFacingAmountField && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>East Facing Amount</Typography>
                                        <Form.Control
                                            required
                                          // Disable the field if showCornerAmountField is false
                                            onChange={(e) => setFacingwise_Amount(e.target.value)}
                                            placeholder='East Facing Amount'
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14, }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Corner Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                        )
                                    }
                                    {
                                        floorwiseamt && (
                                            <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}> Floor Wise Amount</Typography>
                                        <Form.Control
                                            required
                                          // Disable the field if showCornerAmountField is false
                                            onChange={(e) => setFloorwise_Amount(e.target.value)}
                                            placeholder='Floor Wise Amount'
                                            type="text"
                                            style={{ padding: '0.42rem', marginBottom: 14, }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Corner Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                        )
                                    }


                                    {/* Conditionally render Floorwise Amount based on selected floors */}
                                    {selectedFloors.length > 0 && parseInt(selectedFloors[0]) >= 6 && parseInt(selectedFloors[0]) <= 12 && (
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Floorwise Amount</Typography>
                                            <Form.Control
                                                required
                                                onChange={(e) => setFloorwise_Amount(e.target.value)}
                                                type="text"
                                                placeholder='per sft'
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter valid floorwise Amount
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}

                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button type='submit' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Submit</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Dialog
                    style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
                    maxWidth='xl'
                    open={open1}
                    onClose={() => setOpen1(open1)}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <div style={{ width: "37rem" }} >
                            <Form noValidate validated={Validated} onSubmit={formValidation} >
                                <div className='mb-4 mt-1'>
                                    <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update pricing</Typography>
                                </div>
                                <Row className="mb-3">

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Project Name</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setProjectName(e.target.value)}
                                            type="text"
                                            value={ProjectName}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>Select Project Name</option>

                                            {
                                                response1.map((data) => {
                                                    return (
                                                        <option>{data.ProjectName}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid name
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Basic Amount</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setBasic_Amount(e.target.value)}
                                            value={Basic_Amount}
                                            type="text"

                                            style={{ padding: '0.42rem', marginBottom: 14, }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Basic Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Floor</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setFloorwise(e.target.value)}
                                            type="text"
                                            value={Floorwise}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        >
                                            <option>--Select Floor--</option>
                                            <option>1-5 Floor</option>
                                            <option>6-10 Floor</option>
                                            <option>10-12 Floor</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid manager
                                        </Form.Control.Feedback>
                                    </Form.Group>




                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Floorwise Amount</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setFloorwise_Amount(e.target.value)}
                                            type="text"
                                            value={Floorwise_Amount}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid floorwise Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Facing</Typography>
                                        <Form.Select
                                            required
                                            onChange={(e) => setFacing(e.target.value)}
                                            type="date"
                                            value={Facing}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}
                                        >
                                            <option>Select Facing</option>
                                            <option>East</option>
                                            <option>West</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid facing
                                        </Form.Control.Feedback>
                                    </Form.Group>



                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Typography style={{ color: 'gray', fontSize: 13 }}>Facingwise Amount</Typography>
                                        <Form.Control
                                            required
                                            onChange={(e) => setFacingwise_Amount(e.target.value)}
                                            type="text"
                                            value={Facingwise_Amount}
                                            style={{ padding: '0.42rem', marginBottom: 14 }}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter valid floorwise Amount
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <label style={{ color: 'gray', fontSize: 13 }}>Corner</label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="whatsappGroup"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                    value="Yes"
                                                    checked={corner === 'Yes'} // Set the checked attribute based on the current corner value
                                                    onChange={handleCornerCheckboxChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="whatsappGroup"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                    value="No"
                                                    checked={corner === 'No'} // Set the checked attribute based on the current corner value
                                                    onChange={handleCornerCheckboxChange}
                                                />
                                            </div>
                                        ))}
                                        <Form.Control.Feedback type="invalid">
                                            Please select Corner option
                                        </Form.Control.Feedback>
                                    </Form.Group>


                                    {corner === 'Yes' && (
                                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                                            <Typography style={{ color: 'gray', fontSize: 13 }}>Corner Amount*</Typography>
                                            <Form.Control
                                                required
                                                placeholder='per sft'
                                                value={corneramount}
                                                onChange={(e) => setCorno(e.target.value)}
                                                type="number"
                                                style={{ padding: '0.42rem', marginBottom: 14 }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid corner amount
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}




                                </Row>
                                <div className='d-flex'>
                                    <div>
                                        <Button onClick={() => editAlert(id)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => setOpen1(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <Pagination>
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Ellipsis />

                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>

                <Pagination.Next />
                <Pagination.Last />
            </Pagination>
        </div >
    );
}