import React from 'react';
import axios from "axios";

function Message({ ticket, onReply, onCancel, onSubmit,onTicketClosed }) {


  const handleClose = (ticketNumber) => {
    axios
      .put(`https://buildfastapi.emedha.in/internal/api/v1/api/tickets/${ticketNumber}`, { status: "closed" })
      .then((response) => {
        console.log("Ticket closed:", response.data);
        onTicketClosed(ticketNumber); // Notify the parent component to update the state
      })
      .catch((error) => console.error("Error closing ticket:", error));
  };



  return (
    <li>
      <div style={{color:'black'}}>
        <p><strong>Ticket Number:</strong> {ticket.ticket_number}</p>
        <p><strong>Mail Content:</strong> {ticket.mail_content}</p>
        <p><strong>Department:</strong> {ticket.department}</p>
        <p><strong>Type:</strong> {ticket.type}</p>
        <p><strong>Sender:</strong> {ticket.sender}</p>
        <p><strong>Receiver:</strong> {ticket.receiver}</p>
        <button  style={{
          backgroundColor: "#4CAF50", // Green background
          border: "none", // No border
          color: "white", // White text
          padding: "15px 32px", // Padding
          textAlign: "center", // Centered text
          textDecoration: "none", // No underline
          display: "inline-block", // Display inline-block
          fontSize: "16px", // Font size
          margin: "4px 2px", // Margin
          cursor: "pointer", // Pointer cursor on hover
          borderRadius: "12px", // Rounded corners
        }} onClick={() => onReply(ticket.ticket_number)}>Reply</button>
        <button style={{
          backgroundColor: "#4CAF50", // Green background
          border: "none", // No border
          color: "white", // White text
          padding: "15px 32px", // Padding
          textAlign: "center", // Centered text
          textDecoration: "none", // No underline
          display: "inline-block", // Display inline-block
          fontSize: "16px", // Font size
          margin: "4px 2px", // Margin
          cursor: "pointer", // Pointer cursor on hover
          borderRadius: "12px", // Rounded corners
        }}   onClick={() => handleClose(ticket.ticket_number)}>Close</button>
        {/* <button style={{
          backgroundColor: "#4CAF50", // Green background
          border: "none", // No border
          color: "white", // White text
          padding: "15px 32px", // Padding
          textAlign: "center", // Centered text
          textDecoration: "none", // No underline
          display: "inline-block", // Display inline-block
          fontSize: "16px", // Font size
          margin: "4px 2px", // Margin
          cursor: "pointer", // Pointer cursor on hover
          borderRadius: "12px", // Rounded corners
        }} onClick={() => onSubmit(ticket.ticket_number)}>Submit</button> */}
      </div>
    </li>
  );
}

export default Message;



// import React from "react";

// const Message = ({ ticket, onReply, onCancel, onSubmit, onTicketClosed }) => {
//   return (
//     <li style={{ marginBottom: "10px", padding: "10px", border: "1px solid #ddd",color:'black' }}>
//       <p><strong>Ticket Number:</strong> {ticket.ticket_number}</p>
//       <p><strong>Mail Content:</strong> {ticket.mail_content}</p>
//       <p><strong>Department:</strong> {ticket.department}</p>
//       <p><strong>Type:</strong> {ticket.type}</p>
//       <p><strong>Sender:</strong> {ticket.sender}</p>
//       <p><strong>Receiver:</strong> {ticket.receiver}</p>
//       <p><strong>Status:</strong> {ticket.status}</p>
//       {ticket.replies && ticket.replies.length > 0 && (
//         <div>
//           <strong>Replies:</strong>
//           <ul>
//             {ticket.replies.map((reply, index) => (
//               <li key={index}>
//                 <p>{reply.content}</p>
//                 <p><em>{new Date(reply.timestamp).toLocaleString()}</em></p>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       <button onClick={() => onReply(ticket.ticket_number)}>Reply</button>
//       <button onClick={() => onCancel(ticket.ticket_number)}>Cancel</button>
//       <button onClick={() => onSubmit(ticket.ticket_number)}>Submit</button>
//       {ticket.status === 'open' && (
//         <button onClick={() => onTicketClosed(ticket.ticket_number)}>Close Ticket</button>
//       )}
//     </li>
//   );
// };

// export default Message;

