import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  TableBody,
  Typography,
} from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Row from "react-bootstrap/Row";
import {
  FaBlenderPhone,
  FaEdit,
  FaPhoneSquare,
  FaTrash,
  FaEye,
  FaWindowClose,
} from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import { FaMobileAlt } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const PaymentApprove = () => {
  const [value, setValue] = React.useState("1");
  const [id, setId] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   const add_blockform = () => {
  //     console.log({
  //       name: nname,
  //       mobile: nmobile,
  //       alternateMobile: alternatemob,
  //       email: nemail,
  //       permanentAddress: peraddress,
  //       addComm: addresscom,
  //       aadharNo: aadharnumber,
  //       panNo: pannumber,
  //       paySource: paymentsource,
  //       cheqNo: chequenumber,
  //       transferDetail: transferdetails,
  //       amtInWords: amountinwords,
  //       amt: Amount,
  //       tflatNo: tflatno,
  //       ttowerNo: ttowerno,
  //       salesSOurce: salessource,
  //     });
  //     const formdata = new FormData();
  //     formdata.append("name", nname);
  //     formdata.append("mobile", nmobile);
  //     formdata.append("alternateMobile", alternatemob);
  //     formdata.append("email", nemail);
  //     formdata.append("permanentAddress", peraddress);
  //     formdata.append("addComm", addresscom);
  //     formdata.append("aadharNo", aadharnumber);
  //     formdata.append("panNo", pannumber);
  //     formdata.append("paySource", paymentsource);
  //     formdata.append("cheqNo", chequenumber);
  //     formdata.append("transferDetail", transferdetails);
  //     formdata.append("amtInWords", amountinwords);
  //     formdata.append("amt", Amount);
  //     formdata.append("tflatNo", tflatno);
  //     formdata.append("ttowerNo", ttowerno);
  //     formdata.append("salesSOurce", salessource);
  //     formdata.append("sig", signature);
  //     axios
  //       .post(
  //         "https://buildfastapi.emedha.in/create-customer/api/v1/add-blockform",
  //         formdata
  //       )
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           setOpenbf(false);
  //           customerObjsib();
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success !",
  //             text: "Data is added successfully !",
  //             confirmButtonColor: "rgb(126, 110, 228)",
  //             cancelButtonColor: "#d33",
  //             showCancelButton: false,
  //             confirmButtonText: "Ok",
  //           }).then((result) => {
  //             if (result.value) {
  //               return;
  //             }
  //           });
  //         }
  //       });
  //   };

  const [payments, setPayments] = React.useState([]);

  const customerObjsib = () => {
    axios
      .get("https://buildfastapi.emedha.in/create-customer/api/v1/api/payments")
      .then((res) => {
        setPayments(res.data);
      });
  };

  const [customerRes, setCustomerResponse] = React.useState([]);

  const customerObj = () => {
    axios
      .get("https://buildfastapi.emedha.in/create-customer/api/v1/customers")
      .then((res) => {
        setCustomerResponse(res.data);
      });
  };

  React.useEffect(() => {
    customerObj();
    customerObjsib();
  }, []);

  const [openRejectForm, setOpenReject] = React.useState();

  const handleClickOpenReject = () => {
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  
  
    


  const [paymentApproveDate, setDataForPaymentApprove] = useState("");
  const [paymentApproveComment, setCommentForPaymentApprove] = useState("");
  const [paymentTransactionId,setTransactionIdForApprovePayment]=useState("")
  const [paymentCustomers, setPaymentCustomers] = useState(payments);

  const [ValidatedtaPaymentApproveForm, setValidatedPaymentApproveForm] = useState(false);

  const [openApprovePaymentForm, setOpenApprovePaymentForm] = useState();

  const handleClickOpenApprovePayment = () => {
    setOpenApprovePaymentForm(true);
  };

  const handleCloseApprovePaymentForm = () => {
    setOpenApproveBlockForm(false);
  };

//   const formValidationForPaymentApprove =async (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       try {
//         await axios.post('http://localhost:3003/api/payment/approve', {
//           id,
//           approvalDate: paymentApproveDate,
//           comment: paymentApproveComment,
//           transactionId:paymentTransactionId
//         });

    
//         setApproveData({});
//         setCommentForPaymentApprove('');
//         setDataForPaymentApprove('')
//         setOpenApprovePaymentForm(false);
//         alert("Payment Successfully Approved")
//         window.location.reload();
//       } catch (error) {
//         console.error("Error approving payment customer:", error);
//         alert("Error approving payment customer:", error)
//       }
//       setValidatedPaymentApproveForm(false);
//     }
    
//   };

const formValidationForPaymentApprove = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      try {
        const response = await axios.post('https://buildfastapi.emedha.in/create-customer/api/v1/api/payment/approve', {
          id,
          approvalDate: paymentApproveDate,
          comment: paymentApproveComment,
          transactionId: paymentTransactionId
        });
  
        if (response.status === 200) {
          // Update UI or perform other actions upon successful approval
        
          setCommentForPaymentApprove('');
          setDataForPaymentApprove('');
          setOpenApprovePaymentForm(false);
          alert("Payment Successfully Approved");
          window.location.reload(); // Refresh the page or update UI as needed
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error approving payment customer:", error);
        alert("Error approving payment customer: " + error.message);
      }
      setValidatedPaymentApproveForm(false);
    }
  };
  

  return (
    <>
      <div>
      
             <Typography
             style={{
               color: "rgba(41, 16, 105, 1)",
               marginBottom: 3,
               marginLeft: 15.5,
               fontSize: 18,
               color: "gray",
               fontWeight: "500",
               textAlign: "center",
             }}
           >
             Payment Transactions
           </Typography>
           <Table style={{ position: "relative", top: "1.3rem" }}>
           <TableHead sx={{ borderTop: "1px solid lightgray" }}>
             <TableRow>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Name
               </TableCell>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Ucin
               </TableCell>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Invoice
               </TableCell>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Total Payment
               </TableCell>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Paid Amount
               </TableCell>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Transaction Date
               </TableCell>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 UTR/Check
               </TableCell>
             

               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Bank Name
               </TableCell>
               <TableCell
                 align={"start"}
                 style={{ minWidth: 0, fontSize: 14, fontWeight: "600" }}
               >
                 Branch
               </TableCell>

               <TableCell
                 align={"left"}
                 style={{ top: 21, fontWeight: "600", width: 21 }}
               >
                 Action
               </TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {payments.map((item) => (
               <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12 }}
                 >
                   {item.customerName}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.ucin}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.invoice}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.payment}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.paidAmount}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.checkdate}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.utr_check_no}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.bankname}
                 </TableCell>
                 <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.branch}
                 </TableCell>
                 {/* <TableCell
                   align={"start"}
                   style={{ top: 57, fontSize: 12, width: 100 }}
                 >
                   {item.bank_branch}
                 </TableCell> */}

                 <TableCell
                   align={"start"}
                   style={{
                     top: 57,
                     fontSize: 12,
                     color: "rgb(126, 110, 228) ",
                   }}
                 >
                   <div style={{ display: "flex" }}>
                {item.status !=='Approved'? ( // Assuming 'approved' is a field indicating if payment is approved
                  <Badge
                    bg="success"
                    style={{ cursor: "pointer" }}
                   onClick={()=>{handleClickOpenApprovePayment(), setId(item.id)}}
                  >
                    Approve
                  </Badge>
                ) : (
                  <span style={{ color: "gray" }}>Approved</span>
                )}
              </div>
                 </TableCell>
               </TableRow>
             ))}
           </TableBody>
         </Table>

        
      </div>

      {/*Blocked Approve Form*/}

      <Dialog
        style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
        maxWidth="xl"
        open={openApprovePaymentForm}
        onClose={handleCloseApprovePaymentForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ width: "37rem" }}>
            <Form
              noValidate
              validated={ValidatedtaPaymentApproveForm}
              onSubmit={formValidationForPaymentApprove}
            >
              <div className="mb-4 mt-1">
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "rgb(126, 110, 228)",
                    fontSize: 21,
                  }}
                >
                  Payment Approve
                </Typography>
              </div>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Date of Approve *
                  </Typography>
                  <Form.Control
                    required
                    onChange={(e) => setDataForPaymentApprove(e.target.value)}
                    type="datetime-local"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Typography style={{ color: "gray", fontSize: 13 }}>
                    Comment Box
                  </Typography>
                  <Form.Control
                    re
                    onChange={(e) => setCommentForPaymentApprove(e.target.value)}
                    required
                    as="textarea"
                    style={{ padding: "0.42rem", marginBottom: 14 }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Valid Comment
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Typography style={{ color: "gray", fontSize: 11 ,marginTop:'10px'}}>
                        Transaction ID *
                      </Typography>
                      <Form.Control
                        required
                        onChange={(e) => setTransactionIdForApprovePayment(e.target.value)}
                        value={paymentTransactionId}
                        // placeholder="Transaction ID"
                        type="text"
                        style={{ padding: "0.42rem", marginBottom: 14 }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Transaction ID
                      </Form.Control.Feedback>
                    </Form.Group>
              </Row>
              <div className="d-flex">
                <div>
                  <Button
                    type="submit"
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "rgb(126, 110, 228)",
                      color: "white",
                      marginRight: 3,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setOpenApprovePaymentForm(false);
                      setValidatedPaymentApproveForm(false);
                    }}
                    style={{
                      width: "9rem",
                      height: "2.3rem",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </DialogContent>
      </Dialog>

     

    


    </>
  );
};

export default PaymentApprove;
