import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const CustomerDetailsModal = ({ ucin, show, handleClose }) => {
  const [customerDetails, setCustomerDetails] = useState(null);

  React.useEffect(() => {
    if (ucin) {
      axios.get(`https://buildfastapi.emedha.in/create-customer/api/v1/customer/${ucin}`)
        .then(response => setCustomerDetails(response.data))
        .catch(error => console.error('Error fetching customer details:', error));
    }
  }, [ucin]);

  if (!customerDetails) return null;


  const modalStyle = {
    zIndex: 10000// Adjust the zIndex value as needed
  };

  return (
    <Modal show={show} onHide={handleClose} centered style={modalStyle}>
      <Modal.Header closeButton>
        <Modal.Title>Customer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Customer Name:</strong> {customerDetails.customerName}</p>
        <p><strong>Email:</strong> {customerDetails.email}</p>
        <p><strong>Mobile Number:</strong> {customerDetails.mobileNumber}</p>
        <p><strong>Aadhar No:</strong> {customerDetails.aadharNo}</p>
        <p><strong>PAN No:</strong> {customerDetails.panNo}</p>
        <p><strong>Address:</strong> {customerDetails.address}</p>
        <p><strong>Flat No:</strong> {customerDetails.flatNo}</p>
        <p><strong>Project Name:</strong> {customerDetails.projectName}</p>
        <p><strong>UCIN:</strong> {customerDetails.ucin}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerDetailsModal;
