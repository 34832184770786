// import React, { useState } from "react";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import {
//   FaCheck,
//   FaEdit,
//   FaEye,
//   FaMobileAlt,
//   FaPhone,
//   FaTrash,
//   FaUserCircle,
//   FaWindowClose,
// } from "react-icons/fa";
// import {
//   Box,
//   Button,
//   Menu,
//   MenuItem,
//   TableBody,
//   Typography,
// } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import Fade from "@mui/material/Fade";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
// import axios from "axios";
// import Swal from "sweetalert2";
// import Pagination from "@mui/material/Pagination";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import Tab from "@mui/material/Tab";
// import TabPanel from "@mui/lab/TabPanel";
// import FilterListIcon from "@mui/icons-material/FilterList";
// import { BsThreeDotsVertical } from "react-icons/bs";
// import Badge from "react-bootstrap/Badge";
// import Popup from "reactjs-popup";
// import moment from "moment";
// import "reactjs-popup/dist/index.css";

// function Manger() {
//   const [id, setId] = useState();
//   const [wapp, setWapp] = useState();
//   const [isWhatsappNo, setIsWhatsappNo] = useState(false);
//   const [whatsappMobile, setWhatsappMobile] = useState("");
//   const handleWhatsappCheckboxChange = (event) => {
//     setIsWhatsappNo(event.target.value === "No");
//   };

//   const [mobile, setMobile] = useState("");
//   const [sourceType, setSourceType] = useState("Website");

//   const [comment, setComment] = useState();
//   const [location, setLocation] = useState();
//   const [rname, setRName] = useState();
//   const [rmobile, setRMobile] = useState();
//   const [showReferenceFields, setShowReferenceFields] = useState(false);
//   const handleSourceTypeChange = (e) => {
//     const selectedSourceType = e.target.value;
//     setPst(selectedSourceType);
//     setShowReferenceFields(selectedSourceType === "Reference");
//   };

//   const [intrest, setIntrest] = useState("");
//   const [cpName, setCPName] = useState("");
//   const [cpId, setCPId] = useState("");

//   const handleIntrestChange = (e) => {
//     const selectedIntrest = e.target.value;
//     setIntrest(selectedIntrest);
//     // Reset the CP Name and CP ID fields when selecting "No"
//     if (selectedIntrest === "No") {
//       setCPName("");
//       setCPId("");
//     }
//   };

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open2 = Boolean(anchorEl);

//   const handleClick = (event) => {
//     setAnchorEl(event);
//   };

//   const handleClose2 = () => {
//     setAnchorEl(null);
//   };
//   const [open, setOpen] = useState(false);

//   const handleClose = () => {
//     setOpen(false);
//     setValidated(false);
//   };
//   const [Validated, setValidated] = useState(false);

//   const [open1, setOpen1] = useState(false);

//   const formValidation = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       // intrstTab()
//       add_basic();
//     }

//     setValidated(true);
//   };

//   const [Validatedtabi, setValidatedtabi] = useState(false);

//   const formValidationtabi = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       intrstTab();
//     }

//     setValidatedtabi(true);
//   };

//   const [Validatedtabin, setValidatedtabin] = useState(false);

//   const formValidationtabin = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       notintrstTab();
//     }

//     setValidatedtabin(true);
//   };
//   const [Validatedtabcb, setValidatedtabcb] = useState(false);

//   const formValidationtabcb = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       callbackTab();
//     }

//     setValidatedtabcb(true);
//   };
//   const [pname, setPname] = useState("");
//   const [Pemail, setPemail] = useState("");
//   const [pmobile, setPmobile] = useState("");
//   const [pgender, setPgender] = useState("");
//   const [ptym, setPtym] = useState("");
//   const [pst, setPst] = useState("");

//   const viewData = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/view-lead", {
//         id,
//       })
//       .then((res) => {
//         console.log(res.data);
//         setPname(res.data.name);
//         setPemail(res.data.email);
//         setPmobile(res.data.mobile);
//         setPgender(res.data.gender);
//         setPst(res.data.sourceType);
//         setPtym(res.data.pretime);
//       });
//   };

//   const PrName = sessionStorage.getItem("ProjectName");
//   //   https://buildfastapi.emedha.in/api/v1/lead-managements



 

//   const add_basic = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/lead-managements", {
//         name: pname,
//         email: Pemail,
//         mobile: pmobile,
//         gender: pgender,
//         pretime: ptym,
//         sourceType: pst,
//         location,
//         refName: rname,
//         refMobile: rmobile,
//         channelPartner: intrest,
//         cpName,
//         cpId,
//         commentBox: comment,
//         ProjectName: PrName,
        
//       })
//       .then((res) => {
//         if (res.data.status === 200) {
//           setOpen(false);
//           getlm();
//           Swal.fire({
//             icon: "success",
//             title: "Success !",
//             text: "Data is added successfully !",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   const editlead = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/lead-edit", {
//         name: pname,
//         email: Pemail,
//         mobile: pmobile,
//         gender: pgender,
//         pretime: ptym,
//         sourceType: pst,
//         id,
//       })
//       .then((res) => {
//         if (res.data.status === 200) {
//           setOpen(false);
//           getlm();
//         }
//       });
//   };

//   const add_primary = () => {
//     setOpen(false);
//     Swal.fire({
//       icon: "success",
//       title: "Success !",
//       text: "Data added successfully !",
//       confirmButtonColor: "rgb(126, 110, 228)",
//       cancelButtonColor: "#d33",
//       showCancelButton: false,
//       confirmButtonText: "Ok",
//     })``;
//   };

//   const editAlert = () => {
//     setOpen1(false);
//     handleClose2();
//     Swal.fire({
//       icon: "warning",
//       title: "Warning !",
//       text: "Are you sure to edit this data",
//       confirmButtonColor: "green",
//       cancelButtonColor: "#d33",
//       showCancelButton: true,
//       confirmButtonText: "Yes",
//     }).then((result) => {
//       if (result.value) {
//         editlead(id);
//       }
//     });
//   };

//   const deleteAlert = (id) => {
//     handleClose2();
//     Swal.fire({
//       icon: "warning",
//       title: "Warning !",
//       text: "Are you sure to delete this data",
//       confirmButtonColor: "rgb(126, 110, 228)",
//       cancelButtonColor: "#d33",
//       showCancelButton: true,
//       confirmButtonText: "Yes",
//     }).then((result) => {
//       if (result.value) {
//         leadDataDelete(id);
//       }
//     });
//   };

//   const leadDataDelete = (id) => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/delete-lead", {
//         id,
//       })
//       .then((res) => {
//         if (res.data.status === 200) {
//           getlm();
//           Swal.fire({
//             icon: "success",
//             title: "Successfull !",
//             text: "Lead is deleted successfully !",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };
  

//   const [data, setData] = useState([]);

//   const getlm = () => {
//     axios.get("https://buildfastapi.emedha.in/api/v1/lead-data").then((res) => {
//       setData(res.data.data);
//       console.log(res.data.data);
//     });
//   };

//   React.useEffect(() => {
//     getlm();
//   }, []);

//   const [isVeiw, setIsVeiw] = useState(false);

//   const leadDataSearch = (text) => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/search-lead", {
//         text,
//       })
//       .then((res) => {
//         setData(res.data);
//       });
//   };

//   const [page, setPage] = useState(5);
//   const [cpage, setCpage] = useState(1);

//   const pagehandle = (e, val) => {
//     setCpage(val);
//   };

//   const indexOflpage = cpage * page;
//   const indexOffpage = indexOflpage - page;
//   const currentpost = data.slice(indexOffpage, indexOflpage);

//   const [value, setValue] = React.useState("1");

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const [openi, setOpeni] = React.useState(false);

//   const handleClickOpeni = () => {
//     setOpeni(true);
//   };

//   const handleClosei = () => {
//     setOpeni(false);
//   };

//   const [openin, setOpenin] = React.useState(false);

//   const handleClickOpenin = () => {
//     setOpenin(true);
//   };

//   const handleClosein = () => {
//     setOpeni(false);
//   };

//   const [openc, setOpenc] = React.useState(false);

//   const handleClickOpenc = () => {
//     setOpenc(true);
//   };

//   const handleClosec = () => {
//     setOpenc(false);
//   };

//   const [idate, setIdate] = useState("");
//   const [icomment, setIcomment] = useState("");

//   const intrstTab = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/intrst-tab", {
//         id,
//         intrestDate: idate,
//         intrestComment: icomment,
//       })
//       .then((res) => {
//         if (res.status === 200) {
//           getlm();
//           handleClosei();

//           setValidatedtabi(false);
//           Swal.fire({
//             icon: "success",
//             title: "Successfull !",
//             text: "Selected customer is interested!",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   const [incb, setIncb] = useState("");

//   const notintrstTab = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/notintrst-tab", {
//         id,
//         notintrstComment: incb,
//       })
//       .then((res) => {
//         if (res.data.statusCode === 200) {
//           getlm();
//           setOpenin(false);

//           setValidatedtabin(false);
//           Swal.fire({
//             icon: "success",
//             title: "Successfull !",
//             text: "Selected customer is Not-interested!",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   //

//   const siteVisitTab = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/sitevisit-tab", {
//         id,
//         Occupation: occupation,
//         budget: budget,
//         aptsize: apartsize,
//         facing: apartfacing,
//         cornor: Corner,
//         ucon: underConstruction,
//         rmdate: readyToMove,
//       })
//       .then((res) => {
//         if (res.data.statusCode === 200) {
//           getlm();
//           setOpensv(false);

//           setValidatedtabin(false);
//           Swal.fire({
//             icon: "success",
//             title: "Successfull !",
//             text: "Selected customer is move Site Visit!",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   const [cbd, setCbd] = useState("");
//   const [cbc, setCbc] = useState("");

//   const callbackTab = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/callback-tab", {
//         id,
//         cbDate: cbd,
//         cbComment: cbc,
//       })
//       .then((res) => {
//         if (res.data.statusCode === 200) {
//           getlm();
//           handleClosec();
//           setValidatedtabcb(false);
//           Swal.fire({
//             icon: "success",
//             title: "Successfull !",
//             text: "Selected customer said to callBack!",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   const convrtintr = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/cnvrttinstrest", { id })
//       .then((res) => {
//         if (res.data.statusCode === 200) {
//           getlm();
//           Swal.fire({
//             icon: "success",
//             title: "Successfull !",
//             text: "Selected customer converted to intrest!",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   const [Validatedsv, setValidatedsv] = useState(false);

//   const [opensv, setOpensv] = useState(false);
//   const handleClickOpensv = () => {
//     setOpensv(true);
//   };

//   const handleClosesv = () => {
//     setOpensv(false);
//   };

//   const formValidationsv = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       siteVisitTab();
//       // add_sitevisit()
//     }

//     setValidatedsv(true);
//   };

//   const [pexecutive, setPExecutive] = useState("");

//   const [occupation, setOccupation] = useState();
//   const [apartsize, setApartSize] = useState();
//   const [apartfacing, setApartFacing] = useState();
//   const [budget, setBudget] = useState();
//   const [Corner, setCorner] = useState();

//   const [flatDetails, setFlatDetails] = useState("");
//   const [underConstruction, setUnderConstruction] = useState("");
//   const [readyToMove, setReadyToMove] = useState("");
//   const [nname, setNname] = useState("");
//   const [nmobile, setNmobile] = useState("");
//   const [nemail, setNemail] = useState("");
//   const [nst, setNst] = useState("");
//   const [leadloc, setLeadloc] = useState("");
//   const [nrefname, setNrefname] = useState("");
//   const [nrefmob, setNrefmob] = useState("");
//   const [ncp, setNcp] = useState("");
//   const [ncpname, setNcpname] = useState("");
//   const [ncpid, setNcpid] = useState("");
//   const [ncb, setNcb] = useState("");

//   const handleFlatDetailsChange = (e) => {
//     const selectedFlatDetails = e.target.value;
//     setFlatDetails(selectedFlatDetails);
//     // Reset the fields when changing the selected flat details
//     setUnderConstruction("");
//     setReadyToMove("");
//   };

//   const [res, setRes] = React.useState([]);

//   const getLeadname = () => {
//     axios.get("https://buildfastapi.emedha.in/api/v1/lead-data").then((res) => {
//       setRes(res.data.data);
//       // console.log("leaddata",res.data.data);
//       // setIsFilter(true)
//     });
//   };

//   React.useEffect(() => {
//     getLeadname();
//     get_units();
//     // customerObjsi()
//     get_unit();
//     get_floor();
//     get_floorf();
//     customerObjsib();
//     customerObj();
//   }, []);

//   const [typicaluzdrop, setTypicaluzdrop] = useState([]);

//   const get_units = () => {
//     axios.get("https://buildfastapi.emedha.in/project/api/v1/typicalunits").then((res) => {
//       setTypicaluzdrop(res.data);
//       console.log(res.data.data);
//     });
//   };

//   // const get_units = () => {
//   //   axios
//   //     .post("https://buildfastapi.emedha.in/project/api/v1/gettypical-unitsize",)
//   //     .then((res) => {
//   //       if (res.data.status === 200) {
//   //         setTypicaluzdrop(res.data.data);
//   //         console.log(res.data.data);
//   //       }
//   //     });
//   // };

//   const [siteId, setSiteId] = useState("");

//   // const add_sitevisit = () => {

//   //     axios.post("http://localhost:3002/add-sitevistfrm", {
//   //       name:nname,mobile:nmobile, email:nemail, location:leadloc, sourceType:nst, reference:nrefname, channelPartner:ncp,executive:pexecutive,  Occupation:occupation, budget:budget, aptsize:apartsize, facing:apartfacing, cornor:Corner, ucon:underConstruction, rmdate:readyToMove,Id:siteId
//   //     }).then((res) => {
//   //         if (res.status === 200) {
//   //             setOpensv(false)
//   //             setValidatedsv(false)
//   //             customerObjsi()
//   //             Swal.fire({
//   //                 icon: 'success',
//   //                 title: "Success !",
//   //                 text: "Data is added successfully !",
//   //                 confirmButtonColor: "rgb(126, 110, 228)",
//   //                 cancelButtonColor: "#d33",
//   //                 showCancelButton: false,
//   //                 confirmButtonText: "Ok"
//   //             }).then((result) => {
//   //                 if (result.value) {
//   //                     return
//   //                 }
//   //             })
//   //         }
//   //     })
//   // }
//   const [salessource, setSalesSource] = useState("");

//   const getdetls = (value) => {
//     axios
//       .post("https://buildfastapi.emedha.in/api/v1/get-lead-details", {
//         value,
//       })
//       .then((res) => {
//         // setNid(res.data.Id)
//         console.log(res.data.Location);
//         setNname(res.data.name);
//         setNmobile(res.data.mobile);
//         setNemail(res.data.email);
//         setNst(res.data.sourceType);
//         setLeadloc(res.data.location);
//         setNrefname(res.data.refName);
//         setNrefmob(res.data.refMobile);
//         setNcp(res.data.channelPartner);
//         setNcpname(res.data.cpName);
//         setNcpid(res.data.cpId);
//         setNcb(res.data.commentBox);
//         setSalesSource(res.data.salesExecutive);
//       });
//   };

//   const [customerRessi, setCustomerResponsesi] = React.useState([]);

//   // const customerObjsi = () => {
//   //     axios.get('https://buildfastapi.emedha.in/api/v1/getsitform').then((res) => {
//   //         setCustomerResponsesi(res.data)
//   //         console.log(customerRessi)
//   //     })
//   // }

//   const [Validatedbf, setValidatedbf] = useState(false);

//   const [openbf, setOpenbf] = useState(false);

//   const handleClickOpenbf = () => {
//     setOpenbf(true);
//   };

//   const handleClosebf = () => {
//     setOpenbf(false);
//   };

//   const formValidationbf = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       add_blockform();
//     }

//     setValidatedbf(true);
//   };

//   const [alternatemob, setAlternateMob] = useState("");
//   const [aadharnumber, setAadharNumber] = useState("");
//   const [pannumber, setPanNumber] = useState("");
//   const [peraddress, setPerAddress] = useState("");
//   const [addresscom, setAddressCom] = useState("");
//   const [paymentsource, setPaymentSource] = useState("");
//   const [chequenumber, setChequeNumber] = useState("");
//   const [transferdetails, setTransferDetails] = useState("");
//   const [amountinwords, setAmountInWords] = useState("");
//   const [Amount, setAmount] = useState("");
//   const [tflatno, setTowardsFlatNumber] = useState("");
//   const [tfloorno, setTowardsFloorNumber] = useState("");
//   const [ttowerno, setTowardsTowerNumber] = useState("");

//   const [signature, setSignature] = useState("");

//   const [sameaspaddress, setSameaspaddress] = useState(false);

//   const handlecheck = () => {
//     if (sameaspaddress) {
//       setAddressCom("");
//     } else {
//       setAddressCom(peraddress);
//     }
//     setSameaspaddress(!sameaspaddress);
//   };

//   // https://buildfastapi.emedha.in/project/api/v1

//   const [unitdata, setUnitdata] = useState([]);
//   const get_unit = () => {
//     axios.post("https://buildfastapi.emedha.in/project/api/v1/unit-show").then((res) => {
//       setUnitdata(res.data);
//       console.log(res.data);
//     });
//   };

//   const [responset, setResponset] = useState([]);

//   const get_floor = () => {
//     axios
//       .post("https://buildfastapi.emedha.in/project/api/v1/towers-getall")
//       .then((res) => {
//         if (res.data.status === 200) {
//           setResponset(res.data.data);
//           console.log(res.data.data);
//         }
//       });
//   };

//   const [responsef, setResponsef] = useState([]);

//   const get_floorf = () => {
//     axios
//       .get("https://buildfastapi.emedha.in/project/api/v1/get-floor")
//       .then((res) => {
//         if (res.data.status === 200) {
//           setResponsef(res.data.data);
//           console.log(responsef)
//         }
//       });
//   };

//   const add_blockform = () => {
//     console.log({
//       name: nname,
//       mobile: nmobile,
//       alternateMobile: alternatemob,
//       email: nemail,
//       permanentAddress: peraddress,
//       addComm: addresscom,
//       aadharNo: aadharnumber,
//       panNo: pannumber,
//       paySource: paymentsource,
//       cheqNo: chequenumber,
//       transferDetail: transferdetails,
//       amtInWords: amountinwords,
//       amt: Amount,
//       tflatNo: tflatno,
//       ttowerNo: ttowerno,
//       salesSOurce: salessource,
//       ProjectName: Pname,
//     });
//     const formdata = new FormData();
//     formdata.append("name", nname);
//     formdata.append("mobile", nmobile);
//     formdata.append("alternateMobile", alternatemob);
//     formdata.append("email", nemail);
//     formdata.append("permanentAddress", peraddress);
//     formdata.append("addComm", addresscom);
//     formdata.append("aadharNo", aadharnumber);
//     formdata.append("panNo", pannumber);
//     formdata.append("paySource", paymentsource);
//     formdata.append("cheqNo", chequenumber);
//     formdata.append("transferDetail", transferdetails);
//     formdata.append("amtInWords", amountinwords);
//     formdata.append("amt", Amount);
//     formdata.append("tflatNo", tflatno);
//     formdata.append("ttowerNo", ttowerno);
//     formdata.append("salesSOurce", salessource);
//     formdata.append("sig", signature);
//     formdata.append("ProjectName", Pname);
//     formdata.append("tfloor",tfloorno)

//     // "https://buildfastapi.emedha.in/create-customer/api/v1/add-blockform",

//     axios
//       .post(
//         "https://buildfastapi.emedha.in/create-customer/api/v1/add-blockform",

//         formdata
//       )
//       .then((res) => {
//         if (res.data.status === 200) {
//           setOpenbf(false);
//           customerObjsib();
//           Swal.fire({
//             icon: "success",
//             title: "Success !",
//             text: "Data is added successfully !",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   const [customerRessib, setCustomerResponsesib] = React.useState([]);

//   const customerObjsib = () => {
//     axios
//       .get("https://buildfastapi.emedha.in/create-customer/api/v1/getblockform")
//       .then((res) => {
//         setCustomerResponsesib(res.data);
//       });
//   };

//   const [Validatedbok, setValidatedbok] = useState(false);

//   const [openbok, setOpenbok] = useState(false);

//   const handleClickOpenbok = () => {
//     setOpenbok(true);
//   };

//   const handleClosebok = () => {
//     setOpenbok(false);
//   };

//   const formValidationbok = (event) => {
//     event.preventDefault();
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       bookcus();
//     }

//     setValidatedbok(true);
//   };

//   const [pnameb, setPNameb] = useState("");
//   const [Pemailb, setPEmailb] = useState("");
//   const [pmobileb, setPMobileb] = useState("");
//   const [alternatemobb, setAlternateMobb] = useState("");
//   const [aadharnumberb, setAadharNumberb] = useState("");
//   const [pannumberb, setPanNumberb] = useState("");
//   const [addressb, setAddressb] = useState("");
//   const [currentaddressb, setCurrentAddressb] = useState("");
//   const [towerb, setTowerb] = useState("");
//   const [floorb, setFloorb] = useState("");
//   const [flatnob, setFlatNob] = useState("");
//   const [facingb, setFacingb] = useState("");
//   const [cornerb, setCornerb] = useState("");
//   const [amountpaidb, setAmountPaidb] = useState("");
//   const [financeTypeb, setFinanceTypeb] = useState("");

//   // https://buildfastapi.emedha.in/create-customer/api/v1/get-blockform-details

//   const getdetlsb = (value) => {
//     axios
//       .post(
//         "https://buildfastapi.emedha.in/create-customer/api/v1/get-blockform-details",
//         {
//           value,
//         }
//       )
//       .then((res) => {
//         setPNameb(res.data.name);
//         setPMobileb(res.data.mobile);
//         setPEmailb(res.data.email);
//         setAlternateMobb(res.data.alternateMobile);
//         setAddressb(res.data.permanentAddress);
//         setCurrentAddressb(res.data.addComm);
//         setAadharNumberb(res.data.aadharNo);
//         setPanNumberb(res.data.panNo);
//         setFlatNob(res.data.tflatNo);
//         setTowerb(res.data.ttowerNo);
//         setFloorb(res.data.tfloor)
//       });
//   };

//   //   https://buildfastapi.emedha.in/create-customer/api/v1/customer

//   // const bookcus = () => {
//   //   axios
//   //     .post("http://localhost:3003/customer", {
//   //       customerName: pnameb,
//   //       mobileNumber: pmobileb,
//   //       email: Pemailb,
//   //       floor: floorb,
//   //       flatNo: flatnob,
//   //       Tower: towerb,
//   //       Cornor: cornerb,
//   //       addres: addressb,
//   //       curAddres: currentaddressb,
//   //       aadharNo: aadharnumberb,
//   //       panNo: pannumberb,
//   //       amtPaid: amountpaidb,
//   //       loan: financeTypeb,
//   //       aadharImg: aadharImg,
//   //       panImg: panImg,
//   //     })
//   //     .then((res) => {
//   //       if (res.data.status === 200) {
//   //         setOpenbok(false);
//   //         customerObj();
//   //         Swal.fire({
//   //           icon: "success",
//   //           title: "Success !",
//   //           text: "Data is added successfully !",
//   //           confirmButtonColor: "rgb(126, 110, 228)",
//   //           cancelButtonColor: "#d33",
//   //           showCancelButton: false,
//   //           confirmButtonText: "Ok",
//   //         }).then((result) => {
//   //           if (result.value) {
//   //             return;
//   //           }
//   //         });
//   //       }
//   //     });
//   // };
//   const bookcus = () => {
//     const formData = new FormData();
//     formData.append("customerName", pnameb);
//     formData.append("mobileNumber", pmobileb);
//     formData.append("email", Pemailb);
//     formData.append("floor", floorb);
//     formData.append("flatNo", flatnob);
//     formData.append("Tower", towerb);
//     formData.append("Cornor", cornerb);
//     formData.append("addres", addressb);
//     formData.append("curAddres", currentaddressb);
//     formData.append("aadharNo", aadharnumberb);
//     formData.append("panNo", pannumberb);
//     formData.append("amtPaid", amountpaidb);
//     formData.append("loan", financeTypeb);
//     formData.append("ProjectName", Pname);
//     if (aadharImg) formData.append("aadharImg", aadharImg);
//     if (panImg) formData.append("panImg", panImg);

//     // https://buildfastapi.emedha.in/create-customer/api/v1/customer

//     axios
//       .post(
//         "https://buildfastapi.emedha.in/create-customer/api/v1/customer",
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       )
//       .then((res) => {
//         if (res.data.status === 200) {
//           setOpenbok(false);
//           customerObj();
//           Swal.fire({
//             icon: "success",
//             title: "Success!",
//             text: "Data is added successfully!",
//             confirmButtonColor: "rgb(126, 110, 228)",
//             cancelButtonColor: "#d33",
//             showCancelButton: false,
//             confirmButtonText: "Ok",
//           }).then((result) => {
//             if (result.value) {
//               return;
//             }
//           });
//         }
//       });
//   };

//   // const bookcus = () => {
//   //     const formData = new FormData();
//   //     formData.append('customerName', pnameb);
//   //     formData.append('mobileNumber', pmobileb);
//   //     formData.append('email', Pemailb);
//   //     formData.append('floor', floorb);
//   //     formData.append('flatNo', flatnob);
//   //     formData.append('Tower', towerb);
//   //     formData.append('Cornor', cornerb);
//   //     formData.append('addres', addressb);
//   //     formData.append('curAddres', currentaddressb);
//   //     formData.append('aadharNo', aadharnumberb);
//   //     formData.append('panNo', pannumberb);
//   //     formData.append('amtPaid', amountpaidb);
//   //     formData.append('loan', financeTypeb);
//   //     formData.append('aadharImg', aadharImg); // Assuming you have the Aadhar image in state
//   //     formData.append('panImg', panImg); // Assuming you have the PAN image in state

//   //     axios
//   //       .post("http://localhost:3003/customer", formData, {
//   //         headers: {
//   //           'Content-Type': 'multipart/form-data'
//   //         }
//   //       })
//   //       .then((res) => {
//   //         if (res.data.status === 200) {
//   //           setOpenbok(false);
//   //           customerObj();
//   //           Swal.fire({
//   //             icon: "success",
//   //             title: "Success !",
//   //             text: "Data is added successfully !",
//   //             confirmButtonColor: "rgb(126, 110, 228)",
//   //             cancelButtonColor: "#d33",
//   //             showCancelButton: false,
//   //             confirmButtonText: "Ok",
//   //           }).then((result) => {
//   //             if (result.value) {
//   //               return;
//   //             }
//   //           });
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error('Error adding customer:', error);
//   //         // Handle error, e.g., show an error message
//   //       });
//   //   };

//   const [customerRes, setCustomerResponse] = React.useState([]);

//   const customerObj = () => {
//     axios
//       .get("https://buildfastapi.emedha.in/create-customer/api/v1/customers")
//       .then((res) => {
//         setCustomerResponse(res.data);
//       });
//   };

//   //   const PrName=  sessionStorage.getItem('ProjectName')
//   //   console.log(PrName)
//   const Pname = sessionStorage.getItem("ProjectName");
//   console.log(Pname);

//   const [aadharImg, setAadharImg] = useState(null);
//   const [panImg, setPanImg] = useState(null);

//   // const [uploadAadhar, setUploadAadhar] = useState();
//   // const [uploadPan, setUploadPan] = useState();

//   const handleAadharImgChange = (e) => {
//     setAadharImg(e.target.files[0]); // Update state with selected Aadhar image file
//   };

//   const handlePanImgChange = (e) => {
//     setPanImg(e.target.files[0]); // Update state with selected PAN image file
//   };

//   // const storedName = sessionStorage.getItem("itemName");

//   // const handleRowClick = (itemName) => {
//   //   let clickedNames = JSON.parse(sessionStorage.getItem("clickedNames")) || [];
//   //   console.log('Initial clicked names:', clickedNames); // Debug log

//   //   if (!clickedNames.includes(itemName)) {
//   //     clickedNames.push(itemName);
//   //     sessionStorage.setItem("clickedNames", JSON.stringify(clickedNames));
//   //     console.log('Updated clicked names:', clickedNames); // Debug log
//   //   }
//   // };

//   // const clickedNames = JSON.parse(sessionStorage.getItem("clickedNames")) || [];

//   const handleRowClick = (itemName) => {
//     sessionStorage.setItem("clickedName", itemName);
//     console.log("Stored clicked name:", itemName); // Debug log
//   };

//   const clickedName = sessionStorage.getItem("clickedName");
//   console.log("Retrieved clicked name:", clickedName); // Debug log

//   const filteredItems = clickedName
//     ? res.filter((item) => item.name === clickedName)
//     : [];
//   console.log(filteredItems);

//   const handleBookRowClick = (itemName) => {
//     sessionStorage.setItem("clickedBookName", itemName);
//     console.log("Stored clicked name:", itemName); // Debug log
//   };

//   const clickedBookName = sessionStorage.getItem("clickedBookName");
//   console.log("Retrieved clicked name:", clickedName); // Debug log

//   const filteredBookItems = clickedBookName
//     ? res.filter((item) => item.name === clickedBookName)
//     : [];

//   console.log(sessionStorage.getItem("project_name"));

//   // const PrName = sessionStorage.getItem("ProjectName");
//   // console.log(PrName);

//   const [projectName, setProjectName] = useState("");


//   // const [typicaluzdrop, setTypicaluzdrop] = useState([]);


//   // const get_units=()=>{
//   //   axios.get('http://localhost:3003/typicalunits')
//   //   .then((res)=>{
     
//   //       setTypicaluzdrop(res.data);
//   //       console.log(res.data.data);
      
//   //   })
//   // }


//   const handlesub = () => {
//     axios.post('https://buildfastapi.emedha.in/api/v1/updateSalesExecutive', { salesExecutive: salesexct, rowsToUpdate }).then((res) => {
//       handleClose()
//       // setviewData(id)
//       setOpen(false)
//       getlm()
//       window.location.reload()
//       Swal.fire({
//         icon: 'success',
//         title: "Successfull !",
//         text: " Data added successfully !",
//         confirmButtonColor: "rgb(126, 110, 228)",
//         cancelButtonColor: "#d33",
//         showCancelButton: false,
//         confirmButtonText: "Ok"
//       }).then((result) => {
//         if (result.value) {
//           return
//         }
//       })

//     })
//   }


//   return (
//     <>
//       <div>
//         {!isVeiw && (
//           <div className="leadManagementTable">
//             <Typography
//               style={{
//                 color: "rgba(41, 16, 105, 1)",
//                 marginBottom: 6,
//                 marginLeft: 15.5,
//                 fontSize: 18,
//                 color: "gray",
//                 fontWeight: "500",
//                 textAlign: "center",
//               }}
//             >
//               Primary Details
//             </Typography>

//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 marginBottom: 5,
//               }}
//             >
//               <Button
//                 onClick={handleClickOpenbok}
//                 style={{
//                   width: "9rem",
//                   height: "2.3rem",
//                   backgroundColor: "rgb(126, 110, 228)",
//                   color: "white",
//                   marginRight: 10,
//                 }}
//               >
//                 Book
//               </Button>
//               {/* <Button onClick={handleClickOpensv} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight:10 }}>Site Visit</Button> */}
//               <Button
//                 onClick={() => setOpen(true)}
//                 style={{
//                   width: "9rem",
//                   height: "2.3rem",
//                   backgroundColor: "rgb(126, 110, 228)",
//                   color: "white",
//                   marginRight: 10,
//                 }}
//               >
//                 + Add New Lead
//               </Button>
//             </div>
//             <Paper elevation={0}>
//               {/* <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} > */}

//               <Box sx={{ width: "100%", typography: "body1" }}>
//                 <TabContext value={value}>
//                   <Box
//                     sx={{
//                       borderBottom: 1,
//                       borderColor: "divider",
//                       color: "rgb(86, 63, 146)",
//                     }}
//                   >
//                     <TabList
//                       onChange={handleChange}
//                       aria-label="lab API tabs example"
//                       indicatorColor="secondary"
//                     >
//                       <Tab
//                         label=" Lead"
//                         value="1"
//                         style={{ color: "rgb(86, 63, 146)" }}
//                       />
//                       <Tab
//                         label="Interested"
//                         value="2"
//                         style={{ color: "rgb(86, 63, 146)" }}
//                       />
//                       <Tab
//                         label=" Call Back"
//                         value="3"
//                         style={{ color: "rgb(86, 63, 146)" }}
//                       />
//                       <Tab
//                         label="Not Interested"
//                         value="4"
//                         style={{ color: "rgb(86, 63, 146)" }}
//                       />
//                       <Tab
//                         label="Site Visit"
//                         value="5"
//                         style={{ color: "rgb(86, 63, 146)" }}
//                       />
//                       <Tab
//                         label="Blocked"
//                         value="6"
//                         style={{ color: "rgb(86, 63, 146)" }}
//                       />
//                       <Tab
//                         label="Booked"
//                         value="7"
//                         style={{ color: "rgb(86, 63, 146)" }}
//                       />
//                     </TabList>
//                   </Box>
//                   <TabPanel value="1">
//                     <div
//                       style={{
//                         height: "0.1rem",
//                         borderBottom: "0.2px solid lightgray",
//                       }}
//                     ></div>
//                     <div
//                       className="card mt-2"
//                       style={{
//                         border: "none",
//                         paddingLeft: 15,
//                         paddingRight: 15,
//                         flexDirection: "row",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                       }}
//                     >
//                       <div>
//                         <input
//                           onChange={(e) => leadDataSearch(e.target.value)}
//                           style={{
//                             height: "2.3rem",
//                             color: "gray",
//                             borderRadius: "0.3rem",
//                             outline: "none",
//                             width: "26rem",
//                             border: "0.7px solid lightgray",
//                           }}
//                           placeholder="Search..."
//                         />
//                       </div>
//                       <div>
//                         <Button onClick={() => setOpen(true)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Add New Lead</Button>
//                         <div
//                           style={{
//                             width: "9rem",
//                             height: "2.3rem",
//                             fontWeight: "bold",
//                           }}
//                         >
//                           Total Leads :{" "}
//                           {
//                             data.filter(
//                               (item) =>
//                                 item.intrestTab !== 1 &&
//                                 item.notIntrstTab !== 1 &&
//                                 item.callBackTab !== 1 &&
//                                 item.sitevistTab !== 1
//                             ).length
//                           }
//                         </div>
//                       </div>
//                     </div>
//                     <Table style={{ position: "relative", top: "1.3rem" }}>
//                       <TableHead sx={{ borderTop: "1px solid lightgray" }}>
//                         <TableRow>
//                           {/* <TableCell
//                                             align={"start"}
//                                             style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                         >
//                                             Lead
//                                         </TableCell> */}
//                           <TableCell
//                             align={"start"}
//                             style={{
//                               minWidth: 0,
//                               fontSize: 14,
//                               fontWeight: "600",
//                             }}
//                           >
//                             Name
//                           </TableCell>
//                           <TableCell
//                             align={"start"}
//                             style={{
//                               minWidth: 0,
//                               fontSize: 14,
//                               fontWeight: "600",
//                             }}
//                           >
//                             Email
//                           </TableCell>
//                           <TableCell
//                             align={"start"}
//                             style={{
//                               minWidth: 0,
//                               fontSize: 14,
//                               fontWeight: "600",
//                             }}
//                           >
//                             Mobile
//                           </TableCell>
//                           <TableCell
//                             align={"start"}
//                             style={{
//                               minWidth: 0,
//                               fontSize: 14,
//                               fontWeight: "600",
//                             }}
//                           >
//                             Call
//                           </TableCell>
//                           {/* <TableCell

//                                             align={"start"}
//                                             style={{ top: 57, fontWeight: '600' }}
//                                         >
//                                             Whatsapp Mobile
//                                         </TableCell> */}
//                           <TableCell
//                             align={"left"}
//                             style={{ top: 57, fontWeight: "600" }}
//                           >
//                             Gender
//                           </TableCell>
//                           <TableCell
//                             align={"left"}
//                             style={{ top: 57, fontWeight: "600" }}
//                           >
//                             Timings
//                           </TableCell>

//                           <TableCell
//                             align={"left"}
//                             style={{ top: 21, fontWeight: "600", width: 21 }}
//                           >
//                             Action
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {data
//                           .filter(
//                             (item) =>
//                               item.intrestTab !== 1 &&
//                               item.notIntrstTab !== 1 &&
//                               item.callBackTab !== 1 &&
//                               item.sitevistTab !== 1 &&
//                               item.ProjectName === PrName
//                           )
//                           .map((item) => (
//                             <TableRow
//                               hover
//                               role="checkbox"
//                               tabIndex={-1}
//                               key={item.Id}
//                             >
//                               {/* <TableCell align={"start"}
//                                                     style={{ top: 57 }}>
//                                                     <div style={{ border: `3px solid rgb(126, 110, 228)`, padding: '0.1rem', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 35, width: 37, borderRadius: '50%' }}>
//                                                         <FaUserCircle style={{ color: 'rgb(126, 110, 228)', fontSize: 24 }} />
//                                                     </div>
//                                                 </TableCell> */}
//                               <TableCell
//                                 align={"start"}
//                                 style={{ top: 57, fontSize: 12 }}
//                               >
//                                 {item.name}
//                                 {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
//                               </TableCell>
//                               <TableCell
//                                 align={"start"}
//                                 style={{ top: 57, fontSize: 12 }}
//                               >
//                                 {item.email}
//                                 {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
//                               </TableCell>
//                               <TableCell
//                                 align={"start"}
//                                 style={{ top: 57, fontSize: 12 }}
//                               >
//                                 {item.mobile}
//                                 {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
//                               </TableCell>
//                               <TableCell
//                                 align={"start"}
//                                 style={{ top: 57, fontSize: 12 }}
//                               >
//                                 <a
//                                   href={`tel:${item.mobile}`}
//                                   style={{
//                                     textAlign: "center",
//                                     marginTop: "20px",
//                                   }}
//                                 >
//                                   <FaPhone />
//                                 </a>
//                                 {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
//                               </TableCell>

//                               <TableCell
//                                 align={"start"}
//                                 style={{ top: 57, fontSize: 12 }}
//                               >
//                                 {item.gender}
//                               </TableCell>
//                               <TableCell
//                                 align={"start"}
//                                 style={{ top: 57, fontSize: 12, width: 100 }}
//                               >
//                                 {item.pretime}
//                               </TableCell>

//                               <TableCell
//                                 align={"start"}
//                                 style={{
//                                   top: 57,
//                                   fontSize: 12,
//                                   color: "rgb(126, 110, 228) ",
//                                 }}
//                               >
//                                 <div
//                                   style={{
//                                     display: "flex",
//                                     justifyContent: "space-between",
//                                   }}
//                                 >
//                                   <div style={{ cursor: "pointer" }}>
//                                     <Badge
//                                       bg="success"
//                                       onClick={() => {
//                                         setId(item.Id);
//                                         handleClickOpeni();
//                                       }}
//                                     >
//                                       Interested
//                                     </Badge>
//                                   </div>
//                                   <div
//                                     style={{
//                                       marginLeft: 10,
//                                       cursor: "pointer",
//                                     }}
//                                   >
//                                     <Badge
//                                       bg="secondary"
//                                       onClick={() => {
//                                         setId(item.Id);
//                                         handleClickOpenc();
//                                       }}
//                                     >
//                                       Callback
//                                     </Badge>
//                                   </div>
//                                   <div
//                                     style={{
//                                       marginLeft: 10,
//                                       cursor: "pointer",
//                                     }}
//                                   >
//                                     <Badge
//                                       bg="danger"
//                                       onClick={() => {
//                                         handleClickOpenin();
//                                         setId(item.Id);
//                                       }}
//                                     >
//                                       Not Interested
//                                     </Badge>
//                                   </div>
//                                   {/* {
//                                                             (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (

//                                                                 <Button onClick={() => deleteAlert(item.Id)} style={{ backgroundColor: 'red', color: 'white', fontSize: 11, height: '1.7rem', position: 'relative', top: '0.4rem', left: '-0.2rem' }} >Delete</Button>
//                                                             )
//                                                         } */}
//                                   {/* <div>
//                                                                     <IconButton
//                                                                         id="fade-button"
//                                                                         aria-controls={open2 ? 'fade-menu' : undefined}
//                                                                         aria-haspopup="true"
//                                                                         aria-expanded={open2 ? 'true' : undefined}
//                                                                         onClick={(e) => {
//                                                                             handleClick(e.currentTarget)
//                                                                             setId(item.Id)
//                                                                         }}
//                                                                     >
//                                                                         <MoreVertIcon />
//                                                                     </IconButton>
//                                                                     <Menu
//                                                                         id="fade-menu"
//                                                                         MenuListProps={{
//                                                                             'aria-labelledby': 'fade-button',
//                                                                         }}
//                                                                         anchorEl={anchorEl}
//                                                                         open={open2}
//                                                                         onClose={handleClose2}
//                                                                         TransitionComponent={Fade}
//                                                                     >
//                                                                         {
//                                                                             (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                 <MenuItem style={{ color: 'gray' }} onClick={() => {

//                                                                                     handleClose2()
//                                                                                     viewData(id)
//                                                                                     setOpen1(true)
//                                                                                 }}><FaEdit style={{ marginRight: 4 }} /> Edit</MenuItem>
//                                                                             )
//                                                                         }
//                                                                         {
//                                                                             (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                 <MenuItem style={{ color: 'gray' }} onClick={() => {
//                                                                                     viewData(id)
//                                                                                     handleClose2()
//                                                                                     setIsVeiw(true)
//                                                                                 }}><FaEye style={{ marginRight: 4 }} /> View</MenuItem>
//                                                                             )

//                                                                         }


//                                                                         {
//                                                                             (sessionStorage.getItem('Delete') === '1' || sessionStorage.getItem('superAdmin')) && (

//                                                                                 <MenuItem onClick={() => deleteAlert(id)} style={{ color: 'gray' }} ><FaTrash style={{ marginRight: 4 }} /> Delete</MenuItem>
//                                                                             )
//                                                                         }
//                                                                 <MenuItem onClick={handleClickOpeni} style={{ color: 'gray' }} > Interested</MenuItem>
//                                                                 <MenuItem onClick={handleClickOpenc} style={{ color: 'gray' }} > Call Back</MenuItem>
//                                                                 <MenuItem onClick={handleClickOpenin} style={{ color: 'gray' }} > Not Interested</MenuItem>


//                                                                     </Menu>
//                                                                 </div> */}
//                                 </div>
//                               </TableCell>
//                             </TableRow>
//                           ))}
//                       </TableBody>
//                     </Table>

//                     {data.length === 0 && (
//                       <Box
//                         className="card"
//                         style={{
//                           border: "none",
//                           height: "58vh",
//                           width: "100%",
//                           boxShadow: "none",
//                           justifyContent: "center",
//                           alignItems: "center",
//                         }}
//                       >
//                         <img
//                           src="https://cdn-icons-png.flaticon.com/128/2291/2291944.png"
//                           width="145px"
//                         />
//                         <Typography>0 customers</Typography>
//                       </Box>
//                     )}

//                     {/* </TableContainer> */}
//                     {/* </Paper> */}
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "flex-end",
//                         height: 56,
//                         marginTop: 16,
//                       }}
//                     >
//                       <Pagination
//                         count={Math.ceil(data.length / page)}
//                         page={cpage}
//                         onChange={pagehandle}
//                         color="secondary"
//                         sx={{ "& .Mui-selected": { backgroundColor: "blue" } }}
//                       />
//                     </div>
//                   </TabPanel>
//                   {/* </TabPanel> */}
//                   <TabPanel value="2">
//                     <div style={{ width: "58rem" }}>
//                       <Typography
//                         style={{
//                           color: "rgba(41, 16, 105, 1)",
//                           marginBottom: 3,
//                           marginLeft: 15.5,
//                           fontSize: 18,
//                           color: "gray",
//                           fontWeight: "500",
//                           textAlign: "center",
//                         }}
//                       >
//                         Instrested Details
//                       </Typography>
//                       <div style={{ fontWeight: "bold" }}>
//                         Total Interested Leads :{" "}
//                         {data.filter((item) => item.intrestTab === 1).length}
//                       </div>

//                       <Table style={{ position: "relative", top: "1.3rem" }}>
//                         <TableHead sx={{ borderTop: "1px solid lightgray" }}>
//                           <TableRow>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Name
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Email
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Mobile
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Call
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Comment
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Date of site visit
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600", width: 21 }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {data
//                             .filter(
//                               (item) =>
//                                 item.intrestTab === 1 &&
//                                 item.sitevisTab !== 1 &&
//                                 item.ProjectName === PrName
//                             )
//                             .sort(
//                               (a, b) =>
//                                 new Date(b.intrestDate) -
//                                 new Date(a.intrestDate)
//                             )
//                             .map((item) => (
//                               <TableRow
//                                 hover
//                                 role="checkbox"
//                                 tabIndex={-1}
//                                 key={item.Id}
//                               >
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.name}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.email}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.mobile}
//                                 </TableCell>

//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   <a
//                                     href={`tel:${item.mobile}`}
//                                     style={{ textAlign: "center" }}
//                                   >
//                                     <FaPhone />
//                                   </a>
//                                   {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.intrestComment}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12, width: 100 }}
//                                 >
//                                   {moment(item.intrestDate).format(
//                                     "MMM DD, YYYY hh:mm A"
//                                   )}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{
//                                     top: 57,
//                                     fontSize: 12,
//                                     color: "rgb(126, 110, 228) ",
//                                   }}
//                                 >
//                                   <div style={{ display: "flex" }}>
//                                     <div
//                                       style={{
//                                         marginRight: 10,
//                                         cursor: "pointer",
//                                       }}
//                                     >
//                                       <Badge
//                                         bg="warning"
//                                         onClick={() => {
//                                           handleClickOpensv();
//                                           setId(item.Id);
//                                           setSiteId(item.Id);
//                                         }}
//                                       >
//                                         Site Visit
//                                       </Badge>
//                                     </div>
//                                     <div
//                                       style={{
//                                         marginRight: 10,
//                                         cursor: "pointer",
//                                       }}
//                                     >
//                                       <Badge
//                                         bg="secondary"
//                                         onClick={() => {
//                                           handleClickOpenc();
//                                           setId(item.Id);
//                                         }}
//                                       >
//                                         Call Back
//                                       </Badge>
//                                     </div>
//                                     <div
//                                       style={{
//                                         marginRight: 10,
//                                         cursor: "pointer",
//                                       }}
//                                     >
//                                       <Badge
//                                         bg="danger"
//                                         onClick={() => {
//                                           handleClickOpenin();
//                                           setId(item.Id);
//                                         }}
//                                       >
//                                         Not Interested
//                                       </Badge>
//                                     </div>
//                                   </div>

//                                   <div style={{ display: "flex" }}>
//                                     {/* <div>
//                                                                                 <IconButton
//                                                                                     id="fade-button"
//                                                                                     aria-controls={open2 ? 'fade-menu' : undefined}
//                                                                                     aria-haspopup="true"
//                                                                                     aria-expanded={open2 ? 'true' : undefined}
//                                                                                     onClick={(e) => {
//                                                                                         handleClick(e.currentTarget)
//                                                                                         setId(item.Id)
//                                                                                     }}
//                                                                                 >
//                                                                                     <MoreVertIcon />
//                                                                                 </IconButton>
//                                                                                 <Menu
//                                                                                     id="fade-menu"
//                                                                                     MenuListProps={{
//                                                                                         'aria-labelledby': 'fade-button',
//                                                                                     }}
//                                                                                     anchorEl={anchorEl}
//                                                                                     open={open2}
//                                                                                     onClose={handleClose2}
//                                                                                     TransitionComponent={Fade}
//                                                                                 >
//                                                                                     {
//                                                                                         (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                             <MenuItem style={{ color: 'gray' }} onClick={handleClickOpensv}> Site Visit</MenuItem>
//                                                                                         )
//                                                                                     }
                                                                                    
//                                                                                             <MenuItem style={{ color: 'gray' }} onClick={handleClickOpenc}>Call Back</MenuItem>
                                                                                        


                                                                                    

//                                                                                             <MenuItem onClick={handleClickOpenin} style={{ color: 'gray' }} > Not Interested</MenuItem>
                                                                                       

//                                                                                 </Menu>
//                                                                             </div> */}
//                                   </div>
//                                 </TableCell>
//                               </TableRow>
//                             ))}
//                         </TableBody>
//                       </Table>

//                       {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
//                                                 <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
//                                             </div> */}
//                     </div>
//                   </TabPanel>
//                   <TabPanel value="3">
//                     <div style={{ width: "58rem" }}>
//                       <Typography
//                         style={{
//                           color: "rgba(41, 16, 105, 1)",
//                           marginBottom: 3,
//                           marginLeft: 15.5,
//                           fontSize: 18,
//                           color: "gray",
//                           fontWeight: "500",
//                           textAlign: "center",
//                         }}
//                       >
//                         Call Back
//                       </Typography>
//                       <div style={{ fontWeight: "bold" }}>
//                         Total CallBack Leads :{" "}
//                         {data.filter((item) => item.callBackTab === 1).length}
//                       </div>

//                       <Table style={{ position: "relative", top: "1.3rem" }}>
//                         <TableHead sx={{ borderTop: "1px solid lightgray" }}>
//                           <TableRow>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Name
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Email
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Mobile
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Call
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Comment
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Date of Call Back
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600", width: 21 }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {data
//                             .filter(
//                               (item) =>
//                                 item.callBackTab === 1 &&
//                                 item.ProjectName === PrName
//                             )
//                             .sort(
//                               (a, b) => new Date(b.cbDate) - new Date(a.cbDate)
//                             )
//                             .map((item) => (
//                               <TableRow
//                                 hover
//                                 role="checkbox"
//                                 tabIndex={-1}
//                                 key={item.Id}
//                               >
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.name}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.email}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.mobile}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   <a
//                                     href={`tel:${item.mobile}`}
//                                     style={{
//                                       textAlign: "center",
//                                       marginTop: "20px",
//                                     }}
//                                   >
//                                     <FaPhone />
//                                   </a>
//                                   {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
//                                 </TableCell>

//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.cbComment}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12, width: 100 }}
//                                 >
//                                   {moment(item.cbDate).format(
//                                     "MMM DD, YYYY hh:mm A"
//                                   )}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{
//                                     top: 57,
//                                     fontSize: 12,
//                                     color: "rgb(126, 110, 228) ",
//                                   }}
//                                 >
//                                   <div
//                                     style={{
//                                       display: "flex",
//                                       justifyContent: "space-around",
//                                     }}
//                                   >
//                                     <div
//                                       style={{
//                                         marginRight: 10,
//                                         cursor: "pointer",
//                                       }}
//                                     >
//                                       <Badge
//                                         bg="secondary"
//                                         onClick={() => {
//                                           setId(item.Id);
//                                           handleClickOpenc();
//                                         }}
//                                       >
//                                         Call Back{" "}
//                                       </Badge>
//                                     </div>
//                                     <div
//                                       style={{
//                                         marginRight: 10,
//                                         cursor: "pointer",
//                                       }}
//                                     >
//                                       <Badge
//                                         bg="danger"
//                                         onClick={() => {
//                                           setId(item.Id);
//                                           handleClickOpenin();
//                                         }}
//                                       >
//                                         Not Interested
//                                       </Badge>
//                                     </div>
//                                     {/* <div>
//                                                                                 <IconButton
//                                                                                     id="fade-button"
//                                                                                     aria-controls={open2 ? 'fade-menu' : undefined}
//                                                                                     aria-haspopup="true"
//                                                                                     aria-expanded={open2 ? 'true' : undefined}
//                                                                                     onClick={(e) => {
//                                                                                         handleClick(e.currentTarget)
//                                                                                         setId(item.Id)
//                                                                                     }}
//                                                                                 >
//                                                                                     <MoreVertIcon />
//                                                                                 </IconButton>
//                                                                                 <Menu
//                                                                                     id="fade-menu"
//                                                                                     MenuListProps={{
//                                                                                         'aria-labelledby': 'fade-button',
//                                                                                     }}
//                                                                                     anchorEl={anchorEl}
//                                                                                     open={open2}
//                                                                                     onClose={handleClose2}
//                                                                                     TransitionComponent={Fade}
//                                                                                 >
//                                                                                     {
//                                                                                         (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                             <MenuItem style={{ color: 'gray' }} onClick={() => {

//                                                                                                 // handleClose2()
//                                                                                                 // viewData(id)
//                                                                                                 // setOpen1(true)
//                                                                                             }}> CallBack Again</MenuItem>
//                                                                                         )
//                                                                                     }

// {
//                                                                                         (sessionStorage.getItem('Edit') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                             <MenuItem style={{ color: 'gray' }} onClick={handleClickOpenin}>Not Interested</MenuItem>
//                                                                                         )
//                                                                                     }
                                                                                    
//                                                                                 </Menu>
//                                                                             </div> */}
//                                   </div>
//                                 </TableCell>
//                               </TableRow>
//                             ))}
//                         </TableBody>
//                       </Table>

//                       {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
//                                                 <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
//                                             </div> */}
//                     </div>
//                   </TabPanel>
//                   <TabPanel value="4">
//                     <div style={{ width: "58rem" }}>
//                       <Typography
//                         style={{
//                           color: "rgba(41, 16, 105, 1)",
//                           marginBottom: 3,
//                           marginLeft: 15.5,
//                           fontSize: 18,
//                           color: "gray",
//                           fontWeight: "500",
//                           textAlign: "center",
//                         }}
//                       >
//                         Not Instrested Details
//                       </Typography>
//                       <div style={{ fontWeight: "bold" }}>
//                         Total Not Interested Leads :{" "}
//                         {data.filter((item) => item.notIntrstTab === 1).length}
//                       </div>

//                       <Table style={{ position: "relative", top: "1.3rem" }}>
//                         <TableHead sx={{ borderTop: "1px solid lightgray" }}>
//                           <TableRow>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Name
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Email
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Mobile
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Gender
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Comment
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600", width: 21 }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {data
//                             .filter(
//                               (item) =>
//                                 item.notIntrstTab === 1 &&
//                                 item.ProjectName === PrName
//                             )
//                             .map((item) => (
//                               <TableRow
//                                 hover
//                                 role="checkbox"
//                                 tabIndex={-1}
//                                 key={item.Id}
//                               >
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.name}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.email}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.mobile}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.gender}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12, width: 100 }}
//                                 >
//                                   {item.notintrstComment}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{
//                                     top: 57,
//                                     fontSize: 12,
//                                     color: "rgb(126, 110, 228) ",
//                                   }}
//                                 >
//                                   <Badge
//                                     bg="success"
//                                     style={{ cursor: "pointer" }}
//                                     onClick={() => {
//                                       setId(item.Id);
//                                       convrtintr();
//                                     }}
//                                   >
//                                     Move to Intrest
//                                   </Badge>

//                                   <div style={{ display: "flex" }}>
//                                     {/* <div>
//                                                                                 <IconButton
//                                                                                     id="fade-button"
//                                                                                     aria-controls={open2 ? 'fade-menu' : undefined}
//                                                                                     aria-haspopup="true"
//                                                                                     aria-expanded={open2 ? 'true' : undefined}
//                                                                                     onClick={(e) => {
//                                                                                         handleClick(e.currentTarget)
//                                                                                         setId(item.Id)
//                                                                                     }}
//                                                                                 >
//                                                                                     <MoreVertIcon />
//                                                                                 </IconButton>
//                                                                                 <Menu
//                                                                                     id="fade-menu"
//                                                                                     MenuListProps={{
//                                                                                         'aria-labelledby': 'fade-button',
//                                                                                     }}
//                                                                                     anchorEl={anchorEl}
//                                                                                     open={open2}
//                                                                                     onClose={handleClose2}
//                                                                                     TransitionComponent={Fade}
//                                                                                 >
                                                                                   
//                                                                                     {
//                                                                                         (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                             <MenuItem style={{ color: 'gray' }} onClick={convrtintr}>Move To Intrest</MenuItem>
//                                                                                         )

//                                                                                     }


                                                                                  

//                                                                                 </Menu>
//                                                                             </div> */}
//                                   </div>
//                                 </TableCell>
//                               </TableRow>
//                             ))}
//                         </TableBody>
//                       </Table>

//                       {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
//                                                 <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
//                                             </div> */}
//                     </div>
//                   </TabPanel>
//                   <TabPanel value="5">
//                     <div style={{ width: "58rem" }}>
//                       <Typography
//                         style={{
//                           color: "rgba(41, 16, 105, 1)",
//                           marginBottom: 3,
//                           marginLeft: 15.5,
//                           fontSize: 18,
//                           color: "gray",
//                           fontWeight: "500",
//                           textAlign: "center",
//                         }}
//                       >
//                         Site Visit
//                       </Typography>
//                       <div style={{ fontWeight: "bold" }}>
//                         Total Site Visit :{" "}
//                         {
//                           data.filter(
//                             (item) =>
//                               item.sitevistTab === 1 &&
//                               item.notIntrstTab !== 1 &&
//                               item.callBackTab !== 1
//                           ).length
//                         }
//                       </div>

//                       <Table style={{ position: "relative", top: "1.3rem" }}>
//                         <TableHead sx={{ borderTop: "1px solid lightgray" }}>
//                           <TableRow>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Name
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Mobile
//                             </TableCell>
//                             <TableCell
//                               align={"start"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Call
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Occupation
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Budget
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600", width: 18 }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {data
//                             .filter(
//                               (item) =>
//                                 item.callBackTab !== 1 &&
//                                 item.notIntrstTab !== 1 &&
//                                 item.sitevistTab === 1 &&
//                                 item.ProjectName === PrName
                                
//                             )
//                             .map((item) => (
//                               <TableRow
//                                 hover
//                                 role="checkbox"
//                                 tabIndex={-1}
//                                 key={item.Id}
//                                 onClick={() => {
//                                   console.log("Row clicked:", item.name); // Debug log
//                                 }}
//                               >
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.name}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   <button
//                                     style={{
//                                       fontSize: 10.7,
//                                       border: "2px solid rgb(126, 110, 228)",
//                                       backgroundColor: "white",
//                                       borderRadius: "1.2rem",
//                                       cursor: "auto",
//                                     }}
//                                   >
//                                     <FaMobileAlt
//                                       style={{
//                                         marginRight: 3,
//                                         color: "rgb(126, 110, 228)",
//                                         marginTop: "-0.16rem",
//                                         fontSize: 9,
//                                       }}
//                                     />
//                                     {item.mobile}
//                                   </button>
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   <a
//                                     href={`tel:${item.mobile}`}
//                                     style={{
//                                       textAlign: "left",
//                                       marginTop: "20px",
//                                     }}
//                                   >
//                                     <FaPhone />
//                                   </a>
//                                   {/* <FaUserAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }}/> */}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12 }}
//                                 >
//                                   {item.Occupation}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{ top: 57, fontSize: 12, width: 100 }}
//                                 >
//                                   {item.budget}
//                                 </TableCell>
//                                 <TableCell
//                                   align={"start"}
//                                   style={{
//                                     top: 57,
//                                     fontSize: 12,
//                                     color: "rgb(126, 110, 228) ",
//                                   }}
//                                 >
//                                   <div>
//                                     <Popup
//                                       trigger={
//                                         <button>
//                                           <BsThreeDotsVertical />{" "}
//                                         </button>
//                                       }
//                                       position="bottom center"
//                                     >
//                                       <div className="d-flex">
//                                         <div
//                                           style={{
//                                             marginRight: 10,
//                                             cursor: "pointer",
//                                           }}
//                                         >
//                                           <Badge
//                                             bg="secondary"
//                                             onClick={() => {
//                                               setId(item.Id);
//                                               handleClickOpenc();
//                                             }}
//                                           >
//                                             Call Back
//                                           </Badge>
//                                         </div>
//                                         <div
//                                           style={{
//                                             marginRight: 10,
//                                             cursor: "pointer",
//                                           }}
//                                         >
//                                           <Badge
//                                             bg="danger"
//                                             onClick={() => {
//                                               handleClickOpenin();
//                                               setId(item.Id);
//                                             }}
//                                           >
//                                             Not Interested
//                                           </Badge>
//                                           <div
//                                             style={{
//                                               marginRight: 10,
//                                               cursor: "pointer",
//                                             }}
//                                           >
//                                             {" "}
//                                           </div>

//                                           <Badge
//                                             bg="dark"
//                                             style={{ cursor: "pointer" }}
//                                             onClick={() => {
//                                               setId(item.Id);
//                                               handleClickOpenbf();
//                                               handleRowClick(item.name);
//                                             }}
//                                           >
//                                             Block
//                                           </Badge>
//                                         </div>
//                                       </div>
//                                     </Popup>
//                                   </div>
//                                   <div style={{ display: "flex" }}>
//                                     {/* <Badge bg="dark" style={{cursor:'pointer'}} onClick={()=>{
//                                                                      setId(item.Id)
//                                                                     handleClickOpenbf()
//                                                                     }} >Block</Badge> */}
//                                     {/* <div>
//                                                                                 <IconButton
//                                                                                     id="fade-button"
//                                                                                     aria-controls={open2 ? 'fade-menu' : undefined}
//                                                                                     aria-haspopup="true"
//                                                                                     aria-expanded={open2 ? 'true' : undefined}
//                                                                                     onClick={(e) => {
//                                                                                         handleClick(e.currentTarget)
//                                                                                         setId(item.Id)
//                                                                                     }}
//                                                                                 >
//                                                                                     <MoreVertIcon />
//                                                                                 </IconButton>
//                                                                                 <Menu
//                                                                                     id="fade-menu"
//                                                                                     MenuListProps={{
//                                                                                         'aria-labelledby': 'fade-button',
//                                                                                     }}
//                                                                                     anchorEl={anchorEl}
//                                                                                     open={open2}
//                                                                                     onClose={handleClose2}
//                                                                                     TransitionComponent={Fade}
//                                                                                 >
                                                                                   
//                                                                                     {
//                                                                                         (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                             <MenuItem style={{ color: 'gray' }} onClick={handleClickOpenbf}>Block</MenuItem>
//                                                                                         )

//                                                                                     }


                                                                                  

//                                                                                 </Menu>
//                                                                             </div> */}
//                                   </div>
//                                 </TableCell>
//                               </TableRow>
//                             ))}
//                         </TableBody>
//                       </Table>

//                       {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
//                                                 <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
//                                             </div> */}
//                     </div>
//                   </TabPanel>
//                   <TabPanel value="6">
//                     <div style={{ width: "58rem" }}>
//                       <Typography
//                         style={{
//                           color: "rgba(41, 16, 105, 1)",
//                           marginBottom: 3,
//                           marginLeft: 15.5,
//                           fontSize: 18,
//                           color: "gray",
//                           fontWeight: "500",
//                           textAlign: "center",
//                         }}
//                       >
//                         Blocked
//                       </Typography>
//                       <div style={{ fontWeight: "bold" }}>
//                         Total Blocked: {customerRessib.length}
//                       </div>

//                       <Table style={{ position: "relative", top: "1.3rem" }}>
//                         <TableHead sx={{ borderTop: "1px solid lightgray" }}>
//                           <TableRow>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Name
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Mobile
//                             </TableCell>

//                             <TableCell
//                               align={"left"}

//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Aadhar
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Address
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600", width: 18 }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {customerRessib &&
//                             customerRessib
//                               .filter(
//                                 (item) =>
//                                   item.status === "Approved" &&
//                                   item.ProjectName === PrName
//                               )
//                               .map((item) => (
//                                 <TableRow
//                                   hover
//                                   role="checkbox"
//                                   tabIndex={-1}
//                                   key={item.Id}
//                                 >
//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     {item.name}
//                                   </TableCell>
//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     <button
//                                       style={{
//                                         fontSize: 10.7,
//                                         border: "2px solid rgb(126, 110, 228)",
//                                         backgroundColor: "white",
//                                         borderRadius: "1.2rem",
//                                         cursor: "auto",
//                                       }}
//                                     >
//                                       <FaMobileAlt
//                                         style={{
//                                           marginRight: 3,
//                                           color: "rgb(126, 110, 228)",
//                                           marginTop: "-0.16rem",
//                                           fontSize: 9,
//                                         }}
//                                       />
//                                       {item.mobile}
//                                     </button>
//                                   </TableCell>

//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     {item.aadharNo}
//                                   </TableCell>

//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     {item.permanentAddress}
//                                   </TableCell>
//                                   <TableCell
//                                     align={"start"}
//                                     style={{
//                                       top: 57,
//                                       fontSize: 12,
//                                       color: "rgb(126, 110, 228) ",
//                                     }}
//                                   >
//                                     <div style={{ display: "flex" }}>
//                                       <Badge
//                                         bg="primary"
//                                         style={{ cursor: "pointer" }}
//                                         onClick={() => {
//                                           handleClickOpenbok();
//                                           handleBookRowClick(item.name);
//                                           setId(item.Id);
//                                         }}
//                                       >
//                                         Book
//                                       </Badge>
//                                       {/* <div>
//                                                                                 <IconButton
//                                                                                     id="fade-button"
//                                                                                     aria-controls={open2 ? 'fade-menu' : undefined}
//                                                                                     aria-haspopup="true"
//                                                                                     aria-expanded={open2 ? 'true' : undefined}
//                                                                                     onClick={(e) => {
//                                                                                         handleClick(e.currentTarget)
//                                                                                         setId(item.Id)
//                                                                                     }}
//                                                                                 >
//                                                                                     <MoreVertIcon />
//                                                                                 </IconButton>
//                                                                                 <Menu
//                                                                                     id="fade-menu"
//                                                                                     MenuListProps={{
//                                                                                         'aria-labelledby': 'fade-button',
//                                                                                     }}
//                                                                                     anchorEl={anchorEl}
//                                                                                     open={open2}
//                                                                                     onClose={handleClose2}
//                                                                                     TransitionComponent={Fade}
//                                                                                 >
                                                                                   
//                                                                                     {
//                                                                                         (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                             <MenuItem style={{ color: 'gray' }} onClick={handleClickOpenbok}>Book</MenuItem>
//                                                                                         )

//                                                                                     }


                                                                                  

//                                                                                 </Menu>
//                                                                             </div> */}
//                                     </div>
//                                   </TableCell>
//                                 </TableRow>
//                               ))}
//                         </TableBody>
//                       </Table>

//                       {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
//                                                 <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
//                                             </div> */}
//                     </div>
//                   </TabPanel>
//                   <TabPanel value="7">
//                     <div style={{ width: "58rem" }}>
//                       <Typography
//                         style={{
//                           color: "rgba(41, 16, 105, 1)",
//                           marginBottom: 3,
//                           marginLeft: 15.5,
//                           fontSize: 18,
//                           color: "gray",
//                           fontWeight: "500",
//                           textAlign: "center",
//                         }}
//                       >
//                         Booked
//                       </Typography>
//                       <div style={{ fontWeight: "bold" }}>
//                         Total Booked: {customerRes.length}
//                       </div>

//                       <Table style={{ position: "relative", top: "1.3rem" }}>
//                         <TableHead sx={{ borderTop: "1px solid lightgray" }}>
//                           <TableRow>
//                             <TableCell
//                               align={"start"}
//                               style={{
//                                 minWidth: 0,
//                                 fontSize: 14,
//                                 fontWeight: "600",
//                               }}
//                             >
//                               Name
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 57, fontWeight: "600" }}
//                             >
//                               Mobile
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Aadhar
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Pan
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Address
//                             </TableCell>
//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600" }}
//                             >
//                               Status
//                             </TableCell>

//                             <TableCell
//                               align={"left"}
//                               style={{ top: 21, fontWeight: "600", width: 18 }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {customerRes &&
//                             customerRes
//                               .filter(
//                                 (item) =>
//                                   item.status === "approved" &&
//                                   item.ProjectName === PrName
                                  
//                               )
//                               .map((item) => (
//                                 <TableRow
//                                   hover
//                                   role="checkbox"
//                                   tabIndex={-1}
//                                   key={item.Id}
//                                 >
//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     {item.customerName}
//                                   </TableCell>
//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     <button
//                                       style={{
//                                         fontSize: 10.7,
//                                         border: "2px solid rgb(126, 110, 228)",
//                                         backgroundColor: "white",
//                                         borderRadius: "1.2rem",
//                                         cursor: "auto",
//                                       }}
//                                     >
//                                       <FaMobileAlt
//                                         style={{
//                                           marginRight: 3,
//                                           color: "rgb(126, 110, 228)",
//                                           marginTop: "-0.16rem",
//                                           fontSize: 9,
//                                         }}
//                                       />
//                                       {item.mobileNumber}
//                                     </button>
//                                   </TableCell>

//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     {item.aadharNo}
//                                   </TableCell>
//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     {item.panNo}
//                                   </TableCell>
//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12 }}
//                                   >
//                                     {item.addres}
//                                   </TableCell>
//                                   <TableCell
//                                     align={"start"}
//                                     style={{ top: 57, fontSize: 12,color:'green',fontWeight:'bold' }}
//                                   >
//                                     {item.status}
//                                   </TableCell>
//                                   <TableCell
//                                     align={"start"}
//                                     style={{
//                                       top: 57,
//                                       fontSize: 12,
//                                       color: "rgb(126, 110, 228) ",
//                                     }}
//                                   >
//                                     <div style={{ display: "flex" }}>
//                                       <Badge bg="danger">Cancel</Badge>
//                                       {/* <div>
//                                                                                 <IconButton
//                                                                                     id="fade-button"
//                                                                                     aria-controls={open2 ? 'fade-menu' : undefined}
//                                                                                     aria-haspopup="true"
//                                                                                     aria-expanded={open2 ? 'true' : undefined}
//                                                                                     onClick={(e) => {
//                                                                                         handleClick(e.currentTarget)
//                                                                                         setId(item.Id)
//                                                                                     }}
//                                                                                 >
//                                                                                     <MoreVertIcon />
//                                                                                 </IconButton>
//                                                                                 <Menu
//                                                                                     id="fade-menu"
//                                                                                     MenuListProps={{
//                                                                                         'aria-labelledby': 'fade-button',
//                                                                                     }}
//                                                                                     anchorEl={anchorEl}
//                                                                                     open={open2}
//                                                                                     onClose={handleClose2}
//                                                                                     TransitionComponent={Fade}
//                                                                                 >
                                                                                   
//                                                                                     {
//                                                                                         (sessionStorage.getItem('View') === '1' || sessionStorage.getItem('superAdmin')) && (
//                                                                                             <MenuItem style={{ color: 'gray' }} >Cancel</MenuItem>
//                                                                                         )

//                                                                                     }


                                                                                  

//                                                                                 </Menu>
//                                                                             </div> */}
//                                     </div>
//                                   </TableCell>
//                                 </TableRow>
//                               ))}
//                         </TableBody>
//                       </Table>

//                       {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
//                                                 <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
//                                             </div> */}
//                     </div>
//                   </TabPanel>
//                 </TabContext>
//               </Box>
//             </Paper>
//           </div>
//         )}
//         {isVeiw && (
//           <div
//             className="leadManagementTable leadManagementTable1 p-4"
//             style={{
//               backgroundColor: "white",
//               borderRadius: "0.4rem",
//               position: "relative",
//             }}
//           >
//             <FaWindowClose
//               onClick={() => {
//                 setIsVeiw(false);
//               }}
//               style={{
//                 position: "absolute",
//                 right: 10,
//                 top: 12,
//                 color: "rgb(126, 110, 228)",
//                 fontSize: 21,
//                 cursor: "pointer",
//                 zIndex: 10000,
//               }}
//             ></FaWindowClose>
//             <div
//               className="card"
//               style={{
//                 backgroundColor: "white",
//                 borderRadius: "0.4rem",
//                 border: "none",
//                 boxShadow: "none",
//                 alignItems: "center",
//               }}
//             >
//               <Typography
//                 style={{
//                   fontSize: 26,
//                   fontWeight: "bold",
//                   color: "rgb(126, 110, 228)",
//                 }}
//               >
//                 <img
//                   src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
//                   width="145px"
//                 />
//               </Typography>
//               <Typography>Lead customers information details</Typography>
//             </div>
//             <br />
//             <div style={{ padding: 21, paddingLeft: 46, paddingRight: 46 }}>
//               <div
//                 className="card"
//                 style={{
//                   paddingTop: 43,
//                   border: "1px solid rgb(126, 110, 228)",
//                   borderRadius: "0.3rem",
//                   padding: 11,
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <div
//                   className="card"
//                   style={{
//                     backgroundColor: "white",
//                     borderRadius: "0.4rem",
//                     border: "none",
//                     boxShadow: "none",
//                     alignItems: "start",
//                     justifyContent: "space-between",
//                     flexDirection: "column",
//                   }}
//                 >
//                   <Typography
//                     style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
//                   >
//                     <span style={{ fontSize: 17, color: "black" }}> Name:</span>{" "}
//                     {pname}
//                   </Typography>
//                   <Typography
//                     style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
//                   >
//                     <span style={{ fontSize: 17, color: "black" }}>Email:</span>{" "}
//                     {Pemail}
//                   </Typography>
//                   <Typography
//                     style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
//                   >
//                     <span style={{ fontSize: 17, color: "black" }}>
//                       Mobile:
//                     </span>{" "}
//                     {pmobile}
//                   </Typography>
//                   <Typography
//                     style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
//                   >
//                     <span style={{ fontSize: 17, color: "black" }}>
//                       {" "}
//                       Gender:
//                     </span>{" "}
//                     {pgender}
//                   </Typography>
//                   <Typography
//                     style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
//                   >
//                     <span style={{ fontSize: 17, color: "black" }}>
//                       Preffered Timings:
//                     </span>{" "}
//                     {ptym}
//                   </Typography>
//                   <Typography
//                     style={{ marginBottom: 34, color: "rgb(163, 163, 165)" }}
//                   >
//                     <span style={{ fontSize: 17, color: "black" }}>
//                       Source Type :
//                     </span>{" "}
//                     {pst}
//                   </Typography>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//         <div>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="responsive-dialog-title"
//           >
//             <DialogContent>
//               <div style={{ width: "40rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validated}
//                   onSubmit={formValidation}
//                 >
//                   <div className="mb-4 mt-1">
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Add Lead
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Full Name *
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setPname(e.target.value)}
//                         placeholder="Enter Full Name"
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Name
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Email *
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setPemail(e.target.value)}
//                         type="text"
//                         placeholder="Enter Email"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid email
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     {wapp !== "" && (
//                       <>
//                         <Form.Group
//                           as={Col}
//                           md="6"
//                           controlId="validationCustom01"
//                         >
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Mobile *
//                           </Typography>
//                           <Form.Control
//                             required
//                             placeholder="Enter Mobile"
//                             onChange={(e) => setPmobile(e.target.value)}
//                             type="number"
//                             style={{ padding: "0.42rem", marginBottom: 14 }}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             Please Enter valid mobile
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group
//                           as={Col}
//                           md="6"
//                           controlId="validationCustom02"
//                         >
//                           <label style={{ color: "gray", fontSize: 13 }}>
//                             Is This Your WhatsApp Mobile? *
//                           </label>
//                           {["radio"].map((type) => (
//                             <div key={`inline-${type}`} className="mb-3">
//                               <Form.Check
//                                 inline
//                                 label="Yes"
//                                 name="whatsappGroup"
//                                 type={type}
//                                 id={`inline-${type}-1`}
//                                 value="Yes"
//                                 onChange={handleWhatsappCheckboxChange}
//                               />
//                               <Form.Check
//                                 inline
//                                 label="No"
//                                 name="whatsappGroup"
//                                 type={type}
//                                 id={`inline-${type}-2`}
//                                 value="No"
//                                 onChange={handleWhatsappCheckboxChange}
//                               />
//                             </div>
//                           ))}
//                           <Form.Control.Feedback type="invalid">
//                             Please select WhatsApp option
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         {isWhatsappNo && (
//                           <Form.Group
//                             as={Col}
//                             md="6"
//                             controlId="validationCustom03"
//                           >
//                             <Typography style={{ color: "gray", fontSize: 13 }}>
//                               WhatsApp Mobile *
//                             </Typography>
//                             <Form.Control
//                               required
//                               placeholder="Enter Whatsapp Mobile"
//                               onChange={(e) =>
//                                 setWhatsappMobile(e.target.value)
//                               }
//                               type="number"
//                               style={{ padding: "0.42rem", marginBottom: 14 }}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               Please Enter Valid WhatsApp mobile
//                             </Form.Control.Feedback>
//                           </Form.Group>
//                         )}
//                       </>
//                     )}

//                     <Form.Group as={Col} md="12" controlId="validationCustom01">
//                       <div class="radio-wrapper">
//                         <div
//                           className="radio-container d-flex"
//                           style={{ marginBottom: 14 }}
//                         >
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Gender *
//                           </Typography>
//                           <div style={{ marginRight: "5rem", marginLeft: 30 }}>
//                             <input
//                               onChange={(e) => setPgender(e.target.value)}
//                               type="radio"
//                               name="gender"
//                               value="Male"
//                               id="male"
//                             />
//                             <label for="male">MALE</label>
//                           </div>
//                           <div>
//                             <input
//                               onChange={(e) => setPgender(e.target.value)}
//                               type="radio"
//                               name="gender"
//                               value="Female"
//                             />
//                             <label for="female">FEMALE</label>
//                           </div>
//                         </div>
//                       </div>
//                       <Form.Control.Feedback type="invalid">
//                         Please select valid Gender
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Location *
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setLocation(e.target.value)}
//                         placeholder="Enter Location"
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Location
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Preffered Timings
//                       </Typography>
//                       <Form.Control
//                         required
//                         value={ptym}
//                         onChange={(e) => setPtym(e.target.value)}
//                         type="datetime-local"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Timings
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <>
//                       <Form.Group
//                         as={Col}
//                         md="6"
//                         controlId="validationCustom01"
//                       >
//                         <div style={{ marginBottom: 14 }}>
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Source Type
//                           </Typography>
//                           <select
//                             onChange={handleSourceTypeChange}
//                             style={{
//                               height: "2.3rem",
//                               color: "gray",
//                               borderRadius: "0.3rem",
//                               outline: "none",
//                               width: "100%",
//                               border: "0.7px solid lightgray",
//                             }}
//                           >
//                             <option>--select--</option>
//                             <option>Online</option>
//                             <option>Walk In</option>
//                             <option>Reference</option>
//                             <option>Online/Website</option>
//                             <option>OTH</option>
//                             <option>Paper Ad</option>
//                           </select>
//                         </div>
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Source type
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       {showReferenceFields && (
//                         <>
//                           <Form.Group
//                             as={Col}
//                             md="6"
//                             controlId="validationCustom01"
//                           >
//                             <Typography style={{ color: "gray", fontSize: 13 }}>
//                               Reference Name *
//                             </Typography>
//                             <Form.Control
//                               required
//                               value={rname}
//                               placeholder="Enter Reference Name"
//                               onChange={(e) => setRName(e.target.value)}
//                               type="text"
//                               style={{ padding: "0.42rem", marginBottom: 14 }}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               Please Enter Valid Reference Name
//                             </Form.Control.Feedback>
//                           </Form.Group>

//                           <Form.Group
//                             as={Col}
//                             md="6"
//                             controlId="validationCustom01"
//                           >
//                             <Typography style={{ color: "gray", fontSize: 13 }}>
//                               Reference Mobile *
//                             </Typography>
//                             <Form.Control
//                               required
//                               value={rmobile}
//                               placeholder="Enter Reference Mobile"
//                               onChange={(e) => setRMobile(e.target.value)}
//                               type="number"
//                               style={{ padding: "0.42rem", marginBottom: 14 }}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               Please Enter valid Reference Mobile
//                             </Form.Control.Feedback>
//                           </Form.Group>
//                         </>
//                       )}
//                     </>
//                     <>
//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom02"
//                       >
//                         <label style={{ color: "gray", fontSize: 13 }}>
//                           Channel Partner*
//                         </label>
//                         {["radio"].map((type) => (
//                           <div key={`inline-${type}`} className="mb-3">
//                             <Form.Check
//                               inline
//                               label="Yes"
//                               name="interest"
//                               type={type}
//                               id={`inline-${type}-1`}
//                               value="Yes"
//                               onChange={handleIntrestChange}
//                             />
//                             <Form.Check
//                               inline
//                               label="No"
//                               name="intrest"
//                               type={type}
//                               id={`inline-${type}-2`}
//                               value="No"
//                               onChange={handleIntrestChange}
//                             />
//                           </div>
//                         ))}
//                         <Form.Control.Feedback type="invalid">
//                           Please select Interest option
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       {intrest === "Yes" && (
//                         <>
//                           <Form.Group
//                             as={Col}
//                             md="6"
//                             controlId="validationCustom01"
//                           >
//                             <Typography style={{ color: "gray", fontSize: 13 }}>
//                               Channel Partner Name
//                             </Typography>
//                             <Form.Control
//                               required
//                               value={cpName}
//                               onChange={(e) => setCPName(e.target.value)}
//                               type="text"
//                               placeholder="Enter Channel Partner Name"
//                               style={{ padding: "0.42rem", marginBottom: 14 }}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               Please Enter Valid Channel Partner Name
//                             </Form.Control.Feedback>
//                           </Form.Group>
//                           <Form.Group
//                             as={Col}
//                             md="6"
//                             controlId="validationCustom01"
//                           >
//                             <Typography style={{ color: "gray", fontSize: 13 }}>
//                               Channel Partner ID
//                             </Typography>
//                             <Form.Control
//                               required
//                               value={cpId}
//                               onChange={(e) => setCPId(e.target.value)}
//                               type="text"
//                               placeholder="Enter Channel Partner ID"
//                               style={{ padding: "0.42rem", marginBottom: 14 }}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               Please Enter Valid Channel Partner ID
//                             </Form.Control.Feedback>
//                           </Form.Group>
//                         </>
//                       )}
//                     </>
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Comment{" "}
//                       </Typography>
//                       <Form.Control
//                         required
//                         as="textarea"
//                         onChange={(e) => setComment(e.target.value)}
//                         style={{
//                           padding: "0.42rem",
//                           marginBottom: 14,
//                           minHeight: "40px",
//                         }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Comment
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         type="submit"
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => {
//                           setOpen(false);
//                           setValidated(false);
//                         }}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div>

//         <div>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={open1}
//             onClose={() => setOpen1(!open1)}
//             aria-labelledby="responsive-dialog-title"
//           >
//             <DialogContent>
//               <div style={{ width: "37rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validated}
//                   onSubmit={formValidation}
//                 >
//                   <div className="mb-4 mt-1">
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Add Project
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Name *
//                       </Typography>
//                       <Form.Control
//                         required
//                         value={pname}
//                         onChange={(e) => setPname(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Name
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Email *
//                       </Typography>
//                       <Form.Control
//                         required
//                         value={Pemail}
//                         onChange={(e) => setPemail(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid email
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Mobile *
//                       </Typography>
//                       <Form.Control
//                         required
//                         value={pmobile}
//                         onChange={(e) => setPmobile(e.target.value)}
//                         type="number"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter valid moible
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     {/* 
//                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Whatsapp Mobile *</Typography>
//                                             <Form.Control
//                                                 required
                                                
//                                                 onChange={(e) => setWhatsappMobile(e.target.value)}
//                                                 type="number"

//                                                 style={{ padding: '0.42rem', marginBottom: 14 }}


//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Valid whatsapp mobile
//                                             </Form.Control.Feedback>
//                                         </Form.Group> */}

//                     <Form.Group as={Col} md="12" controlId="validationCustom01">
//                       <div class="radio-wrapper">
//                         <div
//                           className="radio-container d-flex"
//                           style={{ marginBottom: 14 }}
//                         >
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Gender *
//                           </Typography>
//                           <div style={{ marginRight: "5rem", marginLeft: 30 }}>
//                             <input
//                               onChange={(e) => setPgender(e.target.value)}
//                               type="radio"
//                               name="gender"
//                               value={pgender}
//                               id="male"
//                             />
//                             <label for="male">MALE</label>
//                           </div>
//                           <div>
//                             <input
//                               onChange={(e) => setPgender(e.target.value)}
//                               type="radio"
//                               name="gender"
//                               value={pgender}
//                             />
//                             <label for="female">FEMALE</label>
//                           </div>
//                         </div>
//                       </div>
//                       <Form.Control.Feedback type="invalid">
//                         Please select valid Gender
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Preffered Timings
//                       </Typography>
//                       <Form.Control
//                         required
//                         value={ptym}
//                         onChange={(e) => setPtym(e.target.value)}
//                         type="datetime-local"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Timings
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <div style={{ marginBottom: 14 }}>
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Source Type
//                         </Typography>
//                         <select
//                           onChange={(e) => setPst(e.target.value)}
//                           value={pst}
//                           style={{
//                             height: "2.3rem",
//                             color: "gray",
//                             borderRadius: "0.3rem",
//                             outline: "none",
//                             width: "100%",
//                             border: "0.7px solid lightgray",
//                           }}
//                         >
//                           <option>Website</option>
//                           <option>Tradeshow</option>
//                           <option>Walkins</option>
//                           <option>Social Media</option>
//                           <option>Relatives</option>
//                           <option>Freinds</option>
//                         </select>
//                       </div>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Source type
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         onClick={() => editAlert()}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Update
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => setOpen1(false)}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div>

//         <React.Fragment>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={openi}
//             onClose={handleClosei}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//           >
//             <DialogContent>
//               <div style={{ width: "37rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validatedtabi}
//                   onSubmit={formValidationtabi}
//                 >
//                   <div className="mb-4 mt-1">
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Interested
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Date of Visit *
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setIdate(e.target.value)}
//                         type="datetime-local"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Name
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="12" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Comment Box
//                       </Typography>
//                       <Form.Control
//                         re
//                         onChange={(e) => setIcomment(e.target.value)}
//                         required
//                         as="textarea"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Permanent Address
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         type="submit"
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => {
//                           setOpeni(false);
//                         }}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </React.Fragment>

//         <React.Fragment>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={openc}
//             onClose={handleClosec}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//           >
//             <DialogContent>
//               <div style={{ width: "37rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validatedtabcb}
//                   onSubmit={formValidationtabcb}
//                 >
//                   <div className="mb-4 mt-1">
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Call Back
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Date of Visit *
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setCbd(e.target.value)}
//                         type="datetime-local"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Name
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="12" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Comment Box
//                       </Typography>
//                       <Form.Control
//                         re
//                         onChange={(e) => setCbc(e.target.value)}
//                         required
//                         as="textarea"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Permanent Address
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         type="submit"
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => {
//                           setOpenc(false);
//                           setValidatedtabcb(false);
//                         }}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </React.Fragment>

//         <React.Fragment>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={openin}
//             onClose={handleClosein}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//           >
//             <DialogContent>
//               <div style={{ width: "37rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validatedtabin}
//                   onSubmit={formValidationtabin}
//                 >
//                   <div className="mb-4 mt-1">
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Not Interested
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     <Form.Group as={Col} md="12" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Comment Box
//                       </Typography>
//                       <Form.Control
//                         re
//                         onChange={(e) => setIncb(e.target.value)}
//                         required
//                         as="textarea"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Permanent Address
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         type="submit"
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => {
//                           setOpenin(false);
//                         }}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </React.Fragment>

//         {/* Site visit form */}

//         <div>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={opensv}
//             onClose={handleClosesv}
//             aria-labelledby="responsive-dialog-title"
//           >
//             <DialogContent>
//               <div style={{ width: "74rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validatedsv}
//                   onSubmit={formValidationsv}
//                 >
//                   <div className="mb-4 mt-1">
//                     <center>
//                       <img
//                         src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
//                         width="145px"
//                       />
//                     </center>
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Site Visit
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     {/* <Row>
//                                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <div style={{ marginBottom: 14 }}>
//                                                 <Typography style={{ color: 'gray', fontSize: 13 }}>Lead Name</Typography>
//                                                 <select onChange={(e) => getdetls(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '100%', border: '0.7px solid lightgray' }} >
//                                                     <option>--Select--</option>
//                                                     {
//                                                         res.map((item => (
//                                                             <option>{item.name},{item.mobile}</option>
//                                                         )))
//                                                     }
//                                                 </select>
//                                             </div>
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Valid Source type
//                                             </Form.Control.Feedback>
//                                         </Form.Group>

//                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Name *</Typography>
//                                             <Form.Control


//                                                 disabled
//                                                 type="text"
//                                                 value={nname}
//                                                 style={{ padding: '0.42rem', marginBottom: 14, }}

//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Valid Name
//                                             </Form.Control.Feedback>
//                                         </Form.Group>

//                                          <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Mobile </Typography>
//                                             <Form.Control


//                                                 disabled
//                                                 type="text"
//                                                 value={nmobile}
//                                                 style={{ padding: '0.42rem', marginBottom: 14, }}

//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Valid Mobile
//                                             </Form.Control.Feedback>
//                                         </Form.Group> 

//                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Email </Typography>
//                                             <Form.Control
//                                                 value={nemail}
//                                                 type="text"
//                                                 disabled
//                                                 style={{ padding: '0.42rem', marginBottom: 14 }}


//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Valid email
//                                             </Form.Control.Feedback>
//                                         </Form.Group>
//                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Location </Typography>
//                                             <Form.Control
//                                                 value={leadloc}
//                                                 type="text"
//                                                 disabled
//                                                 style={{ padding: '0.42rem', marginBottom: 14 }}


//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Valid Location
//                                             </Form.Control.Feedback>
//                                         </Form.Group>

//                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}> Source Type</Typography>
//                                             <Form.Control

//                                                 disabled
//                                                 value={nst}
//                                                 type="text"
//                                                 style={{ padding: '0.42rem', marginBottom: 14 }}
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter valid Source Type
//                                             </Form.Control.Feedback>
//                                         </Form.Group>
//                                         {
//                                             nst === 'Reference' && (
//                                                 <>
//                                                 <Form.Group as={Col} md="4">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Refernce Name *</Typography>
//                                             <Form.Control


//                                                 disabled
//                                                 type="text"
//                                                 value={nrefname}
//                                                 style={{ padding: '0.42rem', marginBottom: 14, }}

//                                             />
                                            
//                                         </Form.Group>
//                                                 <Form.Group as={Col} md="4">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Refernce Mobile *</Typography>
//                                             <Form.Control


//                                                 disabled
//                                                 type="text"
//                                                 value={nrefmob}
//                                                 style={{ padding: '0.42rem', marginBottom: 14, }}

//                                             />
                                            
//                                         </Form.Group>
                                               
//                                                 </>
//                                             )
//                                         } 



//                                              <Form.Group as={Col} md="4" controlId="validationCustom03">
//                                                 <Typography style={{ color: 'gray', fontSize: 13 }}>Reference</Typography>
//                                                 <Form.Control
//                                                     required
//                                                     onChange={(e) => setWhatsappMobile(e.target.value)}
//                                                     type="text"
//                                                     // value={preference}
//                                                     disabled
//                                                     style={{ padding: '0.42rem', marginBottom: 14 }}
//                                                 />
//                                                 <Form.Control.Feedback type="invalid">
//                                                     Please Enter Valid Reference
//                                                 </Form.Control.Feedback>
//                                             </Form.Group> 

//                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Channel Partner</Typography>
//                                             <Form.Control
//                                                 required
//                                                 value={ncp}
//                                                 type="text"
//                                                 disabled
//                                                 style={{ padding: '0.42rem', marginBottom: 14 }}
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter Valid Channel Partner
//                                             </Form.Control.Feedback>
//                                         </Form.Group>
//                                         {

//                                             ncp === 'Yes' && (
//                                                 <>
//                                                 <Form.Group as={Col} md="4">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Cp Name *</Typography>
//                                             <Form.Control


//                                                 disabled
//                                                 type="text"
//                                                 value={ncpname}
//                                                 style={{ padding: '0.42rem', marginBottom: 14, }}

//                                             />
                                            
//                                         </Form.Group>
//                                                 <Form.Group as={Col} md="4">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Cp Id *</Typography>
//                                             <Form.Control


//                                                 disabled
//                                                 type="text"
//                                                 value={ncpid}
//                                                 style={{ padding: '0.42rem', marginBottom: 14, }}

//                                             />
                                            
//                                         </Form.Group>
//                                         </>
//                                             )
//                                         }

//                                         <Form.Group as={Col} md="4" controlId="validationCustom01">
//                                             <Typography style={{ color: 'gray', fontSize: 13 }}>Executive</Typography>
//                                             <Form.Control
//                                                 value={pexecutive}
//                                                 type="text"
//                                                 style={{ padding: '0.42rem', marginBottom: 14 }}
//                                                 onChange={(e) =>setPExecutive(e.target.value)}
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please Enter valid Executive
//                                             </Form.Control.Feedback>
//                                         </Form.Group>



//                                     </Row> */}

//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <div style={{ marginBottom: 14 }}>
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           {" "}
//                           Occupation
//                         </Typography>
//                         <Form.Select
//                           onChange={(e) => setOccupation(e.target.value)}
//                           style={{
//                             height: "2.3rem",
//                             color: "gray",
//                             borderRadius: "0.3rem",
//                             outline: "none",
//                             width: "100%",
//                             border: "0.7px solid lightgray",
//                           }}
//                         >
//                           <option>--select--</option>
//                           <option>Private</option>
//                           <option>Public</option>
//                           <option>Self Employed</option>
//                           <option>Business</option>
//                           <option>Others</option>
//                         </Form.Select>
//                       </div>
//                       <Form.Control.Feedback type="invalid">
//                         Please Select valid Occupation option
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Budget
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setBudget(e.target.value)}
//                         type="text"
//                         value={budget}
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Budget
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Interested Apartment Size
//                       </Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setApartSize(e.target.value)}
//                         value={apartsize}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       >
//                         <option>select Apartment Size</option>

//                         {typicaluzdrop.map((data, index) => (
//                           <option key={index}>{`${data.unitsize}`}</option>
//                         ))}
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Interested Apartment Size
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Interested Apartment Facing
//                       </Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setApartFacing(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       >
//                         <option>select Facing</option>
//                         <option>East</option>
//                         <option>West</option>
//                         <option>North</option>
//                         <option>South</option>
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Interested Apartment Facing
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <div style={{ marginBottom: 14 }}>
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           {" "}
//                           Corner
//                         </Typography>
//                         <Form.Select
//                           onChange={(e) => setCorner(e.target.value)}
//                           style={{
//                             height: "2.3rem",
//                             color: "gray",
//                             borderRadius: "0.3rem",
//                             outline: "none",
//                             width: "100%",
//                             border: "0.7px solid lightgray",
//                           }}
//                         >
//                           <option>--select--</option>
//                           <option>Yes</option>
//                           <option>No</option>
//                         </Form.Select>
//                       </div>
//                       <Form.Control.Feedback type="invalid">
//                         Please Select valid Corner option
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <>
//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <div style={{ marginBottom: 14 }}>
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Flat Details
//                           </Typography>
//                           <select
//                             onChange={handleFlatDetailsChange}
//                             style={{
//                               height: "2.3rem",
//                               color: "gray",
//                               borderRadius: "0.3rem",
//                               outline: "none",
//                               width: "100%",
//                               border: "0.7px solid lightgray",
//                             }}
//                           >
//                             <option>--select--</option>
//                             <option>Under Construction</option>
//                             <option>Ready To Move</option>
//                           </select>
//                         </div>
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Flat Details
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       {flatDetails === "Under Construction" && (
//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationCustom01"
//                         >
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Under Construction
//                           </Typography>
//                           <Form.Control
//                             required
//                             value={underConstruction}
//                             onChange={(e) =>
//                               setUnderConstruction(e.target.value)
//                             }
//                             type="text"
//                             style={{ padding: "0.42rem", marginBottom: 14 }}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             Please Enter Valid Under Construction
//                           </Form.Control.Feedback>
//                         </Form.Group>
//                       )}

//                       {flatDetails === "Ready To Move" && (
//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationCustom01"
//                         >
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Ready To Move
//                           </Typography>
//                           <Form.Control
//                             required
//                             value={readyToMove}
//                             onChange={(e) => setReadyToMove(e.target.value)}
//                             type="date"
//                             style={{ padding: "0.42rem", marginBottom: 14 }}
//                           />
//                         </Form.Group>
//                       )}
//                     </>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         type="submit"
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => {
//                           setOpensv(false);
//                           setValidatedsv(false);
//                         }}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div>

//         {/* Block Form  */}

//         <div>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={openbf}
//             onClose={handleClosebf}
//             aria-labelledby="responsive-dialog-title"
//           >
//             <DialogContent>
//               <div style={{ width: "74rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validatedbf}
//                   onSubmit={formValidationbf}
//                 >
//                   <div className="mb-4 mt-1">
//                     <center>
//                       <img
//                         src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
//                         width="145px"
//                       />
//                     </center>
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Block Form
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     <Row>
//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <div style={{ marginBottom: 14 }}>
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Lead Name
//                           </Typography>
//                           <select
//                             onChange={(e) => getdetls(e.target.value)}
//                             style={{
//                               height: "2.3rem",
//                               color: "gray",
//                               borderRadius: "0.3rem",
//                               outline: "none",
//                               width: "100%",
//                               border: "0.7px solid lightgray",
//                             }}
//                           >
//                             <option>--Select--</option>
//                             {filteredItems.map((item) => (
//                               <option key={item.Id}>
//                                 {item.name},{item.mobile}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Source type
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Name *
//                         </Typography>
//                         <Form.Control
//                           type="text"
//                           value={nname}
//                           onChange={(e) => setNname(e.target.value)}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Name
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Mobile{" "}
//                         </Typography>
//                         <Form.Control
//                           disabled
//                           type="number"
//                           value={nmobile}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Mobile
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Email{" "}
//                         </Typography>
//                         <Form.Control
//                           value={nemail}
//                           type="text"
//                           disabled
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid email
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                     </Row>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Alternate Mobile{" "}
//                       </Typography>
//                       <Form.Control
//                         type="number"
//                         placeholder="Enter Alternate Mobile Number"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                         onChange={(e) => setAlternateMob(e.target.value)}
//                         required
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Alternate Mobile
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Aadhar Number
//                       </Typography>
//                       <Form.Control
//                         placeholder="Enter Aadhar Number"
//                         onChange={(e) => setAadharNumber(e.target.value)}
//                         required
//                         type="number"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter valid Aadhar Number
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom03">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Pan Number
//                       </Typography>
//                       <Form.Control
//                         required
//                         placeholder="Enter Pan Number"
//                         onChange={(e) => setPanNumber(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Pan Number
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Permanent Address
//                       </Typography>
//                       <Form.Control
//                         re
//                         onChange={(e) => setPerAddress(e.target.value)}
//                         required
//                         as="textarea"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Permanent Address
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         {" "}
//                         Current Address
//                       </Typography>
//                       <Form.Control
//                         onChange={(e) => setAddressCom(e.target.value)}
//                         value={addresscom}
//                         required
//                         as="textarea"
//                         disabled={sameaspaddress}
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Check
//                         style={{ color: "red", fontSize: 12 }}
//                         onChange={handlecheck}
//                         label="Same As Permanent Address"
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter valid Address For Communication
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Payment Source
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setPaymentSource(e.target.value)}
//                         type="text"
//                         placeholder="Enter Payment Source"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Payment Source
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Cheque Number/UTR Number
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setChequeNumber(e.target.value)}
//                         type="text"
//                         placeholder="Enter Number"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Cheque Number
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Bank Details
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setTransferDetails(e.target.value)}
//                         type="text"
//                         placeholder="Enter Bank Details"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Transfer Details
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Amount In Words
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setAmountInWords(e.target.value)}
//                         type="text"
//                         placeholder="Enter Amount In Words"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Amount In Words
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Amount
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setAmount(e.target.value)}
//                         type="text"
//                         placeholder="Enter Amount"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Amount
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         ProjectName
//                       </Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setProjectName(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       >
//                         <option>Select Project</option>
//                         <option>{PrName}</option>
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Towards Flat Number
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Tower{" "}
//                       </Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setTowardsTowerNumber(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       >
//                         <option>Select Tower </option>
//                         {responset
//                           .filter((item) => item.Towers.startsWith(projectName)) // Filter towers that start with the project name
//                           .map((item) => (
//                             <option>{item.Towers}</option>
//                           ))}
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Towards Tower Number
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Floor
//                       </Typography>
//                       <Form.Select
//                         required
//                         value={tfloorno}
//                         onChange={(e) => setTowardsFloorNumber(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       >
//                         <option>Select Floor</option>
//                         {responsef.filter(item => item.Floors.startsWith(projectName) && item.Floors.includes(projectName)).map((item) => (
//                           <option>{item.selectType}</option>
//                         ))}
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Towards Flat Number
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="3" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Flat/Unit
//                       </Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setTowardsFlatNumber(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       >
//                         <option>Select unit</option>
//                         {unitdata.filter(item => item.unit_name.startsWith(projectName) && item.unit_name.includes(ttowerno)).map((item) => (
//                           <option
//                             key={item.unit_name}
//                             value={item.unit_name}
//                             disabled={item.booking_status === "booked"}
//                           >
//                             {item.unit_name}
//                           </option>
//                         ))}
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Towards Flat Number
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         {" "}
//                         Sales Source{" "}
//                       </Typography>

//                       <Form.Control
//                         value={salessource}
//                         disabled
//                         // onChange={(e) => setSalesSource(e.target.value)}
//                         type="text"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />

//                       <Form.Control.Feedback type="invalid">
//                         Please Select valid Sales Source
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Signature
//                       </Typography>
//                       <Form.Control
//                         required
//                         onChange={(e) => setSignature(e.target.files[0])}
//                         type="file"
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                       />

//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Signature
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         type="submit"
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => {
//                           setOpenbf(false);
//                           setValidatedbf(false);
//                         }}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div>

//         {/* Booking Form */}

//         <div>
//           <Dialog
//             style={{ borderTop: "12px solid rgb(126, 110, 228)" }}
//             maxWidth="xl"
//             open={openbok}
//             onClose={handleClosebok}
//             aria-labelledby="responsive-dialog-title"
//           >
//             <DialogContent>
//               <div style={{ width: "74rem" }}>
//                 <Form
//                   noValidate
//                   validated={Validatedbok}
//                   onSubmit={formValidationbok}
//                 >
//                   <div className="mb-4 mt-1">
//                     <center>
//                       <img
//                         src="https://static.vecteezy.com/system/resources/previews/004/648/833/non_2x/user-icon-symbol-3d-design-concept-illustration-vector.jpg"
//                         width="145px"
//                       />
//                     </center>
//                     <Typography
//                       style={{
//                         textAlign: "center",
//                         fontWeight: "500",
//                         color: "rgb(126, 110, 228)",
//                         fontSize: 21,
//                       }}
//                     >
//                       Book Customer
//                     </Typography>
//                   </div>
//                   <Row className="mb-3">
//                     <Row>
//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <div style={{ marginBottom: 14 }}>
//                           <Typography style={{ color: "gray", fontSize: 13 }}>
//                             Lead Name
//                           </Typography>
//                           <select
//                             onChange={(e) => getdetlsb(e.target.value)}
//                             style={{
//                               height: "2.3rem",
//                               color: "gray",
//                               borderRadius: "0.3rem",
//                               outline: "none",
//                               width: "100%",
//                               border: "0.7px solid lightgray",
//                             }}
//                           >
//                             <option>--Select--</option>
//                             {filteredBookItems.map((item) => (
//                               <option>
//                                 {item.name},{item.mobile}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Source type
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Name
//                         </Typography>
//                         <Form.Control
//                           type="text"
//                           value={pnameb}
//                           onChange={(e) => setPNameb(e.target.value)}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Name
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Mobile{" "}
//                         </Typography>
//                         <Form.Control
//                           disabled
//                           type="number"
//                           value={pmobileb}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Mobile
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Alternate Mobile{" "}
//                         </Typography>
//                         <Form.Control
//                           disabled
//                           type="text"
//                           value={alternatemobb}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Name
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Email{" "}
//                         </Typography>
//                         <Form.Control
//                           value={Pemailb}
//                           type="text"
//                           disabled
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid email
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Aadhar Number
//                         </Typography>
//                         <Form.Control
//                           value={aadharnumberb}
//                           type="text"
//                           disabled
//                           required
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter valid Aadhar Number
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Upload Aadhar
//                         </Typography>
//                         <Form.Control
//                           onChange={handleAadharImgChange}
//                           disabled={Validated}
//                           type="file"
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         {aadharImg && (
//                           <Typography style={{ color: "gray", fontSize: 11 }}>
//                             {aadharImg.name}
//                           </Typography>
//                         )}
//                         <Typography
//                           style={{
//                             color: "gray",
//                             fontSize: 11,
//                             marginTop: 12,
//                           }}
//                         >
//                           Note : Image Size Needs to be less than 1mb
//                         </Typography>

//                         <Form.Control.Feedback type="invalid">
//                           Please Upload a Valid Aadhar File
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom03"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Pan Number
//                         </Typography>
//                         <Form.Control
//                           value={pannumberb}
//                           type="text"
//                           disabled
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Pan Number
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         md="3"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Upload PAN
//                         </Typography>
//                         <Form.Control
//                           onChange={handlePanImgChange}
//                           disabled={Validated}
//                           type="file"
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         {panImg && (
//                           <Typography style={{ color: "gray", fontSize: 11 }}>
//                             {panImg.name}
//                           </Typography>
//                         )}
//                         <Typography
//                           style={{
//                             color: "gray",
//                             fontSize: 11,
//                             marginTop: 12,
//                           }}
//                         >
//                           Note : Image Size Needs to be less than 1mb
//                         </Typography>

//                         <Form.Control.Feedback type="invalid">
//                           Please Upload a Valid Project Layout File
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="6"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           {" "}
//                           Address
//                         </Typography>
//                         <Form.Control
//                           value={addressb}
//                           type="text"
//                           disabled
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Address
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="6"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           {" "}
//                           Current Address
//                         </Typography>
//                         <Form.Control
//                           disabled
//                           required
//                           value={currentaddressb}
//                           type="text"
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter valid Current Address
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Tower
//                         </Typography>
//                         <Form.Control
//                           required
//                           disabled
//                           type="text"
//                           value={towerb}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Tower
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Floor
//                         </Typography>
//                         <Form.Control
//                           required
//                           disabled
//                           type="text"
//                           value={floorb}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Floor
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Flat No
//                         </Typography>
//                         <Form.Control
//                           required
//                           type="text"
//                           disabled
//                           value={flatnob}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         />
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Flat No
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Facing
//                         </Typography>

//                         <Form.Select
//                           required
//                           type="text"
//                           value={facingb}
//                           onChange={(e) => setFacingb(e.target.value)}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         >
//                           <option>Select Facing</option>
//                           <option>East</option>
//                           <option>West</option>
//                         </Form.Select>
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Facing
//                         </Form.Control.Feedback>
//                       </Form.Group>

//                       <Form.Group
//                         as={Col}
//                         md="4"
//                         controlId="validationCustom01"
//                       >
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Corner
//                         </Typography>
//                         <Form.Select
//                           required
//                           type="text"
//                           value={cornerb}
//                           onChange={(e) => setCornerb(e.target.value)}
//                           style={{ padding: "0.42rem", marginBottom: 14 }}
//                         >
//                           <option>Select Corner</option>
//                           <option>Yes</option>
//                           <option>No</option>
//                         </Form.Select>
//                         <Form.Control.Feedback type="invalid">
//                           Please Enter Valid Corner
//                         </Form.Control.Feedback>
//                       </Form.Group>
//                     </Row>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <Typography style={{ color: "gray", fontSize: 13 }}>
//                         Amount Paid
//                       </Typography>
//                       <Form.Control
//                         required
//                         type="text"
//                         value={amountpaidb}
//                         style={{ padding: "0.42rem", marginBottom: 14 }}
//                         onChange={(e) => setAmountPaidb(e.target.value)}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Amount Paid
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                     <Form.Group as={Col} md="4" controlId="validationCustom01">
//                       <div style={{ marginBottom: 14 }}>
//                         <Typography style={{ color: "gray", fontSize: 13 }}>
//                           Finance Type
//                         </Typography>
//                         <select
//                           onChange={(e) => setFinanceTypeb(e.target.value)}
//                           style={{
//                             height: "2.3rem",
//                             color: "gray",
//                             borderRadius: "0.3rem",
//                             outline: "none",
//                             width: "100%",
//                             border: "0.7px solid lightgray",
//                           }}
//                         >
//                           <option>--select--</option>
//                           <option>PRE EMI</option>
//                           <option>RERA</option>
//                         </select>
//                       </div>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid finance Type
//                       </Form.Control.Feedback>
//                     </Form.Group>
//                   </Row>
//                   <div className="d-flex">
//                     <div>
//                       <Button
//                         type="submit"
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "rgb(126, 110, 228)",
//                           color: "white",
//                           marginRight: 3,
//                         }}
//                       >
//                         Submit
//                       </Button>
//                     </div>
//                     <div>
//                       <Button
//                         onClick={() => {
//                           setOpenbok(false);
//                           setValidatedbok(false);
//                         }}
//                         style={{
//                           width: "9rem",
//                           height: "2.3rem",
//                           backgroundColor: "gray",
//                           color: "white",
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div>
//       </div>

//       {/* <div>
//           <Dialog
//             style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
//             maxWidth='xl'
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="responsive-dialog-title"
//           >

//             <DialogContent>
//               <div style={{ width: "37rem" }} >
//                 <Form noValidate validated={Validated} onSubmit={formValidation} >
//                   <div className='mb-4 mt-1'>
//                     <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Lead</Typography>
//                   </div>
//                   <Row className="mb-3">

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: 'gray', fontSize: 13 }}>Sales Executive</Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setSalexct(e.target.value)}
//                         type="text"

//                         style={{ padding: '0.42rem', marginBottom: 14 }}

//                       >

//                         <option>Sales Executive</option>
//                         {
//                           al.map((item => (
//                             <option>{item.UserName}</option>
//                           )))
//                         }
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Sales Executive
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                   </Row>
//                   <div className='d-flex'>
//                     <div>
//                       <Button onClick={() => handlesub()} type='button' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
//                     </div>
//                     <div>
//                       <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div> */}
//     </>
//   );
// }

// export default Manger;




import React,{useState} from 'react'
import { BsFillArchiveFill } from 'react-icons/bs'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { Box, Button, Menu, MenuItem, TableBody, Typography, useScrollTrigger } from '@mui/material';
import { Navbar, Container, Nav, NavDropdown,Dropdown } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaEdit, FaMapMarkerAlt, FaMarker, FaUserCircle, FaTrash, FaCheck, FaEye, FaPhone } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import axios from 'axios';


function Manager() {

    const [id, setId] = React.useState();
    // const [date, setDate] = React.useState()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };
    const handleClose2 = () => {
        setAnchorEl(null);
    };
    const text = sessionStorage.getItem('user_name')
    const [data, setData] = React.useState([])

    const[tcont, setTcount] = React.useState('')

    React.useEffect(() => {
       axios.post('https://buildfastapi.emedha.in/api/v1/salesexleadname',{
        text
       })
       .then(res => {
        console.log(res.data);
        setData(res.data)

       })

       axios.get('https://buildfastapi.emedha.in/api/v1/leadcount',{
        text
       })
       .then(res => {
        console.log(res.data);
        setTcount(res.data)
       
       })
    },[])


    const [selectedAction, setSelectedAction] = useState(null);

    const handleDropdownSelect = (eventKey) => {
      setSelectedAction(eventKey);
    };
  
    
     
   

      const[canDelete, setCanDelete] = React.useState(true)

    return (
        <>
        <sidebar>
            
        </sidebar>
        <main>
            <div>
                <h1 style={{ color: "black" }}>Manager Dashboard</h1>
            </div>
            <div className='main-cards'>
                <div className='card-box'>
                    <div className='card-inner'>
                        <h3>No.Of Leads</h3>
                        <BsFillArchiveFill className="card-icon" />
                        <h1>{tcont.totalRecords}</h1>
                    </div>
                </div>
                <div className='card-box'>
                    <div className='card-inner'>
                        <h3>No.Of Leads Closed </h3>
                        <BsFillArchiveFill className="card-icon" />
                        <h1>663</h1>

                    </div>
                </div>
            </div>
            <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

            <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
            <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                <div>

                    <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search user..' />
                </div>

            </div>
            

           
        </main>
        </>
    )
}

export default Manager;
