import React, { useState } from "react";
import axios from "axios";

function MailBody() {
  const [mailContent, setMailContent] = useState("");
  const [department, setDepartment] = useState("");
  const [type, setType] = useState("");
  const [ticketNumber, setTicketNumber] = useState(generateTicketNumber());
  const [sender, setSender] = useState("");
  const [receiver, setReceiver] = useState("");

  function generateTicketNumber() {
    return "TICKET-" + Math.floor(Math.random() * 1000000);
  }

  const handleSubmit = () => {
    const ticketData = {
      ticketNumber,
      mailContent,

      type,
      sender,
      receiver,
    };
    axios
      .post("https://buildfastapi.emedha.in/internal/api/v1/api/tickets", ticketData)
      .then((response) => {
        console.log("Ticket created:", response.data);
        // Reset form after submission
        setMailContent("");

        setType("");
        setTicketNumber(generateTicketNumber());
        setSender("");
        setReceiver("");
      })
      .catch((error) => console.error("Error creating ticket:", error));
  };

  const user = sessionStorage.getItem("userType");
  const AdminLogin = sessionStorage.getItem('superAdmin');

  const allReceivers = ["Admin", "CRM Head", "Sales", "Customer", "Accountant"];
  // Filter out the sender from the receiver options
  const receiverOptions = allReceivers.filter((option) => option !== user);

  return (
    <>
      <div className="d-flex flex-row align-items-center p-3">
        <div style={{ marginBottom: "15px" }}>
          <label style={{ color: "black", marginRight: "10px" }}>
            Sender:{" "}
          </label>
          <select
            value={sender}
            onChange={(e) => setSender(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">Select Sender</option>
            {AdminLogin ? (
              <option value="Admin">{AdminLogin}</option>
            ) : (
              <option value="CRM Head">{user}</option>
            )}
          </select>
        </div>
        <div style={{ marginBottom: "15px", marginLeft: "20px" }}>
          <label style={{ color: "black", marginRight: "10px" }}>
            Receiver:{" "}
          </label>
          <select
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">Select Receiver</option>
            {receiverOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginBottom: "15px", marginLeft: "20px" }}>
          <label style={{ color: "black", marginRight: "10px" }}>Type: </label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          >
            <option value="">Select Type</option>
            <option value="Approval">Approval</option>
            <option value="Suggestion">Suggestion</option>
          </select>
        </div>
      </div>
      <div style={{ marginBottom: "15px", marginLeft: "20px" }}>
        <label style={{ color: "black", marginRight: "10px" }}>
          Mail Content:{" "}
        </label>
        <textarea
          value={mailContent}
          onChange={(e) => setMailContent(e.target.value)}
          style={{
            padding: "10px",
            width: "100%",
            height: "100px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        />
      </div>
      <div style={{ marginBottom: "15px" }}>
        <label style={{ color: "black", marginRight: "10px" }}>
          Ticket Number:{" "}
        </label>
        <input
          type="text"
          value={ticketNumber}
          readOnly
          style={{
            padding: "5px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
        />
      </div>
      <button
        onClick={handleSubmit}
        style={{
          backgroundColor: "#4CAF50", // Green background
          border: "none", // No border
          color: "white", // White text
          padding: "15px 32px", // Padding
          textAlign: "center", // Centered text
          textDecoration: "none", // No underline
          display: "inline-block", // Display inline-block
          fontSize: "16px", // Font size
          margin: "4px 2px", // Margin
          cursor: "pointer", // Pointer cursor on hover
          borderRadius: "12px", // Rounded corners
        }}
      >
        Submit
      </button>
    </>
  );
}

export default MailBody;
