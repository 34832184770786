import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import axios from "axios";

function TicketSearch() {
  const [ticketNumber, setTicketNumber] = useState("");
  const [ticketData, setTicketData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearch = () => {
    axios
      .get(`http://localhost:3003/api/tickets/search/${ticketNumber}`)
      .then((response) => {
        setTicketData(response.data);
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage("Ticket not found");
        setTicketData(null);
      });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div
        className="d-flex flex-row align-items-center"
        style={{
          border: "1px solid gray",
          width: "320px",
          borderRadius: "8px",
        }}
      >
        <input
          type="text"
          value={ticketNumber}
          style={{
            width: "250px",
            height: "35px",
            borderRadius: "8px",
            border: "none",
            outline: "none",
          }}
          placeholder="Ticket Number"
          onChange={(e) => setTicketNumber(e.target.value)}
        />
        <button
          onClick={handleSearch}
          style={{ marginLeft: "auto", marginRight: "4px" }}
        >
          <FaSearch />
        </button>
      </div>
      {errorMessage && <p>{errorMessage}</p>}
      {ticketData &&
        ticketData.map((reply) => (
          <div d-flex flex-column>
            <h4 style={{ color: "black", marginTop: "25px" }}>
              Ticket Number: {reply.ticket_number}
            </h4>
            <ul style={{ color: "black" }}>
              <div
                key={reply.timestamp}
                style={{ marginBottom: "10px", display: "flex" }}
              >
                <li style={{ marginRight: "20px" }}>
                  <span style={{ fontWeight: "bold" }}>Mail:</span>{" "}
                  {reply.mail_content}
                </li>
                <li style={{ marginRight: "20px" }}>
                  <span style={{ fontWeight: "bold" }}>Reply:</span>{" "}
                  {reply.reply_content}
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
                  {reply.status}
                </li>
              </div>
            </ul>
          </div>
        ))}
    </div>
  );
}

export default TicketSearch;




// import React, { useState } from 'react'
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import { FaCheck, FaEdit, FaEye, FaMobileAlt, FaPhone, FaTrash, FaUserCircle, FaWindowClose } from 'react-icons/fa';
// import { Box, Button, Menu, MenuItem, TableBody, Typography } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Fade from '@mui/material/Fade';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col';
// import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import Pagination from '@mui/material/Pagination';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import Tab from '@mui/material/Tab';
// import TabPanel from '@mui/lab/TabPanel';
// import FilterListIcon from '@mui/icons-material/FilterList';

// function Manger() {

//   const [isSelected, setIsSelected] = useState(false);
//   const [allocation, setAllocation] = useState()
//   const [id, setId] = useState()
//   const [isVeiw, setIsVeiw] = useState(false)
//   const [dob, setDob] = useState("");
//   const [age, setAge] = useState('');
//   const [mobile, setMobile] = useState()
//   const [address, setAddress] = useState()
//   const [area, setArea] = useState()
//   const [pinCode, setPinCode] = useState()
//   const [empType, setEmpType] = useState('Business')
//   const [sourceType, setSourceType] = useState('Website')
//   const [leadStatus, setLeadStatus] = useState('New')
//   const [Intrest, setIntrest] = useState('')
//   const [budget, setBudget] = useState()
//   const [financeType, setFinanceType] = useState('Own')
//   const [sitevisit, setSiteVisit] = useState('');
//   const [later, setLater] = useState('');
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open2 = Boolean(anchorEl);

//   const handleClick = (event) => {
//     setAnchorEl(event);
//   };

//   const handleClose2 = () => {
//     setAnchorEl(null);
//   };
//   const [open, setOpen] = useState(false)

//   const handleClose = () => {
//     setOpen(false);
//     setValidated(false)
//   };
//   const [Validated, setValidated] = useState(false);

//   const [open1, setOpen1] = useState(false)

//   const formValidation = (event) => {
//     event.preventDefault()
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       add_primary()
//     }

//     setValidated(true);
//   };

//   const [al, setAl] = useState([])

//   const leadDataGet = () => {
//     axios.post('https://buildfastapi.emedha.in/api/v1/getsaleexc').then((res) => {
//       if (res.data.status === 200) {
//         setAl(res.data.data)
//         console.log(res.data.data);
//       }
//     })
//   }

//   React.useEffect(() => {
//     leadDataGet()
//     getlm()
//     customerObjsi()
//     customerObjsib()
//     customerObj()
//   }, [])

//   const [data, setData] = useState([])

//   const getlm = () => {
//     axios.get('https://buildfastapi.emedha.in/api/v1/lead-data').then((res) => {

//       setData(res.data.data)
//       console.log(res.data.data);

//     })
//   }

//   const handlesub = () => {
//     axios.post('https://buildfastapi.emedha.in/api/v1/updateSalesExecutive', { salesExecutive: salesexct, rowsToUpdate }).then((res) => {
//       handleClose()
//       // setviewData(id)
//       setOpen(false)
//       getlm()
//       window.location.reload()
//       Swal.fire({
//         icon: 'success',
//         title: "Successfull !",
//         text: " Data added successfully !",
//         confirmButtonColor: "rgb(126, 110, 228)",
//         cancelButtonColor: "#d33",
//         showCancelButton: false,
//         confirmButtonText: "Ok"
//       }).then((result) => {
//         if (result.value) {
//           return
//         }
//       })

//     })
//   }

//   const handleCheckboxChange = (event, itemId) => {
//     if (event.target.checked) {

//       setRowsToUpdate(prevRows => [...prevRows, itemId]);

//     } else {

//       setRowsToUpdate(prevRows => prevRows.filter(id => id !== itemId));

//     }
//   };

//   const [rowsToUpdate, setRowsToUpdate] = useState([])
//   const [salesexct, setSalexct] = useState('')

//   const leadDataSearch = (text) => {
//     axios.post('https://buildfastapi.emedha.in/api/v1/search-lead', {
//       text
//     }).then((res) => {

//       setData(res.data)

//     })
//   }

//   const [page, setPage] = useState(5)
//   const [cpage, setCpage] = useState(1)

//   const pagehandle = (e, val) => {
//     setCpage(val);
//   }

//   const indexOflpage = cpage * page;
//   const indexOffpage = indexOflpage - page;
//   const currentpost = data.slice(indexOffpage, indexOflpage)

//   const [value, setValue] = React.useState('1');

//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };

//     const [customerRessi, setCustomerResponsesi] = React.useState([])

//     const customerObjsi = () => {
//         axios.get('https://buildfastapi.emedha.in/create-customer/api/v1/getsitform').then((res) => {
//             setCustomerResponsesi(res.data)
//         })
//     }

//     const [customerRessib, setCustomerResponsesib] = React.useState([])

//         const customerObjsib = () => {
//             axios.get('https://buildfastapi.emedha.in/create-customer/api/v1/getblockform').then((res) => {
//                 setCustomerResponsesib(res.data)
//             })
//         }

//         const [customerRes, setCustomerResponse] = React.useState([])

//         const customerObj = () => {
//             axios.get('https://buildfastapi.emedha.in/create-customer/api/v1/customers').then((res) => {
//                 setCustomerResponse(res.data)
//             })
//         }

//         const [fval, setFval] = React.useState('')
//         const [showOptions, setShowOptions] = useState(false);

//   return (
//     <>
//       <div>
//         {
//           !isVeiw && (
//             <div className='leadManagementTable'>
//               <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 30, marginLeft: 15.5, fontSize: 28, color: 'black', fontWeight: '500', marginTop:10 }}>Manager Dashboard</Typography>
//               <Paper elevation={0} >

//                   <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

//                   <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
//                   <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
//                     <div>

//                       <input onChange={(e) => leadDataSearch(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search...' />
//                     </div>
//                     <div>
//                       <Button onClick={() => setOpen(true)} style={{ width: '10rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white' }}>+ Allocate Leads</Button>
//                     </div>
//                   </div>

//                   <TabContext value={value}>
//                                     <Box sx={{ borderBottom: 1, borderColor: 'divider', color: 'rgb(86, 63, 146)' }} >
//                                         <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary" >
//                                             <Tab label="Lead" value="1" style={{ color: 'rgb(86, 63, 146)' }} />
//                                             <Tab label="Intrested" value="2" style={{ color: 'rgb(86, 63, 146)' }} />
//                                             <Tab label=" Call Back" value="3" style={{ color: 'rgb(86, 63, 146)' }} />
//                                             <Tab label="Not Intrested" value="4" style={{ color: 'rgb(86, 63, 146)' }} />
//                                             <Tab label="Site Visit" value="5" style={{ color: 'rgb(86, 63, 146)' }} />
//                                             <Tab label="Blocked" value="6" style={{ color: 'rgb(86, 63, 146)' }} />
//                                             <Tab label="Booked" value="7" style={{ color: 'rgb(86, 63, 146)' }} />

//                                         </TabList>
//                                     </Box>
//                                     <TabPanel value="1" >

//                 <TableContainer sx={{ height: 95 + "vh", p: 0.3 }} >
//                 <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18,  fontWeight: '500' }}>Total Leads : {data.length}</Typography>

//                   <Table style={{ position: 'relative', top: '1.3rem' }}>
//                     <TableHead sx={{ borderTop: '1px solid lightgray' }}>
//                       <TableRow>

//                         <TableCell
//                           align={"start"}
//                           style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                         >
//                           Name
//                         </TableCell>
//                         <TableCell

//                           align={"left"}
//                           style={{ top: 57, fontWeight: '600', }}
//                         >
//                           Mobile
//                         </TableCell>
//                         <TableCell
//                           align={"start"}
//                           style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                         >
//                           Email
//                         </TableCell>
//                         <TableCell
//                           style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                           align={"start"}
//                           >
//                           Date & Time
//                         </TableCell>
//                         <TableCell align={"start"} style={{  fontSize: 14, fontWeight: '600', position: 'relative' }}>
//                         Sales Executive
//                         <FilterListIcon style={{marginLeft:10, cursor:'pointer'}} onClick={() => setShowOptions(!showOptions)}/>
//                         {showOptions && (
//                               <div style={{ position: 'absolute', top: '100%', right: 0, zIndex: 1, background: 'white', border: '1px solid #ccc', borderRadius: '4px', padding: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', maxHeight: '150px', overflowY: 'auto', cursor:'pointer' }}>

//                                 {al.map((item, index) => (
//                                   <div key={index} style={{ color: fval === item.UserName ? 'grey' : 'inherit' }} onClick={() => {
//                                     setFval(item.UserName)
//                                     setShowOptions(false)
//                                   }}>{item.UserName}</div>
//                                 ))}
//                               </div>
//                             )}
//                       </TableCell>

//                         <TableCell
//                           align={"start"}
//                           style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                         >
//                           Allocation
//                         </TableCell>
//                       </TableRow>

//                     </TableHead>

//                     <TableBody>
//                       {
//                         fval != '' ?
//                         data.filter((item => item.salesExecutive === fval)).map((item => (
//                           <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.name}</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.mobile}</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.email}</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.leadDate }</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.salesExecutive}</TableCell>

//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

//                               <div style={{ display: 'flex' }} >
//                               <input type='checkbox'
//                                 onChange={(e) => handleCheckboxChange(e, item.Id)}
//                               ></input>
//                               </div>
//                             </TableCell>
//                           </TableRow>
//                         )))

//                         :   data.map((item => (
//                           <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.name}</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.mobile}</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.email}</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.leadDate }</TableCell>
//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12 }}>{item.salesExecutive}</TableCell>

//                             <TableCell align={"start"}
//                               style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

//                               <div style={{ display: 'flex' }} >
//                               <input type='checkbox'
//                                 onChange={(e) => handleCheckboxChange(e, item.Id)}
//                               ></input>
//                               </div>
//                             </TableCell>
//                           </TableRow>
//                           )))
//                       }
//                     </TableBody>
//                   </Table>

//                   {
//                     data.length === 0 && (
//                       <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
//                         <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
//                         <Typography>0 customers</Typography>
//                       </Box>

//                     )
//                   }
//                 </TableContainer>
//                 </TabPanel>

//                 <TabPanel value="2" >

//                                       <TableContainer sx={{ height: 95 + "vh", p: 0.3 }} >
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Intrested</Typography>
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18,  fontWeight: '500' }}>Total Intrested Leads : {data.filter((item => item.intrestTab === 1)).length}</Typography>

//                                         <Table style={{ position: 'relative', top: '1.3rem' }}>
//                                           <TableHead sx={{ borderTop: '1px solid lightgray' }}>
//                                             <TableRow>

//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                               >
//                                                 Name
//                                               </TableCell>
//                                               <TableCell

//                                                 align={"left"}
//                                                 style={{ top: 57, fontWeight: '600', }}
//                                               >
//                                                 Mobile
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                               >
//                                                 Email
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Date & Time
//                                               </TableCell>

//                                               <TableCell align={"start"} style={{  fontSize: 14, fontWeight: '600', position: 'relative' }}>
//                                                 Sales Executive
//                                                 <FilterListIcon style={{marginLeft:10, cursor:'pointer'}} onClick={() => setShowOptions(!showOptions)}/>
//                                                 {showOptions && (
//                                                       <div style={{ position: 'absolute', top: '100%', right: 0, zIndex: 1, background: 'white', border: '1px solid #ccc', borderRadius: '4px', padding: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', maxHeight: '150px', overflowY: 'auto', cursor:'pointer' }}>

//                                                         {al.map((item, index) => (
//                                                           <div key={index} style={{ color: fval === item.UserName ? 'grey' : 'inherit' }} onClick={() => {
//                                                             setFval(item.UserName)
//                                                             setShowOptions(false)
//                                                           }}>{item.UserName}</div>
//                                                         ))}
//                                                       </div>
//                                                     )}
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Comments
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Allocation
//                                               </TableCell>
//                                             </TableRow>
//                                           </TableHead>
//                                           <TableBody>
//                                             {
//                                               data.filter((fval === '' ? (item => item.intrestTab === 1 ) : (item => item.intrestTab === 1 && item.salesExecutive === fval)))
//                                               .sort((a, b) => new Date(b.intrestDate) - new Date(a.intrestDate)).map((item => (
//                                                 <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.name}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.mobile}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.email}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.intrestDate}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.salesExecutive}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.intrestComment}</TableCell>

//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

//                                                     <div style={{ display: 'flex' }} >
//                                                     <input type='checkbox'
//                                                       onChange={(e) => handleCheckboxChange(e, item.Id)}
//                                                     ></input>
//                                                     </div>
//                                                   </TableCell>
//                                                 </TableRow>
//                                               )))
//                                             }
//                                           </TableBody>
//                                         </Table>

//                                         {
//                                           data.length === 0 && (
//                                             <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
//                                               <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
//                                               <Typography>0 customers</Typography>
//                                             </Box>

//                                           )
//                                         }
//                                       </TableContainer>
//                                       </TabPanel>

//                                       <TabPanel value="3" >

//                                       <TableContainer sx={{ height: 95 + "vh", p: 0.3}} >
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Call Back</Typography>
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18,  fontWeight: '500' }}>Total CallBack Leads : {data.filter((item => item.callBackTab === 1)).length}</Typography>

//                                         <Table style={{ position: 'relative', top: '1.3rem' }}>
//                                           <TableHead sx={{ borderTop: '1px solid lightgray' }}>
//                                             <TableRow>

//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                               >
//                                                 Name
//                                               </TableCell>
//                                               <TableCell

//                                                 align={"left"}
//                                                 style={{ top: 57, fontWeight: '600', }}
//                                               >
//                                                 Mobile
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                               >
//                                                 Email
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Date & Time
//                                               </TableCell>

//                                               <TableCell align={"start"} style={{  fontSize: 14, fontWeight: '600', position: 'relative' }}>
//                                                 Sales Executive
//                                                 <FilterListIcon style={{marginLeft:10, cursor:'pointer'}} onClick={() => setShowOptions(!showOptions)}/>
//                                                 {showOptions && (
//                                                       <div style={{ position: 'absolute', top: '100%', right: 0, zIndex: 1, background: 'white', border: '1px solid #ccc', borderRadius: '4px', padding: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', maxHeight: '150px', overflowY: 'auto', cursor:'pointer' }}>

//                                                         {al.map((item, index) => (
//                                                           <div key={index} style={{ color: fval === item.UserName ? 'grey' : 'inherit' }} onClick={() => {
//                                                             setFval(item.UserName)
//                                                             setShowOptions(false)
//                                                           }}>{item.UserName}</div>
//                                                         ))}
//                                                       </div>
//                                                     )}
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Comments
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Allocation
//                                               </TableCell>
//                                             </TableRow>
//                                           </TableHead>
//                                           <TableBody>
//                                             {
//                                               data
//                                               .filter((fval === '' ? (item => item.callBackTab === 1) : (item => item.callBackTab === 1 && item.salesExecutive === fval)))
//                                               .sort((a, b) => new Date(b.cbDate) - new Date(a.cbDate))
//                                               .map((item => (
//                                                 <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.name}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.mobile}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.email}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.cbDate}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.salesExecutive}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.cbComment}</TableCell>

//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>

//                                                     <div style={{ display: 'flex' }} >
//                                                     <input type='checkbox'
//                                                       onChange={(e) => handleCheckboxChange(e, item.Id)}
//                                                     ></input>
//                                                     </div>
//                                                   </TableCell>
//                                                 </TableRow>
//                                               )))
//                                             }
//                                           </TableBody>
//                                         </Table>
//                                         {
//                                           data.length === 0 && (
//                                             <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
//                                               <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
//                                               <Typography>0 customers</Typography>
//                                             </Box>

//                                           )
//                                         }
//                                       </TableContainer>
//                                       </TabPanel>

//                                       <TabPanel value="4" >

//                                       <TableContainer sx={{ height: 95 + "vh", p: 0.3}} >
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Not intrested</Typography>
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18,  fontWeight: '500' }}>Total Not Intrested Leads : {data.filter((item => item.notIntrstTab === 1)).length}</Typography>

//                                         <Table style={{ position: 'relative', top: '1.3rem' }}>
//                                           <TableHead sx={{ borderTop: '1px solid lightgray' }}>
//                                             <TableRow>

//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                               >
//                                                 Name
//                                               </TableCell>
//                                               <TableCell

//                                                 align={"left"}
//                                                 style={{ top: 57, fontWeight: '600', }}
//                                               >
//                                                 Mobile
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                               >
//                                                 Email
//                                               </TableCell>

//                                               <TableCell align={"start"} style={{  fontSize: 14, fontWeight: '600', position: 'relative' }}>
//                                                 Sales Executive
//                                                 <FilterListIcon style={{marginLeft:10, cursor:'pointer'}} onClick={() => setShowOptions(!showOptions)}/>
//                                                 {showOptions && (
//                                                       <div style={{ position: 'absolute', top: '100%', right: 0, zIndex: 1, background: 'white', border: '1px solid #ccc', borderRadius: '4px', padding: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', maxHeight: '150px', overflowY: 'auto', cursor:'pointer' }}>

//                                                         {al.map((item, index) => (
//                                                           <div key={index} style={{ color: fval === item.UserName ? 'grey' : 'inherit' }} onClick={() => {
//                                                             setFval(item.UserName)
//                                                             setShowOptions(false)
//                                                           }}>{item.UserName}</div>
//                                                         ))}
//                                                       </div>
//                                                     )}
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Comments
//                                               </TableCell>
//                                               <TableCell
//                                                 align={"start"}
//                                                 style={{ minWidth: 0, fontSize: 14, fontWeight: '600' }}
//                                               >
//                                                 Allocation
//                                               </TableCell>
//                                             </TableRow>
//                                           </TableHead>
//                                           <TableBody>
//                                             {
//                                               data
//                                               .filter((fval === '' ? (item => item.notIntrstTab === 1 ) : (item => item.notIntrstTab === 1 && item.salesExecutive === fval)))
//                                               .map((item => (
//                                                 <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.name}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.mobile}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.email}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.salesExecutive}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12 }}>{item.notintrstComment}</TableCell>
//                                                   <TableCell align={"start"}
//                                                     style={{ top: 57, fontSize: 12, color: "rgb(126, 110, 228) " }}>
//                                                     <div style={{ display: 'flex' }} >
//                                                     <input type='checkbox'
//                                                       onChange={(e) => handleCheckboxChange(e, item.Id)}
//                                                     ></input>
//                                                     </div>
//                                                   </TableCell>
//                                                 </TableRow>
//                                               )))
//                                             }
//                                           </TableBody>
//                                         </Table>

//                                         {
//                                           data.length === 0 && (
//                                             <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
//                                               <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
//                                               <Typography>0 customers</Typography>
//                                             </Box>

//                                           )
//                                         }
//                                       </TableContainer>
//                                       </TabPanel>
//                                       <TabPanel value="5" >

//                                       <div style={{ width: "58rem" }} >
//                                         <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Site Visit</Typography>
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18,  fontWeight: '500' }}>Total Site Visit : {customerRessi.length}</Typography>

//                                             <Table style={{ position: 'relative', top: '1.3rem' }}>
//                                                 <TableHead sx={{ borderTop: '1px solid lightgray' }}>
//                                                 <TableRow>

//                                                     <TableCell
//                                                         align={"start"}
//                                                         style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                                     >
//                                                         Name
//                                                     </TableCell>
//                                                     <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 57, fontWeight: '600', }}
//                                                     >
//                                                         Mobile
//                                                     </TableCell>

//                                                     <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 21, fontWeight: '600', }}
//                                                     >
//                                                         Occupation
//                                                     </TableCell>

//                                                     <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 21, fontWeight: '600', }}
//                                                     >
//                                                         Budget
//                                                     </TableCell>

//                                                     </TableRow>
//                                                 </TableHead>
//                                                 <TableBody>
//                                                     {
//                                                          customerRessi && customerRessi
//                                                             .map((item => (
//                                                                 <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

//                                                                     <TableCell align={"start"}
//                                                                         style={{ top: 57, fontSize: 12 }}>{item.name}
//                                                                     </TableCell>
//                                                                     <TableCell align={"start"}
//                                                             style={{ top: 57, fontSize: 12 }}>
//                                                             <button style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', cursor: 'auto' }}><FaMobileAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{item.mobile}</button>
//                                                         </TableCell>
//                                                                     <TableCell align={"start"}
//                                                                         style={{ top: 57, fontSize: 12 }}>{item.occupation}</TableCell>
//                                                                     <TableCell align={"start"}
//                                                                         style={{ top: 57, fontSize: 12, width: 100 }}>{item.budget}</TableCell>

//                                                                 </TableRow>
//                                                             )))
//                                                     }

//                                                 </TableBody>
//                                             </Table>

//                                         </div>
//                                       </TabPanel>

//                                       <TabPanel value="6" >

//                                       <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

//                                       <div style={{ width: "58rem" }} >
//                                         <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Blocked</Typography>
//                                         <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18,  fontWeight: '500' }}>Total Blocked : {customerRessib.length}</Typography>

//                                             <Table style={{ position: 'relative', top: '1.3rem' }}>
//                                                 <TableHead sx={{ borderTop: '1px solid lightgray' }}>
//                                                 <TableRow>

//                                                     <TableCell
//                                                         align={"start"}
//                                                         style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                                     >
//                                                         Name
//                                                     </TableCell>
//                                                     <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 57, fontWeight: '600', }}
//                                                     >
//                                                         Mobile
//                                                     </TableCell>

//                                                     <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 21, fontWeight: '600', }}
//                                                     >
//                                                         Aadhar
//                                                     </TableCell>

//                                                     <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 21, fontWeight: '600', }}
//                                                     >
//                                                         Address
//                                                     </TableCell>

//                                                     </TableRow>
//                                                 </TableHead>
//                                                 <TableBody>
//                                                     {
//                                                          customerRessib && customerRessib
//                                                             .map((item => (
//                                                                 <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

//                                                         <TableCell align={"start"}
//                                                             style={{ top: 57, fontSize: 12 }}>{item.name}</TableCell>
//                                                         <TableCell align={"start"}
//                                                             style={{ top: 57, fontSize: 12 }}>
//                                                             <button style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', cursor: 'auto' }}><FaMobileAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{item.mobile}</button>
//                                                         </TableCell>

//                                                         <TableCell align={"start"}
//                                                             style={{ top: 57, fontSize: 12 }}>{item.aadharNo}</TableCell>

//                                                         <TableCell align={"start"}
//                                                             style={{ top: 57, fontSize: 12 }}>{item.permanentAddress}</TableCell>

//                                                                 </TableRow>
//                                                             )))
//                                                     }

//                                                 </TableBody>
//                                             </Table>

//                                         </div>

//                                         {
//                                           data.length === 0 && (
//                                             <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
//                                               <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
//                                               <Typography>0 customers</Typography>
//                                             </Box>

//                                           )
//                                         }
//                                       </TableContainer>
//                                       </TabPanel>

//                                       <TabPanel value="7" >

//                                       <TableContainer sx={{ height: 95 + "vh", p: 0.3, pt: 2 }} >

//                                       <div style={{ width: "58rem" }} >
//                                         <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500',textAlign:'center' }}>Booked</Typography>
//                                       <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18,  fontWeight: '500' }}>Total Booked : {customerRes.length}</Typography>

//                                             <Table style={{ position: 'relative', top: '1.3rem' }}>
//                                                 <TableHead sx={{ borderTop: '1px solid lightgray' }}>
//                                                 <TableRow>

//                                                         <TableCell
//                                                         align={"start"}
//                                                         style={{ minWidth: 0, fontSize: 14, fontWeight: '600', }}
//                                                         >
//                                                         Name
//                                                         </TableCell>
//                                                         <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 57, fontWeight: '600', }}
//                                                         >
//                                                         Mobile
//                                                         </TableCell>

//                                                         <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 21, fontWeight: '600', }}
//                                                         >
//                                                         Aadhar
//                                                         </TableCell>
//                                                         <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 21, fontWeight: '600', }}
//                                                         >
//                                                         Pan
//                                                         </TableCell>

//                                                         <TableCell

//                                                         align={"left"}
//                                                         style={{ top: 21, fontWeight: '600', }}
//                                                         >
//                                                         Address
//                                                         </TableCell>

//                                                         </TableRow>
//                                                 </TableHead>
//                                                 <TableBody>
//                                                     {
//                                                          customerRes && customerRes
//                                                             .map((item => (
//                                                                 <TableRow hover role="checkbox" tabIndex={-1} key={item.Id}>

//                                                                         <TableCell align={"start"}
//                                                                     style={{ top: 57, fontSize: 12 }}>{item.customerName}</TableCell>
//                                                                     <TableCell align={"start"}
//                                                                     style={{ top: 57, fontSize: 12 }}>
//                                                                     <button style={{ fontSize: 10.7, border: '2px solid rgb(126, 110, 228)', backgroundColor: 'white', borderRadius: '1.2rem', cursor: 'auto' }}><FaMobileAlt style={{ marginRight: 3, color: 'rgb(126, 110, 228)', marginTop: '-0.16rem', fontSize: 9 }} />{item.mobileNumber}</button>
//                                                                     </TableCell>

//                                                                     <TableCell align={"start"}
//                                                                     style={{ top: 57, fontSize: 12 }}>{item.aadharNo}</TableCell>
//                                                                     <TableCell align={"start"}
//                                                                     style={{ top: 57, fontSize: 12 }}>{item.panNo}</TableCell>
//                                                                     <TableCell align={"start"}
//                                                                     style={{ top: 57, fontSize: 12 }}>{item.addres}</TableCell>
//                                                                 </TableRow>
//                                                             )))
//                                                     }

//                                                 </TableBody>
//                                             </Table>

//                                         </div>

//                                         {
//                                           data.length === 0 && (
//                                             <Box className='card' style={{ border: 'none', height: '58vh', width: '100%', boxShadow: 'none', justifyContent: 'center', alignItems: 'center' }} >
//                                               <img src='https://cdn-icons-png.flaticon.com/128/2291/2291944.png' width='145px' />
//                                               <Typography>0 customers</Typography>
//                                             </Box>

//                                           )
//                                         }
//                                       </TableContainer>
//                                       </TabPanel>
//                 </TabContext>
//               </Paper>
//               {/* <div style={{ display: 'flex', justifyContent: 'flex-end', height: 56, marginTop: 16 }}>
//                 <Pagination count={Math.ceil(data.length / page)} page={cpage} onChange={pagehandle} color="secondary" sx={{ "& .Mui-selected": { backgroundColor: "blue" } }} />
//               </div> */}
//             </div>
//           )
//         }

//         <div>
//           <Dialog
//             style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
//             maxWidth='xl'
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="responsive-dialog-title"
//           >

//             <DialogContent>
//               <div style={{ width: "37rem" }} >
//                 <Form noValidate validated={Validated} onSubmit={formValidation} >
//                   <div className='mb-4 mt-1'>
//                     <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Add Lead</Typography>
//                   </div>
//                   <Row className="mb-3">

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: 'gray', fontSize: 13 }}>Sales Executive</Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setSalexct(e.target.value)}
//                         type="text"

//                         style={{ padding: '0.42rem', marginBottom: 14 }}

//                       >

//                         <option>Sales Executive</option>
//                         {
//                           al.map((item => (
//                             <option>{item.UserName}</option>
//                           )))
//                         }
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Sales Executive
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                   </Row>
//                   <div className='d-flex'>
//                     <div>
//                       <Button onClick={() => handlesub()} type='button' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
//                     </div>
//                     <div>
//                       <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div>
//         <div>
//           <Dialog
//             style={{ borderTop: '12px solid rgb(126, 110, 228)' }}
//             maxWidth='xl'
//             open={open1}
//             onClose={() => setOpen1(!open1)}
//             aria-labelledby="responsive-dialog-title"
//           >

//             <DialogContent>
//               <div style={{ width: "37rem" }} >
//                 <Form noValidate validated={Validated} onSubmit={formValidation} >
//                   <div className='mb-4 mt-1'>
//                     <Typography style={{ textAlign: 'center', fontWeight: '500', color: 'rgb(126, 110, 228)', fontSize: 21 }}>Update Lead</Typography>
//                   </div>
//                   <Row className="mb-3">

//                     <Form.Group as={Col} md="6" controlId="validationCustom01">
//                       <Typography style={{ color: 'gray', fontSize: 13 }}>Sales Executive</Typography>
//                       <Form.Select
//                         required
//                         onChange={(e) => setSalexct(e.target.value)}
//                         type="text"

//                         value={salesexct}

//                         style={{ padding: '0.42rem', marginBottom: 14 }}

//                       >

//                         <option>Sales Executive</option>
//                         {
//                           al.map((item => (
//                             <option>{item.UserName}</option>
//                           )))
//                         }
//                       </Form.Select>
//                       <Form.Control.Feedback type="invalid">
//                         Please Enter Valid Sales Executive
//                       </Form.Control.Feedback>
//                     </Form.Group>

//                   </Row>
//                   <div className='d-flex'>
//                     <div>
//                       <Button onClick={() => handlesub()} type='button' style={{ width: '9rem', height: '2.3rem', backgroundColor: 'rgb(126, 110, 228)', color: 'white', marginRight: 3 }}>Update</Button>
//                     </div>
//                     <div>
//                       <Button onClick={() => setOpen(false)} style={{ width: '9rem', height: '2.3rem', backgroundColor: 'gray', color: 'white' }}>Cancel</Button>
//                     </div>
//                   </div>
//                 </Form>
//               </div>
//             </DialogContent>
//           </Dialog>
//         </div>

//       </div>
//     </>
//   )
// }

// export default Manger;
