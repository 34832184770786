import React,{useState} from 'react'
import { BsFillArchiveFill } from 'react-icons/bs'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { Box, Button, Menu, MenuItem, TableBody, Typography, useScrollTrigger } from '@mui/material';
import { Navbar, Container, Nav, NavDropdown,Dropdown } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FaEdit, FaMapMarkerAlt, FaMarker, FaUserCircle, FaTrash, FaCheck, FaEye, FaPhone } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import axios from 'axios';


function Sales() {

    const [id, setId] = React.useState();
    // const [date, setDate] = React.useState()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event);
    };
    const handleClose2 = () => {
        setAnchorEl(null);
    };
    const text = sessionStorage.getItem('user_name')
    const [data, setData] = React.useState([])

    const[tcont, setTcount] = React.useState('')

    React.useEffect(() => {
       axios.post('https://buildfastapi.emedha.in/api/v1/salesexleadname',{
        text
       })
       .then(res => {
        console.log(res.data);
        setData(res.data)

       })

       axios.get('https://buildfastapi.emedha.in/api/v1/leadcount',{
        text
       })
       .then(res => {
        console.log(res.data);
        setTcount(res.data)
       
       })
    },[])


    const [selectedAction, setSelectedAction] = useState(null);

    const handleDropdownSelect = (eventKey) => {
      setSelectedAction(eventKey);
    };
  
    
     
   

      const[canDelete, setCanDelete] = React.useState(true)

    return (
        <>
        <sidebar>
            
        </sidebar>
        <main>
            <div>
                <h1 style={{ color: "black" }}>Sales Executive Dashboard</h1>
            </div>
            <div className='main-cards'>
                <div className='card-box'>
                    <div className='card-inner'>
                        <h3>No.Of Leads</h3>
                        <BsFillArchiveFill className="card-icon" />
                        <h1>{tcont.totalRecords}</h1>
                    </div>
                </div>
                <div className='card-box'>
                    <div className='card-inner'>
                        <h3>No.Of Leads Closed </h3>
                        <BsFillArchiveFill className="card-icon" />
                        <h1>663</h1>

                    </div>
                </div>
            </div>
            <Typography style={{ color: 'rgba(41, 16, 105, 1)', marginBottom: 3, marginLeft: 15.5, fontSize: 18, color: 'gray', fontWeight: '500' }}>Search Filter</Typography>

            <div style={{ height: '0.1rem', borderBottom: '0.2px solid lightgray' }} ></div>
            <div className='card mt-2' style={{ border: 'none', paddingLeft: 15, paddingRight: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                <div>

                    <input onChange={(e) => searchCommon(e.target.value)} style={{ height: '2.3rem', color: 'gray', borderRadius: '0.3rem', outline: 'none', width: '26rem', border: '0.7px solid lightgray' }} placeholder='Search user..' />
                </div>

            </div>
            

           
        </main>
        </>
    )
}

export default Sales;
